import {Component, Input, Output, EventEmitter, OnInit, ViewChild, ElementRef, Renderer2} from '@angular/core';
import {GeneralHelperService} from '../../services/helpers/general-helper.service';

@Component({
    selector: 'app-modal',
    templateUrl: './modal.component.html'
})
export class ModalComponent implements OnInit {
    @Input() classess = 'max-w-sm';
    @Output() modalClosed = new EventEmitter();
    opened = false;
    @ViewChild('modal') modal: ElementRef | undefined;

    constructor(private renderer: Renderer2,
                private generalHelperService: GeneralHelperService) {
    }

    ngOnInit(): void {
        this.opened = true;
        setTimeout(() => {
            this.listenToClickType();
        });
    }

    listenToClickType(): void {
        this.renderer.listen('window', 'click', (e: Event) => {
            if (this.generalHelperService.checkIfClickedOutsideOfTheElement(undefined, this.modal, e)) {
                if (this.opened) {
                    this.modalClosed.emit(true);
                    this.opened = false;
                }
            }
        });
    }

    closeModal(): void {
        this.modalClosed.emit(true);
        this.opened = false;
    }
}
