import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {Subscription} from 'rxjs';
import {ToasterHelperService} from 'src/app/services/helpers/toaster-helper.service';
import {AuthHttpService} from 'src/app/services/http-services/auth-http.service';
import {PAGES_AND_PATHS} from '../../constants/pages-and-paths';

@Component({
    selector: 'app-reset-password',
    templateUrl: './reset-password.component.html'
})
export class ResetPasswordComponent implements OnInit {
    resetPasswordForm!: FormGroup;
    routeSubscription = new Subscription();
    resetToken: string | undefined;
    resetEmail: string | undefined;
    isPasswordLenghtValid = true;
    isPasswordValid = true;

    constructor(
        private fb: FormBuilder,
        private authHttpService: AuthHttpService,
        private route: ActivatedRoute,
        private router: Router,
        private toasterService: ToasterHelperService
    ) {
    }

    ngOnInit(): void {
        this.getParams();
    }

    getParams(): void {
        this.routeSubscription = this.route.paramMap.subscribe(params => {
            this.resetToken = String(params.get('token'));
            this.resetEmail = String(params.get('email'));
            this.buildForm();
        });
    }

    buildForm(): any {
        this.resetPasswordForm = this.fb.group({
            email: [this.resetEmail, Validators.required],
            token: '',
            password: ['', Validators.required],
            password_confirmation: ['', Validators.required]
        });
        this.resetPasswordForm.controls.email.disable();
    }

    onSubmit(): void {
        this.resetPasswordForm.controls.token.patchValue(this.resetToken);
        if (this.resetPasswordForm.controls.password.value.length < 8 && this.resetPasswordForm.controls.password_confirmation.value.length < 8) {
            this.isPasswordLenghtValid = false;
        } else if (this.resetPasswordForm.controls.password.value !== this.resetPasswordForm.controls.password_confirmation.value) {
            this.isPasswordValid = false;
        } else {
            this.resetPasswordForm.value.email = this.resetEmail;
            console.log('Form: ', this.resetPasswordForm.value)
            this.authHttpService.resetPassword(this.resetPasswordForm.value).subscribe((response: any) => {
                this.router.navigateByUrl('/login');
                this.toasterService.showSuccess(response.message);
            }, (error: any) => {
                this.toasterService.showSuccess(error);
            });
        }
    }
}
