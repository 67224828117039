import {Component, ElementRef, EventEmitter, OnInit, Output, Renderer2, ViewChild} from '@angular/core';
import {SlideInOutAnimation} from '../../../animations/slide-modal-animation';
import {GeneralHelperService} from '../../../services/helpers/general-helper.service';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {UseCasesHttpService} from '../../../services/http-services/use-cases-http.service';
import {logger} from "codelyzer/util/logger";

@Component({
    selector: 'app-create-label-modal',
    templateUrl: './create-label-modal.component.html',
    animations: [SlideInOutAnimation]
})
export class CreateLabelModalComponent implements OnInit {
    @Output() modalClosed = new EventEmitter<boolean>();
    opened = false;
    @ViewChild('modal') modal: ElementRef | undefined;

    labelForm = new FormGroup({
        label: new FormControl({value: '', disabled: false}, Validators.required),
        category: new FormControl({value: '', disabled: false}),
    });

    errors: any = {};

    creatingAlias = false;

    createdAliases: Array<string> = [];
    @Output() createLabel = new EventEmitter<boolean>();

    constructor(private renderer: Renderer2,
                private useCaseHttp: UseCasesHttpService,
                private generalHelperService: GeneralHelperService) {
    }

    ngOnInit(): void {
        this.opened = true;

        setTimeout(() => {
            this.listenToClickType();
        });
    }

    listenToClickType(): void {
        this.renderer.listen('window', 'click', (e: Event) => {
            if (this.generalHelperService.checkIfClickedOutsideOfTheElement(undefined, this.modal, e)) {}
        });
    }

    updateModal(): void {
        this.useCaseHttp.storeLabel(
            {
                label: this.labelForm.controls.label.value,
                category: this.labelForm.controls.category.value,
                create_aliases: this.createdAliases,
            },
        ).then(r => this.createLabel.emit(true))
        .catch(e => this.errors = e.error.errors);
    }

    createdAlias(): void {
        this.creatingAlias = true;
    }

    createValue(createAlias: string): void {
        this.createdAliases.push(createAlias);

        this.creatingAlias = false;
    }

    deleteCreatedAlias(alias: string): void {
        if (window.confirm('Are you sure?')) {
            this.createdAliases = this.createdAliases.filter(x => x !== alias);
        }
    }
}
