<app-source-cloud-form *ngIf="ppgFormGroup && addSourceCloud?.activeStep === addSourceCloud?.possibleSteps?.secondStep"
                       [addSourceClass]="addSourceCloud"
                       [ppgFormGroup]="ppgFormGroup"
                       (cloudIntegrationEdited)="onCloudIntegrationEdited($event)"
                       (modalClosed)="modalClosed.emit(true)">
</app-source-cloud-form>
<app-add-source-cloud-third-step *ngIf="addSourceCloud?.activeStep === addSourceCloud?.possibleSteps?.thirdStep"
                                 [addSourceClass]="addSourceCloud"
                                 [cloudIntegrations]="cloudIntegrations"
                                 [cloudRegions]="cloudRegions"
                                 (stepChanged)="onStepChanged($event.cloudIntegration, $event.step)"
                                 (sourceAdded)="sourceAdded.emit($event)"
                                 [cloudFrequencies]="cloudFrequencies"
                                 (modalClosed)="modalClosed.emit(true)">
</app-add-source-cloud-third-step>
