import { Component, OnInit } from '@angular/core';
import { ToasterHelperService } from 'src/app/services/helpers/toaster-helper.service';
import { AuthHttpService } from 'src/app/services/http-services/auth-http.service';
import { LocalStorageProperties, Users } from '../../../../enums/general-enums';
import { PpgPagination } from '../../../../interfaces/general-interfaces';
import { GeneralHelperService } from '../../../../services/helpers/general-helper.service';
import { GeneralHttpHelperService } from '../../../../services/http-services/general-http-helper.service';

@Component({
    selector: 'app-members',
    templateUrl: './members.component.html'
})
export class MembersComponent implements OnInit {
    isNewForm = false;
    isActionsForm = false;
    listOfCompanyUsers: any[] = [];
    listOfCompanyUsersInitial: any[] = [];
    userToUpdate: any;
    loggedUser = localStorage.getItem(LocalStorageProperties.userRole);
    Users = Users;
    ppgPagination: PpgPagination | undefined;

    constructor(
        private authService: AuthHttpService,
        private toasterService: ToasterHelperService,
        private generalHttpHelperService: GeneralHttpHelperService
    ) {
    }

    ngOnInit(): void {
        this.getData();
    }

    getData(): void {
        if (this.loggedUser === Users.superAdmin) {
            this.getAllCompanyAdmins();
        } else if (this.loggedUser === Users.companyAdmin) {
            this.getAllCompanyMembers();
        }
    }

    showMembers(data: Array<any>): boolean {
        return data.length !== 0;
    }

    // COMPANY ADMIN
    // all registered and deleted members
    getAllCompanyMembers(nextPage: number | null = null): void {
        this.authService.getAllCompanyMembers(nextPage).subscribe((response: any) => {
            this.listOfCompanyUsers = response.entity.data;
            if (!nextPage) {
                this.listOfCompanyUsersInitial = response.entity.data;
            }
            this.ppgPagination = this.generalHttpHelperService.returnPagination(response.entity);
        }, (error: any) => {
            this.toasterService.showError(error);
        });
    }

    // SUPER ADMIN
    // all registered and deleted admins
    getAllCompanyAdmins(nextPage: number | null = null): void {
        this.authService.getAllCompanyAdmins(nextPage).subscribe((response: any) => {
            this.listOfCompanyUsers = response.entity.data;
            if (!nextPage) {
                this.listOfCompanyUsersInitial = response.entity.data;
            }
            this.ppgPagination = this.generalHttpHelperService.returnPagination(response.entity);
        }, (error: any) => {
            this.toasterService.showError(error);
        });
    }

    onPageChanged(nextPage: number): void {
        if (this.loggedUser === Users.superAdmin) {
            this.getAllCompanyAdmins(nextPage);
        } else if (this.loggedUser === Users.companyAdmin) {
            this.getAllCompanyMembers(nextPage);
        }
    }

    toggleForm(): void {
        this.isNewForm = !this.isNewForm;
    }

    // shared functions
    createNewUser(formData: any): void {
        if (this.loggedUser === Users.superAdmin) {
            this.authService.inviteNewCompanyAdmin(formData).subscribe((response: any) => {
                this.toasterService.showSuccess('Company admin invited!');
                this.isNewForm = false;
            }, (error: any) => {
                this.toasterService.showError(error);
            });
        } else if (this.loggedUser === Users.companyAdmin) {
            this.authService.inviteNewCompanyMember(formData).subscribe((response: any) => {
                this.toasterService.showSuccess('Company member invited!');
                this.isNewForm = false;
            }, (error: any) => {
                this.toasterService.showError(error);
            });
        }
    }

    callToAction(id: any): void {
        this.userToUpdate = id;
        this.isActionsForm = !this.isActionsForm;
    }

    refreshManageComponent(): void {
        this.getData();
        this.isActionsForm = false;
    }

    restoreCompanyUser(id: any): void {
        if (this.loggedUser === Users.superAdmin) {
            this.authService.restoreCompanyAdmin(id).subscribe((response: any) => {
                this.getAllCompanyAdmins();
                this.toasterService.showSuccess('Company admin successfully restored!');
            }, (error: any) => {
                this.toasterService.showError(error);
            });
        } else if (this.loggedUser === Users.companyAdmin) {
            this.authService.restoreCompanyMember(id).subscribe((response: any) => {
                this.toasterService.showSuccess('Company member successfully restored!');
                this.getAllCompanyMembers();
            }, (error: any) => {
                this.toasterService.showError(error);
            });
        }
    }
}
