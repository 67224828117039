import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {UseCase} from '../../../interfaces/use-case-interfaces';
import {
    Destination, DestinationDeleteModalDetails, DestinationDownloadReqBody, DestinationType,
    GetDestinationsResponse,
    SearchAndFilterDestinationsReqObj
} from '../../../interfaces/destination-interfaces';
import {DestinationsHttpService} from '../../../services/http-services/destinations-http.service';
import {SlideInOutAnimation} from '../../../animations/slide-modal-animation';
import {
    DestinationOutputTypesEnums,
    DestinationTypesEnums
} from '../../../enums/destination-enums';
import {FadeInAnimation} from '../../../animations/fade-in-animation';
import {AuthenticationHelperService} from '../../../services/helpers/authentication-helper.service';
import {environment} from '../../../../environments/environment';
import {PAGES_AND_PATHS} from '../../../constants/pages-and-paths';

@Component({
    selector: 'app-destinations-with-search-and-filters',
    templateUrl: './destinations-with-search-and-filters.component.html',
    animations: [SlideInOutAnimation, FadeInAnimation]
})
export class DestinationsWithSearchAndFiltersComponent implements OnInit, OnChanges {

    @Input() useCaseId = 0;
    @Input() showAddDestinationModal = false;
    @Input() destinationTypes: Array<DestinationType> = [];
    destinations: GetDestinationsResponse | undefined;
    initialDestinations: GetDestinationsResponse | undefined;
    useCase: UseCase | undefined;
    searchAndFilter: SearchAndFilterDestinationsReqObj = {
        query: '',
        destination_type: {id: 0, name: 'All'},
        output_type: {id: 0, destination_type_id: 0, name: 'All'}
    };
    destinationForEdit: { destination: Destination | undefined, show: boolean } = {destination: undefined, show: false};
    initialRequestFinished = false;
    DestinationType = DestinationTypesEnums;
    DestinationOutputType = DestinationOutputTypesEnums;
    destinationDetailsModal: DestinationDeleteModalDetails = {destination: undefined, show: false};
    destinationDeleteModalDetails: DestinationDeleteModalDetails | undefined;
    PAGES_AND_PATHS = PAGES_AND_PATHS;
    @Output() showAddDestinationModalChange = new EventEmitter();

    constructor(private destinationHttpService: DestinationsHttpService,
                private authenticationHelperService: AuthenticationHelperService) {
    }

    ngOnInit(): void {
    }

    ngOnChanges(changes: SimpleChanges): void {
        if ((changes.useCaseId || changes.useCaseId === 0) && (changes.useCaseId.currentValue || changes.useCaseId.currentValue === 0)) {
            this.getDestinations(this.useCaseId);
        }
    }

    downloadDocument(destination: Destination): void {
        console.log('Downloading: ', destination);
        const destinationReqObj: DestinationDownloadReqBody = {
            destinationId: destination.id,
            type: destination.output_type.name,
            useCaseId: destination.use_case_id
        };
        window.open(environment.API_ENDPOINT_DESTINATION + 'api/data/types/' + destinationReqObj.type + '/destinations/'
            + destinationReqObj.destinationId + '/use-cases/' + destinationReqObj.useCaseId + '/'
            + this.authenticationHelperService.getCompanyId + '/' + this.authenticationHelperService.getToken, '_blank');
        // this.destinationHttpService.downloadDocument(destinationReqObj).then(response => {
        //
        // });
    }

    onDestinationDeleteModalClosed(): void {
        if (this.destinationDeleteModalDetails) {
            this.destinationDeleteModalDetails.show = false;
            this.destinationDeleteModalDetails.destination = undefined;
        }
    }

    onDestinationClosed(): void {
        this.showAddDestinationModal = false;
        this.showAddDestinationModalChange.emit(false);
    }

    onDestinationDelete(destination: any): void {
        this.destinationHttpService.deleteDestination(destination).then((deletedDestination: Destination) => {
            if (deletedDestination) {
                this.deleteUseCaseFromView(this.destinationDeleteModalDetails, deletedDestination);
            }
        });
    }

    deleteUseCaseFromView(destinationDeleteModalDetails: DestinationDeleteModalDetails | undefined, deletedDestination: Destination): void {
        const useCaseIndex: number | undefined = this.destinations?.data.findIndex((x: Destination) => x.id === deletedDestination.id);
        if (typeof useCaseIndex !== 'undefined' && useCaseIndex !== -1) {
            this.destinations?.data.splice(useCaseIndex, 1);
        }
        this.destinationDeleteModalDetails = {show: false, destination: undefined};
    }

    resetDestinationDetailsModal(): void {
        this.destinationDetailsModal = {destination: undefined, show: false};
    }

    resetSearchAndFilters(): void {
        this.searchAndFilter = {
            query: '',
            destination_type: {id: 0, name: ''},
            output_type: {destination_type_id: 0, name: '', id: 0}
        };
    }

    searchDestinations(searchAndFilter: SearchAndFilterDestinationsReqObj, pageNUmber: number | null = null): void {
        if (searchAndFilter.destination_type.id === 0 && searchAndFilter.output_type.id === 0 && !searchAndFilter.query.trim()) {
            this.getDestinations(this.useCaseId);
        } else {
            this.destinationHttpService.searchAndFilterDestinations(this.searchAndFilter, this.useCaseId, pageNUmber).then(response => {
                this.destinations = response;
            });
        }
    }

    onPageChanged(pageNumber: number): void {
        (this.searchAndFilter.query.trim() || this.searchAndFilter.destination_type.id !== 0 || this.searchAndFilter.output_type.id !== 0) ?
            this.searchDestinations(this.searchAndFilter, pageNumber) :
            this.getDestinationPagination(pageNumber);
    }

    getDestinationPagination(pageNumber: number | null = null): void {
        this.destinationHttpService.getDestinations(this.useCaseId, pageNumber).then((response: GetDestinationsResponse) => {
            this.destinations = {data: response.data, pagination: response.pagination};
        });
    }

    getDestinations(useCaseId: number): void {
        this.destinationHttpService.getDestinations(useCaseId).then((response: GetDestinationsResponse) => {
            this.destinations = {data: response.data, pagination: response.pagination};
            this.initialDestinations = {data: response.data, pagination: response.pagination};
            this.initialRequestFinished = true;
        });
    }

    showData(destinations: GetDestinationsResponse | undefined): boolean | undefined {
        return destinations && destinations.data && typeof destinations.pagination.totalItems !== 'undefined' && destinations.pagination.totalItems > 0;
    }

    openDestinationDetailsModal(destination: Destination): void {
        this.destinationDetailsModal = {destination, show: true};
    }

    // TODO MIHA IMPLEMENT FUNCTIONS BELLOW
    resetEditDestinationDetailsToInitialState(): void {
        // this.editSourceDetails = {sourceId: null, show: false};
    }

    openEditModal(sourceId: number): void {
        // this.editSourceDetails.sourceId = sourceId;
        // this.editSourceDetails.show = true;
    }

    onDestinationAdded(destination: Destination): void {
        this.getDestinations(this.useCaseId);
        this.resetSearchAndFilters();
    }

}
