<div class="flex flex-row w-full h-screen bg-gray-50">
    <app-sidebar *ngIf="activePageHelper.activePage?.showSidemenu && activePageHelper.showSideMenu" class="relative mr-4 w-1/6"></app-sidebar>
    <div class="flex flex-col w-full flex-grow">
        <div class="bg-gray-200 flex justify-between px-2 py-3 max-w-full  md:hidden">
            <button class="inline-flex items-center justify-center text-gray-400 bg-transparent focus:outline-none relative">
                <img src="../../../assets/images/icons/menu_icon.svg" class="max-h-48" alt="icon">
            </button>
            <button class="inline-flex items-center justify-center text-gray-400 bg-transparent focus:outline-none relative" style="opacity:0.5">
                <!-- Heroicon name: solid/dots-vertical -->
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                     xmlns="http://www.w3.org/2000/svg">
                    <path d="M12 6.43994V9.76994" stroke="white" stroke-width="1.5"
                          stroke-miterlimit="10" stroke-linecap="round"/>
                    <path d="M12.0209 2C8.34087 2 5.36087 4.98 5.36087 8.66V10.76C5.36087 11.44 5.08087 12.46 4.73087 13.04L3.46087 15.16C2.68087 16.47 3.22087 17.93 4.66087 18.41C9.44087 20 14.6109 20 19.3909 18.41C20.7409 17.96 21.3209 16.38 20.5909 15.16L19.3209 13.04C18.9709 12.46 18.6909 11.43 18.6909 10.76V8.66C18.6809 5 15.6809 2 12.0209 2Z"
                          stroke="#9CA3AF" stroke-width="1.5" stroke-miterlimit="10"
                          stroke-linecap="round"/>
                    <path d="M15.3299 18.8198C15.3299 20.6498 13.8299 22.1498 11.9999 22.1498C11.0899 22.1498 10.2499 21.7698 9.64992 21.1698C9.04992 20.5698 8.66992 19.7298 8.66992 18.8198"
                          stroke="#9CA3AF" stroke-width="1.5" stroke-miterlimit="10"/>
                </svg>
                <span class="-ml-4 px-1.5 py-0.5 absolute left-full -top-2 text-xs rounded-full flex bg-red-500 text-white">4</span>
            </button>
        </div>
        <router-outlet></router-outlet>
    </div>
</div>
