<div class="flex flex-col md:flex-row space-y-2 w-full justify-between items-center px-6 py-4 border-b border-gray-200">
    <h1 class="text-2xl text-gray-900 leading-7 font-bold">Use Cases</h1>
    <div class="flex">
        <a class="p-2 rounded-md text-sm font-medium mx-2 cursor-pointer"
           (click)="changeCategory(UseCasesCategories.all)" [ngClass]="{'bg-gray-200': activeCategory === UseCasesCategories.all}"
           style="text-transform: capitalize">{{UseCasesCategories.all}}
        </a>
        <a class="p-2 rounded-md text-sm font-medium mx-2 cursor-pointer" [ngClass]="{'bg-gray-200': activeCategory === UseCasesCategories.personal}"
           (click)="changeCategory(UseCasesCategories.personal)" style="text-transform: capitalize">{{UseCasesCategories.personal}}
        </a>
    </div>
    <button (click)="showModal.emit(true)" type="button"
            class="inline-flex items-center px-3 py-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500">
        <!-- Heroicon name: solid/mail -->
        <svg xmlns="http://www.w3.org/2000/svg" class="-ml-0.5 mr-2 h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
            <path fill-rule="evenodd"
                  d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z"
                  clip-rule="evenodd"/>
        </svg>
        New Use Case
    </button>
</div>
