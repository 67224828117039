import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {
    SearchAndFilterUseCaseData,
    UseCaseDataBodyEmitter, UseCaseDataQueryEmitter,
    UseCaseDataResponse
} from '../../../../interfaces/use-case-data-interfaces';
import {UseCaseDataViewTypes} from '../../../../enums/use-cases-enums';
import {FadeInAnimation} from '../../../../animations/fade-in-animation';
import {Source} from '../../../../interfaces/sources-interfaces';

@Component({
    selector: 'app-use-case-all',
    templateUrl: './use-case-all.component.html',
    animations: [FadeInAnimation]
})
export class UseCaseAllComponent implements OnInit {

    @Input() useCaseId = 0;
    useCaseData: UseCaseDataResponse | undefined;
    UseCaseDataViewTypes = UseCaseDataViewTypes;
    activeTab: string = 'all';
    searchAndFilter: SearchAndFilterUseCaseData = {
        query: '',
        source: null,
        syncSource: null
    };
    @Output() changedView = new EventEmitter<UseCaseDataBodyEmitter>();
    @Output() changedQueryView = new EventEmitter<UseCaseDataQueryEmitter>();

    constructor() {
    }

    ngOnInit(): void {
    }

}
