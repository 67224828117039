export enum AddSourceManualSteps {
    firstStep = 'firstStep',
    secondStep = 'secondStep',
    thirdStep = 'thirdStep'
}

export enum AddSourceCloudSteps {
    firstStep = 'firstStep',
    secondStep = 'secondStep',
    thirdStep = 'thirdStep',
    fourthStep = 'fourthStep'
}

export enum AddingSourceType {
    manual = 'manual',
    cloud = 'integration',
    email = 'email'
}

export enum AddingSourceTypeForView {
    manual = 'Manual upload',
    cloud = 'Cloud',
    email = 'Email'
}

export enum CloudTypes {
    aws = 'AWS',
    ftp = 'FTP',
    sftp = 'SFTP'
}

export enum InputErrorMessages {
    default = 'Please fill out this field with valid format',
    badFormatErrMsg = 'Bad format',
    passwordsAreNotTheSame = 'Passwords are not the same',
    minimumPasswordLength = 'Password need to have at least 8 characters',
    oldPasswordNotCorrect = 'Old password is not correct'
}

export enum EditType {
    accessKey = 'Access key',
    password = 'Password',
    default = 'default'
}
