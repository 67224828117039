<div class="flex-col md:flex-row flex px-6 py-4 items-center bg-white">
    <div class="w-full md:w-auto relative flex items-stretch flex-grow focus-within:z-50 mb-2 xs:space-y-0 md:space-y-2">
        <div class="absolute inset-y-0 left-0 flex items-center pointer-events-none">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M11 20C15.9706 20 20 15.9706 20 11C20 6.02944 15.9706 2 11 2C6.02944 2 2 6.02944 2 11C2 15.9706 6.02944 20 11 20Z"
                      stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M18.9304 20.6898C19.4604 22.2898 20.6704 22.4498 21.6004 21.0498C22.4504 19.7698 21.8904 18.7198 20.3504 18.7198C19.2104 18.7098 18.5704 19.5998 18.9304 20.6898Z"
                      stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
        </div>
        <app-ppg-search-input [searchQuery]="searchAndFilter.query"
                              (searchChanged)="searchAndFilter.query = $event;
                              searchAndFilterChange.emit(searchAndFilter)">
        </app-ppg-search-input>
    </div>

    <div class="flex ml-auto space-x-2">
        <!-- Status -->
        <div class="relative inline-block text-left">
            <div>
                <button #toggleButtonType (click)="showDropdownType = !showDropdownType"
                        class="border border-gray-200 bg-white hover:bg-gray-50 rounded-md px-3 py-2">
                    <div class="flex items-center space-x-2 text-sm text-gray-500 ml-2">
                        Destination Type:
                        <span class="text-sm font-semibold text-gray-700 ml-2">{{searchAndFilter.destination_type?.name}}</span>
                        <svg width="9" height="10" viewBox="0 0 9 10" fill="none"
                             xmlns="http://www.w3.org/2000/svg">
                            <path d="M7.125 3.875L4.5 6.5L1.875 3.875" stroke="black" stroke-width="1.2"
                                  stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </div>
                </button>
            </div>


            <div #menuType [ngClass]="{'hidden': !showDropdownType}"
                 class="origin-top-right absolute z-10 right-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none"
                 role="menu" aria-orientation="vertical" aria-labelledby="menu-button" tabindex="-1">
                <div class="py-1" role="none">
                    <!-- Active: "bg-gray-100 text-gray-900", Not Active: "text-gray-700" -->
                    <a class="text-gray-700 group flex items-center px-4 py-2 text-sm capitalize" role="menuitem"
                       style="cursor:pointer"
                       *ngFor="let type of destinationTypes"
                       (click)="changeDestinationType(type)"
                       tabindex="-1" id="menu-item-0">
                        {{type.name}}
                    </a>
<!--                    <a class="text-gray-700 group flex items-center px-4 py-2 text-sm" role="menuitem"-->
<!--                       style="cursor:pointer"-->
<!--                       (click)="changeSourceType({id: 0, name: 'all'})"-->
<!--                       tabindex="-1" id="menu-item-0">-->
<!--                        All-->
<!--                    </a>-->
                </div>
            </div>
        </div>
        <!-- End Status -->

        <!-- Status -->
        <div class="relative inline-block text-left">
            <div>
                <button #toggleButtonOutput (click)="showDropdownOutput = !showDropdownOutput" [disabled]="searchAndFilter.destination_type.id === 0"
                        class="border border-gray-200 bg-white hover:bg-gray-50 rounded-md px-3 py-2">
                    <div class="flex items-center space-x-2 text-sm text-gray-500 ml-2">
                        Output Type:
                        <span class="text-sm font-semibold text-gray-700 ml-2 capitalize">{{searchAndFilter.output_type.name}}</span>
                        <svg width="9" height="10" viewBox="0 0 9 10" fill="none"
                             xmlns="http://www.w3.org/2000/svg">
                            <path d="M7.125 3.875L4.5 6.5L1.875 3.875" stroke="black" stroke-width="1.2"
                                  stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </div>
                </button>
            </div>


            <div #menuOutput [ngClass]="{'hidden': !showDropdownOutput}"
                 class="hidden origin-top-right absolute z-10 right-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none"
                 role="menu" aria-orientation="vertical" aria-labelledby="menu-button" tabindex="-1">
                <div class="py-1" role="none">
                    <!-- Active: "bg-gray-100 text-gray-900", Not Active: "text-gray-700" -->
                    <a class="text-gray-700 group flex items-center px-4 py-2 text-sm capitalize" role="menuitem"
                       style="cursor:pointer"
                       *ngFor="let type of outputTypes"
                       (click)="changeOutputType(type)"
                       tabindex="-1" id="menu-item-0">
                        {{type.name}}
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>
