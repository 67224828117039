import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {
    CloudFrequency,
    CloudIntegrationResponse,
    CloudRegion,
    Source
} from '../../../../../interfaces/sources-interfaces';
import {SourcesHttpService, SourcesHttpServiceHelper} from '../../../../../services/http-services/sources-http.service';
import {AddSourceCloud} from '../add-source-cloud';
import {AddSourceCloudSteps} from '../../../../../enums/sources-enums';
import {CloudIntegration} from '../cloud-integration';

@Component({
    selector: 'app-add-source-cloud-third-step',
    templateUrl: './add-source-cloud-third-step.component.html'
})
export class AddSourceCloudThirdStepComponent implements OnInit {

    @Input() addSourceClass: AddSourceCloud | null = null;
    @Input() cloudFrequencies: Array<CloudFrequency> = [];
    @Input() cloudIntegrations: Array<CloudIntegration> = [];
    @Input() cloudRegions: Array<CloudRegion> = [];
    @Output() modalClosed = new EventEmitter<boolean>();
    @Output() sourceAdded = new EventEmitter<Source>();
    @Output() stepChanged = new EventEmitter<{ cloudIntegration: CloudIntegration, step: AddSourceCloudSteps }>();
    AddSourceCloudSteps = AddSourceCloudSteps;
    submitted = false;

    constructor(private sourcesHttpService: SourcesHttpService,
                private sourcesHttpServiceHelper: SourcesHttpServiceHelper) {
    }

    ngOnInit(): void {
        this.getCloudIntegrations();
    }

    onCloudIntegrationChanged(event: { event: any, cloudIntegration: CloudIntegration }): void {
        if (this.addSourceClass) {
            if (this.addSourceClass.cloudIntegration.id === event.cloudIntegration.id) {
                event.event.preventDefault();
            }
        }
        if (this.addSourceClass) {
            this.addSourceClass.cloudIntegration = event.cloudIntegration;
        }
    }

    getCloudIntegrations(): void {
        this.sourcesHttpService.getCloudRegionsAndIntegrations(this.addSourceClass?.cloudType?.id).then(response => {
            if (this.addSourceClass) {
                // tslint:disable-next-line:max-line-length
                this.cloudIntegrations = this.cloudIntegrations.concat(this.sourcesHttpServiceHelper.configureCloudIntegrationsObjects(response.cloudIntegrations, this.cloudRegions, this.addSourceClass?.cloudType));
            }
        });
    }

    checkIfNeedToShowErrMessage(): boolean | null {
        return this.addSourceClass && this.addSourceClass.sourceName.trim().length < 2 && this.submitted;
    }

    addNewAccount(): void {
        this.stepChanged.emit({cloudIntegration: new CloudIntegration(), step: AddSourceCloudSteps.secondStep});
    }

    selectCloudFrequency(frequency: CloudFrequency): void {
        if (this.addSourceClass) {
            this.addSourceClass.frequency = frequency;
        }
    }

    saveNewSource(): void {
        this.submitted = true;
        if (this.addSourceClass && this.addSourceClass.sourceName.trim().length > 2) {
            this.submitted = false;
            if (this.addSourceClass) {
                this.sourcesHttpService.addSource(this.addSourceClass).then((source: Source) => {
                    this.sourceAdded.emit(source);
                });
            }
        } else {
            const addSourceForm = document.getElementById('js-add-item-modal');
            if (addSourceForm) {
                addSourceForm.scrollTop = 0;
            }
        }
    }

}
