<div class="flex flex-col custom-height">
    <!-- 3rd Step Content -->
    <div class="p-6 space-y-6 flex-grow">
        <div>
            <label class="block text-sm font-medium text-gray-700">Source Name</label>
            <div class="mt-2">
                <input type="text" [(ngModel)]="addSourceClass!.sourceName"
                        class="shadow-sm focus:ring-gray-400 focus:border-gray-400 block w-full sm:text-sm border-gray-300 rounded-md"
                        placeholder="">
                <small class="text-red-500"  *ngIf="checkIfNeedToShowErrMessage()">Source name need to have at least 2 characters</small>
            </div>
        </div>
        <div class="relative flex items-start">
            <div class="flex items-center h-5">
                <input id="comments" [disabled]="true" aria-describedby="comments-description" name="comments" type="checkbox" [(ngModel)]="addSourceClass!.processSelectedPagesInDocument"
                        class="focus:ring-gray-500 h-4 w-4 text-gray-600 border-gray-300 rounded">
            </div>
            <div class="ml-3 text-sm" style="opacity: 0.5">
                <label for="comments" class="font-medium text-gray-700 text-sm leading-5">Process only selected pages in
                    documents</label>
            </div>
        </div>
        <div class="bg-yellow-50 border-l-4 border-yellow-400 p-4">
            <div class="flex">
                <div class="flex-shrink-0">
                    <!-- Heroicon name: solid/exclamation -->
                    <svg class="h-5 w-5 text-yellow-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                            fill="currentColor" aria-hidden="true">
                        <path fill-rule="evenodd"
                                d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z"
                                clip-rule="evenodd"/>
                    </svg>
                </div>
                <div class="ml-3">
                    <p class="text-sm text-yellow-700">
                        Processing of your data will not start until you select pages in every document.
                    </p>
                </div>
            </div>
        </div>
        <div class="flex flex-col space-y-6">
            <div class="relative">
                <div class="absolute inset-0 flex items-center" aria-hidden="true">
                    <div class="w-full border-t border-gray-300"></div>
                </div>
                <div class="relative flex justify-start">
                    <span class="pr-2 bg-white text-lg leading-7 font-medium text-gray-900">
                        File Upload
                    </span>
                </div>
            </div>
            <p class="text-sm leading-5 font-normal text-gray-500" *ngIf="addSourceClass?.files?.length === 0">
                No files to display. Please add new file.
            </p>
            <app-uploaded-files [uploadedFiles]="addSourceClass?.files"
                                (filesChanged)="(addSourceClass) ? addSourceClass.files = $event: null">
            </app-uploaded-files>
            <div class="relative">
                <div class="absolute inset-0 flex items-center" aria-hidden="true">
                    <div class="w-full border-t border-gray-300"></div>
                </div>
                <div class="relative flex justify-center">
                    <button type="button" (click)="changeStep()"
                            class="inline-flex items-center shadow-sm px-4 py-1.5 border border-gray-300 text-sm leading-5 font-medium rounded-full text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500">
                        <!-- Heroicon name: solid/plus-sm -->
                        <svg class="-ml-1.5 mr-1 h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                            <path fill-rule="evenodd"
                                    d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z"
                                    clip-rule="evenodd"/>
                        </svg>
                        <span>New File</span>
                    </button>
                </div>
            </div>
        </div>
    </div>
    <!-- 3rd Step Content End -->

    <!-- Action buttons -->
    <div class="flex-shrink-0 px-4 border-t border-gray-200 py-5 sm:px-6 mt-auto">
        <div class="space-x-3 flex justify-end">
            <button (click)="modalClosed.emit(true)" type="button"
                    class="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500">
                Cancel
            </button>
            <button type="button"
                    [disabled]="fileStartedUploading"
                    (click)="saveNewSource()"
                    class="bg-gray-700 py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500">
                Save
            </button>
        </div>
    </div>
</div>

