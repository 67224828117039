import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {
    SourceSync,
    SourceSyncDetails, SourceSyncDetailsInSidebar,
    SourceSyncDetailsResponse,
    SourceSyncResponse
} from '../../interfaces/source-syncs-interfaces';
import {GeneralHttpHelperService} from './general-http-helper.service';
import * as moment from 'moment';
import {AuthenticationHelperService} from '../helpers/authentication-helper.service';
import {GeneralHelperService} from '../helpers/general-helper.service';

@Injectable({
    providedIn: 'root'
})
export class SourceSyncsHttpService {

    constructor(private httpClient: HttpClient,
                private sourceSyncsHttpServiceHelper: SourceSyncsHttpServiceHelper,
                private generalHttpHelperService: GeneralHttpHelperService) {
    }

    private getSourceSyncsReq(sourceId: number, nextPageUrl: string | null): Observable<any> {
        const headers = new HttpHeaders({
            'route-key': environment.API_ENDPOINT_MS_UC_ROUTE_KEY,
            'payload-key': environment.API_ENDPOINT_MS_UC_PAYLOAD_KEY,
        });

        return (nextPageUrl) ?
            this.httpClient.get<any>(nextPageUrl, {headers}) :
            this.httpClient.get<any>(environment.API_ENDPOINT_MS_UC + 'api/doc-manipulation/sync-sources/sources/' + sourceId, {headers});
    }

    private syncDataForSourceReq(cloudIntegrationId: number, sourceId: number): Observable<any> {
        const headers = new HttpHeaders({
            'route-key': environment.API_ENDPOINT_MS_UC_ROUTE_KEY,
            'payload-key': environment.API_ENDPOINT_MS_UC_PAYLOAD_KEY,
        });

        return this.httpClient.get<any>(environment.API_ENDPOINT_MS_UC + 'api/doc-manipulation/sync/integration/clouds/' + cloudIntegrationId + '/sources/' + sourceId, {headers});
    }

    private rerunSyncDataForSourceReq(cloudIntegrationId: number, sourceId: number): Observable<any> {
        const headers = new HttpHeaders({
            'route-key': environment.API_ENDPOINT_MS_UC_ROUTE_KEY,
            'payload-key': environment.API_ENDPOINT_MS_UC_PAYLOAD_KEY,
        });

        return this.httpClient.get<any>(environment.API_ENDPOINT_MS_UC + 'api/doc-manipulation/rerun/sync/integration/clouds/' + cloudIntegrationId + '/sources/' + sourceId, {headers});
    }

    private getSingleSyncSourceReq(sourceId: number, nextPageUrl: string | null): Observable<any> {
        const headers = new HttpHeaders({
            'route-key': environment.API_ENDPOINT_MS_UC_ROUTE_KEY,
            'payload-key': environment.API_ENDPOINT_MS_UC_PAYLOAD_KEY,
        });

        return (nextPageUrl) ?
            this.httpClient.get<any>(nextPageUrl, {headers}) :
            this.httpClient.get<any>(environment.API_ENDPOINT_MS_UC + 'api/doc-manipulation/sync-sources/' + sourceId, {headers});
    }

    private getSyncDocumentPreviewReq(documentId: number): Observable<any> {
        const headers = new HttpHeaders({
            'route-key': environment.API_ENDPOINT_MS_UC_ROUTE_KEY,
            'payload-key': environment.API_ENDPOINT_MS_UC_PAYLOAD_KEY,
        });

        return this.httpClient.get<any>(environment.API_ENDPOINT_MS_UC + 'api/data-manipulation/preview/images/documents/' + documentId, {headers});
    }

    private getLastSyncedReq(): Observable<any> {
        const headers = new HttpHeaders({
            'route-key': environment.API_ENDPOINT_MS_UC_ROUTE_KEY,
            'payload-key': environment.API_ENDPOINT_MS_UC_PAYLOAD_KEY,
        });

        return this.httpClient.get<any>(environment.API_ENDPOINT_MS_UC + 'api/doc-manipulation/last-synced/documents', {headers});
    }

    getLastSynced(): Promise<Array<SourceSyncDetailsInSidebar>> {
        return new Promise<Array<SourceSyncDetailsInSidebar>>((resolve: any, reject: any) => {
            this.getLastSyncedReq().subscribe((response: any) => {
                resolve(response.entity);
            }, error => {
                reject(null);
            });
        });
    }

    getSingleSyncSource(sourceId: number, nextPageUrl: string | null = null): Promise<SourceSyncDetailsResponse> {
        return new Promise<SourceSyncDetailsResponse>((resolve: any, reject: any) => {
            this.getSingleSyncSourceReq(sourceId, nextPageUrl).subscribe((response: any) => {
                const pagination = this.generalHttpHelperService.returnPagination(response.entity.documents);
                resolve({data: response.entity.documents.data, pagination});
            }, error => {
                reject(null);
            });
        });
    }

    rerunSyncDataForSource(cloudIntegrationId: number, sourceId: number): Promise<any> {
        return new Promise<any>((resolve: any, reject: any) => {
            this.rerunSyncDataForSourceReq(cloudIntegrationId, sourceId).subscribe((response: any) => {
                console.log('Response: ', response);
                resolve(response.entity);
            }, error => {
                reject(null);
            });
        });
    }

    syncDataForSource(cloudIntegrationId: number, sourceId: number): Promise<any> {
        return new Promise<any>((resolve: any, reject: any) => {
            this.syncDataForSourceReq(cloudIntegrationId, sourceId).subscribe((response: any) => {
                console.log('Response: ', response);
                resolve(response.entity);
            }, error => {
                reject(null);
            });
        });
    }

    getSourceSyncs(sourceId: number, nextPageUrl: string | null = null): Promise<SourceSyncResponse> {
        return new Promise<SourceSyncResponse>((resolve: any, reject: any) => {
            this.getSourceSyncsReq(sourceId, nextPageUrl).subscribe((response: any) => {
                const pagination = this.generalHttpHelperService.returnPagination(response.entity);
                resolve({
                    data: this.sourceSyncsHttpServiceHelper.configureDateFormat(response.entity.data),
                    pagination
                });
            }, error => {
                reject(null);
            });
        });
    }
}

@Injectable({
    providedIn: 'root'
})
export class SourceSyncsHttpServiceHelper {
    constructor(private authHelperService: AuthenticationHelperService,
                private generalHelperService: GeneralHelperService) {
    }

    configureDateFormat(sourceSyncData: Array<SourceSync>): Array<SourceSync> {
        sourceSyncData.forEach(sourceSync => {
            sourceSync.sync_date = this.generalHelperService.formatTimestampToMomentDateAndTime(Number(sourceSync.sync_date));
            console.log(sourceSync.sync_date);
        });
        return sourceSyncData;
    }
}
