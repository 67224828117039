import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LocalStorageProperties, Users } from 'src/app/enums/general-enums';
import { ToasterHelperService } from 'src/app/services/helpers/toaster-helper.service';
import { AuthHttpService } from 'src/app/services/http-services/auth-http.service';

@Component({
  selector: 'app-actions',
  templateUrl: './actions.component.html'
})
export class ActionsComponent implements OnInit {
  @Input() userToUpdate: any;
  @Output() refreshManageComponent: EventEmitter<any> = new EventEmitter<any>();
  loggedUser = localStorage.getItem(LocalStorageProperties.userRole);
  alertText = 'Are you sure you want to delete this user?';
  isDelete = false;
  isPassword = false;

  constructor(private authHttpService: AuthHttpService, private toasterService: ToasterHelperService) { }

  ngOnInit(): void {
  }

  changePass(): void {
    this.isPassword = true;
  }

  // send new password to user email address
  changeUserPassword(): void {
    if (this.loggedUser === Users.superAdmin) {
      this.authHttpService.changeCompanyAdminPassword(this.userToUpdate.id).subscribe((response: any) => {
        this.toasterService.showSuccess('New password has been sent to user\'s email address!');
        this.isPassword = false;
        this.refreshManageComponent.emit(true);
      }, (error: any) => {
        this.toasterService.showError(error);
      });
    } else if (this.loggedUser === Users.companyAdmin) {
      this.authHttpService.changeCompanyMemberPassword(this.userToUpdate.id).subscribe((response: any) => {
        this.toasterService.showSuccess('New password has been sent to user\'s email address!');
        this.isPassword = false;
        this.refreshManageComponent.emit(true);
      }, (error: any) => {
        this.toasterService.showError(error);
      });
    }
  }

  delete(): void {
    this.isDelete = true;
  }

  closeAlertModal(): void {
    this.isDelete = false;
  }

  deleteUser(): void {
    if (this.loggedUser === Users.superAdmin) {
      this.authHttpService.deleteCompanyAdmin(this.userToUpdate.id).subscribe((response: any) => {
        this.isDelete = false;
        this.refreshManageComponent.emit(true);
        this.toasterService.showSuccess('Company admin successfully deleted!');
      }, (error: any) => {
        this.toasterService.showError(error);
      });
    } else if (this.loggedUser === Users.companyAdmin) {
      this.authHttpService.deleteCompanyMember(this.userToUpdate.id).subscribe((response: any) => {
        this.isDelete = false;
        this.toasterService.showSuccess('Company member successfully deleted!');
        this.refreshManageComponent.emit(true);
      }, (error: any) => {
        this.toasterService.showError(error);
      });
    }
  }

}
