<!-- Add Source Manual-->
<div class="w-screen max-w-xl" #modal [@slideInOut]="syncOpened.show ? 'out' : 'in'"
     [ngStyle]="{'transform': (syncOpened.show && !animationFinished) ? 'translateX(100%)' : 'translateX(0)'}">
    <div class="h-full flex flex-col bg-white shadow-xl">
        <div class="flex-1">
            <!-- Header -->
            <div class="px-4 py-6 sm:px-6">
                <div class="flex items-start justify-between space-x-3">
                    <div class="space-y-1">
                        <h2 class="text-lg font-medium text-gray-900" id="slide-over-title">
                            {{source?.name}}
                        </h2>
                        <p class="bg-gray-100 text-sm text-gray-800 py-0.5 px-3 rounded font-medium mr-2 capitalize">
                            {{source?.source_type?.name + " " + ((source?.cloud) ? "- " + source?.cloud?.name : "")}}
                        </p>
                    </div>
                    <div class="h-7 flex items-center">
                        <button (click)="modalClosed.emit(true)" type="button"
                                class="text-gray-400 hover:text-gray-500">
                            <span class="sr-only">Close panel</span>
                            <!-- Heroicon name: outline/x -->
                            <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none"
                                 viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                      d="M6 18L18 6M6 6l12 12"/>
                            </svg>
                        </button>
                    </div>
                </div>
            </div>
            <div class="h-16 px-4 py-3 bg-gray-700 sm:px-6">
                <div class="flex items-start justify-between space-x-3">
                    <div class="space-y-1">
                        <button type="button" [disabled]="requestedSyncing" (click)="rerunSyncDataForSource()" class="mr-2 inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none">
                            Re-run
                        </button>
                        <button type="button" [disabled]="requestedSyncing" (click)="syncDataForSource()" class="inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none">
                            Re-sync
                        </button>
                    </div>
                </div>
            </div>
            <!-- 1st Step Content -->
            <div class="overflow-y-scroll syncs-modal-scroll" (scroll)="paginateSourceSyncs($event)" #syncSourcesRef>
                <div *ngFor="let sync of syncSources?.data" (click)="openSync(sync)" class="cursor-pointer" @elementAppearsAnimation [@.disabled]="isAnimationDisabled">
                    <div class="flex justify-between py-4 px-6">
                        <div class="flex flex-col">
                            <div class="mb-2"><span class="font-medium text-gray-900 text-sm">Synced {{moment(sync.sync_date).fromNow()}}</span></div>
                            <div class="flex items-center">
                                <img class="mr-2" src="../../../../assets/images/icons/icon-successful.svg" alt="">
                                <span class="text-gray-500 text-sm font-medium">Sync Successful</span>
                            </div>
                        </div>
                        <div class="flex flex-col mt-0">
                            <div class="flex items-center mb-2">
                                <img class="mr-3" src="../../../../assets/images/icons/icon-file.svg" alt="">
                                <span class="font-medium text-gray-900 text-sm">{{sync.new_files}} New files</span>
                            </div>
                            <div class="flex items-center">
                                <img class="mr-3" src="../../../../assets/images/icons/icon-new-file.svg" alt="">
                                <span class="font-mesdium text-gray-900 text-sm">{{sync.total_files}} Total files</span>
                            </div>
                        </div>
                        <div class="flex">
                            <img src="../../../../assets/images/icons/icon-gray-arrow-right.svg" alt="">
                        </div>
                    </div>
                    <div class="h-px bg-gray-200 w-full"></div>
                </div>
                <div class="h-40 flex justify-center items-center" *ngIf="syncSources?.data?.length === 0">
                    <span class="text-2xl text-gray-600 font-medium">No syncs</span>
                </div>
            </div>
            <!-- 1st Step Content End -->
        </div>
    </div>
</div>
<app-single-sync-data-modal class="w-screen max-w-xl" *ngIf="syncOpened.show" @slide
                            [sourceSync]="syncOpened.sourceSync"
                            (syncClosed)="syncOpened = $event">
</app-single-sync-data-modal>
<!--End Add Source Manual-->
