import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {AuthenticationHelperService} from '../helpers/authentication-helper.service';

@Injectable({
    providedIn: 'root'
})
export class AuthHttpService {

    constructor(private httpClient: HttpClient,
                private authHelper: AuthenticationHelperService) {
    }

    finishGuide(): any {
        const headers = new HttpHeaders({
            'route-key': environment.API_ENDPOINT_ROUTE_KEY,
            'payload-key': environment.API_ENDPOINT_PAYLOAD_KEY,
        });

        return this.httpClient.get(environment.API_ENDPOINT + 'api/guide/users', {headers});
    }

    // AUTH
    login(reqBody: any): any {
        const headers = new HttpHeaders({
            'route-key': environment.API_ENDPOINT_ROUTE_KEY,
            'payload-key': environment.API_ENDPOINT_PAYLOAD_KEY,
        });

        return this.httpClient.post<any>(environment.API_ENDPOINT + 'api/auth/login', reqBody, {headers});
    }

    logout(): any {
        const headers = new HttpHeaders({
            'route-key': environment.API_ENDPOINT_ROUTE_KEY,
            'payload-key': environment.API_ENDPOINT_PAYLOAD_KEY,
        });

        return this.httpClient.post<any>(environment.API_ENDPOINT + 'api/auth/logout', '', {headers});
    }

    getUserData(): any {
        const headers = new HttpHeaders({
            'route-key': environment.API_ENDPOINT_ROUTE_KEY,
            'payload-key': environment.API_ENDPOINT_PAYLOAD_KEY,
        });

        return this.httpClient.get(environment.API_ENDPOINT + 'api/auth/user-profile', {headers});
    }

    sendForgotPasswordLink(reqBody: any): any {
        const headers = new HttpHeaders({
            'route-key': environment.API_ENDPOINT_ROUTE_KEY,
            'payload-key': environment.API_ENDPOINT_PAYLOAD_KEY,
        });

        return this.httpClient.post<any>(environment.API_ENDPOINT + 'api/auth/password/email', reqBody, {headers});
    }

    getForgotPasswordToken(token: any): any {
        const headers = new HttpHeaders({
            'route-key': environment.API_ENDPOINT_ROUTE_KEY,
            'payload-key': environment.API_ENDPOINT_PAYLOAD_KEY,
        });

        return this.httpClient.get<any>(environment.API_ENDPOINT + `api/auth/password/reset/${token}`, {headers});
    }

    resetPassword(reqBody: any): any {
        const headers = new HttpHeaders({
            'route-key': environment.API_ENDPOINT_ROUTE_KEY,
            'payload-key': environment.API_ENDPOINT_PAYLOAD_KEY,
        });

        return this.httpClient.post<any>(environment.API_ENDPOINT + 'api/auth/password/reset', reqBody, {headers});
    }


    // GENERAL
    updateProfileInfo(reqBody: any): any {
        const headers = new HttpHeaders({
            'route-key': environment.API_ENDPOINT_ROUTE_KEY,
            'payload-key': environment.API_ENDPOINT_PAYLOAD_KEY,
        });

        return this.httpClient.put<any>(environment.API_ENDPOINT + `api/settings`, reqBody, {headers});
    }

    changePassword(reqObj: any): any {
        const headers = new HttpHeaders({
            'route-key': environment.API_ENDPOINT_ROUTE_KEY,
            'payload-key': environment.API_ENDPOINT_PAYLOAD_KEY,
        });

        return this.httpClient.put<any>(environment.API_ENDPOINT + `api/change/password`, reqObj, {headers});
    }

    // SUPER ADMIN
    getAllSuperAdminInvitations(): any {
        const headers = new HttpHeaders({
            'route-key': environment.API_ENDPOINT_ROUTE_KEY,
            'payload-key': environment.API_ENDPOINT_PAYLOAD_KEY,
        });

        return this.httpClient.get<any>(environment.API_ENDPOINT + 'api/super/admin/invitations', {headers});
    }

    inviteNewCompanyAdmin(reqBody: any): any {
        const headers = new HttpHeaders({
            'route-key': environment.API_ENDPOINT_ROUTE_KEY,
            'payload-key': environment.API_ENDPOINT_PAYLOAD_KEY,
        });

        return this.httpClient.post<any>(environment.API_ENDPOINT + 'api/super/admin/invitations', reqBody, {headers});
    }

    cancelAdminInvitation(invitation: any): any {
        const headers = new HttpHeaders({
            'route-key': environment.API_ENDPOINT_ROUTE_KEY,
            'payload-key': environment.API_ENDPOINT_PAYLOAD_KEY,
        });

        return this.httpClient.get<any>(environment.API_ENDPOINT + `api/super/admin/cancel/invitations/${invitation}`, {headers});
    }

    resendAdminInvitation(invitation: any): any {
        const headers = new HttpHeaders({
            'route-key': environment.API_ENDPOINT_ROUTE_KEY,
            'payload-key': environment.API_ENDPOINT_PAYLOAD_KEY,
        });

        return this.httpClient.get<any>(environment.API_ENDPOINT + `api/super/admin/re/invitations/${invitation}`, {headers});
    }

    getAllCompanyAdmins(nextPage: number | null = null): any {
        const headers = new HttpHeaders({
            'route-key': environment.API_ENDPOINT_ROUTE_KEY,
            'payload-key': environment.API_ENDPOINT_PAYLOAD_KEY,
        });

        return (!nextPage) ?
            this.httpClient.get<any>(environment.API_ENDPOINT + 'api/super/admin/company/admins', {headers}) :
            this.httpClient.get<any>(environment.API_ENDPOINT + 'api/super/admin/company/admins?page=' + nextPage, {headers});
    }

    getAllCompanyMembers(nextPage: number | null = null): any {
        const headers = new HttpHeaders({
            'route-key': environment.API_ENDPOINT_ROUTE_KEY,
            'payload-key': environment.API_ENDPOINT_PAYLOAD_KEY,
        });

        return (!nextPage) ?
            this.httpClient.get<any>(environment.API_ENDPOINT + 'api/company/admin/company/members', {headers}) :
            this.httpClient.get<any>(environment.API_ENDPOINT + 'api/company/admin/company/members?page=' + nextPage, {headers});
    }

    deleteCompanyAdmin(companyAdmin: any): any {
        const headers = new HttpHeaders({
            'route-key': environment.API_ENDPOINT_ROUTE_KEY,
            'payload-key': environment.API_ENDPOINT_PAYLOAD_KEY,
        });

        return this.httpClient.delete<any>(environment.API_ENDPOINT + `api/super/admin/company/admins/${companyAdmin}`, {headers});
    }

    restoreCompanyAdmin(companyAdmin: any): any {
        const headers = new HttpHeaders({
            'route-key': environment.API_ENDPOINT_ROUTE_KEY,
            'payload-key': environment.API_ENDPOINT_PAYLOAD_KEY,
        });

        return this.httpClient.get(environment.API_ENDPOINT + `api/super/admin/restore/company/admins/${companyAdmin}`, {headers});
    }

    changeCompanyAdminPassword(companyAdmin: any): any {
        const headers = new HttpHeaders({
            'route-key': environment.API_ENDPOINT_ROUTE_KEY,
            'payload-key': environment.API_ENDPOINT_PAYLOAD_KEY,
        });

        return this.httpClient.get<any>(environment.API_ENDPOINT + `api/super/admin/change/password/company/admins/${companyAdmin}`, {headers});
    }

    updateCompanyNameAdmin(reqBody: any): any {
        const headers = new HttpHeaders({
            'route-key': environment.API_ENDPOINT_ROUTE_KEY,
            'payload-key': environment.API_ENDPOINT_PAYLOAD_KEY,
        });

        return this.httpClient.put<any>(environment.API_ENDPOINT + `api/super/admin/companies/${this.authHelper.getCompanyId}`, reqBody, {headers});
    }

    // COMPANY ADMIN
    registerCompanyUser(reqBody: any): any {
        const headers = new HttpHeaders({
            'route-key': environment.API_ENDPOINT_ROUTE_KEY,
            'payload-key': environment.API_ENDPOINT_PAYLOAD_KEY,
        });

        return this.httpClient.post<any>(environment.API_ENDPOINT + 'api/auth/register', reqBody, {headers});
    }

    inviteNewCompanyMember(reqBody: any): any {
        const headers = new HttpHeaders({
            'route-key': environment.API_ENDPOINT_ROUTE_KEY,
            'payload-key': environment.API_ENDPOINT_PAYLOAD_KEY,
        });

        return this.httpClient.post<any>(environment.API_ENDPOINT + 'api/company/admin/invitations', reqBody, {headers});
    }

    cancelMemberInvitation(invitation: any): any {
        const headers = new HttpHeaders({
            'route-key': environment.API_ENDPOINT_ROUTE_KEY,
            'payload-key': environment.API_ENDPOINT_PAYLOAD_KEY,
        });

        return this.httpClient.get<any>(environment.API_ENDPOINT + `api/company/admin/cancel/invitations/${invitation}`, {headers});
    }

    resendMemberInvitation(invitation: any): any {
        const headers = new HttpHeaders({
            'route-key': environment.API_ENDPOINT_ROUTE_KEY,
            'payload-key': environment.API_ENDPOINT_PAYLOAD_KEY,
        });

        return this.httpClient.get<any>(environment.API_ENDPOINT + `api/company/admin/re/invitations/${invitation}`, {headers});
    }

    deleteCompanyMember(companyMember: any): any {
        const headers = new HttpHeaders({
            'route-key': environment.API_ENDPOINT_ROUTE_KEY,
            'payload-key': environment.API_ENDPOINT_PAYLOAD_KEY,
        });

        return this.httpClient.delete<any>(environment.API_ENDPOINT + `api/company/admin/company/members/${companyMember}`, {headers});
    }

    getAllMemberInvitations(): any {
        const headers = new HttpHeaders({
            'route-key': environment.API_ENDPOINT_ROUTE_KEY,
            'payload-key': environment.API_ENDPOINT_PAYLOAD_KEY,
        });

        return this.httpClient.get<any>(environment.API_ENDPOINT + 'api/company/admin/invitations', {headers});
    }

    getSingleCompanyMember(companyMember: any): any {
        const headers = new HttpHeaders({
            'route-key': environment.API_ENDPOINT_ROUTE_KEY,
            'payload-key': environment.API_ENDPOINT_PAYLOAD_KEY,
        });

        return this.httpClient.get<any>(environment.API_ENDPOINT + `api/company/admin/company/members/${companyMember}`, {headers});
    }

    restoreCompanyMember(companyMember: any): any {
        const headers = new HttpHeaders({
            'route-key': environment.API_ENDPOINT_ROUTE_KEY,
            'payload-key': environment.API_ENDPOINT_PAYLOAD_KEY,
        });

        return this.httpClient.get<any>(environment.API_ENDPOINT + `api/company/admin/restore/company/members/${companyMember}`, {headers});
    }

    changeCompanyMemberPassword(companyMember: any): any {
        const headers = new HttpHeaders({
            'route-key': environment.API_ENDPOINT_ROUTE_KEY,
            'payload-key': environment.API_ENDPOINT_PAYLOAD_KEY,
        });

        return this.httpClient.get<any>(environment.API_ENDPOINT + `api/company/admin/change/password/company/members/${companyMember}`, {headers});
    }

    updateCompanyNameMember(company: any, reqBody: any): any {
        const headers = new HttpHeaders({
            'route-key': environment.API_ENDPOINT_ROUTE_KEY,
            'payload-key': environment.API_ENDPOINT_PAYLOAD_KEY,
        });

        return this.httpClient.put<any>(environment.API_ENDPOINT + `api/company/admin/companies/${company}`, reqBody, {headers});
    }
}
