import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AddSourceManualSteps} from '../../../../../enums/sources-enums';
import {AddSourceManual} from '../add-source-manual';
import {PpgFile} from '../../../../../derived-classes/ppg-file';

@Component({
	selector: 'app-add-source-manual-modal-second-step',
	templateUrl: './add-source-manual-modal-second-step.component.html'
})
export class AddSourceManualModalSecondStepComponent implements OnInit {

	@Input() addSourceClass: AddSourceManual | null = null;
	@Output() stepChanged = new EventEmitter<AddSourceManualSteps>();
	@Output() modalClosed = new EventEmitter<boolean>();
	@Output() filesChanged = new EventEmitter<Array<PpgFile>>();
	@Output() finishAddingFiles = new EventEmitter<boolean>();

	constructor() {
	}

	ngOnInit(): void {
	}

	goToNextStep(): void {
		setTimeout(() => {
			this.addSourceClass?.nextStep();
			this.finishAddingFiles.emit(true);
		});
	}

	onFileDropped(file: PpgFile): void {
		this.addSourceClass?.files.push(file);
		this.filesChanged.emit(this.addSourceClass?.files);
	}

}
