<div>
    <a class="text-gray-700 font-medium text-sm mr-4 cursor-pointer" (click)="changePass()">Change password</a>

    <button type="button" (click)="delete()"
        class="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500">
        Delete user
    </button>
</div>

<app-modal *ngIf="isPassword">
    <div modal-header>
        <svg class="h-6 w-6 text-green-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
            stroke="currentColor" aria-hidden="true">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
        </svg>
    </div>
    <div modal-body>
        <h1 class="my-6">Send link for password change</h1>
        <button type="button" (click)="changeUserPassword()"
            class="mb-4 inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-gray-600 text-base font-medium text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm">send</button>
        <button (click)="isPassword = false"
            class="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-gray-200 text-base font-medium text-gray-700 hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm">cancel</button>
    </div>
</app-modal>

<app-alert-modal *ngIf="isDelete" [displayAlertText]="alertText" (closeAlertModal)="closeAlertModal()"
    (deleteFunction)="deleteUser()"></app-alert-modal>
