<div class="space-y-6">

    <!-- First name input -->
    <div>
        <label for="fname" class="block text-sm font-medium text-gray-700">
            First name
        </label>
        <div class="mt-1">
            <input type="text" [(ngModel)]="credentials.first_name" required autocomplete="false" spellcheck="false" placeholder="First name" class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-gray-500 focus:border-gray-500 sm:text-sm">
        </div>
    </div>
    <!-- ./First name input -->

    <!-- Last name input -->
    <div>
        <label for="lname" class="block text-sm font-medium text-gray-700">
            Last name
        </label>
        <div class="mt-1">
            <input type="text" [(ngModel)]="credentials.last_name" required autocomplete="false" spellcheck="false" placeholder="Last name" class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-gray-500 focus:border-gray-500 sm:text-sm">
        </div>

    </div>
    <!-- ./Last name input -->

    <!-- Email input -->
    <div>
        <label for="fname" class="block text-sm font-medium text-gray-700">
            Email address
        </label>
        <div class="mt-1">
            <input type="email" [(ngModel)]="credentials.email" [readonly]="true" disabled autocomplete="false" spellcheck="false" placeholder="Email address" class="appearance-none cursor-not-allowed block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-gray-500 focus:border-gray-500 sm:text-sm">
        </div>
    </div>
    <!-- ./Email input -->

    <!-- Next step button -->
    <div>
        <button (click)="secondStep()" [disabled]="!credentials.email || !credentials.last_name || !credentials.first_name" [ngClass]="!credentials.first_name || !credentials.last_name || !credentials.email ? 'cursor-not-allowed bg-gray-500' : 'bg-gray-600 hover:bg-gray-700'"
            class="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500">
            Next
        </button>
    </div>
    <!-- ./Next step button -->
</div>