import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-alert-modal',
  templateUrl: './alert-modal.component.html'
})
export class AlertModalComponent implements OnInit {
  @Input()
  displayAlertText!: string;
  @Output() closeAlertModal: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() deleteFunction: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor() { }

  ngOnInit(): void {
  }

  closeModal(): void {
    this.closeAlertModal.emit(false);
  }

  delete(): void {
    this.deleteFunction.emit(true);
  }
}
