import {Component, OnInit} from '@angular/core';
import {UseCasesHttpService} from '../../services/http-services/use-cases-http.service';
import {Labels} from '../../interfaces/use-case-interfaces';
import {SearchAndFilterCloudsReqObj} from '../../interfaces/sources-interfaces';
import {PpgPagination} from '../../interfaces/general-interfaces';
import {FadeInAnimation} from '../../animations/fade-in-animation';
import {SlideInOutAnimation} from '../../animations/slide-modal-animation';

@Component({
    selector: 'app-labels',
    templateUrl: './labels.component.html',
    animations: [FadeInAnimation, SlideInOutAnimation]
})

export class LabelsComponent implements OnInit {
    labels: Array<Labels> = [];
    pagination: PpgPagination | undefined;
    searchAndFilter: SearchAndFilterCloudsReqObj = {
        query: '',
        cloud_type: {id: 0, name: ''},
        type_id: 0
    };
    editModal = false;
    createModal = false;
    label: Labels | undefined;
    pageNumber = 1;

    constructor(private useCaseHttp: UseCasesHttpService) {
    }

    ngOnInit(): void {
        this.getLabels(this.pageNumber);
    }

    getLabels(pageNumber: number): void {
        this.pageNumber = pageNumber;

        this.useCaseHttp.getLabels(pageNumber).then((response: any): void => {
            this.labels = response.labels;
            this.pagination = response.pagination;
        });
    }

    onPageChanged(pageNumber: number): void {
        this.pageNumber = pageNumber;

        if (this.searchAndFilter.query !== '') {
            this.searchLabel(this.searchAndFilter, pageNumber);
        } else {
            this.getLabels(pageNumber);
        }
    }

    createLabel(): void {

    }

    editLabel(label: Labels): void {
        this.editModal = true;
        this.label = label;
    }

    searchLabel(searchAndFilter: SearchAndFilterCloudsReqObj, pageNumber: number | null = null): void {
        this.useCaseHttp.searchLabels(this.searchAndFilter, pageNumber ? pageNumber : 1).then((response: any) => {
            this.labels = response.labels;
            this.pagination = response.pagination;
        });
    }

    resetEditModal(): void {
        this.editModal = false;
    }

    resetCreateModal(): void {
        this.createModal = false;
    }

    updateLabelModal(): void {
        this.resetEditModal();
        this.getLabels(this.pageNumber);
    }

    createLabelModal(): void {
        this.resetCreateModal();
        this.getLabels(this.pageNumber);
    }
}
