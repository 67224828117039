import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {SourcesHttpService} from '../../services/http-services/sources-http.service';
import {FileExtension} from '../../interfaces/sources-interfaces';
import {FileExtensions} from '../../constants/general-variables';

@Component({
    selector: 'app-file-extensions',
    templateUrl: './file-extensions.component.html'
})
export class FileExtensionsComponent implements OnInit {

    @Input() checkedExtensions: Array<FileExtension> | undefined | null = [];
    fileExtensions: Array<FileExtension> = [];
    @Output() extensionsChanged = new EventEmitter<Array<FileExtension>>();

    constructor(private sourcesHttpService: SourcesHttpService) {
    }

    ngOnInit(): void {
        this.getExtensions();
    }

    checkAlreadyCheckedExtensions(): void {
        this.checkedExtensions?.forEach(ext => {
            const extIndex = this.fileExtensions.findIndex(x => x.id === ext.id);
            if (extIndex !== -1) {
                this.fileExtensions[extIndex].isChecked = true;
            }
        });
    }

    extensionChanged(extension: FileExtension): void {
        extension.isChecked = !extension.isChecked;
        this.extensionsChanged.emit(this.fileExtensions);
    }

    getExtensions(): void {
        this.sourcesHttpService.getExtensions().then((response: any) => {
            this.fileExtensions = response;
            this.fileExtensions.map(ext => {
                ext.isChecked = false;
                if (ext.name === FileExtensions.pdf.name) {
                    ext.isChecked = true;
                }
                if (this.checkedExtensions) {
                    this.checkAlreadyCheckedExtensions();
                }
            });
            this.extensionsChanged.emit(this.fileExtensions);
        });
    }

    returnExtensionIcon(extension: FileExtension): string {
        // @ts-ignore
        return FileExtensions[extension.name].imagePath;
    }

}
