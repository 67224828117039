import {trigger, style, transition, animate} from '@angular/animations';
import {fadeInAnimation} from '../constants/general-variables';

export const FadeInAnimation = [
    trigger('fadingIn', [
        transition('void => *', [
            style({opacity: 0}),
            animate(fadeInAnimation, style({opacity: 1}))
        ]),
        transition('* => void', [
            animate(0, style({opacity: 0}))
        ])
    ])
];

