<div class="flex w-full bg-white pr-6 border-b border-gray-200"
     *ngFor="let body of useCaseSingleData?.body">
    <div *ngIf="body" class="flex items-center cursor-pointer" (click)="clickedDataBody.emit(body)">
        <div class="p-2 flex justify-center items-center w-14">
            <img src="../../../assets/images/icons/icon-gps.svg" alt="">
        </div>
    </div>
    <div class="flex flex-col w-full border-l border-gray-200 pl-6" *ngIf="body">
        <div class="pt-2" *ngIf="body.standardizeLabels.length">
            <div class="flex flex-wrap items-center">
                <span class="text-gray-800 text-xs font-medium mb-2 mr-3">Labels: </span>
                <span class="font-medium text-xs text-green-800 bg-green-100 px-2 py-1 rounded mb-2 mr-3"
                      *ngFor="let label of body.standardizeLabels" title="{{ label.initLabel }}">
                    <span *ngIf="label.label_name">{{ label.label_name }}</span>
                    <span *ngIf="! label.label_name">{{ label.label }}</span>
                </span>
                <span class="font-medium text-xs text-green-800 bg-green-100 px-2 py-1 rounded mb-2 mr-3"
                      *ngIf="body.labels.length === 0">
                    No labels</span>
            </div>
        </div>
        <div class="flex justify-between py-2">
            <div class="flex flex-col" *ngIf="body.categories?.length">
                <span class="text-xs text-gray-500 font-medium">Field Name</span>
                <span class="text-sm text-gray-900 font-medium" *ngFor="let category of body.categories">{{ category }}</span>
            </div>
            <div class="flex flex-col" *ngIf="!body.categories?.length">
                <span class="text-xs text-gray-500 font-medium">Field Name</span>
                <span class="text-sm text-gray-900 font-medium">/</span>
            </div>
            <div class="flex flex-col">
                <span class="text-xs text-gray-500 font-medium">Value</span>
                <span
                    class="text-sm text-gray-900 font-medium">{{body?.value?.standardized_value ? body?.value?.standardized_value : '/'}}</span>
            </div>
            <div class="flex flex-col">
                <span class="text-xs text-gray-500 font-medium">Date</span>
                <span
                    class="text-sm text-gray-900 font-medium">{{body?.value?.date ? body?.value?.date : '/'}}</span>
            </div>
            <div class="flex flex-col">
                <span class="text-xs text-gray-500 font-medium">Currency</span>
                <span
                    class="text-sm text-gray-900 font-medium">{{body?.value?.currency ? body?.value?.currency : '/'}}</span>
            </div>
            <div class="flex flex-col">
                <span class="text-xs text-gray-500 font-medium">Unit</span>
                <span
                    class="text-sm text-gray-900 font-medium">{{body?.value?.unit ? body?.value?.unit : '/'}}</span>
            </div>
        </div>
    </div>
</div>
