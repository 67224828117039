import {Component, ElementRef, EventEmitter, Input, OnInit, Output, Renderer2, ViewChild} from '@angular/core';
import {SlideInOutAnimation} from '../../../animations/slide-modal-animation';
import {GeneralHelperService} from '../../../services/helpers/general-helper.service';
import {LabelAliases, Labels, UpdateAliasesReq} from '../../../interfaces/use-case-interfaces';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {UseCasesHttpService} from '../../../services/http-services/use-cases-http.service';

@Component({
    selector: 'app-view-single-label-modal',
    templateUrl: './view-single-label-modal.component.html',
    animations: [SlideInOutAnimation]
})
export class ViewSingleLabelModalComponent implements OnInit {

    @Input() label: Labels | undefined;
    @Output() modalClosed = new EventEmitter<boolean>();
    opened = false;
    errors: any = {};
    @ViewChild('modal') modal: ElementRef | undefined;

    labelForm = new FormGroup({
        label: new FormControl({value: '', disabled: false}, Validators.required),
        category: new FormControl({value: '', disabled: false}),
    });

    updatingAlias: LabelAliases | undefined;
    creatingAlias = false;

    createdAliases: Array<string> = [];
    updatedAliases: Array<LabelAliases> = [];
    deletedAliases: Array<LabelAliases> = [];
    @Output() updateLabel = new EventEmitter<boolean>();

    constructor(private renderer: Renderer2,
                private useCaseHttp: UseCasesHttpService,
                private generalHelperService: GeneralHelperService) {
    }

    ngOnInit(): void {
        this.opened = true;

        setTimeout(() => {
            this.listenToClickType();
        });

        this.labelForm.controls.label.setValue(this.label?.label);

        if (this.label?.categories.length) {
            this.labelForm.controls.category.setValue(this.label?.categories[0].category);
        }
    }

    listenToClickType(): void {
        this.renderer.listen('window', 'click', (e: Event) => {
            if (this.generalHelperService.checkIfClickedOutsideOfTheElement(undefined, this.modal, e)) {}
        });
    }

    updateModal(): void {
        const updateAlias: Array<UpdateAliasesReq> = this.updatedAliases.map(x => {
            return {
                id: x.id,
                alias: x.newAlias,
            };
        });

        this.useCaseHttp.updateLabel(
            // @ts-ignore
            this.label.id,
            {
                label: this.labelForm.controls.label.value,
                category: this.labelForm.controls.category.value,
                create_aliases: this.createdAliases,
                update_aliases: updateAlias,
                delete_aliases: this.deletedAliases.map(x => x.id),
            },
        ).then(r => this.updateLabel.emit(true))
        .catch(e => this.errors = e.error.errors);
    }

    createdAlias(): void {
        this.creatingAlias = true;
    }

    createValue(createAlias: string): void {
        this.createdAliases.push(createAlias);

        this.creatingAlias = false;
    }

    deleteCreatedAlias(alias: string): void {
        if (window.confirm('Are you sure?')) {
            this.createdAliases = this.createdAliases.filter(x => x !== alias);
        }
    }

    updatedAlias(alias: LabelAliases): void {
        this.updatingAlias = alias;
    }

    updateValue(alias: LabelAliases, updatingAlias: string): void {
        const filtered: Array<LabelAliases> = this.updatedAliases.filter(x => x.id === alias.id);

        if (filtered.length) {
            this.updatedAliases = filtered.map(x => {
                x.newAlias = updatingAlias;

                return x;
            });
        } else {
            this.updatedAliases.push({
                id: alias.id,
                label_id: alias.label_id,
                alias: alias.alias,
                newAlias: updatingAlias
            });
        }

        this.updatingAlias = undefined;
    }

    deleteAlias(alias: LabelAliases): void {
        if (window.confirm('Are you sure?')) {
            this.deletedAliases.push(alias);

            // @ts-ignore
            this.label?.aliases = this.label?.aliases.filter(x => x.id !== alias.id);
        }
    }

    deleteUpdatedAlias(alias: LabelAliases): void {
        if (window.confirm('Are you sure?')) {
            this.updatedAliases = this.updatedAliases.filter(x => x.id !== alias.id);
        }
    }
}
