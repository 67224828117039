import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {UseCaseDataViewTypes} from '../../../enums/use-cases-enums';
import {
    DataBody, QueryBody,
    UseCaseData,
    UseCaseDataBodyEmitter, UseCaseDataQueryEmitter,
    UseCaseDataResponse, UseCaseQuery
} from '../../../interfaces/use-case-data-interfaces';

@Component({
    selector: 'app-use-case-data',
    templateUrl: './use-case-data.component.html'
})
export class UseCaseDataComponent implements OnInit {

    @Input() useCaseId = 0;
    UseCaseDataViewTypes = UseCaseDataViewTypes;
    activeView: UseCaseDataViewTypes = UseCaseDataViewTypes.all;
    useCaseData: UseCaseDataResponse | undefined;
    singleUseCaseData: UseCaseData | undefined;
    singleUseCaseQuery: UseCaseQuery | undefined;
    activeDataBody: DataBody | undefined;
    activeQueryBody: QueryBody | undefined;
    @Output() changedView = new EventEmitter<UseCaseDataViewTypes>();
    @Output() changedViewQuery = new EventEmitter<UseCaseDataViewTypes>();

    constructor() {
    }

    ngOnInit(): void {
    }

    changedViewToSingle(event: UseCaseDataBodyEmitter): void {
        this.activeView = event.viewType;
        this.singleUseCaseData = event.dataBody;
        this.activeDataBody = event.singleDataBody;
        this.useCaseData = event.useCaseData;
        this.changedView.emit(this.activeView);
    }

    changedQueryViewToSingle(event: UseCaseDataQueryEmitter): void {
        this.activeView = UseCaseDataViewTypes.singleQuery;
        this.singleUseCaseQuery = event.dataBody;
        this.activeQueryBody = event.singleDataBody;
        this.useCaseData = event.useCaseData;
        this.changedView.emit(this.activeView);
    }
}
