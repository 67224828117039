import { ElementRef, Injectable } from '@angular/core';
import { FileExtension } from '../../interfaces/sources-interfaces';
import * as moment from 'moment';

@Injectable({
    providedIn: 'root'
})
export class GeneralHelperService {

    constructor() {
    }

    formatTimestampToMomentDateAndTime(timestamp: number): string {
        return moment(timestamp * 1000).format('YYYY-MM-DD HH:mm');
    }

    checkIfScrolledToBottom(scrollEvent: any): boolean {
        return Math.round(scrollEvent.target.scrollTop) === Math.round((scrollEvent.target.scrollHeight - scrollEvent.target.offsetHeight));
    }

    checkIfClickedOutsideOfTheElement(toggleButton: ElementRef | undefined, menu: ElementRef | undefined, e: any): boolean {
        return e.target !== toggleButton?.nativeElement && !toggleButton?.nativeElement.contains(e.target)
            && e.target !== menu?.nativeElement && !menu?.nativeElement.contains(e.target);
    }

    returnIpWithDots(ip: string): string {
        return (ip.includes('.')) ? ip : ip.slice(0, 2) + '.' + ip.slice(2, 5) + '.' + ip.slice(5, 8) + '.' + ip.slice(8, 10);
    }

    checkExtensionValidity(fileExtension: Array<FileExtension>): boolean {
        let isCheckedExt = false;
        for (const ext of fileExtension) {
            if (ext.isChecked) {
                isCheckedExt = true;
                break;
            }
        }
        return isCheckedExt;
    }

    validateEmail(email: string): any {
        const mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        if (email.match(mailformat)) {
            return true;
        }
        else {
            return false;
        }
    }

    checkForSpaces(value: any): any {
        if (!value.replace(/\s/g, '').length) {
            return true;
        } else {
            return false;
        }
    }

}
