import {ChangeDetectorRef, Component, OnInit, ViewChild} from '@angular/core';
import {UseCasesCategories, UseCasesViewTypes} from '../../enums/use-cases-enums';
import {UseCasesHttpService} from '../../services/http-services/use-cases-http.service';
import {
    GetUseCasesPaginationResponse,
    GetUseCasesResponse,
    UseCase,
    UseCaseDeleteModal,
    UseCaseEditModal
} from '../../interfaces/use-case-interfaces';
import {PpgPagination} from '../../interfaces/general-interfaces';
import {AuthenticationHelperService} from '../../services/helpers/authentication-helper.service';
import {AuthHttpService} from '../../services/http-services/auth-http.service';
import Swiper, {SwiperOptions} from 'swiper';
import {SwiperComponent} from 'swiper/angular';
import {FadeInAnimation} from '../../animations/fade-in-animation';

@Component({
    selector: 'app-use-cases',
    templateUrl: './use-cases.component.html',
    animations: [FadeInAnimation]
})
export class UseCasesComponent implements OnInit {

    collapsedPinned = true;
    showModal = false;
    categoryView = UseCasesCategories.all;
    viewType = UseCasesViewTypes.grid;
    UseCasesViewTypes = UseCasesViewTypes;
    useCases: GetUseCasesResponse | undefined;
    useCasesFetchRequestFinished = false;
    deleteUseCaseModal: UseCaseDeleteModal | undefined;
    editUseCaseModal: UseCaseEditModal | undefined;
    searchQuery = '';
    initialUseCases: GetUseCasesResponse | undefined;
    mySwiper: Swiper = new Swiper('.swiper-container', {
        // @ts-ignore
        pagination: '.swiper-pagination',
        paginationClickable: true,
        nextButton: '.swiper-button-next',
        prevButton: '.swiper-button-prev',
        // @ts-ignore
        autoplay: 3000,
        spaceBetween: 30
    });
    config: SwiperOptions = {
        slidesPerView: 1,
        spaceBetween: 25,
        pagination: {clickable: true},
        scrollbar: {draggable: true},
    };
    numberOfPinnedUseCasesPerSlide = 5;
    activeSwiperIndex = 0;
    @ViewChild('swiper', {static: false}) swiper?: SwiperComponent;

    constructor(private useCasesHttp: UseCasesHttpService,
                public authHelperService: AuthenticationHelperService,
                private authHttpService: AuthHttpService,
                private changeDetectorRef: ChangeDetectorRef) {
    }

    ngOnInit(): void {
        this.getUseCases();
    }

    onActiveSwiperIndexChange(swiper: any): void {
        this.activeSwiperIndex = swiper.activeIndex;
        this.changeDetectorRef.detectChanges();
    }

    slideTo(index: number): void {
        this.activeSwiperIndex = index;
        // @ts-ignore
        this.swiper.swiperRef.slideTo(index);
    }

    calculateNumberOfSlidesPerPinnedUseCases(): Array<number> {
        if (this.useCases && this.useCases.pinnedUseCases && this.useCases.pinnedUseCases.length > 0) {
            const numArrOfNumsToRet = [];
            if (this.useCases.pinnedUseCases.length >= this.numberOfPinnedUseCasesPerSlide) {
                const numberOfEls = Math.floor(this.useCases.pinnedUseCases.length / this.numberOfPinnedUseCasesPerSlide);
                for (let i = 0; i < numberOfEls; i++) {
                    numArrOfNumsToRet.push(i);
                }
                if (this.useCases.pinnedUseCases.length % this.numberOfPinnedUseCasesPerSlide) {
                    numArrOfNumsToRet.push(numArrOfNumsToRet.length);
                }
                return numArrOfNumsToRet;
            }
            return [0];
        }
        return [];
    }

    returnUseCases(indexNum: number): Array<UseCase> {
        const arrOfUseCasesToRet: Array<UseCase> = [];
        if (indexNum !== 0) {
            indexNum += (this.numberOfPinnedUseCasesPerSlide - 1) * indexNum;
        }
        for (let i = indexNum; i < (indexNum + this.numberOfPinnedUseCasesPerSlide); i++) {
            if (this.useCases && this.useCases.pinnedUseCases) {
                if (this.useCases?.pinnedUseCases[i]) {
                    arrOfUseCasesToRet.push(this.useCases?.pinnedUseCases[i]);
                }
            }
        }
        return arrOfUseCasesToRet;
    }

    onSelectedPaginationDetails(paginationNumber: number): void {
        this.authHelperService.pagination = paginationNumber;
        this.getUseCases();
    }

    onFinishedOnboarding(): void {
        this.authHttpService.finishGuide().subscribe((response: any) => {
            const userData = this.authHelperService.getUserData;
            userData.guide = 1;
            this.authHelperService.setUserData(userData);
        });
    }

    showModalForUseCaseEdit(useCase: UseCase, isPinned: boolean): void {
        this.editUseCaseModal = {useCase, isPinned, show: true};
    }

    restoreEditUseCaseModalToDefault(): void {
        this.editUseCaseModal = {show: false, useCase: undefined, isPinned: false};
    }

    onUseCaseEdited(editedUseCaseModal: UseCaseEditModal | undefined): void {
        (editedUseCaseModal?.isPinned) ?
            this.changeViewForPinnedUseCase(editedUseCaseModal.useCase) :
            this.changeViewForUnPinnedUseCase(editedUseCaseModal?.useCase);
        this.restoreEditUseCaseModalToDefault();
    }

    changeViewForUnPinnedUseCase(useCase: UseCase | undefined): void {
        if (useCase) {
            const useCaseIndex = this.useCases?.allUseCases.data.findIndex(x => x.id === useCase.id);
            if (useCaseIndex !== -1 && useCaseIndex) {
                if (this.useCases) {
                    this.useCases.allUseCases.data[useCaseIndex] = useCase;
                }
            }
        }
    }

    changeViewForPinnedUseCase(useCase: UseCase | undefined): void {
        if (useCase) {
            const useCaseIndex = this.useCases?.pinnedUseCases.findIndex(x => x.id === useCase.id);
            if (useCaseIndex !== -1 && useCaseIndex) {
                if (this.useCases) {
                    this.useCases.pinnedUseCases[useCaseIndex] = useCase;
                }
            }
        }
    }

    onUseCaseDeleteModalClosed(): void {
        if (this.deleteUseCaseModal) {
            this.deleteUseCaseModal.show = false;
        }
    }

    onUseCaseTogglePin(isPinned: boolean, useCase: UseCase, useCasesCategory: UseCasesCategories): void {
        this.useCasesHttp.toggleUseCasePin(useCase, isPinned, useCasesCategory).then((pinnedUseCase: UseCase) => {
            if (pinnedUseCase) {
                (isPinned) ? this.unPinUseCaseOnView(useCase) : this.pinUseCaseOnView(useCase);
            }
        });
    }

    pinUseCaseOnView(useCase: UseCase): void {
        this.getUseCases();
    }

    unPinUseCaseOnView(useCase: UseCase): void {
        this.getUseCases();
    }

    onUseCaseDelete(useCase: UseCase): void {
        this.useCasesHttp.deleteUseCase(useCase).then((deletedUseCase: UseCase) => {
            if (deletedUseCase) {
                this.deleteUseCaseFromView(this.deleteUseCaseModal, deletedUseCase);
            }
        });
    }

    deleteUseCaseFromView(deleteUseCaseModal: UseCaseDeleteModal | undefined, deletedUseCase: UseCase): void {
        (this.deleteUseCaseModal?.isPinned) ?
            this.deletePinnedUseCaseFromView(deletedUseCase) :
            this.deletedUnPinnedUseCaseFromView(deletedUseCase);
        this.deleteUseCaseModal = {show: false, useCase: undefined, isPinned: false};
    }

    deletePinnedUseCaseFromView(deletedUseCase: UseCase): void {
        const useCaseIndex: number | undefined = this.useCases?.pinnedUseCases.findIndex((x: UseCase) => x.id === deletedUseCase.id);
        if (typeof useCaseIndex !== 'undefined' && useCaseIndex !== -1) {
            this.useCases?.pinnedUseCases.splice(useCaseIndex, 1);
        }
    }

    deletedUnPinnedUseCaseFromView(deletedUseCase: UseCase): void {
        const useCaseIndex: number | undefined = this.useCases?.allUseCases.data.findIndex((x: UseCase) => x.id === deletedUseCase.id);
        if (typeof useCaseIndex !== 'undefined' && useCaseIndex !== -1) {
            this.useCases?.allUseCases.data.splice(useCaseIndex, 1);
        }
    }

    promptDeleteUseCaseModal(useCase: UseCase, isPinned: boolean): void {
        this.deleteUseCaseModal = {show: true, useCase, isPinned};
    }

    onPageChanged(pageNumber: number): void {
        (this.searchQuery.trim()) ?
            this.searchUseCases(pageNumber) :
            this.getUseCasePagination(pageNumber);
    }

    searchUseCases(pageNumber: number | null = null): void {
        this.useCasesHttp.searchUseCases(this.searchQuery, pageNumber).then((response: { pagination: PpgPagination, useCases: Array<UseCase> }) => {
            this.useCasesFetchRequestFinished = true;
            if (this.useCases) {
                this.useCases.allUseCases.data = response.useCases;
                this.useCases.allUseCases.pagination = response.pagination;
            }
        });
    }

    getUseCasePagination(pageNumber: number): void {
        this.useCasesHttp.getUseCasesPagination(pageNumber, this.categoryView).then((response: GetUseCasesPaginationResponse) => {
            if (this.useCases) {
                this.useCases.allUseCases = response.allUseCases;
            }
        });
    }

    showUseCases(useCases: GetUseCasesResponse | undefined): boolean | undefined {
        return (useCases && useCases.allUseCases && typeof useCases.allUseCases.pagination.totalItems !== 'undefined' && useCases.allUseCases.pagination.totalItems > 0) ||
            (useCases && useCases.pinnedUseCases.length > 0);
    }

    onSearchChanged(newSearchQuery: string): void {
        this.searchQuery = newSearchQuery;
        this.categoryView = UseCasesCategories.all;
        if (newSearchQuery) {
            this.searchUseCases();
        } else {
            this.getUseCases();
        }
    }

    getUseCases(): void {
        this.useCasesHttp.getUseCases(this.categoryView).then((response: GetUseCasesResponse) => {
            this.useCasesFetchRequestFinished = true;
            this.initialUseCases = JSON.parse(JSON.stringify(response));
            this.useCases = response;
        });
    }

    onChangedCategoryView(newCategory: UseCasesCategories): void {
        this.categoryView = newCategory;
        this.getUseCases();
    }

    onChangedDisplayType(newType: UseCasesViewTypes): void {
        this.viewType = newType;
    }

    collapse(): void {
        this.collapsedPinned = !this.collapsedPinned;
    }

}
