import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {SearchDataType, UseCaseBodyDataViewTypes, UseCaseDataViewTypes} from '../../../../enums/use-cases-enums';
import {
    ActiveDocumentImage,
    DataBody,
    DataDocument,
    DocumentImage,
    DocumentImagesResponse, SearchAndFilterUseCaseData,
    SearchTypeParams,
    UseCaseData,
    UseCaseDataResponse
} from '../../../../interfaces/use-case-data-interfaces';
import {ActivePageHelperService} from '../../../../services/helpers/active-page-helper.service';
import {UseCaseDataHttpService} from '../../../../services/http-services/use-case-data-http.service';
import {FadeInAnimation} from '../../../../animations/fade-in-animation';

@Component({
    selector: 'app-use-case-single',
    templateUrl: './use-case-single.component.html',
    animations: [FadeInAnimation]
})
export class UseCaseSingleComponent implements OnInit, OnDestroy {

    @Input() singleUseCaseData: UseCaseData | undefined;
    @Input() activeDataBody: DataBody | undefined;
    @Input() useCaseData: UseCaseDataResponse | undefined;
    @Input() useCaseId = 0;
    useCaseDataForView: Array<UseCaseData> | undefined = [];
    UseCaseDataViewTypes = UseCaseDataViewTypes;
    documentImage = '';
    UseCaseBodyDataViewTypes = UseCaseBodyDataViewTypes;
    activeDataBodyTypeView = UseCaseBodyDataViewTypes.current;
    waitingImageResponse = false;
    dataDocument: DataDocument | undefined;
    documentImages: Array<DocumentImage> = [];
    activeDocImgPage: ActiveDocumentImage | undefined;
    searchAndFilter: SearchAndFilterUseCaseData = {
        query: '',
        source: null,
        syncSource: null
    };
    @Output() changedView = new EventEmitter<UseCaseDataViewTypes>();

    constructor(private activePageHelper: ActivePageHelperService,
                private useCaseDataHttpService: UseCaseDataHttpService) {
    }

    ngOnInit(): void {
        if (this.singleUseCaseData) {
            this.fillUseCaseDataForView([this.singleUseCaseData]);
            this.getDocumentImage(this.singleUseCaseData?.image_id);
            this.getDocumentImages(this.singleUseCaseData?.document_id);
        }
        this.activePageHelper.showSideMenu = false;
    }

    changeDataBody(event: DataBody | undefined, useCaseSingleData: UseCaseData): void {
        if (this.singleUseCaseData && useCaseSingleData && this.singleUseCaseData?.id !== useCaseSingleData.id) {
            this.singleUseCaseData = useCaseSingleData;
            this.getDocumentImage(this.singleUseCaseData?.image_id);
            this.getDocumentImages(this.singleUseCaseData?.document_id);
        }
        this.activeDataBody = event;
    }

    fillUseCaseDataForView(useCaseData: Array<UseCaseData | undefined> | undefined): void {
        if (this.useCaseDataForView && useCaseData) {
            this.useCaseDataForView = JSON.parse(JSON.stringify(useCaseData));
        }
    }

    ngOnDestroy(): void {
        this.activePageHelper.showSideMenu = true;
    }

    getDocumentImages(documentId: number): void {
        this.useCaseDataHttpService.getDocumentImages(documentId).then((documentImagesResponse: DocumentImagesResponse) => {
            this.dataDocument = documentImagesResponse.document;
            this.documentImages = documentImagesResponse.images;
        }, error => {
        });
    }

    changeDocImageBySpecificNumber(): void {
        if (this.activeDocImgPage?.page_number || this.activeDocImgPage?.page_number === 0) {
            if (this.activeDocImgPage?.page_number > this.documentImages.length) {
                this.findDocumentImageOrFetch(this.documentImages[this.documentImages.length - 1].id);
            } else if (this.activeDocImgPage?.page_number <= 0) {
                this.findDocumentImageOrFetch(this.documentImages[0].id);
            } else {
                for (const doc of this.documentImages) {
                    if (doc.page_number === this.activeDocImgPage?.page_number) {
                        this.findDocumentImageOrFetch(doc.id);
                        break;
                    }
                }
            }
        }
    }

    changeDocImage(inc: boolean): void {
        if (this.singleUseCaseData && this.activeDocImgPage) {
            let imageId = 0;
            if (!inc) {
                imageId = this.documentImages[this.activeDocImgPage.index - 1].id;
            } else {
                imageId = this.documentImages[this.activeDocImgPage.index + 1].id;
            }
            this.findDocumentImageOrFetch(imageId);
        }
    }

    findDocumentImageOrFetch(docImageId: number): void | true {
        this.waitingImageResponse = true;
        const searchTypeParams: SearchTypeParams = {
            searchDataType: SearchDataType.documentImage,
            searchDataValue: docImageId
        };
        this.useCaseDataHttpService.searchUseCaseData('', null, searchTypeParams).then((response: UseCaseDataResponse) => {
            this.waitingImageResponse = false;
            this.useCaseData?.useCaseData.push(JSON.parse(JSON.stringify(response.useCaseData[0])));
            this.singleUseCaseData = JSON.parse(JSON.stringify(response.useCaseData[0]));
            this.activeDataBody = this.singleUseCaseData?.body[0];
            if (this.singleUseCaseData) {
                this.getDocumentImage(this.singleUseCaseData.image_id);
                this.getDocumentImages(this.singleUseCaseData.document_id);
            }
            this.fillUseCaseDataForView([this.singleUseCaseData]);
            this.activeDataBodyTypeView = UseCaseBodyDataViewTypes.current;
        }, error => {
            this.waitingImageResponse = false;
        });
    }

    changeCurrentPage(): void {
        for (let i = 0; i < this.documentImages.length; i++) {
            if (this.singleUseCaseData && this.singleUseCaseData?.image_id === this.documentImages[i].id) {
                const activeDocImg = this.documentImages[i];
                this.activeDocImgPage = {index: i, id: activeDocImg.id, page_number: activeDocImg.page_number};
            }
        }
    }

    getDocumentImage(imageId: number): void {
        this.waitingImageResponse = true;
        this.useCaseDataHttpService.getImagePreview(imageId).then((documentPreview: string) => {
            this.waitingImageResponse = false;
            this.documentImage = documentPreview;
            this.changeCurrentPage();
        }, error => {
            this.waitingImageResponse = false;
        });
    }

    showData(useCaseData: UseCaseDataResponse | undefined): boolean | undefined {
        return useCaseData && useCaseData.useCaseData && typeof useCaseData.pagination.totalItems !== 'undefined' && useCaseData.pagination.totalItems > 0;
    }

}
