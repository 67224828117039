import {Injectable} from '@angular/core';
import {ActivePage} from '../../interfaces/general-interfaces';

@Injectable({
    providedIn: 'root'
})
export class ActivePageHelperService {

    private _showSideMenu = true;
    private _activePage: ActivePage | undefined;

    constructor() {
    }

    get showSideMenu(): boolean {
        return this._showSideMenu;
    }

    set showSideMenu(value: boolean) {
        this._showSideMenu = value;
    }

    get activePage(): ActivePage | undefined {
        return this._activePage;
    }

    set activePage(value: ActivePage | undefined) {
        console.log('Active page: ', value)
        this._activePage = value;
    }
}
