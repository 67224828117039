<form class="space-y-6" [formGroup]="useCaseForm">
    <div>
        <label for="useCaseName" class="block text-sm font-medium text-gray-700">Use Case name</label>
        <div class="mt-2">
            <input formControlName="useCaseName" type="text" name="useCaseName" id="useCaseName"
                   class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                   placeholder="">
        </div>
    </div>

    <div class="grid grid-cols-2 gap-x-4">
        <button class="inline-flex items-center justify-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md"
                (click)="closeModal()">Cancel
        </button>
        <button [disabled]="!useCaseForm.valid" (click)="saveUseCase()"
                class="inline-flex items-center justify-center px-3 py-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500">
            Save
        </button>
    </div>
</form>
