import {Pipe, PipeTransform} from '@angular/core';
import {FileExtension} from '../../interfaces/sources-interfaces';

@Pipe({
    name: 'allowedFileExtensions'
})
export class AllowedFileExtensionsPipe implements PipeTransform {

    transform(value: Array<FileExtension> | undefined, ...args: unknown[]): unknown {
        let strToRet = 'Allowed file extensions: ';
        if (value && value.length) {
            value.forEach(ext => {
                if (ext.isChecked) {
                    strToRet += ext.name + ' ';
                }
            });
        }
        return strToRet;
    }

}
