<li class="bg-white shadow overflow-hidden sm:rounded-md hover:shadow-xl mb-2">
    <div class="block">
        <div class="flex items-center px-4 py-4 sm:px-6">
            <div class="min-w-0 flex-1 flex items-center">
                <div class="flex-shrink-0">
                    <svg width="25" height="34" viewBox="0 0 25 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M2.06836 14V12C2.06836 9 4.06836 7 7.06836 7H17.0684C20.0684 7 22.0684 9 22.0684 12V14" stroke="#4B5563" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M2.06836 20V22C2.06836 25 4.06836 27 7.06836 27H17.0684C20.0684 27 22.0684 25 22.0684 22V20" stroke="#4B5563" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M6.76758 14.2598L12.0676 17.3298L17.3276 14.2798" stroke="#4B5563" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M12.0684 22.7698V17.3198" stroke="#4B5563" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M10.8286 11.2901L7.62859 13.0701C6.90859 13.4701 6.30859 14.4801 6.30859 15.3101V18.7001C6.30859 19.5301 6.89859 20.5401 7.62859 20.9401L10.8286 22.7201C11.5086 23.1001 12.6286 23.1001 13.3186 22.7201L16.5186 20.9401C17.2386 20.5401 17.8386 19.5301 17.8386 18.7001V15.3101C17.8386 14.4801 17.2486 13.4701 16.5186 13.0701L13.3186 11.2901C12.6286 10.9001 11.5086 10.9001 10.8286 11.2901Z" stroke="#4B5563" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </div>
                <div class="ml-3">
                    <h3 class="text-sm text-gray-900 leading-5 font-medium">{{useCase?.name}}</h3>
                </div>
                <div class="flex gap-4 ml-auto">
                    <div class="flex items-center">
                        <div class="flex items-center">
                            <div class="flex items-center">
                                <svg class="transform xl:scale-125" width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M5.90104 3.49984H8.23438C9.40104 3.49984 9.40104 2.9165 9.40104 2.33317C9.40104 1.1665 8.81771 1.1665 8.23438 1.1665H5.90104C5.31771 1.1665 4.73438 1.1665 4.73438 2.33317C4.73438 3.49984 5.31771 3.49984 5.90104 3.49984Z" stroke="#9CA3AF" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M8.23503 12.8335H5.31836C2.40169 12.8335 1.81836 11.6669 1.81836 9.33355V5.83355C1.81836 3.17355 2.79253 2.45021 4.73503 2.34521" stroke="#9CA3AF" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M9.40234 2.34521C11.3448 2.45021 12.319 3.16771 12.319 5.83355V8.75021" stroke="#9CA3AF" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M12.3184 11.0835V12.8335H10.5684" stroke="black" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M8.81836 9.3335L12.295 12.8102" stroke="black" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                                <span class="text-base ml-2">{{useCase?.destinations_count}}</span>
                            </div>
                            <div class="text-sm text-gray-500 font-medium ml-2">Destinations</div>
                        </div>
                        <div class="flex items-center ml-4">
                            <div class="flex items-center">
                                <svg class="transform xl:scale-125" width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M5.83268 3.49984H8.16602C9.33268 3.49984 9.33268 2.9165 9.33268 2.33317C9.33268 1.1665 8.74935 1.1665 8.16602 1.1665H5.83268C5.24935 1.1665 4.66602 1.1665 4.66602 2.33317C4.66602 3.49984 5.24935 3.49984 5.83268 3.49984Z" stroke="#9CA3AF" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M8.16667 12.8335H5.25C2.33333 12.8335 1.75 11.6669 1.75 9.33355V5.83355C1.75 3.17355 2.72417 2.45021 4.66667 2.34521" stroke="#9CA3AF" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M9.33398 2.34521C11.2765 2.45021 12.2507 3.16771 12.2507 5.83355V8.75021" stroke="#9CA3AF" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M8.75 11.0835V9.3335H10.5" stroke="black" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M12.2501 12.8331L8.77344 9.35645" stroke="black" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                                <span class="text-base ml-2">{{useCase?.sources_count}}</span>
                            </div>
                            <div class="text-sm text-gray-500 font-medium ml-2">Sources</div>
                        </div>
                    </div>
                    <div class="flex items-center -mr-6 space-x-4">
                        <a class="px-2" (click)="deleteUseCase.emit(useCase)">
                            <svg class="transform xl:scale-125" width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M14.9316 3.98665C12.7116 3.76665 10.4783 3.65332 8.25164 3.65332C6.93164 3.65332 5.61164 3.71999 4.29164 3.85332L2.93164 3.98665" stroke="#EF4444" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M6.59766 3.3135L6.74432 2.44016C6.85099 1.80683 6.93099 1.3335 8.05766 1.3335H9.80432C10.931 1.3335 11.0177 1.8335 11.1177 2.44683L11.2643 3.3135" stroke="#EF4444" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M13.4986 6.09326L13.0652 12.8066C12.9919 13.8533 12.9319 14.6666 11.0719 14.6666H6.7919C4.9319 14.6666 4.8719 13.8533 4.79857 12.8066L4.36523 6.09326" stroke="#EF4444" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M7.81836 11H10.0384" stroke="#EF4444" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M7.26562 8.3335H10.599" stroke="#EF4444" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                        </a>
                        <a (click)="togglePinUseCase.emit(useCase)" class="px-2">
                            <svg *ngIf="isPinned" class="transform xl:scale-125" width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M3.34543 5.66016C4.65876 -0.113169 13.2121 -0.106502 14.5188 5.66683C15.2854 9.0535 13.1788 11.9202 11.3321 13.6935C9.9921 14.9868 7.8721 14.9868 6.52543 13.6935C4.68543 11.9202 2.57876 9.04683 3.34543 5.66016Z" stroke="#4B5563" stroke-width="1.5"/>
                                <path d="M10.265 8.64L7.625 6" stroke="#4B5563" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M10.2377 6.02686L7.59766 8.66686" stroke="#292D32" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                            <svg *ngIf="!isPinned" class="transform xl:scale-125" width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M2.41379 5.66016C3.72712 -0.113169 12.2805 -0.106502 13.5871 5.66683C14.3538 9.0535 12.2471 11.9202 10.4005 13.6935C9.06046 14.9868 6.94046 14.9868 5.59379 13.6935C3.75379 11.9202 1.64712 9.04683 2.41379 5.66016Z" stroke="#4B5563" stroke-width="1.5"/>
                                <path d="M6.16602 7.66667L7.16602 8.66667L9.83268 6" stroke="#4B5563" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                        </a>
                        <a (click)="editUseCase.emit(useCase)">
                            <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M9.39156 3.04987L3.57614 9.20528C3.35656 9.43903 3.14406 9.89945 3.10156 10.2182L2.83948 12.5132C2.74739 13.342 3.34239 13.9086 4.16406 13.767L6.44489 13.3774C6.76364 13.3207 7.20989 13.087 7.42948 12.8461L13.2449 6.6907C14.2507 5.6282 14.7041 4.41695 13.1386 2.93654C11.5803 1.47029 10.3974 1.98737 9.39156 3.04987Z" stroke="#4B5563" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M8.42188 4.07715C8.72646 6.03215 10.3131 7.52673 12.2823 7.72506" stroke="#4B5563" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M2.125 16.0833H14.875" stroke="#292D32" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                        </a>
                        <a href="px-2" [routerLink]="[PAGES_AND_PATHS.useCaseSingle.pagePath + useCase?.id]">
                            <svg width="26" height="32" viewBox="0 0 26 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0 16C0 7.16344 7.16344 0 16 0H26V32H16C7.16344 32 0 24.8366 0 16Z" fill="#38EF7D"/>
                                <path d="M12 16.0001V14.2201C12 12.0134 13.5667 11.1068 15.48 12.2134L17.02 13.1068L18.56 14.0001C20.4733 15.1068 20.4733 16.9134 18.56 18.0201L17.02 18.9134L15.48 19.8068C13.5667 20.8934 12 19.9934 12 17.7801V16.0001Z" stroke="#00752C" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                        </a>
                    </div>

                </div>
            </div>
        </div>
    </div>
</li>
