import {Component, ElementRef, EventEmitter, Input, OnInit, Output, Renderer2, ViewChild} from '@angular/core';
import {
    SearchAndFilterCloudsReqObj,
} from '../../../interfaces/sources-interfaces';
import {GeneralHelperService} from '../../../services/helpers/general-helper.service';

@Component({
    selector: 'app-label-search',
    templateUrl: './label-search.component.html'
})
export class LabelSearchComponent implements OnInit {

    @Input() searchAndFilter: SearchAndFilterCloudsReqObj = {query: '', cloud_type: {id: 0, name: ''}, type_id: 0};
    @ViewChild('toggleButtonType') toggleButtonType: ElementRef | undefined;
    @ViewChild('menuType') menuType: ElementRef | undefined;
    @Output() searchAndFilterChange = new EventEmitter<SearchAndFilterCloudsReqObj>();
    @Output() openCreateModal = new EventEmitter<boolean>();
    showDropdownType = false;

    constructor(private renderer: Renderer2,
                private generalHelperService: GeneralHelperService) {
        this.listenToClickType();
    }

    ngOnInit(): void {
    }

    listenToClickType(): void {
        this.renderer.listen('window', 'click', (e: Event) => {
            if (this.generalHelperService.checkIfClickedOutsideOfTheElement(this.toggleButtonType, this.menuType, e)) {
                this.showDropdownType = false;
            }
        });
    }
}
