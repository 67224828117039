import {Component} from '@angular/core';
import {TranslateHelperService} from './services/helpers/translate-helper.service';
import {ActivePageHelperService} from './services/helpers/active-page-helper.service';
import {EchoHelperService} from './services/helpers/echo-helper.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html'
})
export class AppComponent {
    title = 'Atyso-client';

    constructor(private translateHelper: TranslateHelperService,
                public activePageHelper: ActivePageHelperService,
                private echoHelperService: EchoHelperService) {
        this.translateHelper.initTraslate();
        this.echoHelperService.initEcho();
    }
}
