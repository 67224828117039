import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { GeneralHelperService } from 'src/app/services/helpers/general-helper.service';
import { ToasterHelperService } from 'src/app/services/helpers/toaster-helper.service';
import { LocalStorageProperties, Users } from '../../../../enums/general-enums';
import { AuthenticationHelperService } from '../../../../services/helpers/authentication-helper.service';

@Component({
    selector: 'app-new-user',
    templateUrl: './new-user.component.html'
})
export class NewUserComponent implements OnInit {
    companyMemberForm!: FormGroup;
    @Output() newUser: EventEmitter<any> = new EventEmitter<any>();
    loggedUser = localStorage.getItem(LocalStorageProperties.userRole);

    constructor(
        private fb: FormBuilder,
        private authHelper: AuthenticationHelperService,
        private generalHelperService: GeneralHelperService,
        private toasterService: ToasterHelperService
    ) {
    }

    ngOnInit(): void {
        this.buildForm();
    }

    buildForm(): any {
        return this.companyMemberForm = this.fb.group({
            email: ['', Validators.required],
            company: '',
            role: ''
        });
    }

    onSubmit(): void {
        if (this.generalHelperService.validateEmail(this.companyMemberForm.controls.email.value)) {
            if (this.loggedUser === Users.superAdmin) {
                this.companyMemberForm.controls.role.patchValue(Users.companyAdmin);
                if (!this.generalHelperService.checkForSpaces(this.companyMemberForm.value.company)) {
                    this.newUser.emit(this.companyMemberForm.value);
                    this.buildForm();
                } else {
                    this.toasterService.showWarning('You must include company name!');
                }
            } else if (this.loggedUser === Users.companyAdmin) {
                this.companyMemberForm.controls.role.patchValue(Users.companyMember);
                const companyId = this.authHelper.getCompanyId;
                this.companyMemberForm.controls.company.patchValue(Number(companyId));
                this.newUser.emit(this.companyMemberForm.value);
                this.buildForm();
            }
        } else {
            this.toasterService.showWarning('Your email address is not valid!');
        }
    }

}
