import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {DestinationsHttpService} from '../../../services/http-services/destinations-http.service';
import {DestinationWithAPIKey} from '../../../interfaces/destination-interfaces';

@Component({
    selector: 'app-destination-details-modal',
    templateUrl: './destination-details-modal.component.html'
})
export class DestinationDetailsModalComponent implements OnInit {

    @Input() destinationWithAPIKeyINPUT: DestinationWithAPIKey | undefined | null;
    @Input() destinationId: number | undefined = 0;
    destinationWithAPIKey: DestinationWithAPIKey | undefined;
    @Output() modalClosed = new EventEmitter();

    constructor(private destinationHttpService: DestinationsHttpService) {
    }

    ngOnInit(): void {
        (this.destinationWithAPIKeyINPUT) ?
            this.destinationWithAPIKey = this.destinationWithAPIKeyINPUT :
            this.getDestinationDetails();
    }

    getDestinationDetails(): void {
        if (this.destinationId) {
            this.destinationHttpService.getDestinationDetails(this.destinationId).then((destinationWithAPIKey: DestinationWithAPIKey) => {
                this.destinationWithAPIKey = destinationWithAPIKey;
            });
        }
    }

    closeModal(): void {
        this.modalClosed.emit(true);
    }

}
