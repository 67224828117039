import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {PpgPagination} from '../../interfaces/general-interfaces';

@Component({
    selector: 'app-ppg-pagination',
    templateUrl: './ppg-pagination.component.html'
})
export class PpgPaginationComponent implements OnInit, PpgPagination {

    @Input() totalItems: undefined | number = 50;
    @Input() currentPage: undefined | number = 1;
    @Input() perPage: undefined | number = 10;
    @Input() collection: any[] = [];
    @Input() from: number | undefined;
    @Input() to: number | undefined;
    @Input() pagId: undefined | string;
    @Output() pageChanged = new EventEmitter<any>();
    randomNum = Math.random();
    nextPageUrl: string | undefined;

    constructor() {
    }

    ngOnInit(): void {
    }

    onPageChanged(event: number): void {
        this.pageChanged.emit(event);
    }

}
