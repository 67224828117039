<!-- Add Source Manual-->
<div class="w-screen max-w-2xl">
    <form class="h-full flex flex-col bg-white shadow-xl overflow-y-scroll" id="js-add-source-modal" #modal>
        <div class="flex-1">
            <!-- Header -->
            <div class="px-4 py-6 bg-gray-700 sm:px-6">
                <div class="flex items-start justify-between space-x-3">
                    <div class="space-y-1">
                        <h2 class="text-lg font-medium text-white" id="slide-over-title">
                            New Source
                        </h2>
                        <p class="text-sm text-gray-300">
                            Get started by filling in the information below to create your new use case.
                        </p>
                    </div>
                    <div class="h-7 flex items-center">
                        <button (click)="modalClosed.emit(true)" type="button"
                                class="text-gray-400 hover:text-gray-500">
                            <span class="sr-only">Close panel</span>
                            <!-- Heroicon name: outline/x -->
                            <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none"
                                 viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                      d="M6 18L18 6M6 6l12 12"/>
                            </svg>
                        </button>
                    </div>
                </div>
            </div>
            <app-add-source-modal-first-step *ngIf="!activeSelectionType"
                                             [useCaseId]="useCaseId"
                                             (cloudFrequenciesFetched)="cloudFrequencies = $event"
                                             (sourceAdded)="onSourceAdded($event, 0)"
                                             (typeOfAddingSourceSelected)="onTypeOfAddingSourceSelected($event)">
            </app-add-source-modal-first-step>
            <app-add-source-manual-modal *ngIf="activeSelectionType === AddingSourceType.manual"
                                         [addSourceManualClass]="addSourceManualClass"
                                         (sourcesAdded)="onSourceAdded($event, 1000)"
                                         (modalClosed)="modalClosed.emit(true)">
            </app-add-source-manual-modal>
            <app-add-source-cloud-modal *ngIf="activeSelectionType === AddingSourceType.cloud"
                                        [cloudFrequencies]="cloudFrequencies"
                                        (sourceAdded)="onSourceAdded($event, 0)"
                                        (modalClosed)="modalClosed.emit(true)"
                                        [cloudRegions]="cloudRegions"
                                        [addSourceCloud]="addSourceCloudClass">
            </app-add-source-cloud-modal>
            <app-add-source-email-modal *ngIf="activeSelectionType === AddingSourceType.email">
            </app-add-source-email-modal>

        </div>
    </form>
</div>
<!--End Add Source Manual-->
