<div class="space-y-6">
    <div>
        <div class="flex items-center">
            <img class="mr-3" src="assets/images/icons/icon-destination-data.svg" alt="">
            <span class="font-medium text-sm text-gray-900">{{destinationWithAPIKey?.destination?.name}}</span>
        </div>
    </div>
    <div class="h-px bg-indigo-50 w-full"></div>
    <div class="grid gap-x-4">
        <h4 class="text-sm font-medium text-gray-700">Access your data below:</h4>
        <br>
        <div class="border border-gray-300 rounded-md p-4">
            <div class="mb-2">
                <p class="text-gray-400 text-sm font-normal">Key (as header 'access-key')</p>
                <p class="font-medium text-gray-700 text-sm">{{destinationWithAPIKey?.api_key?.access_key}}</p>
            </div>
            <div class="mb-2">
                <p class="text-gray-400 text-sm font-normal" *ngIf="destinationWithAPIKey?.api_key?.access_secret_key">Secret (as header 'access-secret-key')</p>
                <p class="font-medium text-gray-700 text-sm" *ngIf="destinationWithAPIKey?.api_key?.access_secret_key">{{destinationWithAPIKey?.api_key?.access_secret_key}}</p>
            </div>
            <div>
                <p class="text-gray-400 text-sm font-normal">Url</p>
                <p class="font-medium text-gray-700 text-sm">{{destinationWithAPIKey?.api_key?.url}}</p>
            </div>
        </div>
    </div>
</div>
