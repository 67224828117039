

<div class="flex flex-col">
    <!-- form for inviting members - TODO Djole -->
    <div>
        <app-new-user *ngIf="isNewForm" (newUser)="createNewUser($event)"></app-new-user>
        <app-actions *ngIf="isActionsForm" [userToUpdate]="userToUpdate"
            (refreshManageComponent)="refreshManageComponent()">
        </app-actions>
    </div>

    <!-- COMPANY ADMINS -->
    <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div class=" overflow-hidden  sm:rounded-lg">
                <table class="min-w-full divide-y divide-gray-200">
                    <thead class="bg-gray-50">
                        <tr>
                            <th *ngIf="loggedUser === Users.superAdmin" scope="col"
                                class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                Company name
                            </th>
                            <th scope="col"
                                class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                Member name
                            </th>
                            <th scope="col"
                                class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                email
                            </th>
                            <th scope="col"
                                class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                Role
                            </th>
                            <th scope="col"
                                class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                Status
                            </th>
                            <th scope="col" class="relative px-6 py-3">
                                <span class="sr-only">Edit</span>
                            </th>
                            <th scope="col" class="relative px-6 py-3">
                                <span class="sr-only">Restore</span>
                            </th>
                        </tr>
                    </thead>
                    <tbody class="bg-white divide-y divide-gray-200">
                        <tr *ngFor="let user of listOfCompanyUsers">
                            <div *ngIf="loggedUser === Users.superAdmin">
                                <td *ngFor="let company of user.company_admin_companies"
                                    class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                    {{company.name}}
                                </td>
                            </div>
                            <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                {{user.first_name}} {{user.last_name}}
                            </td>
                            <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                {{user.email}}
                            </td>
                            <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                <span *ngIf="loggedUser === Users.superAdmin"
                                    class="inline-flex bg-yellow-100 items-center px-2.5 py-0.5 rounded-full text-xs font-medium  text-gray-800">
                                    Manager
                                </span>
                                <span *ngIf="loggedUser === Users.companyAdmin"
                                    class="inline-flex bg-green-100 items-center px-2.5 py-0.5 rounded-full text-xs font-medium  text-gray-800">
                                    Member
                                </span>
                            </td>
                            <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                {{user.deleted_at === null ? 'Active' : 'Disabled'}}
                            </td>
                            <td *ngIf="user.deleted_at === null"
                                class="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                                <a (click)="callToAction(user)"
                                    class="text-indigo-600 hover:text-indigo-900">actions</a>
                            </td>
                            <td *ngIf="user.deleted_at !== null"
                                class="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                                <a (click)="restoreCompanyUser(user.id)"
                                    class="text-indigo-600 hover:text-indigo-900">Restore</a>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <!-- Pagination -->
                <div class="px-6 mt-4" *ngIf="showMembers(listOfCompanyUsersInitial)">
                    <div class="flex w-full justify-between items-center border-t border-gray-200 pt-2">
                        <app-pagination-data-details [pagination]="ppgPagination">
                        </app-pagination-data-details>
                        <app-ppg-pagination [currentPage]="ppgPagination?.currentPage"
                                            [pagId]="'js-members-pag'"
                                            [perPage]="ppgPagination?.perPage"
                                            [totalItems]="ppgPagination?.totalItems"
                                            [from]="ppgPagination?.from"
                                            [to]="ppgPagination?.to"
                                            (pageChanged)="onPageChanged($event)">
                        </app-ppg-pagination>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
