<!-- Add Source Manual-->
<div class="w-screen max-w-2xl" #modal>
    <div class="h-full flex flex-col bg-white shadow-xl overflow-y-scroll" id="js-add-source-modal">
        <div class="flex-1">
            <!-- Header -->
            <div class="px-4 py-6 bg-gray-700 sm:px-6">
                <div class="flex items-start justify-between space-x-3">
                    <div class="space-y-1">
                        <h2 class="text-lg font-medium text-white" id="slide-over-title">
                            Edit destination
                        </h2>
                    </div>
                    <div class="h-7 flex items-center">
                        <button (click)="modalClosed.emit(true)" type="button"
                                class="text-gray-400 hover:text-gray-500">
                            <span class="sr-only">Close panel</span>
                            <!-- Heroicon name: outline/x -->
                            <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none"
                                 viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                      d="M6 18L18 6M6 6l12 12"/>
                            </svg>
                        </button>
                    </div>
                </div>
            </div>
            <div class="p-6 space-y-6">
                <div class="flex flex-col custom-height">
                    <!-- 3rd Step Content -->
                    <div class="p-6 space-y-6 flex-grow">
                        <div>
                            <label class="block text-sm font-medium text-gray-700">Destination
                                Name</label>
                            <div class="mt-2">
                                <input type="text" *ngIf="destinationForEdit" [(ngModel)]="destinationForEdit.name"
                                       class="shadow-sm focus:ring-gray-400 focus:border-gray-400 block w-full sm:text-sm border-gray-300 rounded-md"
                                       placeholder="">
                                <small *ngIf="checkIfNeedToShowErrMessage() && submitted" class="text-red-500">Destination
                                    name need to have at least 2
                                    characters</small>
                            </div>
                        </div>
                    </div>
                    <!-- Action buttons -->
                    <div class="flex-shrink-0 px-4 border-t border-gray-200 py-5 sm:px-6 mt-auto">
                        <div class="space-x-3 flex justify-end">
                            <button type="button"
                                    (click)="modalClosed.emit(true)"
                                    class="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500">
                                Cancel
                            </button>
                            <button (click)="editDestination()" type="button"
                                    class="bg-gray-700 py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500">
                                Save
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!--End Add Destination-->
