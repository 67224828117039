import {trigger, style, transition, animate} from '@angular/animations';
import {modalSliderTime} from '../constants/general-variables';

export const SlideInOutAnimation = [
    trigger('slide', [
        transition(':enter', [
            style({
                transform: 'translateX(100%)',
            }),
            animate(modalSliderTime,
                style({
                    transform: 'translateX(0)',
                })
            )
        ]),
        transition(':leave', [
            style({}),
            animate(modalSliderTime,
                style({
                    transform: 'translateX(100%)',
                })
            )
        ])
    ])
];

