import {Component, EventEmitter, OnInit, Output} from '@angular/core';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html'
})
export class HeaderComponent implements OnInit {

    @Output() addNewSource = new EventEmitter<boolean>();

    constructor() {
    }

    ngOnInit(): void {
    }

}
