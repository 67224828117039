import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    Input,
    OnChanges,
    OnInit,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import {
    CanvasOptions,
    DataBody,
    MouseCanvasOptions,
    WorldAndScreenCanvasOptions
} from '../../interfaces/use-case-data-interfaces';
import {Subject} from 'rxjs';

@Component({
    selector: 'app-ppg-canvas',
    templateUrl: './ppg-canvas.component.html'
})
export class PpgCanvasComponent implements OnInit, OnChanges {

    @Input() singleUseCaseDataBody: DataBody | undefined;
    @Input() imageSrc = '';
    imageElement = new Image();
    imageSize: { width: number, height: number } = {width: 0, height: 0};
    canvasOptions: CanvasOptions = {
        scale: 1,
        scaleFactor: 0.2,
        scrollX: 0,
        scrollY: 0,
        rectOptions: {rectBorderColor: '#38EF7D', rectBorderWidth: 4}
    };
    mouse: MouseCanvasOptions = {x: 0, y: 0, rx: 0, ry: 0, button: 0, bounds: 0};
    worldAndScreen: WorldAndScreenCanvasOptions = {wx: 0, wy: 0, sx: 0, sy: 0};
    imgSrcReady = false;
    dataBodyReady = false;
    listenersAdded = false;
    @ViewChild('canvasElement') canvasElement: ElementRef<HTMLCanvasElement> | undefined;

    constructor() {
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.imageSrc && changes.imageSrc.currentValue) {
            this.imageElement.src = this.imageSrc;
            this.imgSrcReady = true;
            if (this.dataBodyReady) {
                setTimeout(() => {
                    this.initCanvas();
                });
            }
        }
        if (changes.singleUseCaseDataBody) {
            this.dataBodyReady = true;
            if (this.imgSrcReady) {
                setTimeout(() => {
                    this.initCanvas();
                });
            }
        }
    }

    ngOnInit(): void {
    }

    initCanvas(): void {
        if (!this.listenersAdded) {
            this.listenersAdded = true;
            this.addListenersForCanvas();
        }
        this.drawCanvas();
    }

    addListenersForCanvas(): void {
        // @ts-ignore
        this.canvasElement.nativeElement.addEventListener('wheel', e => {
            this.trackWheel(e);
        }, {passive: false});
        // @ts-ignore
        this.canvasElement.nativeElement.addEventListener('mousemove', e => {
            this.move(e);
        });
        // @ts-ignore
        this.canvasElement.nativeElement.addEventListener('mousedown', e => {
            this.move(e);
        });
        // @ts-ignore
        this.canvasElement.nativeElement.addEventListener('mouseup', e => {
            this.move(e);
        });
        // @ts-ignore
        this.canvasElement.nativeElement.addEventListener('mouseout', e => {
            this.move(e);
        }); // to stop mouse button locking up
    }

    zoomedX_INV(num: number): number { // scale & origin INV
        return Math.floor((num - this.worldAndScreen.sx) * (1 / this.canvasOptions.scale) + this.worldAndScreen.wx);
        // or return Math.floor((number - sx) / scale + wx);
    }

    zoomedY_INV(num: number): number { // scale & origin INV
        return Math.floor((num - this.worldAndScreen.sy) * (1 / this.canvasOptions.scale) + this.worldAndScreen.wy);
        // or return Math.floor((number - sy) / scale + wy);
    }

    zoomed(num: number): number { // just scale
        return Math.floor(num * this.canvasOptions.scale);
    }

    // converts from world coord to screen pixel coord
    zoomedX(num: number): number { // scale & origin X
        return Math.floor((num - this.worldAndScreen.wx) * this.canvasOptions.scale + this.worldAndScreen.sx);
    }

    zoomedY(num: number): number { // scale & origin Y
        return Math.floor((num - this.worldAndScreen.wy) * this.canvasOptions.scale + this.worldAndScreen.sy);
    }

    trackWheel(e: any): void {
        if (e.deltaY < 0) {
            this.canvasOptions.scale = Math.min(5, this.canvasOptions.scale * 1.1); // zoom in
        } else {
            this.canvasOptions.scale = Math.max(0.1, this.canvasOptions.scale * (1 / 1.1)); // zoom out is inverse of zoom in
        }
        this.worldAndScreen.wx = this.mouse.rx; // set world origin
        this.worldAndScreen.wy = this.mouse.ry;
        this.worldAndScreen.sx = this.mouse.x; // set screen origin
        this.worldAndScreen.sy = this.mouse.y;
        this.mouse.rx = this.zoomedX_INV(this.mouse.x); // recalc mouse world (real) pos
        this.mouse.ry = this.zoomedY_INV(this.mouse.y);
        this.drawCanvas();
        e.preventDefault(); // stop the page scrolling
    }

    move(event: any): void { // mouse move event
        if (event.type === 'mousedown') {
            this.mouse.button = 1;
        } else if (event.type === 'mouseup' || event.type === 'mouseout') {
            this.mouse.button = 0;
        }
        // @ts-ignore
        this.mouse.bounds = this.canvasElement.nativeElement.getBoundingClientRect();
        this.mouse.x = event.clientX - this.mouse.bounds.left;
        this.mouse.y = event.clientY - this.mouse.bounds.top;
        const xx = this.mouse.rx; // get last real world pos of mouse
        const yy = this.mouse.ry;
        this.mouse.rx = this.zoomedX_INV(this.mouse.x); // get the mouse real world pos via inverse scale and translate
        this.mouse.ry = this.zoomedY_INV(this.mouse.y);
        if (this.mouse.button === 1) { // is mouse button down
            this.worldAndScreen.wx -= this.mouse.rx - xx; // move the world origin by the distance
            // moved in world coords
            this.worldAndScreen.wy -= this.mouse.ry - yy;
            // recaculate mouse world
            this.mouse.rx = this.zoomedX_INV(this.mouse.x);
            this.mouse.ry = this.zoomedY_INV(this.mouse.y);
        }
        this.drawCanvas();
    }

    drawCanvas(): void {
        // @ts-ignore
        this.canvasElement.nativeElement.getContext('2d')?.clearRect
        // @ts-ignore
        (0, 0, this.canvasElement.nativeElement.getContext('2d').canvas.width, this.canvasElement.nativeElement.getContext('2d')?.canvas.height);
        this.drawImage();
    }

    drawImage(): void {
        this.imageSize = {width: this.imageElement.width, height: this.imageElement.height};
        // @ts-ignore
        this.canvasElement.nativeElement.getContext('2d')?.beginPath();
        // @ts-ignore
        this.canvasElement.nativeElement.getContext('2d')?.canvas.width = this.imageSize.width / this.canvasOptions.scale;
        // @ts-ignore
        this.canvasElement.nativeElement.getContext('2d')?.canvas.height = this.imageSize.height / this.canvasOptions.scale;
        // @ts-ignore
        this.canvasElement.nativeElement.getContext('2d')?.drawImage(this.imageElement, this.zoomedX(0), this.zoomedY(0), this.zoomed(this.imageSize.width), this.zoomed(this.imageSize.height));
        this.drawPolygon();
    }

    drawPolygon(): void {
        if (this.singleUseCaseDataBody) {
            const canvasWidth = this.imageElement.width;
            const canvasHeight = this.imageElement.height;
            // @ts-ignore
            const polygonX = (this.singleUseCaseDataBody.value.geometry.BoundingBox.Left * canvasWidth);
            // @ts-ignore
            const polygonY = (this.singleUseCaseDataBody.value.geometry.BoundingBox.Top * canvasHeight);
            // @ts-ignore
            const polygonWidth = (this.singleUseCaseDataBody.value.geometry.BoundingBox.Width * canvasWidth);
            // @ts-ignore
            const polygonHeight = (this.singleUseCaseDataBody.value.geometry.BoundingBox.Height * canvasHeight);
            // @ts-ignore
            this.canvasElement.nativeElement.getContext('2d')?.beginPath();
            // @ts-ignore
            this.canvasElement.nativeElement.getContext('2d').strokeStyle = this.canvasOptions.rectOptions.rectBorderColor;
            // @ts-ignore
            this.canvasElement.nativeElement.getContext('2d').lineWidth = this.canvasOptions.rectOptions.rectBorderWidth;
            // @ts-ignore
            this.canvasElement.nativeElement.getContext('2d')?.rect(
                this.zoomedX(polygonX),
                this.zoomedY(polygonY),
                this.zoomed(polygonWidth),
                this.zoomed(polygonHeight));
            // @ts-ignore
            this.canvasElement.nativeElement.getContext('2d')?.stroke();
        }
    }

}
