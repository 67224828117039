<app-integration-search-and-filters [searchAndFilter]="searchAndFilter"
                                    (searchAndFilterChange)="searchIntegrations($event)">
</app-integration-search-and-filters>
<!-- ./Header loaded -->
<div class="flex flex-col w-full px-6 py-4" *ngIf="showData(integrations)">
    <div class="flex flex-col">
        <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-6">
            <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-6">
                <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                    <table class="min-w-full divide-y divide-gray-200">
                        <thead class="bg-gray-50">
                        <tr>
                            <th scope="col"
                                class="px-6 py-3 text-left text-xs font-medium text-gray-500 tracking-wider">
                                Name
                            </th>
                            <th scope="col"
                                class="px-6 py-3 text-left text-xs font-medium text-gray-500 tracking-wider">
                                Folder
                            </th>
                            <th scope="col"
                                class="px-6 py-3 text-left text-xs font-medium text-gray-500 tracking-wider">
                                Type
                            </th>
                            <th scope="col"
                                class="px-6 py-3 text-left text-xs font-medium text-gray-500 tracking-wider">
                                Actions
                            </th>
                        </tr>
                        </thead>
                        <tbody class="bg-white divide-y divide-gray-200">
                        <tr *ngFor="let integration of integrations?.data" @fadingIn>
                            <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                {{integration.name}}
                            </td>
                            <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500"
                                style="text-transform: capitalize">
                                {{integration.folder}}
                            </td>
                            <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                {{integration.cloudType?.name}}
                            </td>
                            <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                <div class="flex items-center">
                                    <!--TODO Zoca stilizuj ova tri anchor linka ispod-->

                                    <a class="text-indigo-600 hover:text-indigo-900 cursor-pointer"
                                       (click)="viewIntegrationDetails = {integration: integration, show: true}">View</a>
                                    <a class="text-indigo-600 hover:text-indigo-900 cursor-pointer"
                                       (click)="getCloudRegionAndOpenModal(integration, editType.default)">Edit</a>
                                    <a class="text-indigo-600 hover:text-indigo-900 cursor-pointer"
                                       (click)="getCloudRegionAndOpenModal(integration, (integration.cloudType?.name === cloudTypes.aws) ? editType.accessKey : editType.password)">
                                        {{(integration.cloudType?.name === cloudTypes.aws) ? 'Edit Access Key' : 'Edit Password'}}</a>
                                    <a class="text-indigo-600 hover:text-indigo-900 cursor-pointer"
                                       (click)="deleteIntegrationDetails = {integration: integration, show: true}">Delete</a>
                                </div>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
    <!-- Pagination -->
    <div class="mt-4">
        <div class="flex w-full justify-between items-center border-t border-gray-200 pt-2">
            <app-pagination-data-details *ngIf="showData(integrations)" [pagination]="integrations?.pagination">
            </app-pagination-data-details>
            <app-ppg-pagination *ngIf="showData(integrations)" [currentPage]="integrations?.pagination?.currentPage"
                                [pagId]="'js-integration-pag'"
                                [perPage]="integrations?.pagination?.perPage"
                                [totalItems]="integrations?.pagination?.totalItems"
                                (pageChanged)="onPageChanged($event)">
            </app-ppg-pagination>
        </div>
    </div>
    <!-- End Pagination -->
</div>

<app-delete-confirmation-modal *ngIf="deleteIntegrationDetails?.show"
                               [deleteTitle]="deleteIntegrationDetails?.integration?.name"
                               [objToEmitBack]="deleteIntegrationDetails?.integration"
                               (modalClosed)="onIntegrationDeleteModalClosed()"
                               (deleted)="onIntegrationDelete($event)">
</app-delete-confirmation-modal>
<app-ppg-slide-modal-wrapper [showModal]="viewIntegrationDetails.show">
    <app-view-single-integration-modal class="fixed inset-y-0 right-0 pl-10 max-w-full flex sm:pl-16" modal-body @slide
                                       *ngIf="viewIntegrationDetails.show"
                                       [integration]="viewIntegrationDetails.integration"
                                       (modalClosed)="resetViewIntegrationDetailsToInitialState()">
    </app-view-single-integration-modal>
</app-ppg-slide-modal-wrapper>
<app-ppg-slide-modal-wrapper [showModal]="integrationEdit.show">
    <div class="fixed inset-y-0 right-0 pl-10 max-w-full flex sm:pl-16" #modal modal-body @slide *ngIf="integrationEdit.show">
        <div class="w-screen max-w-2xl">
            <form class="h-full flex flex-col bg-white shadow-xl overflow-y-scroll" id="js-edit-integration-modal">
                <div class="flex-1">
                    <!-- Header -->
                    <div class="px-4 py-6 bg-gray-700 sm:px-6">
                        <div class="flex items-start justify-between space-x-3">
                            <div class="space-y-1">
                                <h2 class="text-lg font-medium text-white" id="slide-over-title">
                                    Edit Integration
                                </h2>
                                <p class="text-sm text-gray-300">
                                </p>
                            </div>
                            <div class="h-7 flex items-center">
                                <button (click)="resetEditIntegrationDetailsToInitialState()" type="button"
                                        class="text-gray-400 hover:text-gray-500">
                                    <span class="sr-only">Close panel</span>
                                    <!-- Heroicon name: outline/x -->
                                    <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none"
                                         viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                              d="M6 18L18 6M6 6l12 12"/>
                                    </svg>
                                </button>
                            </div>
                        </div>
                    </div>
                    <app-source-cloud-form *ngIf="integrationEdit.show && integrationEdit.ppgForm"
                                           [addSourceClass]="integrationEdit.source"
                                           [ppgFormGroup]="integrationEdit.ppgForm"
                                           (cloudIntegrationEdited)="onCloudIntegrationEdited($event)"
                                           (modalClosed)="resetEditIntegrationDetailsToInitialState()">
                    </app-source-cloud-form>
                </div>
            </form>
        </div>
    </div>
</app-ppg-slide-modal-wrapper>
<!--<app-ppg-slide-modal-wrapper [showModal]="syncSourceDetails.show">-->
<!--    <app-syncs-modal class="fixed inset-y-0 right-0 pl-10 max-w-full flex sm:pl-16" modal-body @slide-->
<!--                     *ngIf="syncSourceDetails.show" (modalClosed)="resetSyncSourceDetails()"-->
<!--                     [source]="syncSourceDetails.source">-->
<!--    </app-syncs-modal>-->
<!--</app-ppg-slide-modal-wrapper>-->
<!--<app-ppg-slide-modal-wrapper [showModal]="editSourceDetails.show">-->
<!--    <app-edit-source-modal class="fixed inset-y-0 right-0 pl-10 max-w-full flex sm:pl-16" modal-body @slide-->
<!--                           *ngIf="editSourceDetails.show"-->
<!--                           (modalClosed)="resetEditSourceDetailsToInitialState()"-->
<!--                           (sourceEdited)="onSourceEdited($event)"-->
<!--                           [sourceId]="editSourceDetails.sourceId">-->
<!--    </app-edit-source-modal>-->
<!--</app-ppg-slide-modal-wrapper>-->
<app-ppg-no-results *ngIf="!showData(integrations) && initialRequestFinished"
                    [title]="'No results'"
                    [subtitle]="'Looks like there are no Integrations with such name.'">
</app-ppg-no-results>
<!--<app-add-new-item *ngIf="!showData(initialSources) && initialRequestFinished"-->
<!--                  [title]="'You need to add at least one source to the usecase to start.'"-->
<!--                  [announcement]="'No Sources here yet.'"-->
<!--                  [buttonTitle]="'Add Source'"-->
<!--                  (addNewItem)="showAddSourceModal = true">-->
<!--</app-add-new-item>-->
<!--<app-ppg-slide-modal-wrapper [showModal]="showAddSourceModal">-->
<!--    <app-add-source-modal class="fixed inset-y-0 right-0 pl-10 max-w-full flex sm:pl-16" modal-body @slide-->
<!--                          *ngIf="showAddSourceModal"-->
<!--                          (modalClosed)="onClosedModalForAdding()"-->
<!--                          (sourceAdded)="onSourceAdded($event)"-->
<!--                          [useCaseId]="useCaseId">-->
<!--    </app-add-source-modal>-->
<!--</app-ppg-slide-modal-wrapper>-->
