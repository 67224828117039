import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {
    Destination, DestinationDownloadReqBody,
    DestinationOutputTypes, DestinationReqObj,
    DestinationType,
    DestinationTypeWithOutput, DestinationWithAPIKey, GetDestinationsResponse, SearchAndFilterDestinationsReqObj
} from '../../interfaces/destination-interfaces';
import {GetUseCaseSourcesResponse, SearchAndFilterSourcesReqObj, Source} from '../../interfaces/sources-interfaces';
import {GeneralHttpHelperService} from './general-http-helper.service';
import {PpgPagination} from '../../interfaces/general-interfaces';
import {AddDestination} from "../../pages/destinations/add-destination-modal/add-destination";
import {AuthenticationHelperService} from "../helpers/authentication-helper.service";
import {ToasterHelperService} from "../helpers/toaster-helper.service";
import {UseCase} from '../../interfaces/use-case-interfaces';

@Injectable({
    providedIn: 'root'
})
export class DestinationsHttpService {

    constructor(private httpClient: HttpClient,
                private destinationHttpServiceHelper: DestinationHttpServiceHelper,
                private generalHttpHelperService: GeneralHttpHelperService,
                private toasterHelper: ToasterHelperService) {
    }

    private getDestinationsTypesReq(): Observable<any> {
        const headers = new HttpHeaders({
            'route-key': environment.API_ENDPOINT_MS_UC_ROUTE_KEY,
            'payload-key': environment.API_ENDPOINT_MS_UC_PAYLOAD_KEY,
        });

        return this.httpClient.get<any>(environment.API_ENDPOINT_MS_UC + 'api/doc-manipulation/destination-types', {headers});
    }

    private getSingleDestinationTypeWithOutputTypesReq(destinationType: DestinationType): Observable<any> {
        const headers = new HttpHeaders({
            'route-key': environment.API_ENDPOINT_MS_UC_ROUTE_KEY,
            'payload-key': environment.API_ENDPOINT_MS_UC_PAYLOAD_KEY,
        });

        return this.httpClient.get<any>(environment.API_ENDPOINT_MS_UC + 'api/doc-manipulation/destination-types/' + destinationType.id, {headers});
    }

    private getDestinationsReq(useCaseId: number | null, pageNumber: number | null = null): Observable<any> {
        const headers = new HttpHeaders({
            'route-key': environment.API_ENDPOINT_MS_UC_ROUTE_KEY,
            'payload-key': environment.API_ENDPOINT_MS_UC_PAYLOAD_KEY,
        });

        if (useCaseId === 0) {
            return (pageNumber) ?
                this.httpClient.get<any>(environment.API_ENDPOINT_MS_UC + 'api/doc-manipulation/destinations?page=' + pageNumber, {headers}) :
                this.httpClient.get<any>(environment.API_ENDPOINT_MS_UC + 'api/doc-manipulation/destinations', {headers});
        } else {
            return (pageNumber) ?
                this.httpClient.get<any>(environment.API_ENDPOINT_MS_UC + 'api/doc-manipulation/destinations/use-cases/' + useCaseId + '?page=' + pageNumber, {headers}) :
                this.httpClient.get<any>(environment.API_ENDPOINT_MS_UC + 'api/doc-manipulation/destinations/use-cases/' + useCaseId, {headers});
        }
    }

    private addDestinationReq(destinationReqObj: DestinationReqObj): Observable<any> {
        const headers = new HttpHeaders({
            'route-key': environment.API_ENDPOINT_MS_UC_ROUTE_KEY,
            'payload-key': environment.API_ENDPOINT_MS_UC_PAYLOAD_KEY,
        });

        return this.httpClient.post<any>(environment.API_ENDPOINT_MS_UC + 'api/doc-manipulation/destinations', destinationReqObj, {headers});
    }

    private searchAndFilterDestinationsReq(reqObj: SearchAndFilterDestinationsReqObj | null, useCaseId: number, pageNumber: number | null = null): Observable<any> {
        const headers = new HttpHeaders({
            'route-key': environment.API_ENDPOINT_MS_UC_ROUTE_KEY,
            'payload-key': environment.API_ENDPOINT_MS_UC_PAYLOAD_KEY,
        });

        return (pageNumber) ?
            // tslint:disable-next-line:max-line-length
            this.httpClient.post<any>(environment.API_ENDPOINT_MS_UC + 'api/search/destinations?query=' + reqObj?.query + ' ' + reqObj?.destination_type.name + ' ' + reqObj?.output_type.name + '&page=' + pageNumber,
                {
                    query: reqObj?.query + ' ' + reqObj?.destination_type.name + ' ' + reqObj?.output_type.name,
                    use_case_id: (useCaseId === 0) ? undefined : Number(useCaseId),
                    pagination: 10
                }, {headers}) :
            this.httpClient.post<any>(environment.API_ENDPOINT_MS_UC + 'api/search/destinations',
                {
                    query: reqObj?.query + ' ' + reqObj?.destination_type.name + ' ' + reqObj?.output_type.name,
                    use_case_id: (useCaseId === 0) ? undefined : Number(useCaseId),
                    pagination: 10
                }, {headers});
    }

    private getDestinationDetailsReq(destinationId: number): Observable<any> {
        const headers = new HttpHeaders({
            'route-key': environment.API_ENDPOINT_MS_UC_ROUTE_KEY,
            'payload-key': environment.API_ENDPOINT_MS_UC_PAYLOAD_KEY,
        });

        return this.httpClient.get<any>(environment.API_ENDPOINT_MS_UC + 'api/doc-manipulation/destinations/' + destinationId, {headers});
    }

    private deleteDestinationReq(destination: Destination): Observable<any> {
        const headers = new HttpHeaders({
            'route-key': environment.API_ENDPOINT_MS_UC_ROUTE_KEY,
            'payload-key': environment.API_ENDPOINT_MS_UC_PAYLOAD_KEY,
        });

        return this.httpClient.delete<any>(environment.API_ENDPOINT_MS_UC + 'api/doc-manipulation/destinations/' + destination.id, {headers});
    }

    private editDestinationReq(destination: Destination): Observable<any> {
        const headers = new HttpHeaders({
            'route-key': environment.API_ENDPOINT_MS_UC_ROUTE_KEY,
            'payload-key': environment.API_ENDPOINT_MS_UC_PAYLOAD_KEY,
        });

        return this.httpClient.put<any>(environment.API_ENDPOINT_MS_UC + 'api/doc-manipulation/destinations/' + destination.id, {name: destination.name}, {headers});
    }

    editDestination(destination: Destination): Promise<Destination> {
        return new Promise<Destination>((resolve: any, reject: any) => {
            this.editDestinationReq(destination).subscribe((response: any) => {
                this.toasterHelper.showSuccess('Successfully edited destination');
                resolve(response.entity);
            }, error => {
                reject(null);
            });
        });
    }

    deleteDestination(destination: Destination): Promise<Destination> {
        return new Promise<Destination>((resolve: any, reject: any) => {
            this.deleteDestinationReq(destination).subscribe(response => {
                resolve(destination);
                this.toasterHelper.showSuccess('Successfully deleted destination');
            }, error => {
                this.toasterHelper.showError('Can\'t delete this destination, try again later');
                reject(null);
            });
        });
    }

    getDestinationDetails(destinationId: number): Promise<DestinationWithAPIKey> {
        return new Promise<DestinationWithAPIKey>((resolve: any, reject: any) => {
            this.getDestinationDetailsReq(destinationId).subscribe((response: any) => {
                console.log('Response: ', response);
                resolve(response.entity);
            }, error => {
                reject(null);
            });
        });
    }

    addDestination(addDestinationClass: AddDestination): Promise<DestinationWithAPIKey> {
        return new Promise<DestinationWithAPIKey>((resolve: any, reject: any) => {
            this.addDestinationReq(this.destinationHttpServiceHelper.returnDestinationReqObj(addDestinationClass)).subscribe((response: any) => {
                this.toasterHelper.showSuccess('Successfully created destination');
                resolve(response.entity);
            }, error => {
                reject(null);
            });
        });
    }

    // tslint:disable-next-line:max-line-length
    searchAndFilterDestinations(reqObj: SearchAndFilterDestinationsReqObj | null, useCaseId: number, pageNumber: number | null = null): Promise<{ pagination: PpgPagination, data: Array<Destination> }> {
        return new Promise<{ pagination: PpgPagination, data: Array<Destination> }>((resolve: any, reject: any) => {
            this.searchAndFilterDestinationsReq(reqObj, useCaseId, pageNumber).subscribe((response: any) => {
                const pagination = this.generalHttpHelperService.returnPagination(response);
                resolve({
                    data: response.data.hits,
                    pagination
                });
            }, error => {
                reject(null);
            });
        });
    }

    getDestinations(useCaseId: number | null, pageNumber: number | null = null): Promise<GetDestinationsResponse> {
        return new Promise<GetDestinationsResponse>((resolve: any, reject: any) => {
            this.getDestinationsReq(useCaseId, pageNumber).subscribe((response: any) => {
                const pagination = this.generalHttpHelperService.returnPagination(response.entity);
                resolve({data: response.entity.data, pagination});
            }, error => {
                reject(null);
            });
        });
    }

    getSingleDestinationTypeWithOutputTypes(destinationType: DestinationType): Promise<DestinationTypeWithOutput> {
        return new Promise<DestinationTypeWithOutput>((resolve: any, reject: any) => {
            this.getSingleDestinationTypeWithOutputTypesReq(destinationType).subscribe((response: any) => {
                console.log('Response: ', response);
                resolve(response.entity);
            }, error => {
                reject(null);
            });
        });
    }

    getDestinationsTypes(): Promise<Array<DestinationType>> {
        return new Promise<Array<DestinationType>>((resolve: any, reject: any) => {
            this.getDestinationsTypesReq().subscribe((response: any) => {
                console.log('Response: ', response);
                resolve(response.entity);
            }, error => {
                reject(null);
            });
        });
    }
}


@Injectable({
    providedIn: 'root'
})
export class DestinationHttpServiceHelper {
    constructor(private authHelperService: AuthenticationHelperService) {
    }

    returnDestinationReqObj(addDestinationClass: AddDestination): DestinationReqObj {
        return {
            name: addDestinationClass.name,
            company_id: Number(this.authHelperService.getCompanyId),
            use_case_id: addDestinationClass.useCaseId,
            output_type_id: addDestinationClass.outputType?.id
        };
    }
}
