import {Component, ElementRef, EventEmitter, Input, OnInit, Output, Renderer2, ViewChild} from '@angular/core';
import {PpgFile} from '../../../derived-classes/ppg-file';
import {Source} from '../../../interfaces/sources-interfaces';
import {SourcesHttpService, SourcesHttpServiceHelper} from '../../../services/http-services/sources-http.service';
import {AddSourceManual} from '../add-source-modal/add-source-manual-modal/add-source-manual';
import {ToasterHelperService} from '../../../services/helpers/toaster-helper.service';
import {GeneralHelperService} from '../../../services/helpers/general-helper.service';

@Component({
    selector: 'app-add-files-modal',
    templateUrl: './add-files-modal.component.html'
})
export class AddFilesModalComponent implements OnInit {
    @Input() source: Source | null | undefined;
    @Output() modalClosed = new EventEmitter<boolean>();
    ppgFiles: Array<PpgFile> = [];
    addSourceManual = new AddSourceManual();
    opened = false;
    @ViewChild('modal') modal: ElementRef | undefined;

    constructor(private sourcesHttpService: SourcesHttpService,
                private sourcesHttpServiceHelper: SourcesHttpServiceHelper,
                private toasterHelper: ToasterHelperService,
                private renderer: Renderer2,
                private generalHelperService: GeneralHelperService) {
    }

    ngOnInit(): void {
        this.getSingleSource();
        this.opened = true;
        setTimeout(() => {
            this.listenToClickType();
        });
    }

    listenToClickType(): void {
        this.renderer.listen('window', 'click', (e: Event) => {
            if (this.generalHelperService.checkIfClickedOutsideOfTheElement(undefined, this.modal, e)) {
                if (this.opened) {
                    this.modalClosed.emit(true);
                    this.opened = false;
                }
            }
        });
    }

    uploadFiles(): void {
        if (this.source) {
            this.sourcesHttpService.uploadFiles(this.source, this.addSourceManual.files).then((response: Source) => {
                setTimeout(() => {
                    this.modalClosed.emit(true);
                    this.sourcesHttpServiceHelper.resetFileUploadSubject();
                    this.toasterHelper.showSuccess('Successfully uploaded documents!');
                }, 500);
            });
        }
    }

    getSingleSource(): void {
        if (this.source) {
            this.sourcesHttpService.getSource(this.source.id).then((source: Source) => {
                this.addSourceManual.expectedFileExtension = source.extensions;
                this.addSourceManual.expectedFileExtension.map(fileExt => {
                    fileExt.isChecked = true;
                });
            });
        }
    }

    finishAddingFiles(ppgFiles: Array<PpgFile>): void {
        this.ppgFiles = ppgFiles;
    }

}
