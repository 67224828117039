import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'app-destinations',
    templateUrl: './destinations.component.html'
})
export class DestinationsComponent implements OnInit {

    constructor() {
    }

    ngOnInit(): void {
    }
}
