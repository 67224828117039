<div class="flex flex-col">
    <div *ngFor="let document of documents?.data; let i = index;"
         class="bg-gray-100 border border-gray-200 mt-10 rounded-md">
        <div class="flex justify-between items-center p-2 cursor-pointer"
             (click)="document.isCollapsed = !document.isCollapsed;searchUseCaseData(1, document.id, document);">
            <span class="text-gray-800 font-medium text-xs">{{document.file_name}}</span>
            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 transform transition"
                 [class]="document.isCollapsed ? '-rotate-90' : 'rotate-0'" fill="none"
                 viewBox="0 0 24 24"
                 stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7"/>
            </svg>
        </div>
        <div *ngIf="document.isCollapsed">
            <div class="bg-gray-100 border border-gray-200 mt-10 rounded-md"
                 *ngFor="let useCaseSingleData of document?.dataDocumentWithPag?.useCaseData">
                <div class="flex justify-between items-center p-2 cursor-pointer"
                     (click)="useCaseSingleData.collapsed = !useCaseSingleData.collapsed;$event.stopPropagation();">
                    <span class="text-gray-800 font-medium text-xs">{{useCaseSingleData.body.length}} RECORDS</span>
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 transform transition"
                         [class]="!useCaseSingleData.collapsed ? '-rotate-90' : 'rotate-0'" fill="none"
                         viewBox="0 0 24 24"
                         stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7"/>
                    </svg>
                </div>
                <div *ngIf="!useCaseSingleData.collapsed">
                    <app-use-case-single-body-data *ngIf="useCaseSingleData.body.length"
                                                   [useCaseSingleData]="useCaseSingleData"
                                                   (clickedDataBody)="emitUseCaseData(useCaseSingleData, $event);">
                    </app-use-case-single-body-data>
                </div>
            </div>
            <!-- Pagination -->
            <div class="mt-4">
                <div class="flex w-full justify-between items-center border-t border-gray-200 pt-2">
                    <!--                        <app-pagination-data-details *ngIf="showUseCasePag(document?.dataDocumentWithPag)" [pagination]="document?.dataDocumentWithPag?.pagination">-->
                    <!--                        </app-pagination-data-details>-->
                    <app-ppg-pagination *ngIf="showUseCasePag(document?.dataDocumentWithPag)"
                                        [pagId]="'js-use-case-pag'"
                                        [currentPage]="document?.dataDocumentWithPag?.pagination?.currentPage"
                                        [perPage]="document?.dataDocumentWithPag?.pagination?.perPage"
                                        [totalItems]="document?.dataDocumentWithPag?.pagination?.totalItems"
                                        (pageChanged)="searchUseCaseData($event, document.id, document)">
                    </app-ppg-pagination>
                </div>
            </div>
        </div>
    </div>
    <app-add-new-item *ngIf="!showData(documents)"
                      [title]="'You need to add at least one use case data to the use case to start.'"
                      [announcement]="'No use case data here yet.'">
    </app-add-new-item>
</div>
<!-- Pagination -->
<div class="mt-4">
    <div class="flex w-full justify-between items-center border-t border-gray-200 pt-2">
        <app-pagination-data-details *ngIf="showData(documents)" [pagination]="documents?.pagination">
        </app-pagination-data-details>
        <app-ppg-pagination *ngIf="showData(documents)" [currentPage]="documents?.pagination?.currentPage"
                            [pagId]="'js-document-pag'"
                            [perPage]="documents?.pagination?.perPage"
                            [totalItems]="documents?.pagination?.totalItems"
                            (pageChanged)="searchUseCaseDataDocuments($event)">
        </app-ppg-pagination>
    </div>
</div>
