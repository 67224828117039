import {Component, ElementRef, EventEmitter, Input, OnInit, Output, Renderer2, ViewChild} from '@angular/core';
import {UseCase} from '../../../interfaces/use-case-interfaces';
import {
    CloudIntegrationResponse,
    CloudRegion,
    GetIntegrationsResponse, IntegrationModalDetails,
    SearchAndFilterCloudsReqObj
} from '../../../interfaces/sources-interfaces';
import {UseCasesHttpService} from '../../../services/http-services/use-cases-http.service';
import {SourcesHttpService, SourcesHttpServiceHelper} from '../../../services/http-services/sources-http.service';
import {AddingSourceType, CloudTypes, EditType} from '../../../enums/sources-enums';
import {FadeInAnimation} from '../../../animations/fade-in-animation';
import {AddingSourceTypeCloudFirstStep} from '../../source/add-source-modal/AddingSourceTypeCloudFirstStep';
import {CloudIntegration} from '../../source/add-source-modal/add-source-cloud-modal/cloud-integration';
import {AddSourceCloud} from '../../source/add-source-modal/add-source-cloud-modal/add-source-cloud';
import {PpgFormGroup} from '../../../derived-classes/ppg-form-group';
import {SourcesHelperService} from '../../../services/helpers/sources-helper.service';
import {SlideInOutAnimation} from '../../../animations/slide-modal-animation';
import {GeneralHelperService} from '../../../services/helpers/general-helper.service';

@Component({
    selector: 'app-integration-with-search-and-filters',
    templateUrl: './integration-with-search-and-filters.component.html',
    animations: [FadeInAnimation, SlideInOutAnimation]
})
export class IntegrationWithSearchAndFiltersComponent implements OnInit {

    @Input() useCaseId = 0;
    @Input() useCase: UseCase | undefined;
    @Input() showAddSourceModal = false;
    viewIntegrationDetails: IntegrationModalDetails = {integration: undefined, show: false};
    deleteIntegrationDetails: IntegrationModalDetails = {integration: undefined, show: false};
    integrations: GetIntegrationsResponse | undefined;
    initialSources: GetIntegrationsResponse | undefined;
    searchAndFilter: SearchAndFilterCloudsReqObj = {
        query: '',
        cloud_type: {id: 0, name: ''},
        type_id: 0
    };
    initialRequestFinished = false;
    selectedIntegrationType: AddingSourceType = AddingSourceType.cloud;
    integrationEdit: { show: boolean, source: AddSourceCloud | null, ppgForm: PpgFormGroup | null } = {
        show: false,
        source: null,
        ppgForm: null
    };
    cloudRegions: Array<CloudRegion> = [];
    cloudTypes = CloudTypes;
    editType = EditType;
    opened = false;
    @ViewChild('modal') modal: ElementRef | undefined;
    @Output() showAddSourceModalChange = new EventEmitter();

    constructor(private useCasesHttpService: UseCasesHttpService,
                private sourcesHttpService: SourcesHttpService,
                private sourcesHttpServiceHelper: SourcesHttpServiceHelper,
                private sourcesHelperService: SourcesHelperService,
                private renderer: Renderer2,
                private generalHelperService: GeneralHelperService) {
    }

    ngOnInit(): void {
        this.getIntegrations(this.selectedIntegrationType);
    }

    listenToClickType(): void {
        this.renderer.listen('window', 'click', (e: Event) => {
            if (this.generalHelperService.checkIfClickedOutsideOfTheElement(undefined, this.modal, e)) {
                if (this.opened) {
                    this.resetEditIntegrationDetailsToInitialState();
                    this.opened = false;
                }
            }
        });
    }

    onIntegrationDeleteModalClosed(): void {
        if (this.deleteIntegrationDetails) {
            this.deleteIntegrationDetails.show = false;
            this.deleteIntegrationDetails.integration = undefined;
        }
    }

    onIntegrationDelete(destination: any): void {
        this.sourcesHttpService.deleteIntegration(destination).then((deletedDestination: CloudIntegration) => {
            if (deletedDestination) {
                this.deleteIntegrationFromView(this.deleteIntegrationDetails, deletedDestination);
            }
        });
    }

    deleteIntegrationFromView(integrationModalDetails: IntegrationModalDetails | undefined, deletedIntegration: CloudIntegration): void {
        const integrationIndex: number | undefined = this.integrations?.data.findIndex((x: CloudIntegration) => x.id === deletedIntegration.id);
        if (typeof integrationIndex !== 'undefined' && integrationIndex !== -1) {
            this.integrations?.data.splice(integrationIndex, 1);
        }
        this.deleteIntegrationDetails = {show: false, integration: undefined};
    }

    resetViewIntegrationDetailsToInitialState(): void {
        this.viewIntegrationDetails = {integration: undefined, show: false};
    }

    resetEditIntegrationDetailsToInitialState(): void {
        this.integrationEdit = {
            show: false,
            source: null,
            ppgForm: null
        };
    }

    getCloudRegionAndOpenModal(cloudIntegration: CloudIntegration, editType: EditType): void {
        this.integrationEdit.show = true;
        this.sourcesHttpService.getCloudRegionsAndIntegrations(cloudIntegration.cloudType?.id).then((response: { cloudRegions: Array<CloudRegion>, cloudIntegrations: Array<CloudIntegrationResponse> }) => {
            this.cloudRegions = response.cloudRegions;
            const selectedAddingType = new AddingSourceTypeCloudFirstStep();
            selectedAddingType.cloudIntegration = cloudIntegration;
            const addingSourceClass = this.sourcesHttpServiceHelper.createAndReturnSourceObject(selectedAddingType, this.useCaseId);
            this.integrationEdit = {
                show: true,
                source: (addingSourceClass instanceof AddSourceCloud) ? addingSourceClass : null,
                ppgForm: null
            };
            setTimeout(() => {
                this.opened = true;
                this.listenToClickType();
            });
            if (addingSourceClass instanceof AddSourceCloud) {
                addingSourceClass.cloudType = cloudIntegration.cloudType;
                addingSourceClass.cloudIntegration.editType = editType;
                addingSourceClass.cloudIntegration.secretAccessKey = '';
                addingSourceClass.cloudIntegration.password = '';
                console.log('OPen: ', this.integrationEdit);
                this.integrationEdit.ppgForm = this.sourcesHelperService.buildPpgForm(addingSourceClass, response.cloudRegions);
            }
        });
    }

    // TODO Implement bellow
    onCloudIntegrationEdited(cloudIntegration: CloudIntegration): void {
        console.log('edited: ', cloudIntegration);
    }

    // onSourceEdited(editedSource: Source | null): void {
    //     if (this.sources && this.sources.data && editedSource) {
    //         const sourceIndex = this.sources.data.findIndex(x => x.id === editedSource.id);
    //         if (sourceIndex !== -1) {
    //             this.sources.data[sourceIndex] = editedSource;
    //             this.resetEditSourceDetailsToInitialState();
    //         }
    //     }
    // }

    // openEditModal(sourceId: number): void {
    //     this.editSourceDetails.sourceId = sourceId;
    //     this.editSourceDetails.show = true;
    // }

    searchIntegrations(searchAndFilter: SearchAndFilterCloudsReqObj, pageNUmber: number | null = null): void {
        if (searchAndFilter.cloud_type.id === 0 && !searchAndFilter.query.trim()) {
            this.getIntegrations(this.selectedIntegrationType);
        } else {
            this.sourcesHttpService.searchAndFilterIntegrations(this.searchAndFilter, this.useCaseId, pageNUmber).then(response => {
                this.integrations = response;
                console.log('Response: ', response)
            });
        }
    }

    // onSourceAdded(source: Source): void {
    //     this.getUseCaseSources(this.useCaseId);
    // }

    onPageChanged(pageNumber: number): void {
        (this.searchAndFilter.query.trim() || this.searchAndFilter.cloud_type.id !== 0) ?
            this.searchIntegrations(this.searchAndFilter, pageNumber) :
            this.getIntegrationPagination(pageNumber);
    }

    getIntegrationPagination(pageNumber: number | null = null): void {
        this.sourcesHttpService.getIntegrations(this.selectedIntegrationType, pageNumber).then((response: GetIntegrationsResponse) => {
            this.integrations = {data: response.data, pagination: response.pagination};
        });
    }

    getIntegrations(cloudType: AddingSourceType): void {
        this.sourcesHttpService.getIntegrations(cloudType).then((response: GetIntegrationsResponse) => {
            this.integrations = {data: response.data, pagination: response.pagination};
            this.initialSources = {data: response.data, pagination: response.pagination};
            this.initialRequestFinished = true;
        });
    }

    showData(integrations: GetIntegrationsResponse | undefined): boolean | undefined {
        return integrations && integrations.data && typeof integrations.pagination.totalItems !== 'undefined' && integrations.pagination.totalItems > 0;
    }

    // onClosedModalForAdding(): void {
    //     this.showAddSourceModal = false;
    //     this.showAddSourceModalChange.emit(false);
    // }
}
