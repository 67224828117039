<div class="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
    <div class="sm:mx-auto sm:w-full sm:max-w-md">
        <img class="mx-auto h-20 w-auto text-red-600 fill-current" src="/assets/images/logo-light.svg" alt="Workflow">
        <h2 class="mt-6 text-center text-3xl font-extrabold text-gray-900 leading-9">
            Forgot password
        </h2>
    </div>

    <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div class="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
            <form [formGroup]="resetPasswordForm" (ngSubmit)="onSubmit()" class="space-y-6">
                <div>
                    <label class="block text-sm font-medium text-gray-700">Email</label>
                    <input class="appearance-none block w-full px-3 py-2 border border-gray-300
                         rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-gray-500 focus:border-gray-500 sm:text-sm"
                           formControlName="email" type="email">
                </div>
                <div>
                    <label class="block text-sm font-medium text-gray-700">Password</label>
                    <input class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm
                     placeholder-gray-400 focus:outline-none focus:ring-gray-500 focus:border-gray-500 sm:text-sm"
                           formControlName="password" (focus)="isPasswordValid = true; isPasswordLenghtValid = true"
                           type="password">
                </div>
                <div>
                    <label class="block text-sm font-medium text-gray-700">Confirm Password</label>
                    <input class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm
                     placeholder-gray-400 focus:outline-none focus:ring-gray-500 focus:border-gray-500 sm:text-sm"
                           formControlName="password_confirmation"
                           (focus)="isPasswordValid = true; isPasswordLenghtValid = true"
                           type="password">
                    <div *ngIf="!isPasswordValid" class="text-xs text-red-500 mt-2">
                        Passwords are not same!
                    </div>
                    <div *ngIf="!isPasswordLenghtValid" class="text-xs text-red-500 mt-2">
                        Passwords length must be at least 8 characters long!
                    </div>
                </div>

                <button type="submit" [disabled]="!resetPasswordForm.valid"
                        class="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500">
                    send
                </button>
            </form>

            <!-- Successfull message -->
            <div class="space-y-6 flex flex-col items-center hidden">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-16 w-16 text-green-500" viewBox="0 0 20 20"
                     fill="currentColor">
                    <path fill-rule="evenodd"
                          d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                          clip-rule="evenodd"/>
                </svg>
                <p class="text-2xl text-gray-900">
                    Password successfully changed!
                </p>
                <a href="#0"
                   class="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500">
                    Back to Login
                </a>
            </div>
            <!-- ./Successfull message -->
        </div>
    </div>
</div>
