<!-- Old User Settings -->
<!-- <div class="flex flex-col w-full px-6 py-4">
    <h1 class="text-2xl text-gray-900 leading-7 font-bold">Profile Settings</h1>
    <div>
        <div>personal information <button (click)="editUser()"
                class="justify-center py-2 px-4 border border-transparent bg-gray-400 rounded-md shadow-sm text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500">edit</button>
        </div>
        <p>{{userDataDetails?.first_name}}</p>
        <p>{{userDataDetails?.last_name}}</p>
        <p>{{userDataDetails?.email}}</p>

        <a (click)="changePassword()">change password</a>

        <app-edit-user-profile *ngIf="isEditUserInfo" [userToBeEdited]="userToBeEdited"
            (updatedUser)="updateUser($event)">
        </app-edit-user-profile>

        <app-change-password *ngIf="isChangeUserPassword" (changedPassword)="updatePassword($event)">
        </app-change-password>
    </div>



    <div *ngIf="isAdmin">
        <div>companyinformation <button (click)="editCompanyInfo()"
                class="justify-center py-2 px-4 border border-transparent bg-gray-400 rounded-md shadow-sm text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500">editCompanyInfo</button>
        </div>
        <p></p>
    </div>
</div> -->
<!-- End Old User Settings -->
<div class="flex flex-col flex-grow w-full overflow-y-auto max-h-screen">
  <div class="bg-white pt-4 pb-4 px-8 text-xl leading-7 font-semibold min-w-full">
    Settings
  </div>

  <!-- Personal Information Setting -->
  <div class="p-8 md:w-auto md:max-w-2xl">
    <div class="bg-white overflow-hidden shadow rounded-lg">
      <div class="px-4 py-5 sm:p-6">
        <h4 class="text-lg leading-7 font-medium mb-8">Personal Information</h4>

        <form class="space-y-4" [formGroup]="userInfo" *ngIf="userInfo.controls.email">
          <div>
            <label class="block text-sm font-medium text-gray-700">First name</label>
            <div class="mt-1">
              <input formControlName="firstName" type="text"
                     class="shadow-sm focus:ring-gray-500 focus:border-gray-400 block w-full sm:text-sm border-gray-300 rounded-md"
                     placeholder="First name">
              <small
                *ngIf="userInfo?.controls?.firstName?.invalid && (userInfo?.controls?.firstName?.dirty || userInfo?.controls?.firstName?.touched)"
                class="text-red-500 font-normal text-sm">{{InputErrorMessages.default}}</small>
            </div>
          </div>
          <div>
            <label class="block text-sm font-medium text-gray-700">Last name</label>
            <div class="mt-1">
              <input formControlName="lastName" type="text"
                     class="shadow-sm focus:ring-gray-500 focus:border-gray-400 block w-full sm:text-sm border-red-500 rounded-md"
                     placeholder="Last name">
              <small
                *ngIf="userInfo?.controls?.lastName?.invalid && (userInfo?.controls?.lastName?.dirty || userInfo?.controls?.lastName?.touched)"
                class="text-red-500 font-normal text-sm">{{InputErrorMessages.default}}</small>
            </div>
          </div>
          <div>
            <label class="block text-sm font-medium text-gray-700">Email address</label>
            <div class="mt-1">
              <input formControlName="email" type="email"
                     class="shadow-sm focus:ring-gray-500 focus:border-gray-400 block w-full sm:text-sm border-gray-300 rounded-md"
                     placeholder="you@example.com">
              <small
                *ngIf="userInfo?.controls?.email?.invalid && (userInfo?.controls?.email?.dirty || userInfo?.controls?.email?.touched)"
                class="text-red-500 font-normal text-sm">{{InputErrorMessages.default}}</small>
            </div>
          </div>
        </form>

        <div class="flex mt-4 justify-end">
          <button (click)="updateUser()" [disabled]="!userInfo?.valid" type="button" disabled
                  class="inline-flex items-center justify-center px-3 py-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500">
            Save Changes
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- End Personal Information Setting -->
  <div class="px-8">
    <div class="my-4 border-t border-gray-300"></div>
  </div>
  <!-- Change Password -->
  <div class="p-8 md:w-auto md:max-w-2xl">
    <div class="bg-white overflow-hidden shadow rounded-lg">
      <div class="px-4 py-5 sm:p-6">
        <h4 class="text-lg leading-7 font-medium mb-8">Change Password</h4>

        <form class="space-y-4" [formGroup]="userPassword">
          <div>
            <label class="block text-sm font-medium text-gray-700">Old Password</label>
            <div class="mt-1">
              <input type="password" formControlName="oldPassword"
                     (ngModelChange)="oldPasswordNotCorrect = false"
                     class="shadow-sm focus:ring-gray-500 focus:border-gray-400 block w-full sm:text-sm border-gray-300 rounded-md"
                     placeholder="Old Password">
              <small
                *ngIf="userPassword?.controls?.oldPassword?.invalid &&
                 (userPassword?.controls?.oldPassword?.dirty || userPassword?.controls?.oldPassword?.touched)"
                class="text-red-500 font-normal text-sm">{{InputErrorMessages.default}}<br></small>
              <small
                *ngIf="userPassword?.controls?.oldPassword?.value.length < 8 && submitted"
                class="text-red-500 font-normal text-sm">{{InputErrorMessages.minimumPasswordLength}}<br>
              </small>
              <small
                *ngIf="oldPasswordNotCorrect"
                class="text-red-500 font-normal text-sm">{{InputErrorMessages.oldPasswordNotCorrect}}</small>
            </div>
          </div>
          <div>
            <label class="block text-sm font-medium text-gray-700">New Password</label>
            <div class="mt-1">
              <input type="password" formControlName="newPassword"
                     class="shadow-sm focus:ring-gray-500 focus:border-gray-400 block w-full sm:text-sm border-gray-300 rounded-md"
                     placeholder="New Password">
              <small
                *ngIf="userPassword?.controls?.newPassword?.invalid && (userPassword?.controls?.newPassword?.dirty || userPassword?.controls?.newPassword?.touched)"
                class="text-red-500 font-normal text-sm">{{InputErrorMessages.default}}</small>
              <small
                *ngIf="userPassword?.controls?.newPassword?.value.length < 8 && submitted"
                class="text-red-500 font-normal text-sm">{{InputErrorMessages.minimumPasswordLength}}</small>
            </div>
          </div>
          <div>
            <label class="block text-sm font-medium text-gray-700">Confirm
              Password</label>
            <div class="mt-1">
              <input type="password" formControlName="confirmNewPassword"
                     class="shadow-sm focus:ring-gray-500 focus:border-gray-400 block w-full sm:text-sm border-gray-300 rounded-md"
                     placeholder="Confirm Password">
              <small
                *ngIf="userPassword?.controls?.confirmNewPassword?.invalid && (userPassword?.controls?.confirmNewPassword?.dirty || userPassword?.controls?.confirmNewPassword?.touched)"
                class="text-red-500 font-normal text-sm">{{InputErrorMessages.default}}
                <br></small>
              <small *ngIf="!arePasswordSame(userPassword.controls.newPassword.value, userPassword.controls.confirmNewPassword.value) &&
                              userPassword.controls.newPassword.value &&  userPassword.controls.confirmNewPassword.value"
                     class="text-red-500 font-normal text-sm">{{InputErrorMessages.passwordsAreNotTheSame}}
                <br></small>
              <small
                *ngIf="userPassword?.controls?.confirmNewPassword?.value.length < 8 && submitted"
                class="text-red-500 font-normal text-sm">{{InputErrorMessages.minimumPasswordLength}}</small>

            </div>
          </div>
        </form>

        <div class="flex mt-4 justify-end">
          <button (click)="updatePassword()" [disabled]="!userPassword?.valid" type="button" disabled
                  class="inline-flex items-center justify-center px-3 py-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500">
            Save Changes
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- Change Password -->

  <!-- Company Information-->
  <div class="px-8" *ngIf="UsersRoles.companyAdmin === loggedUser.role_name">
    <div class="my-4 border-t border-gray-300"></div>
  </div>
  <!-- Company Name -->
  <div class="p-8 md:w-auto md:max-w-2xl" *ngIf="UsersRoles.companyAdmin === loggedUser.role_name">
    <div class="bg-white overflow-hidden shadow rounded-lg">
      <div class="px-4 py-5 sm:p-6">
        <h4 class="text-lg leading-7 font-medium mb-8">Company Information</h4>

        <div class="space-y-4">
          <div>
            <label class="block text-sm font-medium text-gray-700">Company Name</label>
            <div class="mt-1">
              <input type="text" [(ngModel)]="loggedUser.company_admin_companies[0].name"
                     class="shadow-sm focus:ring-indigo-500 focus:border-indigo-400 block w-full sm:text-sm border-gray-300 rounded-md"
                     placeholder="Company name" value="ComponyName_1">
            </div>
          </div>
        </div>

        <div class="flex mt-4 justify-end">
          <button type="button" [disabled]="!loggedUser.company_admin_companies[0].name.trim()"
                  (click)="updateCompanyName()"
                  class="inline-flex items-center justify-center px-3 py-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500">
            Save Changes
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- Company Name -->

</div>
