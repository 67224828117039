<div *ngFor="let cloudIntegration of cloudIntegrations">
    <div class="flex content-between items-center p-4">
        <img src="../../../assets/images/icons/PG_Icon.svg" alt="icon">
        <div class="flex flex-col flex-grow space-y-1">
            <h4 class="text-sm leading-5 font-normal gray-400 uppercase">Data source: {{cloudIntegration?.cloudType?.name}}</h4>
            <h3 class="text-sm leading-5 font-semibold">{{cloudIntegration.name}}</h3>
        </div>
        <!--TODO Zoca stylize integration edit button and edit access key and password buttons-->
        <button *ngIf="CloudTypes.aws === cloudIntegration?.cloudType?.name"
                (click)="changeStepAndSetActiveIntegration($event, cloudIntegration, EditType.accessKey)">
                <span class="text-sm text-gray-600 font-normal mr-2" >Change access key</span>
        </button>
        <button *ngIf="CloudTypes.ftp === cloudIntegration?.cloudType?.name || CloudTypes.sftp === cloudIntegration?.cloudType?.name"
                (click)="changeStepAndSetActiveIntegration($event, cloudIntegration, EditType.password)">
            <span class="text-sm text-gray-600 font-normal mr-2">
                Change password
            </span>
        </button>
        <button (click)="changeStepAndSetActiveIntegration($event, cloudIntegration, EditType.default)">
            <img class="mr-2" src="assets/images/icons/icon-edit.svg" alt="">
        </button>
        <input name="comments" type="checkbox" (click)="onCloudIntegrationCheckChange(cloudIntegration, $event)" [checked]="cloudIntegration.id === checkedCloudIntegration?.id"
               class="focus:ring-gray-500 h-6 w-6 text-gray-600 border-gray-300 rounded cursor-pointer">
    </div>
    <div class="w-full border-t border-gray-200"></div>
</div>
