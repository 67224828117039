import {Injectable} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {UseCase, UseCaseType} from '../../interfaces/use-case-interfaces';
import {UseCasesHttpService} from '../http-services/use-cases-http.service';
import {ToasterHelperService} from './toaster-helper.service';
import {Subject} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class UseCaseHelperService {

    private _resetUseCaseFilters = new Subject<boolean>();

    constructor(private useCaseHttp: UseCasesHttpService,
                private toasterHelperService: ToasterHelperService) {
    }

    get resetUseCaseFilters(): Subject<boolean> {
        return this._resetUseCaseFilters;
    }

    createUseCase(formGroup: FormGroup, useCaseTypes: Array<UseCaseType>): Promise<UseCase> {
        return new Promise<UseCase>((resolve => {
            if (this.checkTypeValidity(formGroup, useCaseTypes)) {
                this.useCaseHttp.createUseCase(formGroup, useCaseTypes).then((useCase: UseCase) => {
                    resolve(useCase);
                });
            } else {
                this.toasterHelperService.showWarning('Please check Type of extraction');
            }
        }));
    }

    checkTypeValidity(formGroup: FormGroup, useCaseTypes: Array<UseCaseType>): boolean {
        for (const type of useCaseTypes) {
            if (formGroup.controls[type.name].value) {
                return true;
            }
        }
        return false;
    }

}
