import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, Renderer2, SimpleChanges, ViewChild } from '@angular/core';
import { GetDocumentsResponse, SearchAndFilterSourcesReqObj, SourceType } from 'src/app/interfaces/sources-interfaces';
import { UseCase } from 'src/app/interfaces/use-case-interfaces';
import { GeneralHelperService } from 'src/app/services/helpers/general-helper.service';
import { SourcesHttpService } from 'src/app/services/http-services/sources-http.service';
import { UseCasesHttpService } from 'src/app/services/http-services/use-cases-http.service';
import {SearchDataType, UseCaseDataViewTypes} from '../../../enums/use-cases-enums';
import {DataDocument, SearchTypeParams, UseCaseDataResponse} from '../../../interfaces/use-case-data-interfaces';
import {UseCaseDataHttpService} from '../../../services/http-services/use-case-data-http.service';

@Component({
  selector: 'app-use-case-documents',
  templateUrl: './use-case-documents.component.html'
})
export class UseCaseDocumentsComponent implements OnInit, OnChanges {
  @Input() useCaseId: any;
  searchAndFilter: SearchAndFilterSourcesReqObj = {
    query: '',
    source_type: { id: 0, name: '', nameForView: '' },
    status_id: 0
  };
  @ViewChild('toggleButtonType') toggleButtonType: ElementRef | undefined;
  documents: GetDocumentsResponse | undefined;
  activeSourceType = { id: 0, name: 'All', nameForView: 'All' };
  useCaseSingleData: any;
  searchQuery = '';
  sourcesDropdownList: any = [];
  showDropdownType = false;
    protected readonly UseCaseDataViewTypes = UseCaseDataViewTypes;

  constructor(
    private useCasesHttpService: UseCasesHttpService,
    private sourcesHttpService: SourcesHttpService,
    private renderer: Renderer2,
    private generalHelperService: GeneralHelperService,
    private useCaseDataHttp: UseCaseDataHttpService
  ) {
    this.listenToClickType();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ((changes.useCaseId || changes.useCaseId === 0) && (changes.useCaseId.currentValue || changes.useCaseId.currentValue === 0)) {
      this.getDocuments(this.useCaseId);
      this.getUseCaseSources(this.useCaseId);
    }
  }

  ngOnInit(): void {
  }

  getDocuments(useCaseId: number): any {
    this.useCasesHttpService.getUseCaseDocuments(useCaseId).subscribe((response: any) => {
      this.documents = {
        data: response.entity.data,
        pagination: {
          totalItems: response.entity.total,
          perPage: response.entity.per_page,
          currentPage: response.entity.current_page,
          nextPageUrl: response.entity.next_page_url,
          from: response.entity.from,
          to: response.entity.to
        }
      };
    }, (error: any) => {
      console.log(error);
    });
  }

  onPageChanged(event: any): void {
    (this.searchAndFilter.query.trim() || this.searchAndFilter.source_type.id !== 0) ?
      this.searchDocuments(this.searchAndFilter, this.documents?.pagination.currentPage) :
      this.getDocuments(this.useCaseId);
  }

  getUseCaseSources(useCaseId: number): void {
    this.sourcesHttpService.getUseCaseSources(useCaseId).then((response: any) => {
      this.sourcesDropdownList = response.data;
    });
  }

    searchUseCaseData(pageNum: number, documentId: number, document: DataDocument): void {
        const searchTypeParams: SearchTypeParams = {
            searchDataType: SearchDataType.document,
            searchDataValue: documentId
        };
        this.useCaseDataHttp.searchUseCaseData('', pageNum, searchTypeParams).then((response: UseCaseDataResponse) => {
            document.dataDocumentWithPag = response;
        });
    }

  searchDocuments(event: any, pageNumber: any): void {
    if (event) {
      this.searchAndFilter.query = event;
      this.useCasesHttpService.searchUseCaseDocuments(this.searchAndFilter, this.useCaseId, pageNumber).then((response: any) => {
        this.documents = {
          pagination: response.pagination,
          data: response.documents
        };
      });
    } else {
      this.getDocuments(this.useCaseId);
    }
  }

  changeSourceType(sourceType: SourceType): void {
    console.log(sourceType)
    this.activeSourceType.name = sourceType.name;
    console.log(this.activeSourceType)
    if (sourceType.nameForView === 'All') {
      this.getDocuments(this.useCaseId);
    } else {
      this.searchDocuments(sourceType.name, this.documents?.pagination.currentPage);
      // this.activeSourceType = sourceType;
      this.searchAndFilter.source_type = sourceType;
      this.showDropdownType = false;

    }
  }

  listenToClickType(): void {
    this.renderer.listen('window', 'click', (e: Event) => {
      if (this.generalHelperService.checkIfClickedOutsideOfTheElement(this.toggleButtonType, undefined, e)) {
        this.showDropdownType = false;
      }
    });
  }
}
