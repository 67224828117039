<div class="flex flex-col w-full">
  <app-use-case-navigation
    *ngIf="!((activeUseCaseDataView === UseCaseDataViewTypes.single || activeUseCaseDataView === UseCaseDataViewTypes.singleQuery) && useCaseTabsClass.activeTab === useCaseTabsEnums.data)"
    [useCase]="useCase">
  </app-use-case-navigation>
  <app-header-with-tabs
    *ngIf="!waitingForRes && !((activeUseCaseDataView === UseCaseDataViewTypes.single || activeUseCaseDataView === UseCaseDataViewTypes.singleQuery) && useCaseTabsClass.activeTab === useCaseTabsEnums.data)"
    (addNewSource)="showAddSourceModal = true" (addNewDestination)="showAddDestinationModal = true"
    [(useCaseTabsClass)]="useCaseTabsClass" [useCase]="useCase">
  </app-header-with-tabs>
  <app-sources-with-search-and-filters *ngIf="useCaseTabsClass.activeTab === useCaseTabsEnums.sources"
    [useCaseId]="useCaseId" [useCase]="useCase" [(showAddSourceModal)]="showAddSourceModal">
  </app-sources-with-search-and-filters>
  <app-destinations-with-search-and-filters *ngIf="useCaseTabsClass.activeTab === useCaseTabsEnums.destinations"
    [(showAddDestinationModal)]="showAddDestinationModal" [useCaseId]="useCaseId">
  </app-destinations-with-search-and-filters>
  <app-use-case-documents *ngIf="useCaseTabsClass.activeTab === useCaseTabsEnums.documents" [useCaseId]="useCaseId">
  </app-use-case-documents>
  <app-use-case-data *ngIf="useCaseTabsClass.activeTab === useCaseTabsEnums.data"
    (changedView)="activeUseCaseDataView = $event" [useCaseId]="useCaseId">
  </app-use-case-data>
</div>


<!-- <div class="flex flex-col w-full h-screen">
  <div class="p-4 w-full">
    <nav aria-label="Progress">
      <ol role="list" class="border border-gray-300 rounded-md divide-y divide-gray-300 md:flex md:divide-y-0">
        <li class="relative md:flex-1 md:flex">
          <a href="#" class="group flex items-center w-full">
            <span class="px-6 py-4 flex items-center text-sm font-medium">
              <span
                class="flex-shrink-0 w-10 h-10 flex items-center justify-center bg-green-600 rounded-full group-hover:bg-green-800">
                <svg class="w-6 h-6 text-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                  fill="currentColor" aria-hidden="true">
                  <path fill-rule="evenodd"
                    d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                    clip-rule="evenodd" />
                </svg>
              </span>
              <span class="ml-4 text-sm font-medium text-gray-900">Files Downloading</span>
            </span>
          </a>

          <div class="hidden md:block absolute top-0 right-0 h-full w-5" aria-hidden="true">
            <svg class="h-full w-full text-gray-300" viewBox="0 0 22 80" fill="none" preserveAspectRatio="none">
              <path d="M0 -2L20 40L0 82" vector-effect="non-scaling-stroke" stroke="currentcolor"
                stroke-linejoin="round" />
            </svg>
          </div>
        </li>

        <li class="relative md:flex-1 md:flex">
          <a href="#" class="px-6 py-4 flex items-center text-sm font-medium" aria-current="step">
            <span
              class="flex-shrink-0 w-10 h-10 flex items-center justify-center border-2 border-green-600 rounded-full">
              <span class="text-green-600">02</span>
            </span>
            <span class="ml-4 text-sm font-medium text-green-600">Select Pages</span>
          </a>
          <div class="hidden md:block absolute top-0 right-0 h-full w-5" aria-hidden="true">
            <svg class="h-full w-full text-gray-300" viewBox="0 0 22 80" fill="none" preserveAspectRatio="none">
              <path d="M0 -2L20 40L0 82" vector-effect="non-scaling-stroke" stroke="currentcolor"
                stroke-linejoin="round" />
            </svg>
          </div>
        </li>
        <li class="relative md:flex-1 md:flex">
          <a href="#" class="group flex items-center">
            <span class="px-6 py-4 flex items-center text-sm font-medium">
              <span
                class="flex-shrink-0 w-10 h-10 flex items-center justify-center border-2 border-gray-300 rounded-full group-hover:border-gray-400">
                <span class="text-gray-500 group-hover:text-gray-900">03</span>
              </span>
              <span class="ml-4 text-sm font-medium text-gray-500 group-hover:text-gray-900">Read-Data Process</span>
            </span>
          </a>
        </li>

      </ol>
    </nav>
  </div>

  <div class="flex-col md:flex-row flex w-full h-full">

    <ul class="hidden md:block w-1/4 h-full overflow-y-auto pb-4">
      <li class="flex w-full px-6 py-4 bg-gray-100 cursor-pointer border-b border-gray-200">
        <div class="flex flex-col space-y-1 flex-grow">
          <span class="text-sm leading-5 font-medium text-gray-900">10Q-10K-8K Docs</span>
          <div class="flex space-x-1 items-center">
            <svg width="12" height="16" viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M0 2C0 0.895431 0.89543 0 2 0H6.58579C7.11622 0 7.62493 0.210714 8 0.585786L11.4142 4C11.7893 4.37507 12 4.88378 12 5.41421V14C12 15.1046 11.1046 16 10 16H2C0.89543 16 0 15.1046 0 14V2Z" fill="#9CA3AF"/>
            </svg>
            <span class="text-sm leading-5 font-medium text-gray-500">Pages: 39</span>
          </div>
        </div>
        <button class="p-1.5">
          <svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M0.292894 9.70711C-0.0976307 9.31658 -0.0976307 8.68342 0.292894 8.29289L3.58579 5L0.292893 1.70711C-0.0976311 1.31658 -0.0976311 0.683418 0.292893 0.292894C0.683417 -0.0976312 1.31658 -0.0976312 1.70711 0.292894L5.70711 4.29289C6.09763 4.68342 6.09763 5.31658 5.70711 5.70711L1.70711 9.70711C1.31658 10.0976 0.683418 10.0976 0.292894 9.70711Z" fill="#9CA3AF"/>
          </svg>
        </button>
      </li>
    </ul>

    <div class="md:hidden p-4">
      <div class="mt-1 relative">
        <button type="button" class="bg-white relative w-full border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-green-500 focus:border-green-500 sm:text-sm" aria-haspopup="listbox" aria-expanded="true" aria-labelledby="listbox-label">
          <div class="flex flex-col space-y-1 flex-grow">
            <span class="text-sm leading-5 font-medium text-gray-900">10Q-10K-8K Docs</span>
            <div class="flex space-x-1 items-center">
              <svg width="12" height="16" viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M0 2C0 0.895431 0.89543 0 2 0H6.58579C7.11622 0 7.62493 0.210714 8 0.585786L11.4142 4C11.7893 4.37507 12 4.88378 12 5.41421V14C12 15.1046 11.1046 16 10 16H2C0.89543 16 0 15.1046 0 14V2Z" fill="#9CA3AF"/>
              </svg>
              <span class="text-sm leading-5 font-medium text-gray-500">Pages: 39</span>
            </div>
          </div>
          <span class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
            <svg class="h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path fill-rule="evenodd" d="M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L10 5.414 7.707 7.707a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z" clip-rule="evenodd" />
            </svg>
          </span>
        </button>
        <ul class="absolute z-50 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm" tabindex="-1" role="listbox" aria-labelledby="listbox-label" aria-activedescendant="listbox-option-3">

          <li class="text-gray-900 cursor-default select-none relative py-2 pl-3 pr-9" id="listbox-option-0" role="option">
            <div class="flex flex-col space-y-1 flex-grow">
              <span class="text-sm leading-5 font-medium text-gray-900">10Q-10K-8K Docs</span>
              <div class="flex space-x-1 items-center">
                <svg width="12" height="16" viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M0 2C0 0.895431 0.89543 0 2 0H6.58579C7.11622 0 7.62493 0.210714 8 0.585786L11.4142 4C11.7893 4.37507 12 4.88378 12 5.41421V14C12 15.1046 11.1046 16 10 16H2C0.89543 16 0 15.1046 0 14V2Z" fill="#9CA3AF"/>
                </svg>
                <span class="text-sm leading-5 font-medium text-gray-500">Pages: 39</span>
              </div>
            </div>

            <span class="text-green-600 absolute inset-y-0 right-0 flex items-center pr-4">
              <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
              </svg>
            </span>
          </li>
          <li class="text-gray-900 cursor-default select-none relative py-2 pl-3 pr-9" id="listbox-option-0" role="option">
            <div class="flex flex-col space-y-1 flex-grow">
              <span class="text-sm leading-5 font-medium text-gray-900">10Q-10K-8K Docs</span>
              <div class="flex space-x-1 items-center">
                <svg width="12" height="16" viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M0 2C0 0.895431 0.89543 0 2 0H6.58579C7.11622 0 7.62493 0.210714 8 0.585786L11.4142 4C11.7893 4.37507 12 4.88378 12 5.41421V14C12 15.1046 11.1046 16 10 16H2C0.89543 16 0 15.1046 0 14V2Z" fill="#9CA3AF"/>
                </svg>
                <span class="text-sm leading-5 font-medium text-gray-500">Pages: 39</span>
              </div>
            </div>
          </li>
          <li class="text-gray-900 cursor-default select-none relative py-2 pl-3 pr-9" id="listbox-option-0" role="option">
            <div class="flex flex-col space-y-1 flex-grow">
              <span class="text-sm leading-5 font-medium text-gray-900">10Q-10K-8K Docs</span>
              <div class="flex space-x-1 items-center">
                <svg width="12" height="16" viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M0 2C0 0.895431 0.89543 0 2 0H6.58579C7.11622 0 7.62493 0.210714 8 0.585786L11.4142 4C11.7893 4.37507 12 4.88378 12 5.41421V14C12 15.1046 11.1046 16 10 16H2C0.89543 16 0 15.1046 0 14V2Z" fill="#9CA3AF"/>
                </svg>
                <span class="text-sm leading-5 font-medium text-gray-500">Pages: 39</span>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>

    <div class="md:w-3/4 w-full relative pb-24">

      <div class="grid grid-cols-1 md:grid-cols-4 gap-4 p-4 bg-gray-100 relative w-full h-full overflow-y-scroll" style="grid-template-rows: max-content;">

        <div class="relative" style="height: max-content">
          <input id="comments" aria-describedby="comments-description" name="comments" type="checkbox" class="absolute top-1 right-1 h-8 w-8 bg-gray-200 border-0 z-10 rounded-md">
          <i class="p-2 bg-gray-100 absolute top-1 left-1 cursor-pointer">
            <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M1.5 6V1.5M1.5 1.5H6M1.5 1.5L7.125 7.125M19.5 6V1.5M19.5 1.5H15M19.5 1.5L13.875 7.125M1.5 15V19.5M1.5 19.5H6M1.5 19.5L7.125 13.875M19.5 19.5L13.875 13.875M19.5 19.5V15M19.5 19.5H15" stroke="#111827" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </i>
          <img class="shadow-md rounded-lg" src="https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fimg.yumpu.com%2F21416461%2F1%2F500x640%2Fsample-ieee-paper-for-a4-page-size.jpg&f=1&nofb=1" alt="">
        </div>
        <div class="relative" style="height: max-content">
          <input id="comments" aria-describedby="comments-description" name="comments" type="checkbox" class="absolute top-1 right-1 h-8 w-8 bg-gray-200 border-0 z-10 rounded-md">
          <i class="p-2 bg-gray-100 absolute top-1 left-1 cursor-pointer">
            <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M1.5 6V1.5M1.5 1.5H6M1.5 1.5L7.125 7.125M19.5 6V1.5M19.5 1.5H15M19.5 1.5L13.875 7.125M1.5 15V19.5M1.5 19.5H6M1.5 19.5L7.125 13.875M19.5 19.5L13.875 13.875M19.5 19.5V15M19.5 19.5H15" stroke="#111827" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </i>
          <img class="shadow-md rounded-lg" src="https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fimg.yumpu.com%2F21416461%2F1%2F500x640%2Fsample-ieee-paper-for-a4-page-size.jpg&f=1&nofb=1" alt="">
        </div>
      </div>
      <div class="fixed md:sticky bg-gradient-to-t from-gray-900 w-full bottom-0 md:bottom-0 p-8 flex justify-center items-center z-50">
        <button class="flex space-x-16 space-between bg-brand px-8 py-4 rounded-md">
          <span class="text-sm leading-5 font-medium">Confirm selected pages:</span>
          <span class="text-sm leading-5 font-medium">7 / 54</span>
        </button>
      </div>
    </div>

  </div>
</div> -->
