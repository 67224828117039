import {Injectable} from '@angular/core';
import Echo from 'laravel-echo';
import {environment} from '../../../environments/environment';
import {AuthenticationHelperService} from './authentication-helper.service';
import {Subject} from 'rxjs';
import {WebsocketEnums} from '../../enums/websocket-enums';
import {SourceSync} from '../../interfaces/source-syncs-interfaces';

const Pusher = require('pusher-js');
declare var require: any;

@Injectable({
    providedIn: 'root'
})
export class EchoHelperService {

    cloudIntegratedSubject = new Subject();
    sourceSyncedSubject = new Subject<SourceSync>();
    dataSyncedSubject = new Subject<SourceSync>();

    constructor(private authenticationHelperService: AuthenticationHelperService) {
    }

    initEcho(): void {
        // console.log('Init echo');
        (window as any).io = require('socket.io-client');
        (window as any).Echo = new Echo({
            broadcaster: 'pusher',
            key: environment.PUSHER_KEY,
            forceTLS: true,
            cluster: 'eu',
            disableStats: true,
            authEndpoint: environment.WEBSOCKET_API_ENDPOINT,
        });
        setTimeout(() => {
            console.log((window as any).Echo.connector.pusher.connection.state);
            if ((window as any).Echo.connector.pusher.connection.state === 'disconnected' || (window as any).Echo.connector.pusher.connection.state === 'connecting') {
                this.initEcho();
            }
        }, 3000);
    }

    listenToDataSyncedConnection(): void {
        (window as any).Echo.channel(WebsocketEnums.dataSyncedChannelNamePrefix + this.authenticationHelperService.getCompanyId)
        .listen(WebsocketEnums.dataSyncedEventName, (e: any) => {
            const parsedData = JSON.parse(e[0]);
            this.dataSyncedSubject.next(parsedData);
        });
    }

    disconnectFromDataSyncedChannel(): void {
        (window as any).Echo.leaveChannel(WebsocketEnums.dataSyncedChannelNamePrefix + this.authenticationHelperService.getCompanyId);
    }

    listenToCloudConnection(): void {
        (window as any).Echo.channel(WebsocketEnums.cloudConnectionChannelNamePrefix + this.authenticationHelperService.getUserData.websockets_hash)
        .listen(WebsocketEnums.cloudConnectionEventName, (e: any) => {
            const parsedData = JSON.parse(e[0]);
            this.cloudIntegratedSubject.next(parsedData);
        });
    }

    disconnectFromCloudChannel(): void {
        (window as any).Echo.leaveChannel(WebsocketEnums.cloudConnectionChannelNamePrefix + this.authenticationHelperService.getUserData.websockets_hash);
    }

    listenToSourceSynced(): void {
        (window as any).Echo.channel(WebsocketEnums.sourceSyncingChannelNamePrefix + this.authenticationHelperService.getUserData.websockets_hash)
        .listen(WebsocketEnums.sourceSyncingEventName, (e: any) => {
            const parsedData = JSON.parse(e[0]);
            this.sourceSyncedSubject.next(parsedData);
        });
    }

    disconnectFromSourceSyncingChannel(): void {
        (window as any).Echo.leaveChannel(WebsocketEnums.sourceSyncingChannelNamePrefix + this.authenticationHelperService.getUserData.websockets_hash);
    }
}
