import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges, OnDestroy,
    OnInit,
    Output,
    Renderer2,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import {SourcesHttpService} from '../../../../services/http-services/sources-http.service';
import {
    GetUseCaseSourcesResponse,
    GetUseCaseSourcesSyncsResponse,
    Source
} from '../../../../interfaces/sources-interfaces';
import {GeneralHelperService} from '../../../../services/helpers/general-helper.service';
import {PpgMatDatepicker} from '../../../../interfaces/general-interfaces';
import {SearchAndFilterUseCaseData} from '../../../../interfaces/use-case-data-interfaces';
import {SourceSync} from '../../../../interfaces/source-syncs-interfaces';
import * as moment from 'moment';
import {Subscription} from 'rxjs';
import {UseCaseHelperService} from '../../../../services/helpers/use-case-helper.service';

@Component({
    selector: 'app-use-case-data-search-with-filters',
    templateUrl: './use-case-data-search-with-filters.component.html'
})
export class UseCaseDataSearchWithFiltersComponent implements OnInit, OnChanges, OnDestroy {

    @Input() searchAndFilter: SearchAndFilterUseCaseData = {
        query: '',
        source: null,
        syncSource: null
    };
    @Input() useCaseId = 0;
    @ViewChild('toggleButtonSource') toggleButtonSource: ElementRef | undefined;
    @ViewChild('menuSource') menuSource: ElementRef | undefined;
    @ViewChild('toggleButtonSync') toggleButtonSync: ElementRef | undefined;
    @ViewChild('menuSync') menuSync: ElementRef | undefined;
    showDropdownSource = false;
    showDropdownSourceSync = false;
    sources: GetUseCaseSourcesResponse | undefined;
    sourcesSyncs: GetUseCaseSourcesSyncsResponse | undefined;
    sourceSearchDetails = {query: '', pageNum: 1};
    syncSourceSearchDetails = {query: '', pageNum: 1};
    ppgMatDatepickerStart: PpgMatDatepicker | undefined = {label: 'Start date'};
    ppgMatDatepickerEnd: PpgMatDatepicker | undefined = {label: 'End date'};
    moment = moment;
    resetUseCaseFiltersSub = new Subscription();
    @Output() searchAndFilterChange = new EventEmitter<SearchAndFilterUseCaseData>();

    constructor(private sourcesHttpService: SourcesHttpService,
                private renderer: Renderer2,
                public generalHelperService: GeneralHelperService,
                private useCaseHelper: UseCaseHelperService) {
        this.listenToClickSource();
        this.listenToClickSync();
        this.useCaseHelper.resetUseCaseFilters.subscribe((response: boolean) => {
            this.resetSearchFilters();
        });
    }

    ngOnDestroy(): void {
        this.resetUseCaseFiltersSub.unsubscribe();
    }

    ngOnChanges(changes: SimpleChanges): void {
        console.log('Changes: ', changes)
        if ((changes.useCaseId || changes.useCaseId === 0) && (changes.useCaseId.currentValue || changes.useCaseId.currentValue === 0)) {
            this.searchSources(1);
            this.searchSyncSources(1);
        }
    }

    resetSearchFilters(): void {
        this.searchAndFilter = {
            query: '',
            source: null,
            syncSource: null
        };
    }

    ngOnInit(): void {
    }

    listenToClickSource(): void {
        this.renderer.listen('window', 'click', (e: Event) => {
            if (this.generalHelperService.checkIfClickedOutsideOfTheElement(this.toggleButtonSource, this.menuSource, e)) {
                this.showDropdownSource = false;
            }
        });
    }

    listenToClickSync(): void {
        this.renderer.listen('window', 'click', (e: Event) => {
            if (this.generalHelperService.checkIfClickedOutsideOfTheElement(this.toggleButtonSync, this.menuSync, e)) {
                this.showDropdownSourceSync = false;
            }
        });
    }

    formatTimestampToMomentDateAndTime(momentDate: any): string {
        return this.generalHelperService.formatTimestampToMomentDateAndTime(Number(momentDate));
    }

    onDateChanged(event: any, dateType: string): void {
        // @ts-ignore
        // this.searchAndFilter.date[dateType] = event;
        this.searchAndFilterChange.emit(this.searchAndFilter);
    }

    paginateSourceSyncDetails(scrollEvent: any): void {
        if (this.generalHelperService.checkIfScrolledToBottom(scrollEvent)) {
            if (this.sourcesSyncs?.pagination?.nextPageUrl && this.sourcesSyncs) {
                this.syncSourceSearchDetails.pageNum++;
                this.searchSyncSources(this.syncSourceSearchDetails.pageNum);
            }
        }
    }

    searchSyncSources(pageNUmber: number): void {
        this.syncSourceSearchDetails.pageNum = pageNUmber;
        this.sourcesHttpService.searchAndFilterSyncSources(this.syncSourceSearchDetails.query, this.useCaseId, pageNUmber).then((response: GetUseCaseSourcesSyncsResponse) => {
            (this.syncSourceSearchDetails.pageNum > 1) ?
                this.sources = {
                    pagination: response.pagination,
                    data: this.sourcesSyncs?.data.concat(response.data) as Array<any>
                } :
                this.sourcesSyncs = response;
        });
    }

    searchSources(pageNUmber: number): void {
        this.sourceSearchDetails.pageNum = pageNUmber;
        this.sourcesHttpService.searchAndFilterSources({
            query: this.sourceSearchDetails.query,
            status_id: 0,
            source_type: {nameForView: '', name: '', id: 0}
        }, this.useCaseId, pageNUmber).then(response => {
            (this.sourceSearchDetails.pageNum > 1) ?
                this.sources = {
                    pagination: response.pagination,
                    data: this.sources?.data.concat(response.data) as Array<any>
                } :
                this.sources = response;

        });
    }

    paginateSourceDetails(scrollEvent: any): void {
        if (this.generalHelperService.checkIfScrolledToBottom(scrollEvent)) {
            console.log('Req');
            if (this.sources?.pagination?.nextPageUrl && this.sources) {
                this.sourceSearchDetails.pageNum++;
                this.searchSources(this.sourceSearchDetails.pageNum);
            }
        }
    }

    changeSource(source: Source | null): void {
        this.searchAndFilter.source = source;
        this.searchAndFilterChange.emit(this.searchAndFilter);
        this.showDropdownSource = false;
    }

    changeSync(sync: SourceSync | null): void {
        this.searchAndFilter.syncSource = sync;
        this.searchAndFilterChange.emit(this.searchAndFilter);
        this.showDropdownSourceSync = false;
    }

}
