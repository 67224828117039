import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AuthGuardService} from '../../guards/auth-guard.service';
import {TestPageComponent} from './test-page.component';
import {PAGES_AND_PATHS} from '../../constants/pages-and-paths';

const routes: Routes = [
    {
        path: PAGES_AND_PATHS.testPage.pageInRouting,
        data: {data: PAGES_AND_PATHS.testPage},
        component: TestPageComponent,
        canActivate: [AuthGuardService],
        loadChildren: () => import('./test-page.module').then(m => m.TestPageModule)
    },];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class TestPageRoutingModule {
}
