import {Component, ElementRef, EventEmitter, Input, OnInit, Output, Renderer2, ViewChild} from '@angular/core';
import {AddSourceManual} from './add-source-manual-modal/add-source-manual';
import {AddingSourceTypeCloudFirstStep} from './AddingSourceTypeCloudFirstStep';
import {AddingSourceTypeEmailFirstStep} from './AddingSourceTypeEmailFirstStep';
import {AddingSourceTypeManualFirstStep} from './AddingSourceTypeManualFirstStep';
import {AddingSourceType} from '../../../enums/sources-enums';
import {SourcesHttpService, SourcesHttpServiceHelper} from '../../../services/http-services/sources-http.service';
import {
    CloudFrequency,
    CloudIntegrationResponse,
    CloudRegion,
    CloudType,
    Source
} from '../../../interfaces/sources-interfaces';
import {ToasterHelperService} from '../../../services/helpers/toaster-helper.service';
import {AddSourceCloud} from './add-source-cloud-modal/add-source-cloud';
import {GeneralHelperService} from '../../../services/helpers/general-helper.service';

@Component({
    selector: 'app-add-source-modal',
    templateUrl: './add-source-modal.component.html'
})
export class AddSourceModalComponent implements OnInit {

    @Input() useCaseId = 0;
    addSourceManualClass: AddSourceManual | any = new AddSourceManual();
    addSourceCloudClass: AddSourceCloud = new AddSourceCloud();
    AddingSourceType = AddingSourceType;
    activeSelectionType = '';
    cloudRegions: Array<CloudRegion> = [];
    cloudFrequencies: Array<CloudFrequency> = [];
    opened = false;
    @ViewChild('modal') modal: ElementRef | undefined;
    @Output() modalClosed = new EventEmitter<boolean>();
    @Output() sourceAdded = new EventEmitter<Source>();

    constructor(private sourcesHttpService: SourcesHttpService,
                private sourcesHttpServiceHelper: SourcesHttpServiceHelper,
                private toasterHelper: ToasterHelperService,
                private httpSourceService: SourcesHttpService,
                private renderer: Renderer2,
                private generalHelperService: GeneralHelperService) {
    }

    ngOnInit(): void {
        this.opened = true;
        setTimeout(() => {
            this.listenToClickType();
        });
    }

    listenToClickType(): void {
        this.renderer.listen('window', 'click', (e: Event) => {
            if (this.generalHelperService.checkIfClickedOutsideOfTheElement(undefined, this.modal, e)) {
                if (this.opened) {
                    this.modalClosed.emit(true);
                    this.opened = false;
                }
            }
        });
    }

    getCloudRegion(cloudType: CloudType, type: AddingSourceTypeEmailFirstStep | AddingSourceTypeCloudFirstStep | AddingSourceTypeManualFirstStep): void {
        this.httpSourceService.getCloudRegionsAndIntegrations(cloudType.id).then((response: { cloudRegions: Array<CloudRegion>, cloudIntegrations: Array<CloudIntegrationResponse> }) => {
            this.cloudRegions = response.cloudRegions;
            this.activeSelectionType = type.sourceType;
        });
    }

    onSourceAdded(source: Source, timeout: number): void {
        this.sourceAdded.emit(source);
        setTimeout(() => {
            this.toasterHelper.showSuccess('Successfully added source and documents');
            this.sourcesHttpServiceHelper.resetFileUploadSubject();
            this.modalClosed.emit(true);
        }, timeout);
    }

    onTypeOfAddingSourceSelected(type: AddingSourceTypeEmailFirstStep | AddingSourceTypeCloudFirstStep | AddingSourceTypeManualFirstStep): void {
        if (type.sourceType === AddingSourceType.manual) {
            this.addSourceManualClass = this.sourcesHttpServiceHelper.createAndReturnSourceObject(type, this.useCaseId);
            this.activeSelectionType = type.sourceType;
        }
        if (type.sourceType === AddingSourceType.cloud) {
            const cloudType = type as AddingSourceTypeCloudFirstStep;
            this.addSourceCloudClass = this.sourcesHttpServiceHelper.createAndReturnSourceObject(cloudType, this.useCaseId) as AddSourceCloud;
            this.getCloudRegion(cloudType.cloudType, type);
        }
        // if (type.sourceType === AddingSourceType.manual) {
        //     const manualType = type as AddingSourceTypeManualFirstStep;
        //     this.addSourceManualClass.sourceName = manualType.sourceName;
        //     this.addSourceManualClass.processSelectedPagesInDocument = manualType.processOnlySelectedPages;
        //     this.addSourceManualClass.expectedFileExtension = manualType.expectedFileExtension;
        //     this.addSourceManualClass.activeStep = this.addSourceManualClass?.possibleSteps?.secondStep;
        //     this.addSourceManualClass.selectedType = manualType.sourceTypeObj;
        //     this.activeSelectionType = type.sourceType;
        // }
        // if (type.sourceType === AddingSourceType.cloud) {
        //     const cloudType = type as AddingSourceTypeCloudFirstStep;
        //     this.getCloudRegion(cloudType.cloudType, type);
        //     this.addSourceCloudClass.sourceName = cloudType.sourceName;
        //     this.addSourceCloudClass.cloudIntegration = cloudType.cloudIntegration;
        //     this.addSourceCloudClass.processSelectedPagesInDocument = cloudType.processOnlySelectedPages;
        //     this.addSourceCloudClass.expectedFileExtension = cloudType.expectedFileExtension;
        //     this.addSourceCloudClass.activeStep = this.addSourceCloudClass?.possibleSteps?.secondStep;
        //     this.addSourceCloudClass.selectedType = cloudType.sourceTypeObj;
        //     this.addSourceCloudClass.frequency = cloudType.frequency;
        //     this.addSourceCloudClass.cloudType = cloudType.cloudType;
        // }
    }

}
