import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    Renderer2,
    ViewChild
} from '@angular/core';
import {GeneralHelperService} from '../../../services/helpers/general-helper.service';
import {DestinationsHttpService} from '../../../services/http-services/destinations-http.service';
import {
    DestinationOutputTypes,
    DestinationType,
    DestinationTypeWithOutput, SearchAndFilterDestinationsReqObj
} from '../../../interfaces/destination-interfaces';
import {UseCaseHelperService} from '../../../services/helpers/use-case-helper.service';
import {Subscription} from 'rxjs';

@Component({
    selector: 'app-destination-search-with-type-and-output-filter',
    templateUrl: './destination-search-with-type-and-output-filter.component.html'
})
export class DestinationSearchWithTypeAndOutputFilterComponent implements OnInit, OnDestroy {

    @Input() searchAndFilter: SearchAndFilterDestinationsReqObj = {
        query: '',
        destination_type: {id: 0, name: 'All'},
        output_type: {id: 0, destination_type_id: 0, name: 'All'}
    };
    @ViewChild('toggleButtonType') toggleButtonType: ElementRef | undefined;
    @ViewChild('menuType') menuType: ElementRef | undefined;
    @ViewChild('toggleButtonOutput') toggleButtonStatus: ElementRef | undefined;
    @ViewChild('menuOutput') menuStatus: ElementRef | undefined;
    @Output() searchAndFilterChange = new EventEmitter<SearchAndFilterDestinationsReqObj>();
    @Output() destinationTypesFetched = new EventEmitter<Array<DestinationType>>();
    destinationTypes: Array<DestinationType> = [];
    outputTypes: Array<DestinationOutputTypes> = [];
    showDropdownType = false;
    showDropdownOutput = false;
    resetUseCaseFiltersSub = new Subscription();

    constructor(private destinationHttpService: DestinationsHttpService,
                private renderer: Renderer2,
                private generalHelperService: GeneralHelperService,
                private useCaseHelper: UseCaseHelperService) {
        this.listenToClickType();
        this.listenToClickStatus();
        this.useCaseHelper.resetUseCaseFilters.subscribe((response: boolean) => {
            this.resetSearchFilters();
        });
    }

    ngOnDestroy(): void {
        this.resetUseCaseFiltersSub.unsubscribe();
    }

    ngOnInit(): void {
        this.getDestinationTypes();
    }

    resetSearchFilters(): void {
        this.searchAndFilter = {
            query: '',
            destination_type: {id: 0, name: 'All'},
            output_type: {id: 0, destination_type_id: 0, name: 'All'}
        };
    }

    getDestinationTypes(): void {
        this.destinationHttpService.getDestinationsTypes().then((response: Array<DestinationType>) => {
            this.destinationTypes = response;
            this.destinationTypesFetched.emit(JSON.parse(JSON.stringify(this.destinationTypes)));
            this.getDestinationTypeWithOutput(this.destinationTypes[0]);
            this.destinationTypes.push({name: 'All', id: 0});
        });
    }

    getDestinationTypeWithOutput(destinationType: DestinationType): void {
        this.searchAndFilter.output_type = {id: 0, destination_type_id: 0, name: 'All'};
        if (destinationType.id !== 0) {
            this.destinationHttpService.getSingleDestinationTypeWithOutputTypes(destinationType).then((response: DestinationTypeWithOutput) => {
                this.outputTypes = response.output_types;
                this.outputTypes.push({name: 'All', id: 0, destination_type_id: 0});
            });
        }
    }

    changeDestinationType(destinationType: DestinationType): void {
        this.searchAndFilter.destination_type = destinationType;
        this.showDropdownType = false;
        this.searchAndFilter.output_type = {id: 0, destination_type_id: 0, name: ''};
        this.searchAndFilterChange.emit(this.searchAndFilter);
        this.getDestinationTypeWithOutput(this.searchAndFilter.destination_type);
    }

    changeOutputType(outputType: DestinationOutputTypes): void {
        this.searchAndFilter.output_type = outputType;
        this.searchAndFilterChange.emit(this.searchAndFilter);
        this.showDropdownOutput = false;
    }

    listenToClickType(): void {
        this.renderer.listen('window', 'click', (e: Event) => {
            if (this.generalHelperService.checkIfClickedOutsideOfTheElement(this.toggleButtonType, this.menuType, e)) {
                this.showDropdownType = false;
            }
        });
    }

    listenToClickStatus(): void {
        this.renderer.listen('window', 'click', (e: Event) => {
            if (this.generalHelperService.checkIfClickedOutsideOfTheElement(this.toggleButtonStatus, this.menuStatus, e)) {
                this.showDropdownOutput = false;
            }
        });
    }

}
