import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {UseCase} from '../../../interfaces/use-case-interfaces';
import {PAGES_AND_PATHS} from '../../../constants/pages-and-paths';

@Component({
    selector: 'app-use-case-single-grid',
    templateUrl: './use-case-single-grid.component.html'
})
export class UseCaseSingleGridComponent implements OnInit {

    @Input() useCase: UseCase | undefined;
    @Input() isPinned: boolean | undefined;
    @Output() deleteUseCase = new EventEmitter<UseCase>();
    @Output() togglePinUseCase = new EventEmitter<UseCase>();
    @Output() editUseCase = new EventEmitter<UseCase>();
    PAGES_AND_PATHS = PAGES_AND_PATHS;

    constructor() {
    }

    ngOnInit(): void {
    }

}
