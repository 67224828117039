import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AddSourceCloud} from './add-source-cloud';
import {PpgFormGroup} from '../../../../derived-classes/ppg-form-group';
import {SourcesHttpService} from '../../../../services/http-services/sources-http.service';
import {CloudFrequency, CloudRegion, Source} from '../../../../interfaces/sources-interfaces';
import {SourcesHelperService} from '../../../../services/helpers/sources-helper.service';
import {CloudIntegration} from './cloud-integration';
import {AddSourceCloudSteps} from '../../../../enums/sources-enums';

@Component({
    selector: 'app-add-source-cloud-modal',
    templateUrl: './add-source-cloud-modal.component.html'
})
export class AddSourceCloudModalComponent implements OnInit {

    @Input() addSourceCloud: AddSourceCloud | null = null;
    @Input() cloudRegions: Array<CloudRegion> = [];
    @Input() cloudFrequencies: Array<CloudFrequency> = [];
    cloudIntegrations: Array<CloudIntegration> = [];
    ppgFormGroup: PpgFormGroup | null = null;
    @Output() modalClosed = new EventEmitter<boolean>();
    @Output() sourceAdded = new EventEmitter<Source>();

    constructor(private httpSourceService: SourcesHttpService,
                private sourcesHelperService: SourcesHelperService) {
    }

    ngOnInit(): void {
        this.generatePpgFormGroup();
    }

    onCloudIntegrationEdited(cloudIntegration: CloudIntegration): void {
        const cloudIntegrationIndex = this.cloudIntegrations.findIndex(x => x.id === cloudIntegration.id);
        if (cloudIntegrationIndex !== -1) {
            this.cloudIntegrations[cloudIntegrationIndex] = cloudIntegration;
        }
    }

    onStepChanged(cloudIntegration: CloudIntegration, step: AddSourceCloudSteps): void {
        if (this.addSourceCloud) {
            this.addSourceCloud.cloudIntegration = cloudIntegration;
            this.ppgFormGroup = this.sourcesHelperService.buildPpgForm(this.addSourceCloud, this.cloudRegions);
            console.log('Form: ', this.ppgFormGroup)
            this.addSourceCloud.activeStep = step;
        }
    }

    generatePpgFormGroup(): void {
        if (this.addSourceCloud) {
            this.addSourceCloud.cloudIntegration.secretAccessKey = '';
            this.addSourceCloud.cloudIntegration.password = '';
        }
        this.ppgFormGroup = this.sourcesHelperService.buildPpgForm(this.addSourceCloud, this.cloudRegions);
    }

}
