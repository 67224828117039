import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {Subject} from 'rxjs';
import {LocalStorageProperties, Users} from '../../enums/general-enums';

@Injectable({
    providedIn: 'root'
})
export class AuthenticationHelperService {
    private isUserAuthenticated = false;
    private userData: any = null;
    private token = '';
    private companyId = 0;
    private _pagination = 10;

    showNavbar = new Subject<any>();

    constructor(private router: Router) {
        this.checkIfUserIsLogged();
        this.getPaginationFromStorage();
    }

    private getPaginationFromStorage(): void {
        const pagination = localStorage.getItem(LocalStorageProperties.ppgPagination);
        if (pagination) {
            this._pagination = JSON.parse(pagination);
        }
    }

    get pagination(): number {
        return this._pagination;
    }

    set pagination(value: number) {
        this._pagination = value;
        localStorage.setItem(LocalStorageProperties.ppgPagination, JSON.stringify(value));
    }

    showGuideToUser(): boolean {
        return (this.userData.guide === 0);
    }

    get isAuthenticated(): boolean {
        return this.isUserAuthenticated;
    }

    get getToken(): string {
        return this.token;
    }

    get getCompanyId(): number {
        return this.companyId;
    }

    get getUserData(): any {
        return this.userData;
    }

    clientLogout(redirectionUrl: string): void {
        // this.oneSignal.setSubscription(false);
        this.deleteClientInfoFromStorage();
        this.router.navigate([redirectionUrl]);
        localStorage.removeItem(LocalStorageProperties.user);
        this.showNavbar.next(null);
    }

    setUserData(newData: any): void {
        this.userData = newData;
        localStorage.setItem(LocalStorageProperties.user, JSON.stringify(this.userData));
    }

    setCompanyId(id: any): void {
        this.companyId = id;
        localStorage.setItem(LocalStorageProperties.companyId, id);
    }

    setUserRole(role: string): void {
        if (role === Users.companyMember) {
            this.showNavbar.next(role);
        }

        localStorage.setItem(LocalStorageProperties.userRole, role);
    }

    clientLogin(user: any, token: string, redirectionUrl: string): void {
        this.setUserData(user);
        this.setToken(token);
        this.isUserAuthenticated = true;
        // this.oneSignal.setSubscription(true);
        if (redirectionUrl) {
            setTimeout(() => {
                this.router.navigate([redirectionUrl]);
            });
        }
    }

    private setToken(newData: any): void {
        this.token = newData;
        localStorage.setItem(LocalStorageProperties.token, JSON.stringify(this.token));
    }

    private deleteClientInfoFromStorage(): void {
        localStorage.removeItem(LocalStorageProperties.user);
        localStorage.removeItem(LocalStorageProperties.token);
        localStorage.removeItem(LocalStorageProperties.companyId);
        localStorage.removeItem(LocalStorageProperties.userRole);
        this.isUserAuthenticated = false;
    }

    // If user token is still active, set user data
    private checkIfUserIsLogged(): void {
        let user: any;
        let token: any;
        let companyId: any;
        if (localStorage.getItem(LocalStorageProperties.user) !== 'undefined') {
            user = JSON.parse(localStorage.getItem(LocalStorageProperties.user) as any);
        }
        if (localStorage.getItem(LocalStorageProperties.token) !== 'undefined') {
            token = JSON.parse(localStorage.getItem(LocalStorageProperties.token) as any);
        }
        if (localStorage.getItem(LocalStorageProperties.companyId) !== 'undefined') {
            companyId = JSON.parse(localStorage.getItem(LocalStorageProperties.companyId) as any);
            this.companyId = companyId;
        }
        if (user && !this.isUserAuthenticated) {
            this.clientLogin(user, token, '');
        }
    }
}
