import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { UseCaseComponent } from './use-case.component';
import {PAGES_AND_PATHS} from '../../constants/pages-and-paths';
import {AuthGuardService} from '../../guards/auth-guard.service';

const routes: Routes = [{
  path: PAGES_AND_PATHS.useCaseSingle.pageInRouting,
  data: { data: PAGES_AND_PATHS.useCaseSingle },
  component: UseCaseComponent,
  canActivate: [AuthGuardService],
  loadChildren: () => import('./use-case.module').then(m => m.UseCaseModule)
}];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class UseCaseRoutingModule { }
