import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthGuardService } from './guards/auth-guard.service';
import { OuterPagesGuardService } from './guards/outer-pages-guard.service';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { TokenInterceptor } from './interceptors/token.interceptor';
import { BaseUrl } from './interceptors/base-url.interceptor';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HeaderModule } from './shared-components/header/header.module';
import { SidebarModule } from './shared-components/sidebar/sidebar.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import {NgxUiLoaderModule} from 'ngx-ui-loader';
import {NgxPaginationModule} from 'ngx-pagination';
import {NgxMaskModule} from 'ngx-mask';
import {MatNativeDateModule} from '@angular/material/core';
import {MatFormFieldControl, MatFormFieldModule} from '@angular/material/form-field';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {OverlayModule} from '@angular/cdk/overlay';
import {MatInputModule} from '@angular/material/input';
// import {MatNativeDateModule} from '@angular/material/core';
// import {OverlayModule} from '@angular/cdk/overlay';
// import {MatFormFieldModule} from '@angular/material/form-field';
// import {MatDatepickerModule} from '@angular/material/datepicker';
// import {MatInputModule} from '@angular/material/input';

export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, '../assets/translate/', '.json');
}

@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        BrowserModule,
        HeaderModule,
        SidebarModule,
        AppRoutingModule,
        HttpClientModule,
        FormsModule,
        ReactiveFormsModule,
        BrowserAnimationsModule,
        ToastrModule.forRoot(),
        MatNativeDateModule,
        MatFormFieldModule,
        MatDatepickerModule,
        OverlayModule,
        MatInputModule,
        NgxUiLoaderModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: (createTranslateLoader),
                deps: [HttpClient]
            }
        }),
        NgxMaskModule.forRoot(),
        NgxPaginationModule
    ],
    providers: [
        AuthGuardService,
        OuterPagesGuardService,
        {provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true},
        {provide: HTTP_INTERCEPTORS, useClass: BaseUrl, multi: true},
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
