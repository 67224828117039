<div class="flex flex-col md:flex-row items-center justify-between px-6 py-4">
    <div class="text-sm text-gray-500 font-semibold">
        All Personal Use Cases
    </div>
    <div class="flex mx-auto space-y-2 md:ml-auto md:mr-0 space-x-2 items-baseline">
        <button *ngFor="let pagNum of PAGINATION_POSSIBLE_NUMBERS"
                class="border border-gray-200 bg-white hover:bg-gray-50 rounded-md p-2 text-sm font-semibold text-gray-300"
                [ngClass]="{'text-gray-500': currentPaginationNum === pagNum}"
                (click)="selectedPaginationDetails.emit(pagNum);currentPaginationNum = pagNum;">
            {{pagNum}}
        </button>
    </div>
</div>
