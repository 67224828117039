<!-- 2nd Step Content -->
<div class="flex flex-col custom-height">
    <form [formGroup]="ppgFormGroup">
        <div class="">
            <div>
                <!--TODO Zoca stilizuj za sva tri slucaja (izbaci ngIf pa vrati za testiranje, prvi slucaj treba bude zeleno (uspesno),
                 drugi slucaj plavo (konektuje se), treci slucaj crveno (neuspesna konekcija)-->

                <div *ngIf="cloudIntegratedDetails.triedConnection && cloudIntegratedDetails.successful"
                     class="bg-green-50 h-14 flex items-center justify-center">
                    <img class="mr-3" src="assets/images/icons/icon-check.svg" alt="">
                    <p class="text-green-600 text-sm font-medium">Test connection passed</p>
                </div>
                <div *ngIf="cloudIntegratedDetails.triedConnection && cloudIntegratedDetails.connecting"
                     class="bg-blue-50 h-14 flex items-center justify-center">
                    <img class="mr-3" src="/assets/images/icons/Icon-testing.svg" alt="">
                    <p class="text-blue-800 text-sm font-medium">Testing connection...</p>
                </div>
                <div
                    *ngIf="cloudIntegratedDetails.triedConnection && !cloudIntegratedDetails.successful && !cloudIntegratedDetails.connecting"
                    class="bg-red-50 h-14 flex items-center justify-center">
                    <img class="mr-3" src="/assets/images/icons/icon-failed.svg" alt="">
                    <p class="text-red-600 text-sm font-medium">Test connection failed</p>
                </div>

            </div>
            <div class="p-6 space-y-6" *ngFor="let formControl of ppgFormGroup.controls | values">
                <div
                    *ngIf="formControl.controlType === ControlType.input && InputType.checkbox !== formControl.inputType">
                    <label class="block text-sm font-medium text-gray-700">{{formControl.label}}</label>
                    <div class="mt-2">
                        <!--                        [mask]="formControl.ngInputMask"-->
                        <input [type]="formControl.inputType" [formControlName]="formControl.controlName"
                               [email]="formControl.inputType === InputType.email"
                               [mask]="(formControl.controlName === 'port') ? '9999999999' : ''"
                               [specialCharacters]="[]"
                               class="shadow-sm focus:ring-gray-400 focus:border-gray-400 block w-full sm:text-sm border-gray-300 rounded-md"
                               placeholder="">
                        <small class="text-red-500"
                               *ngIf="formControl.invalid && (formControl.dirty || formControl.touched)">
                            {{formControl.errorMsg}}
                        </small>
                    </div>
                </div>
                <div
                    *ngIf="formControl.controlType === ControlType.input && InputType.checkbox === formControl.inputType">
                    <label class="block text-sm font-medium text-gray-700">{{formControl.label}}</label>
                    <div class="mt-2">
                        <input [type]="formControl.inputType" [formControlName]="formControl.controlName"
                               [checked]="formControl.value"
                               class="shadow-sm focus:ring-gray-400 focus:border-gray-400 block w-full sm:text-sm border-gray-300 rounded-md"
                               placeholder="">
                        <small class="text-red-500"
                               *ngIf="formControl.invalid && (formControl.dirty || formControl.touched)">
                            {{formControl.errorMsg}}
                        </small>
                    </div>
                </div>
                <div *ngIf="formControl.controlType === ControlType.select">
                    <label class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                        {{formControl.label}}
                    </label>
                    <div class="mt-2">
                        <select #selectRegion
                                class="w-full block focus:ring-gray-400 focus:border-gray-400 shadow-sm border-gray-300 rounded-md"
                                (ngModelChange)="onSelect($event)" [formControlName]="formControl.controlName">
                            <option *ngFor="let option of formControl.options"
                                    [ngValue]="option">{{option.name}}</option>
                        </select>
                        <small class="text-red-500"
                               *ngIf="formControl.invalid && (formControl.dirty || formControl.touched)">{{formControl.errorMsg}}
                        </small>
                    </div>
                </div>
            </div>
        </div>
        <div class="flex-shrink-0 px-4 border-t border-gray-200 py-5 sm:px-6 mt-auto">
            <div class="space-x-3 flex justify-end">
                <button type="button"
                        (click)="modalClosed.emit(true)"
                        class="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500">
                    Cancel
                </button>
                <button type="button"
                        [disabled]="ppgFormGroup.invalid || cloudIntegratedDetails.redirecting || cloudIntegratedDetails.connecting"
                        (click)="saveForm()"
                        class="bg-gray-700 hover:bg-gray-600 py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500">
                    Save
                </button>
            </div>
        </div>
    </form>
</div>
<!-- End 2nd Step Content -->
