import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FileSystemDirectoryEntry, FileSystemFileEntry, NgxFileDropEntry} from 'ngx-file-drop';
import {PpgFile} from '../../derived-classes/ppg-file';
import {FileExtension} from '../../interfaces/sources-interfaces';

@Component({
    selector: 'app-ppg-drag-and-drop-files',
    templateUrl: './ppg-drag-and-drop-files.component.html'
})
export class PpgDragAndDropFilesComponent implements OnInit {

    @Input() acceptedExtensions: Array<FileExtension> | undefined = [];
    public files: NgxFileDropEntry[] = [];
    @Output() fileDropped = new EventEmitter<PpgFile>();

    constructor() {
    }

    ngOnInit(): void {
    }

    invalidFileUploaded(err: any): void {
        console.log('Invalid: ', err);
    }

    returnStringOfAcceptedExtensions(): string {
        let stringToRet = '';
        if (this.acceptedExtensions) {
            this.acceptedExtensions.forEach(extension => {
                if (extension.isChecked) {
                    stringToRet = stringToRet + '.' + extension.name;
                }
            });
        }
        return stringToRet;
    }

    droppedFromInput(event: any): void {
        this.dropped(event.target.files);
    }

    public dropped(files: NgxFileDropEntry[]): void {
        this.files = files;
        console.log('Dropped: ', this.files);
        for (const droppedFile of files) {
            // Is it a file?
            if (droppedFile.fileEntry.isFile) {
                const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
                fileEntry.file((file: File) => {
                    const ppgFile = file as PpgFile;
                    ppgFile.isChecked = true;
                    this.fileDropped.emit(ppgFile);
                    // Here you can access the real file
                    // // You could upload it like this:
                    // const formData = new FormData()
                    // formData.append('logo', file, relativePath)
                    // // Headers
                    // const headers = new HttpHeaders({
                    //     'security-token': 'mytoken'
                    // });
                    // this.http.post('https://mybackend.com/api/upload/sanitize-and-save-logo', formData, {
                    //     headers: headers,
                    //     responseType: 'blob'
                    // })
                    //     .subscribe(data => {
                    //         // Sanitized logo returned from backend
                    //     })
                });
            } else {
                // It was a directory (empty directories are added, otherwise only files)
                const fileEntry = droppedFile.fileEntry as FileSystemDirectoryEntry;
                console.log('Failed')
            }
        }
    }

    public fileOver(event: any): void {
        console.log('Failed')
    }

    public fileLeave(event: any): void {
        console.log('Failed')
    }

}
