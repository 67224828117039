import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Labels} from '../../../../../interfaces/use-case-interfaces';
import {UseCasesHttpService} from '../../../../../services/http-services/use-cases-http.service';

@Component({
	selector: 'app-add-new-query-modal',
	templateUrl: './add-new-query-modal.component.html'
})
export class AddNewQueryModalComponent implements OnInit {
	@Input() useCaseId: number = 0;
	@Output() modalClosed = new EventEmitter<boolean>();
	queryForm = new FormGroup({
		name: new FormControl({value: '', disabled: false}, Validators.required),
		category: new FormControl({value: '', disabled: false}),
		tags: new FormControl({value: '', disabled: false}),
		label: new FormControl({value: '', disabled: false}),
	});
	dates: {start: any, end: any} = {start: '', end: ''};
	errors: any = {};
	labels: Array<Labels> = [];
	labelsInputSelection: { labelId: number, searchQuery: string, alias: string, match: string }[] = [this.returnDummyLabelInputSelection()];

	constructor(private useCaseHttp: UseCasesHttpService) {
	}

	ngOnInit(): void {
	}

	returnDummyLabelInputSelection(): { labelId: number, searchQuery: string, alias: string, match: string } {
		return {labelId: 0, searchQuery: '', alias: '', match: 'and'};
	}

	addNewLabel(): void {
		this.labelsInputSelection.push(this.returnDummyLabelInputSelection());
	}

	searchLabels(category: string): void {
		this.useCaseHttp.searchLabelsByCategory({category})
		.then(r => {
			console.log('LABELS: ', r);
			this.labels = r.entity;
		})
		.catch(e => this.errors = e.error.errors);
	}

	updateAlias(labelId: any, index: number) {
		const label = this.labels.filter(x => x.id == labelId);
		this.labelsInputSelection[index].labelId = labelId;
		if (label.length) {
			this.labelsInputSelection[index].alias = label[0].aliases.map(x => x.alias).join(', ');
		}
	}

	onDateChanged(type: string, ev: any): void {
		// @ts-ignore
		this.dates[type] = ev;
		// @ts-ignore
		console.log('DATE CHANGED: ', this.dates);
	}

	createQuery(): void {
		this.useCaseHttp.createQuery({
			name: this.queryForm.controls.name.value,
			tags: this.queryForm.controls.tags.value,
			labels: this.labelsInputSelection.map(el => {
				return {label_id: el.labelId, match: el.match};
			}),
			category: this.queryForm.controls.category.value,
			use_case_id: this.useCaseId,
			start_date: this.dates.start.value,
			end_date: this.dates.end.value,
			frequency: '',
			source_id: undefined,
			sync_source_id: undefined,
		}).then();
	}
}
