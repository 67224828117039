import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PAGES_AND_PATHS } from 'src/app/constants/pages-and-paths';
import { AuthGuardService } from 'src/app/guards/auth-guard.service';
import { SourceComponent } from './source.component';

const routes: Routes = [{
  path: PAGES_AND_PATHS.sources.pageInRouting,
  data: { data: PAGES_AND_PATHS.sources },
  component: SourceComponent,
  canActivate: [AuthGuardService],
  loadChildren: () => import('./source.module').then(m => m.SourceModule)
}];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SourceRoutingModule { }
