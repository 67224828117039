import {EventEmitter} from '@angular/core';

export class OnboardingSection {
    private _haveNextButton: boolean;
    private _icon: string;
    private _title: string;
    private _subtitle: string;

    constructor(haveNextButton: boolean, icon: string, title: string, subtitle: string) {
        this._haveNextButton = haveNextButton;
        this._icon = icon;
        this._title = title;
        this._subtitle = subtitle;
    }

    get subtitle(): string {
        return this._subtitle;
    }

    set subtitle(value: string) {
        this._subtitle = value;
    }

    get haveNextButton(): boolean {
        return this._haveNextButton;
    }

    set haveCloseButton(value: boolean) {
        this._haveNextButton = value;
    }

    get icon(): string {
        return this._icon;
    }

    set icon(value: string) {
        this._icon = value;
    }

    get title(): string {
        return this._title;
    }

    set title(value: string) {
        this._title = value;
    }
}
