import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { PAGES_AND_PATHS } from 'src/app/constants/pages-and-paths';
import { AuthenticationHelperService } from 'src/app/services/helpers/authentication-helper.service';
import { AuthHttpService } from 'src/app/services/http-services/auth-http.service';
import { LocalStorageProperties, Navigation, Users } from '../../enums/general-enums';

@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html'
})
export class SidebarComponent implements OnInit, OnDestroy {
    Navigation = Navigation;
    navSubscription: Subscription | undefined;
    isCompanyMember = localStorage.getItem(LocalStorageProperties.userRole) === Users.companyMember ? true : false;
    isOpen = false;
    PAGES_AND_PATHS = PAGES_AND_PATHS;
    userInfo = {
        userName: '',
        userRole: ''
    };

    constructor(
        private router: Router,
        private authHelperService: AuthenticationHelperService,
        private authHttpService: AuthHttpService
    ) {
    }

    ngOnInit(): void {
        this.checkWhatNavListToShow();
        this.getUserData();
    }

    routeToPage(route: string): void {
        this.router.navigate([route]);
    }

    checkWhatNavListToShow(): void {
        this.navSubscription = this.authHelperService.showNavbar.subscribe(logged => {
            if (logged === Users.companyMember) {
                this.isCompanyMember = true;
            } else {
                this.isCompanyMember = false;
            }
        });
    }

    getUserData(): void {
        this.authHttpService.getUserData().subscribe((response: any) => {
            this.userInfo.userRole = response.entity.role_name.split('-')
                .map((s: string) => s.charAt(0).toUpperCase() + s.substring(1))
                .join(' ');
            this.userInfo.userName = response.entity.first_name + ' ' + response.entity.last_name;
        }, (error: any) => {
            console.log(error);
        });
    }

    getRoute(): string {
        return this.router.url;
    }

    logout(): void {
        this.authHelperService.clientLogout('/login');
    }

    ngOnDestroy(): void {
        this.navSubscription?.unsubscribe();
    }

    toggleDropdown(): void {
        this.isOpen = !this.isOpen;
    }

}
