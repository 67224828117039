import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UseCase } from '../../interfaces/use-case-interfaces';
import { UseCaseTabsClass } from '../../pages/use-case-single/use-case-tabs-class';
import { UseCaseTabs } from '../../enums/general-enums';

@Component({
    selector: 'app-header-with-tabs',
    templateUrl: './header-with-tabs.component.html'
})
export class HeaderWithTabsComponent implements OnInit {

    @Input()
    useCaseTabsClass!: UseCaseTabsClass;
    @Input() useCase: UseCase | undefined;
    useCaseTabsEnums = UseCaseTabs;
    @Output() addNewSource = new EventEmitter<boolean>();
    @Output() addNewDestination = new EventEmitter<boolean>();
    @Output() useCaseTabsClassChange = new EventEmitter<UseCaseTabsClass>();

    constructor() {
    }

    ngOnInit(): void {
    }

    changeTab(tab: string): void {
        this.useCaseTabsClass.activeTab = tab;
    }

    emitAddSource(): void {
        this.addNewSource.emit(true);
    }

}
