import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PAGES_AND_PATHS } from 'src/app/constants/pages-and-paths';
import { ManageRouteGuardService } from 'src/app/guards/manage-route-guard.service';
import { ManageComponent } from './manage.component';

const routes: Routes = [{
  path: PAGES_AND_PATHS.manage.pageInRouting,
  data: { data: PAGES_AND_PATHS.manage },
  component: ManageComponent,
  canActivate: [ManageRouteGuardService],
  loadChildren: () => import('./manage.module').then(m => m.ManageModule)
}];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ManageRoutingModule { }
