import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {UseCasesCategories} from '../../../enums/use-cases-enums';

@Component({
    selector: 'app-use-cases-header',
    templateUrl: './use-cases-header.component.html'
})
export class UseCasesHeaderComponent implements OnInit {

    @Input() activeCategory = UseCasesCategories.all;
    @Output() changedCategoryView = new EventEmitter<UseCasesCategories>();
    @Output() showModal = new EventEmitter<boolean>();
    UseCasesCategories = UseCasesCategories;

    constructor() {
    }

    ngOnInit(): void {
    }

    changeCategory(category: UseCasesCategories): void {
        this.activeCategory = category;
        this.changedCategoryView.emit(category);
    }

}
