import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ToasterHelperService } from 'src/app/services/helpers/toaster-helper.service';

@Component({
  selector: 'app-register-step-one',
  templateUrl: './register-step-one.component.html'
})
export class RegisterStepOneComponent implements OnInit {
  @Input() userEmail: any;
  @Output() step2: EventEmitter<any> = new EventEmitter<any>();
  // credentials = { first_name: '', last_name: '', email: '' };
  userCred = JSON.parse(localStorage.getItem('usersInfo') as any);
  isMailNotValid = false;
  credentials: any;

  constructor(private toasterService: ToasterHelperService) { }

  ngOnInit(): void {
    this.credentials = this.userCred !== null ? this.userCred : { first_name: '', last_name: '', email: this.userEmail };
  }

  secondStep(): void {
    if (this.credentials.last_name.length <= 2) {
      this.toasterService.showWarning('Your last name must be between 2 and 100 characters!');
    } else {
      this.step2.emit({ form: this.credentials, step: true });
      localStorage.setItem('usersInfo', JSON.stringify(this.credentials));
    }
  }

}
