<!-- Add Source Manual-->
<div class="w-screen max-w-2xl" #modal>
    <div class="h-full flex flex-col bg-white shadow-xl overflow-y-scroll" id="js-add-source-modal">
        <div class="flex-1">
            <!-- Header -->
            <div class="px-4 py-6 bg-gray-700 sm:px-6">
                <div class="flex items-start justify-between space-x-3">
                    <div class="space-y-1">
                        <h2 class="text-lg font-medium text-white" id="slide-over-title">
                            Detailed Integration
                        </h2>
                        <p class="text-sm text-gray-300">
                            Detailed information about integration
                        </p>
                    </div>
                    <div class="h-7 flex items-center">
                        <button (click)="modalClosed.emit(true)" type="button"
                                class="text-gray-400 hover:text-gray-500">
                            <span class="sr-only">Close panel</span>
                            <!-- Heroicon name: outline/x -->
                            <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none"
                                 viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                      d="M6 18L18 6M6 6l12 12"/>
                            </svg>
                        </button>
                    </div>
                </div>
            </div>
            <!-- 1st Step Content -->
            <div class="p-6 space-y-6">
                <div>
                    <label class="block text-sm font-medium text-gray-700">Integration Name</label>
                    <div class="mt-2">
                        <p>{{integration?.name}}</p>
                    </div>
                </div>
                <div *ngIf="integration?.cloudType?.name === CloudTypes.aws">
                    <label class="block text-sm font-medium text-gray-700">Region</label>
                    <div class="mt-2">
                        <p>{{integration?.cloudRegion?.region}}</p>
                    </div>
                </div>
                <div>
                    <label class="block text-sm font-medium text-gray-700">Folder</label>
                    <div class="mt-2">
                        <p>{{integration?.folder}}</p>
                    </div>
                </div>
                <div *ngIf="integration?.cloudType?.name === CloudTypes.aws">
                    <label class="block text-sm font-medium text-gray-700">Bucket</label>
                    <div class="mt-2">
                        <p>{{integration?.bucket}}</p>
                    </div>
                </div>
                <div *ngIf="integration?.cloudType?.name === CloudTypes.aws">
                    <label class="block text-sm font-medium text-gray-700">Access Key</label>
                    <div class="mt-2">
                        <p>{{integration?.accessKey}}</p>
                    </div>
                </div>
                <div *ngIf="integration?.cloudType?.name === CloudTypes.ftp || integration?.cloudType?.name === CloudTypes.sftp">
                    <label class="block text-sm font-medium text-gray-700">Username</label>
                    <div class="mt-2">
                        <p>{{integration?.username}}</p>
                    </div>
                </div>
                <div *ngIf="integration?.cloudType?.name === CloudTypes.ftp || integration?.cloudType?.name === CloudTypes.sftp">
                    <label class="block text-sm font-medium text-gray-700">Port</label>
                    <div class="mt-2">
                        <p>{{integration?.port}}</p>
                    </div>
                </div>
                <div *ngIf="integration?.cloudType?.name === CloudTypes.ftp || integration?.cloudType?.name === CloudTypes.sftp">
                    <label class="block text-sm font-medium text-gray-700">Ip</label>
                    <div class="mt-2">
                        <p>{{integration?.ipAddress}}</p>
                    </div>
                </div>
                <div *ngIf="integration?.cloudType?.name === CloudTypes.ftp || integration?.cloudType?.name === CloudTypes.sftp">
                    <label class="block text-sm font-medium text-gray-700">SSL</label>
                    <div class="mt-2">
                        <p>{{(integration?.ssl) ? 'Yes' : 'No'}}</p>
                    </div>
                </div>
                <!-- Action buttons -->
                <div class="flex-shrink-0 px-4 border-t border-gray-200 py-5 sm:px-6 mt-auto">
                    <div class="space-x-3 flex justify-end">
                        <button type="button"
                                (click)="modalClosed.emit(true)"
                                class="bg-gray-700 py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500">
                            Close
                        </button>
                    </div>
                </div>
            </div>
            <!-- 1st Step Content End -->
        </div>
    </div>
</div>
