import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AddSourceManual} from './add-source-manual';
import {AddSourceManualSteps} from '../../../../enums/sources-enums';
import {Source} from '../../../../interfaces/sources-interfaces';

@Component({
    selector: 'app-add-source-manual-modal',
    templateUrl: './add-source-manual-modal.component.html'
})
export class AddSourceManualModalComponent implements OnInit {

    @Input() addSourceManualClass: AddSourceManual | null = null;
    @Output() sourcesAdded = new EventEmitter<Source>();
    @Output() modalClosed = new EventEmitter<boolean>();

    constructor() {
    }

    ngOnInit(): void {
    }

    onStepChanged(stepChanged: AddSourceManualSteps): void {
        if (this.addSourceManualClass) {
            this.addSourceManualClass.activeStep = stepChanged;
        }
    }

}
