import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {PAGINATION_POSSIBLE_NUMBERS} from '../../constants/general-variables';

@Component({
    selector: 'app-pagination-details-selector',
    templateUrl: './pagination-details-selector.component.html'
})
export class PaginationDetailsSelectorComponent implements OnInit {

    @Input() currentPaginationNum = 10;
    @Output() selectedPaginationDetails = new EventEmitter<number>();
    PAGINATION_POSSIBLE_NUMBERS = PAGINATION_POSSIBLE_NUMBERS;

    constructor() {
    }

    ngOnInit(): void {
    }

}
