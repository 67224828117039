<div class="space-y-6">
    <!-- Password input -->
    <div>
        <label for="password" class="block text-sm font-medium text-gray-700">
            Password
        </label>
        <div class="mt-1">
            <input type="password" [(ngModel)]="password.password" required autocomplete="false" spellcheck="false"
                placeholder="Password"
                class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-gray-500 focus:border-gray-500 sm:text-sm">
        </div>

    </div>
    <!-- ./Password input -->

    <!-- Confirm password input -->
    <div>
        <label for="cpass" class="block text-sm font-medium text-gray-700">
            Confirm password
        </label>
        <div class="mt-1">
            <input (focus)="isPasswordValid = true" type="password" [(ngModel)]="password.password_confirmation"
                required autocomplete="false" spellcheck="false" placeholder="Confirm password"
                class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-gray-500 focus:border-gray-500 sm:text-sm">
        </div>
    </div>
    <!-- ./Confirm password input -->
    <div *ngIf="!isPasswordValid">
        Passwords are not valid!
    </div>
    <div *ngIf="!isPasswordLenghtValid">
        Passwords length must be at least 8 characters long!
    </div>


    <!-- Next step button -->
    <div>
        <button (click)="thirdStep()"
            [disabled]="!password.password_confirmation || !password.password || !isPasswordValid"
            [ngClass]="!password.password_confirmation || !password.password || !isPasswordValid ? 'cursor-not-allowed bg-gray-500' : 'bg-gray-600 hover:bg-gray-700'"
            class="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500">
            Submit
        </button>
    </div>
    <!-- ./Next step button -->
</div>