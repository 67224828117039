import {Component, OnDestroy, OnInit} from '@angular/core';
import {UseCasesHttpService} from '../../services/http-services/use-cases-http.service';
import {ActivatedRoute} from '@angular/router';
import {Subscription} from 'rxjs';
import {SourcesHttpService} from '../../services/http-services/sources-http.service';
import {UseCase} from '../../interfaces/use-case-interfaces';
import {UseCaseTabsClass} from './use-case-tabs-class';
import {UseCaseTabs} from '../../enums/general-enums';
import {UseCaseDataViewTypes} from '../../enums/use-cases-enums';

@Component({
    selector: 'app-use-case',
    templateUrl: './use-case.component.html'
})
export class UseCaseComponent implements OnInit, OnDestroy {

    paramsSubscription = new Subscription();
    useCaseId = 0;
    showAddSourceModal = false;
    showAddDestinationModal = false;
    useCase: UseCase | undefined;
    useCaseTabsClass = new UseCaseTabsClass();
    useCaseTabsEnums = UseCaseTabs;
    UseCaseDataViewTypes = UseCaseDataViewTypes;
    activeUseCaseDataView: UseCaseDataViewTypes = UseCaseDataViewTypes.all;
    waitingForRes = true;

    constructor(private useCasesHttpService: UseCasesHttpService,
                private sourcesHttpService: SourcesHttpService,
                private route: ActivatedRoute) {
    }

    ngOnInit(): void {
        this.getUseCaseIdFromParams();
    }

    getSingleUseCase(useCaseId: number): void {
        this.waitingForRes = true;
        this.useCasesHttpService.getSingleUseCase(useCaseId).then((useCase: UseCase) => {
            this.useCase = useCase;
            this.waitingForRes = false;
        });
    }

    getUseCaseIdFromParams(): void {
        this.paramsSubscription = this.route.params.subscribe(params => {
            this.waitingForRes = false;
            this.useCaseId = params.id;
            this.getSingleUseCase(this.useCaseId);
        });
    }

    ngOnDestroy(): void {
        this.paramsSubscription.unsubscribe();
    }

}
