import {AddSourceCloudSteps} from '../../../../enums/sources-enums';
import {CloudType, FileExtension, Frequency, SourceType} from '../../../../interfaces/sources-interfaces';
import {CloudIntegration} from './cloud-integration';

export interface PossibleStepsCloud {
    firstStep: AddSourceCloudSteps.firstStep;
    secondStep: AddSourceCloudSteps.secondStep;
    thirdStep: AddSourceCloudSteps.thirdStep;
    fourthStep: AddSourceCloudSteps.fourthStep;
}

export class AddSourceCloud {

    private _possibleSteps: PossibleStepsCloud = {
        firstStep: AddSourceCloudSteps.firstStep,
        secondStep: AddSourceCloudSteps.secondStep,
        thirdStep: AddSourceCloudSteps.thirdStep,
        fourthStep: AddSourceCloudSteps.fourthStep,
    };
    private _steps: Array<any> = [];
    private _activeStep = '';
    private _sourceName = '';
    private _processSelectedPagesInDocument = false;
    private _expectedFileExtension: Array<FileExtension> = [];
    private _selectedType: SourceType | null = null;
    private _frequency: Frequency | null = null;
    private _cloudIntegration: CloudIntegration = new CloudIntegration();
    private _companyId = 0;
    private _useCaseId = 0;
    private _sync = false;
    private _cloudType: CloudType | null = null;

    get sync(): boolean {
        return this._sync;
    }

    set sync(value: boolean) {
        this._sync = value;
    }

    get cloudType(): CloudType | null {
        return this._cloudType;
    }

    set cloudType(value: CloudType | null) {
        this._cloudType = value;
    }

    get possibleSteps(): PossibleStepsCloud {
        return this._possibleSteps;
    }

    set possibleSteps(value: PossibleStepsCloud) {
        this._possibleSteps = value;
    }

    get steps(): Array<any> {
        return this._steps;
    }

    set steps(value: Array<any>) {
        this._steps = value;
    }

    get activeStep(): string {
        return this._activeStep;
    }

    set activeStep(value: string) {
        this._activeStep = value;
    }

    get sourceName(): string {
        return this._sourceName;
    }

    set sourceName(value: string) {
        this._sourceName = value;
    }

    get processSelectedPagesInDocument(): boolean {
        return this._processSelectedPagesInDocument;
    }

    set processSelectedPagesInDocument(value: boolean) {
        this._processSelectedPagesInDocument = value;
    }

    get expectedFileExtension(): Array<FileExtension> {
        return this._expectedFileExtension;
    }

    set expectedFileExtension(value: Array<FileExtension>) {
        this._expectedFileExtension = value;
    }

    get selectedType(): SourceType | null {
        return this._selectedType;
    }

    set selectedType(value: SourceType | null) {
        this._selectedType = value;
    }

    get frequency(): Frequency | null {
        return this._frequency;
    }

    set frequency(value: Frequency | null) {
        this._frequency = value;
    }

    get cloudIntegration(): CloudIntegration {
        return this._cloudIntegration;
    }

    set cloudIntegration(value: CloudIntegration) {
        this._cloudIntegration = value;
    }

    get companyId(): number {
        return this._companyId;
    }

    set companyId(value: number) {
        this._companyId = value;
    }

    get useCaseId(): number {
        return this._useCaseId;
    }

    set useCaseId(value: number) {
        this._useCaseId = value;
    }

    constructor() {
        this.fillStepsArray();
    }

    nextStep(): void {
        const indexOfActiveStep = this._steps.indexOf(this.activeStep);
        if (indexOfActiveStep < (this._steps.length - 1)) {
            this.activeStep = this._steps[indexOfActiveStep + 1];
        }
    }

    fillStepsArray(): void {
        for (const step in this._possibleSteps) {
            if (step) {
                this._steps.push(step);
            }
        }
        this._activeStep = this._steps[0];
    }
}
