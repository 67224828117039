import {Component, ElementRef, EventEmitter, Input, OnInit, Output, Renderer2, ViewChild} from '@angular/core';
import {CloudIntegration} from '../../source/add-source-modal/add-source-cloud-modal/cloud-integration';
import {SlideInOutAnimation} from '../../../animations/slide-modal-animation';
import {CloudTypes} from '../../../enums/sources-enums';
import {GeneralHelperService} from '../../../services/helpers/general-helper.service';

@Component({
    selector: 'app-view-single-integration-modal',
    templateUrl: './view-single-integration-modal.component.html',
    animations: [SlideInOutAnimation]
})
export class ViewSingleIntegrationModalComponent implements OnInit {

    @Input() integration: CloudIntegration | undefined;
    CloudTypes = CloudTypes;
    @Output() modalClosed = new EventEmitter<boolean>();
    opened = false;
    @ViewChild('modal') modal: ElementRef | undefined;

    constructor(private renderer: Renderer2,
                private generalHelperService: GeneralHelperService) {
    }

    ngOnInit(): void {
        this.opened = true;
        setTimeout(() => {
            this.listenToClickType();
        });
    }

    listenToClickType(): void {
        this.renderer.listen('window', 'click', (e: Event) => {
            if (this.generalHelperService.checkIfClickedOutsideOfTheElement(undefined, this.modal, e)) {
                if (this.opened) {
                    this.modalClosed.emit(true);
                    this.opened = false;
                }
            }
        });
    }

}
