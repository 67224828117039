import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
    selector: 'app-add-new-item',
    templateUrl: './add-item.component.html'
})
export class AddItemComponent implements OnInit {

    @Input() title = '';
    @Input() buttonTitle = '';
    @Input() announcement = '';
    @Output() addNewItem = new EventEmitter<any>();
    constructor() {
    }

    ngOnInit(): void {
    }

}
