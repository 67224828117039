import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {DataBody, QueryBody, UseCaseData, UseCaseQuery} from '../../../../../interfaces/use-case-data-interfaces';
import {UseCaseDataViewTypes} from '../../../../../enums/use-cases-enums';
import {FadeInAnimation} from '../../../../../animations/fade-in-animation';

@Component({
    selector: 'app-use-case-single-query-data',
    templateUrl: './use-case-single-query-data.component.html',
    animations: [FadeInAnimation]
})
export class UseCaseSingleQueryDataComponent implements OnInit {

    @Input() useCaseSingleData: UseCaseQuery | undefined;
    @Output() clickedDataBody = new EventEmitter<QueryBody | undefined>();
    UseCaseDataViewTypes = UseCaseDataViewTypes;

    constructor() {
    }

    ngOnInit(): void {
    }

}
