import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PAGES_AND_PATHS } from 'src/app/constants/pages-and-paths';
import { AuthGuardService } from 'src/app/guards/auth-guard.service';
import { UseCasesComponent } from './use-cases.component';

const routes: Routes = [{
  path: PAGES_AND_PATHS.useCases.pageInRouting,
  data: { data: PAGES_AND_PATHS.useCases },
  component: UseCasesComponent,
  canActivate: [AuthGuardService],
  loadChildren: () => import('./use-cases.module').then(m => m.UseCasesModule)
}];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class UseCasesRoutingModule { }
