<!-- Add Source Manual-->
<div class="w-screen max-w-2xl" #modal>
    <div class="h-full flex flex-col bg-white shadow-xl overflow-y-scroll" id="js-add-source-modal">
        <div class="flex-1">
            <!-- Header -->
            <div class="px-4 py-6 bg-gray-700 sm:px-6">
                <div class="flex items-start justify-between space-x-3">
                    <div class="space-y-1">
                        <h2 class="text-lg font-medium text-white" id="slide-over-title">
                            Export data
                        </h2>
                        <p class="text-sm text-gray-300">
                            Create new or select from your integrations.
                        </p>
                    </div>
                    <div class="h-7 flex items-center">
                        <button (click)="modalClosed.emit(true)" type="button"
                                class="text-gray-400 hover:text-gray-500">
                            <span class="sr-only">Close panel</span>
                            <!-- Heroicon name: outline/x -->
                            <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none"
                                 viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                      d="M6 18L18 6M6 6l12 12"/>
                            </svg>
                        </button>
                    </div>
                </div>
            </div>
            <div class="p-6 space-y-6">
                <div class="flex flex-col custom-height">
                    <!-- 3rd Step Content -->
                    <div class="p-6 space-y-6 flex-grow">
                        <div>
                            <label class="block text-sm font-medium text-gray-700">Destination
                                Name</label>
                            <div class="mt-2">
                                <input type="text" [(ngModel)]="addDestinationClass.name"
                                       class="shadow-sm focus:ring-gray-400 focus:border-gray-400 block w-full sm:text-sm border-gray-300 rounded-md"
                                       placeholder="">
                                <small *ngIf="checkIfNeedToShowErrMessage()" class="text-red-500">Destination
                                    name need to have at least 2
                                    characters</small>
                            </div>
                        </div>
                        <div>
                            <label class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                Destination Type
                            </label>
                            <div class="mt-2">
                                <select id="cloudFrequency" name="cloudFrequency"
                                        class="w-full block focus:ring-gray-400 focus:border-gray-400 shadow-sm border-gray-300 rounded-md capitalize"
                                        (ngModelChange)="selectIntegrationType($event)"
                                        [(ngModel)]="addDestinationClass!.destinationType">
                                    <option *ngFor="let type of destinationTypes" [ngValue]="type"
                                            class="capitalize">{{type.name}}</option>
                                </select>
                            </div>
                        </div>
                        <div
                            class="w-full rounded-tl-md rounded-tr-md relative border flex flex-col content mt-2">
                            <div *ngFor="let outputType of outputTypes">
                                <div class="flex content-between items-center p-4">
                                    <img src="../../../assets/images/icons/PG_Icon.svg" alt="icon">
                                    <div class="flex flex-col flex-grow space-y-1">
                                        <h3 class="text-sm leading-5 font-semibold">{{outputType.name}}</h3>
                                    </div>
                                    <input name="comments" type="checkbox"
                                           (click)="onCloudIntegrationChanged(outputType, $event)"
                                           [checked]="addDestinationClass.outputType?.id === outputType.id"
                                           class="focus:ring-gray-500 h-6 w-6 text-gray-600 border-gray-300 rounded cursor-pointer">
                                </div>
                                <div class="w-full border-t border-gray-200"></div>
                            </div>

                        </div>
                    </div>
                    <!-- Action buttons -->
                    <div class="flex-shrink-0 px-4 border-t border-gray-200 py-5 sm:px-6 mt-auto">
                        <div class="space-x-3 flex justify-end">
                            <button type="button"
                                    (click)="modalClosed.emit(true)"
                                    class="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500">
                                Cancel
                            </button>
                            <button (click)="saveDestination()" type="button"
                                    class="bg-gray-700 py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500">
                                Save
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-modal [classess]="'max-w-2xl'" *ngIf="showDestinationDetailsModal?.show"
           (modalClosed)="resetDestinationDetailsModal()">
    <div modal-header>
        <h3 class="mt-2 font-medium text-lg leading-6 text-gray-900">Destination Data</h3>
    </div>
    <div modal-body>
        <div class="mt-6">
            <app-destination-details-modal *ngIf="showDestinationDetailsModal?.show"
                                           [destinationId]="showDestinationDetailsModal?.destinationWithAPIKey?.destination?.id"
                                           [destinationWithAPIKeyINPUT]="showDestinationDetailsModal?.destinationWithAPIKey">
            </app-destination-details-modal>
        </div>
    </div>
</app-modal>
<!--End Add Destination-->
