export enum DestinationTypesEnums {
    fileStorage = 'File storage',
    businessIntelligence = 'Business intelligence'
}

export enum DestinationOutputTypesEnums {
    json = 'json',
    csv = 'csv',
    api = 'api'
}
