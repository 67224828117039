import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PAGES_AND_PATHS } from 'src/app/constants/pages-and-paths';
import { AuthGuardService } from 'src/app/guards/auth-guard.service';
import { LabelsComponent } from './labels.component';

const routes: Routes = [{
  path: PAGES_AND_PATHS.labels.pageInRouting,
  data: { data: PAGES_AND_PATHS.labels },
  component: LabelsComponent,
  canActivate: [AuthGuardService],
  loadChildren: () => import('./labels.module').then(m => m.LabelsModule)
}];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class LabelsRoutingModule { }
