import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AddSourceManualSteps} from '../../../../../enums/sources-enums';
import {AddSourceManual} from '../add-source-manual';
import {Source} from '../../../../../interfaces/sources-interfaces';
import {SourcesHttpService} from '../../../../../services/http-services/sources-http.service';

@Component({
	selector: 'app-add-source-manual-modal-third-step',
	templateUrl: './add-source-manual-modal-third-step.component.html'
})
export class AddSourceManualModalThirdStepComponent implements OnInit {

	@Input() addSourceClass: AddSourceManual | null = null;
	submitted = false;
	AddSourceManualSteps = AddSourceManualSteps;
	fileStartedUploading = false;
	@Output() stepChanged = new EventEmitter<AddSourceManualSteps>();
	@Output() sourceAdded = new EventEmitter<Source>();
	@Output() modalClosed = new EventEmitter<boolean>();

	constructor(private sourcesHttpService: SourcesHttpService) {
	}

	ngOnInit(): void {
	}

	changeStep(): void {
		setTimeout(() => {
			this.stepChanged.emit(AddSourceManualSteps.secondStep);
		});
	}

	checkIfNeedToShowErrMessage(): boolean | null {
		return this.addSourceClass && this.addSourceClass.sourceName.trim().length < 2 && this.submitted;
	}

	saveNewSource(): void {
		this.submitted = true;
		this.fileStartedUploading = true;
		if (this.addSourceClass && this.addSourceClass.sourceName.trim().length > 2) {
			this.submitted = false;
			if (this.addSourceClass) {
				this.sourcesHttpService.addSource(this.addSourceClass).then((source: Source) => {
					this.uploadFiles(source);
					if (this.addSourceClass?.files.length === 0) {
						this.sourceAdded.emit(source);
					}
				});
			}
		}
	}

	uploadFiles(source: Source): void {
		if (this.addSourceClass) {
			this.sourcesHttpService.uploadFiles(source, this.addSourceClass.files).then((response: Source) => {
				this.sourceAdded.emit(source);
				setTimeout(() => {
					this.fileStartedUploading = false;
				}, 1000);
			});
		}
	}

}
