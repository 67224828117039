import {Injectable} from '@angular/core';
import {AddSourceCloud} from '../../pages/source/add-source-modal/add-source-cloud-modal/add-source-cloud';
import {PpgFormGroup} from '../../derived-classes/ppg-form-group';
import {CloudTypes, EditType, InputErrorMessages} from '../../enums/sources-enums';
import {CloudIntegration} from '../../pages/source/add-source-modal/add-source-cloud-modal/cloud-integration';
import {Validators} from '@angular/forms';
import {ControlType, InputType, PpgFormControl} from '../../derived-classes/ppg-form-control';
import {CloudRegion} from '../../interfaces/sources-interfaces';
import {NgMasks} from '../../enums/general-enums';
import {SourcesHttpServiceHelper} from '../http-services/sources-http.service';

@Injectable({
    providedIn: 'root'
})
export class SourcesHelperService {

    constructor(private sourcesHttpHelperService: SourcesHttpServiceHelper) {
    }

    buildPpgForm(cloudSource: AddSourceCloud | null, regionOptions: Array<CloudRegion>): PpgFormGroup {
        let formToRet: PpgFormGroup = new PpgFormGroup({});
        if (cloudSource) {
            switch (cloudSource.cloudType?.name) {
                case (CloudTypes.ftp):
                    formToRet = (cloudSource.cloudIntegration.editType === EditType.password) ?
                        this.buildPpgFTPChangePasswordForm(cloudSource.cloudIntegration) :
                        this.buildPpgFTPForm(cloudSource.cloudIntegration, regionOptions, false);
                    break;
                case (CloudTypes.sftp):
                    formToRet = (cloudSource.cloudIntegration.editType === EditType.password) ?
                        this.buildPpgFTPChangePasswordForm(cloudSource.cloudIntegration) :
                        this.buildPpgFTPForm(cloudSource.cloudIntegration, regionOptions, true);
                    break;
                case (CloudTypes.aws):
                    formToRet = (cloudSource.cloudIntegration.editType === EditType.accessKey) ?
                        this.buildPpgAWSChangeAccessKeyForm(cloudSource.cloudIntegration) :
                        this.buildPpgAWSForm(cloudSource.cloudIntegration, regionOptions);
                    break;
            }
        }
        return formToRet;
    }

    private buildPpgAWSChangeAccessKeyForm(cloudIntegration: CloudIntegration): PpgFormGroup {
        // tslint:disable-next-line:max-line-length
        const secretAccessKeyFormControl: PpgFormControl = this.buildPpgControl('secretAccessKey', cloudIntegration.secretAccessKey, false, true, [], ControlType.input, 'Secret Access Key', InputErrorMessages.default, InputType.text, InputErrorMessages.badFormatErrMsg);
        // tslint:disable-next-line:max-line-length
        const accessKeyFormControl: PpgFormControl = this.buildPpgControl('accessKey', cloudIntegration.accessKey, false, true, [], ControlType.input, 'Access Key', InputErrorMessages.default, InputType.text, InputErrorMessages.badFormatErrMsg);
        const syncControl = this.buildPpgControl('sync', false, false, true, [], ControlType.input, 'Immediately pull all the files', InputErrorMessages.default, InputType.checkbox, InputErrorMessages.badFormatErrMsg);
        return new PpgFormGroup({
            secretAccessKey: secretAccessKeyFormControl,
            accessKey: accessKeyFormControl,
            sync: syncControl
        });
    }

    private buildPpgFTPChangePasswordForm(cloudIntegration: CloudIntegration): PpgFormGroup {
        // tslint:disable-next-line:max-line-length
        const passwordControl: PpgFormControl = this.buildPpgControl('password', cloudIntegration.password, false, true, [], ControlType.input, 'Password', InputErrorMessages.default, InputType.password, InputErrorMessages.badFormatErrMsg);
        const syncControl = this.buildPpgControl('sync', false, false, true, [], ControlType.input, 'Immediately pull all the files', InputErrorMessages.default, InputType.checkbox, InputErrorMessages.badFormatErrMsg);
        return new PpgFormGroup({
            password: passwordControl,
            sync: syncControl
        });
    }

    private buildPpgAWSForm(cloudIntegration: CloudIntegration, regionOptions: Array<CloudRegion>): PpgFormGroup {
        // tslint:disable-next-line:max-line-length
        const integrationNameFormControl: PpgFormControl = this.buildPpgControl('integrationName', cloudIntegration.name, false, true, [], ControlType.input, 'Name', InputErrorMessages.default, InputType.text, InputErrorMessages.badFormatErrMsg);
        // const emailFormControl: PpgFormControl = this.buildPpgControl('email', cloudIntegration.email, false, true, [], ControlType.input, 'Email', InputErrorMessages.default, InputType.email, InputErrorMessages.badFormatErrMsg);
        // tslint:disable-next-line:max-line-length
        let regionFormControl: PpgFormControl | undefined;
        if (cloudIntegration?.cloudRegion) {
            // tslint:disable-next-line:max-line-length
            regionFormControl = this.buildPpgControl('region', cloudIntegration?.cloudRegion.region, false, true, regionOptions, ControlType.select, 'Region', InputErrorMessages.default, null, InputErrorMessages.badFormatErrMsg);
        } else {
            const region = this.sourcesHttpHelperService.returnRegionById(cloudIntegration.cloudRegionId, regionOptions);
            // tslint:disable-next-line:max-line-length
            regionFormControl = this.buildPpgControl('region', region ? region.region : undefined, false, true, regionOptions, ControlType.select, 'Region', InputErrorMessages.default, null, InputErrorMessages.badFormatErrMsg);
        }
        const bucketFormControl: PpgFormControl = this.buildPpgControl('bucket', cloudIntegration.bucket, false, true, [], ControlType.input, 'Bucket', InputErrorMessages.default, InputType.text, InputErrorMessages.badFormatErrMsg);
        const folderFormControl: PpgFormControl = this.buildPpgControl('folder', cloudIntegration.folder, false, false, [], ControlType.input, 'Folder', InputErrorMessages.default, InputType.text, InputErrorMessages.badFormatErrMsg);
        // tslint:disable-next-line:max-line-length
        const secretAccessKeyFormControl: PpgFormControl = this.buildPpgControl('secretAccessKey', cloudIntegration.secretAccessKey, false, true, [], ControlType.input, 'Secret Access Key', InputErrorMessages.default, InputType.text, InputErrorMessages.badFormatErrMsg);
        // tslint:disable-next-line:max-line-length
        const accessKeyFormControl: PpgFormControl = this.buildPpgControl('accessKey', cloudIntegration.accessKey, false, true, [], ControlType.input, 'Access Key', InputErrorMessages.default, InputType.text, InputErrorMessages.badFormatErrMsg);
        const ppgForm: PpgFormGroup = new PpgFormGroup({
            integrationName: integrationNameFormControl,
            // email: emailFormControl,
            region: regionFormControl,
            bucket: bucketFormControl,
            folder: folderFormControl
        });
        if (cloudIntegration.editType) {
            const syncControl = this.buildPpgControl('sync', false, false, true, [], ControlType.input, 'Immediately pull all the files', InputErrorMessages.default, InputType.checkbox, InputErrorMessages.badFormatErrMsg);
            ppgForm.addControl('sync', syncControl);
        }
        if (!cloudIntegration.name) {
            ppgForm.addControl('secretAccessKey', secretAccessKeyFormControl);
            ppgForm.addControl('accessKey', accessKeyFormControl);
        }
        return ppgForm;
    }

    private buildPpgFTPForm(cloudIntegration: CloudIntegration, regionOptions: Array<CloudRegion>, sftp: boolean): PpgFormGroup {
        // tslint:disable-next-line:max-line-length
        const integrationNameFormControl: PpgFormControl = this.buildPpgControl('integrationName', cloudIntegration.name, false, true, [], ControlType.input, 'Name', InputErrorMessages.default, InputType.text, InputErrorMessages.badFormatErrMsg);
        // tslint:disable-next-line:max-line-length
        // const emailFormControl: PpgFormControl = this.buildPpgControl('email', cloudIntegration.email, false, true, [], ControlType.input, 'Email', InputErrorMessages.default, InputType.email, InputErrorMessages.badFormatErrMsg);
        // tslint:disable-next-line:max-line-length
        const folderFormControl: PpgFormControl = this.buildPpgControl('folder', cloudIntegration.folder, false, false, [], ControlType.input, 'Folder', InputErrorMessages.default, InputType.text, InputErrorMessages.badFormatErrMsg);
        // tslint:disable-next-line:max-line-length
        const usernameControl: PpgFormControl = this.buildPpgControl('username', cloudIntegration.username, false, true, [], ControlType.input, 'Username', InputErrorMessages.default, InputType.text, InputErrorMessages.badFormatErrMsg);
        // tslint:disable-next-line:max-line-length
        const portControl: PpgFormControl = this.buildPpgControl('port', cloudIntegration.port, false, true, [], ControlType.input, 'Port', InputErrorMessages.default, InputType.text, InputErrorMessages.badFormatErrMsg);
        // tslint:disable-next-line:max-line-length
        const ipAddressControl: PpgFormControl = this.buildPpgControl('ipAddress', cloudIntegration.ipAddress, false, true, [], ControlType.input, 'IP Address', InputErrorMessages.default, InputType.text, InputErrorMessages.badFormatErrMsg, NgMasks.ipMask);
        let sslControl: PpgFormControl | undefined;
        (sftp) ?
            // tslint:disable-next-line:max-line-length
            sslControl = this.buildPpgControl('ssl', true, true, true, [], ControlType.input, 'SSL', InputErrorMessages.default, InputType.checkbox, InputErrorMessages.badFormatErrMsg) :
            // tslint:disable-next-line:max-line-length
            sslControl = this.buildPpgControl('ssl', cloudIntegration.ssl, false, true, [], ControlType.input, 'SSL', InputErrorMessages.default, InputType.checkbox, InputErrorMessages.badFormatErrMsg);
        // tslint:disable-next-line:max-line-length
        const passwordControl: PpgFormControl = this.buildPpgControl('password', cloudIntegration.password, false, true, [], ControlType.input, 'Password', InputErrorMessages.default, InputType.password, InputErrorMessages.badFormatErrMsg);
        const ppgForm: PpgFormGroup = new PpgFormGroup({
            integrationName: integrationNameFormControl,
            // email: emailFormControl,
            folder: folderFormControl,
            username: usernameControl,
            port: portControl,
            ipAddress: ipAddressControl,
            ssl: sslControl
        });
        if (cloudIntegration.editType) {
            const syncControl = this.buildPpgControl('sync', false, false, true, [], ControlType.input, 'Immediately pull all the files', InputErrorMessages.default, InputType.checkbox, InputErrorMessages.badFormatErrMsg);
            ppgForm.addControl('sync', syncControl);
        }
        console.log('CLOUD INTG', cloudIntegration);
        if (!cloudIntegration.name) {
            ppgForm.addControl('password', passwordControl);
        }
        return ppgForm;
    }

    // tslint:disable-next-line:max-line-length
    private buildPpgControl(controlName: string, value: string | number | boolean | undefined, isDisabled: boolean, isRequired: boolean, selectOptions: Array<any>, controlType: ControlType, label: string, errMsg: InputErrorMessages, inputType: InputType | null, badFormatErrMsg: InputErrorMessages, ngMask: string = '')
        : PpgFormControl {
        const ppgFormControl: PpgFormControl = new PpgFormControl({
            value,
            disabled: isDisabled
        }, (isRequired) ? Validators.required : Validators.nullValidator);
        ppgFormControl.controlType = controlType;
        ppgFormControl.options = selectOptions;
        ppgFormControl.label = label;
        ppgFormControl.controlName = controlName;
        ppgFormControl.badFormatErrorMsg = badFormatErrMsg;
        if (ngMask) {
            ppgFormControl.ngInputMask = ngMask;
        }
        if (ControlType.input === controlType) {
            ppgFormControl.inputType = inputType;
        }
        if (errMsg) {
            ppgFormControl.errorMsg = errMsg;
        }
        return ppgFormControl;
    }
}
