<form class="space-y-6" [formGroup]="useCaseForm">
    <div>
        <label for="useCaseName" class="block text-sm font-medium text-gray-700">Use Case name</label>
        <div class="mt-2">
            <input formControlName="useCaseName" type="text" name="useCaseName" id="useCaseName"
                   class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                   placeholder="">
        </div>
    </div>

    <div class="text-sm font-medium text-gray-700">Type of extraction</div>
    <div class="flex items-center space-x-6">
        <div class="relative flex items-start" *ngFor="let type of types" [ngStyle]="{'opacity': useCaseForm.controls[type.name].disabled ? '0.5' : '1'}">
            <div class="flex items-center h-5">
                <input [formControlName]="type.name" id="comments" name="comments" type="checkbox"
                       class="focus:ring-gray-500 h-4 w-4 text-gray-600 border-gray-300 rounded cursor-pointer">
            </div>
            <div class="ml-3 text-sm">
                <label for="comments" class="font-medium text-gray-700"
                       style="text-transform: capitalize">{{type.name}}</label>
            </div>
        </div>
    </div>

    <div class="grid grid-cols-2 gap-x-4">
        <button class="inline-flex items-center justify-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md"
                (click)="closeModal()">Cancel
        </button>
        <button [disabled]="!useCaseForm.valid" (click)="createUseCase()"
                class="inline-flex items-center justify-center px-3 py-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500">
            Create
        </button>
    </div>
</form>
