<div>
    <form [formGroup]="companyMemberForm" (ngSubmit)="onSubmit()">
        <div class="mb-4">
            <label for="email" class="block text-sm font-medium text-gray-700">Email</label>
            <div class="mt-1">
                <input formControlName="email" type="text" name="email" id="email" autocomplete="off"
                    class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                    placeholder="you@example.com">
            </div>
        </div>
        <div class="mb-4" *ngIf="loggedUser !== 'company-admin'">
            <label for="company" class="block text-sm font-medium text-gray-700">Company</label>
            <div class="mt-1">
                <input formControlName="company" type="text"
                    class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                    placeholder="company">
            </div>
        </div>
        <button type="submit"
            class="w-full justify-center inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white w-100 bg-gray-600 hover:bg-gray-700 ">
            Send invite
        </button>
    </form>
</div>
