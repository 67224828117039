export enum UseCasesCategories {
    all = 'all',
    personal = 'personal'
}

export enum UseCasesViewTypes {
    grid = 'grid',
    list = 'list'
}

export enum UseCaseDataViewTypes {
    singleQuery = 'single-query',
    single = 'single',
    all = 'all'
}

export enum UseCaseBodyDataViewTypes {
    current = 'current',
    all = 'all'
}

export enum SearchDataType {
    useCase = 'use_case_id',
    documentImage = 'image_id',
    document = 'document_id',
    query = 'use_case_query_id'
}
