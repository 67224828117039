import {
  DestinationOutputTypes,
  DestinationType,
} from '../../../interfaces/destination-interfaces';

export class AddDestination {
  private _name = '';
  private _destinationType: DestinationType = {name: '', id: 0};
  private _outputType: DestinationOutputTypes | undefined;
  private _useCaseId = 0;

  constructor() {
  }

  get useCaseId(): number {
    return this._useCaseId;
  }

  set useCaseId(value: number) {
    this._useCaseId = value;
  }

  get outputType(): DestinationOutputTypes | undefined {
    return this._outputType;
  }

  set outputType(value: DestinationOutputTypes | undefined) {
    this._outputType = value;
  }

  get destinationType(): DestinationType {
    return this._destinationType;
  }

  set destinationType(value: DestinationType) {
    this._destinationType = value;
  }

  get name(): string {
    return this._name;
  }

  set name(value: string) {
    this._name = value;
  }
}
