import {FormControl} from '@angular/forms';
import {InputErrorMessages} from '../enums/sources-enums';

export enum ControlType {
    input = 'input',
    select = 'select'
}

export enum InputType {
    text = 'text',
    email = 'email',
    number = 'number',
    password = 'password',
    checkbox = 'checkbox'
}

export class PpgFormControl extends FormControl {
    controlType: string = ControlType.input;
    inputType: InputType | null = null;
    options: Array<any> = [];
    label = '';
    controlName = '';
    ngInputMask = '';
    errorMsg = InputErrorMessages.default;
    badFormatErrorMsg = InputErrorMessages.badFormatErrMsg;
}
