<!-- 2nd Step Content -->
<div class="flex flex-col custom-height">
    <div class="p-6 space-y-6">
        <div class="relative block mb-2">
            <div class="absolute inset-0 flex items-center" aria-hidden="true">
                <div class="w-full border-t border-gray-300"></div>
            </div>
            <div class="relative flex justify-start">
            <span class="pr-2 bg-white text-lg leading-7 font-medium text-gray-500 mb-2">
              Upload Data
            </span>
            </div>
        </div>

        <app-ppg-drag-and-drop-files (fileDropped)="onFileDropped($event)"
                                     [acceptedExtensions]="addSourceClass?.expectedFileExtension">
        </app-ppg-drag-and-drop-files>
        <div class="relative">
            <div class="absolute inset-0 flex items-center" aria-hidden="true">
                <div class="w-full border-t border-gray-300"></div>
            </div>
            <div class="relative flex justify-start">
            <span class="pr-2 bg-white text-lg leading-7 font-medium text-gray-500">
              Files uploaded
            </span>
            </div>
        </div>
        <p class="text-sm leading-5 font-normal text-gray-500 pb-2" *ngIf="addSourceClass?.files?.length === 0">
            No files to display. Please add new file.
        </p>
        <app-uploaded-files [uploadedFiles]="addSourceClass?.files"
                            (filesChanged)="(addSourceClass) ? addSourceClass.files = $event: null">
        </app-uploaded-files>
    </div>
    <div class="flex-shrink-0 px-4 border-t border-gray-200 py-5 sm:px-6 mt-auto">
        <div class="space-x-3 flex justify-end">
            <button (click)="modalClosed.emit(true)" type="button"
                    class="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500">
                Cancel
            </button>
            <button type="button"
                    (click)="goToNextStep()"
                    class="bg-gray-700 hover:bg-gray-600 py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500">
                Next
            </button>
        </div>
    </div>
</div>
<!-- End 2nd Step Content -->
