import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    Renderer2,
    ViewChild
} from '@angular/core';
import {SearchAndFilterSourcesReqObj, Source, SourceType} from '../../../interfaces/sources-interfaces';
import {SourcesHttpService} from '../../../services/http-services/sources-http.service';
import {AddingSourceType} from '../../../enums/sources-enums';
import {Router} from '@angular/router';
import {GeneralHelperService} from '../../../services/helpers/general-helper.service';
import {UseCaseHelperService} from '../../../services/helpers/use-case-helper.service';
import {Subscription} from 'rxjs';

@Component({
    selector: 'app-search-with-type-status-chrono',
    templateUrl: './search-with-type-status-chrono.component.html'
})
export class SearchWithTypeStatusChronoComponent implements OnInit, OnDestroy {

    @Input() searchAndFilter: SearchAndFilterSourcesReqObj = {
        query: '',
        source_type: {id: 0, name: '', nameForView: ''},
        status_id: 0
    };
    @ViewChild('toggleButtonType') toggleButtonType: ElementRef | undefined;
    @ViewChild('menuType') menuType: ElementRef | undefined;
    @ViewChild('toggleButtonStatus') toggleButtonStatus: ElementRef | undefined;
    @ViewChild('menuStatus') menuStatus: ElementRef | undefined;
    @Output() searchAndFilterChange = new EventEmitter<SearchAndFilterSourcesReqObj>();
    sourceTypes: Array<SourceType> = [];
    activeSourceType: SourceType = {id: 0, name: 'all', nameForView: 'All'};
    showDropdownType = false;
    showDropdownStatus = false;
    resetUseCaseFiltersSub = new Subscription();

    constructor(private sourcesHttpService: SourcesHttpService,
                private renderer: Renderer2,
                private generalHelperService: GeneralHelperService,
                private useCaseHelper: UseCaseHelperService) {
        this.listenToClickType();
        this.listenToClickStatus();
        this.useCaseHelper.resetUseCaseFilters.subscribe((response: boolean) => {
            this.resetSearchFilters();
        });
    }

    ngOnDestroy(): void {
        this.resetUseCaseFiltersSub.unsubscribe();
    }

    ngOnInit(): void {
        this.getSourceTypes();
    }

    resetSearchFilters(): void {
        this.searchAndFilter = {
            query: '',
            source_type: {id: 0, name: '', nameForView: ''},
            status_id: 0
        };
        this.activeSourceType = {id: 0, name: 'all', nameForView: 'All'};
    }

    listenToClickType(): void {
        this.renderer.listen('window', 'click', (e: Event) => {
            if (this.generalHelperService.checkIfClickedOutsideOfTheElement(this.toggleButtonType, this.menuType, e)) {
                this.showDropdownType = false;
            }
        });
    }

    listenToClickStatus(): void {
        this.renderer.listen('window', 'click', (e: Event) => {
            if (this.generalHelperService.checkIfClickedOutsideOfTheElement(this.toggleButtonStatus, this.menuStatus, e)) {
                this.showDropdownStatus = false;
            }
        });
    }

    getSourceTypes(): void {
        this.sourcesHttpService.getSourceTypes().then(response => {
            this.sourceTypes = response;
        });
    }

    changeSourceType(sourceType: SourceType): void {
        this.activeSourceType = sourceType;
        this.searchAndFilter.source_type = sourceType;
        this.searchAndFilterChange.emit(this.searchAndFilter);
        this.showDropdownType = false;
    }

}
