import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PAGES_AND_PATHS } from 'src/app/constants/pages-and-paths';
import { OuterPagesGuardService } from 'src/app/guards/outer-pages-guard.service';
import { RegisterComponent } from './register.component';

const routes: Routes = [{
  path: PAGES_AND_PATHS.register.pageInRouting,
  data: { data: PAGES_AND_PATHS.register },
  component: RegisterComponent,
  canActivate: [OuterPagesGuardService],
  loadChildren: () => import('./register.module').then(m => m.RegisterModule)
},];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class RegisterRoutingModule { }
