<div class="flex mt-6 mb-4">
    <div class="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-12 flex-1 pr-4">
        <div class="sm:col-span-3">
            <div class="relative flex items-stretch flex-grow focus-within:z-10">
                <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <!-- Heroicon name: solid/users -->
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-gray-400" viewBox="0 0 20 20"
                        fill="currentColor">
                        <path fill-rule="evenodd"
                            d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                            clip-rule="evenodd" />
                    </svg>
                </div>
                <input type="text" name="email" id="email"
                    class="focus:ring-indigo-500 focus:border-indigo-500 block w-full rounded-none rounded-md pl-10 sm:text-sm border-gray-300"
                    placeholder="John Doe">
            </div>
        </div>
        <div class="col-span-9 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-12">
            <div class="sm:col-span-3">
                <div>
                    <select id="location" name="location"
                        class="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md">
                        <option selected>Status</option>
                        <option>Pending</option>
                        <option>Accepted</option>
                        <option>Cancelled</option>
                    </select>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
    <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
        <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <table class="min-w-full divide-y divide-gray-200">
                <thead class="bg-gray-50">
                    <tr>
                        <th scope="col"
                            class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Member email
                        </th>
                        <th scope="col"
                            class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Role
                        </th>
                        <th scope="col"
                            class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Status
                        </th>
                        <th scope="col"
                            class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Invited
                        </th>
                        <th scope="col" class="relative px-6 py-3">
                            <span class="sr-only">Reinvite</span>
                        </th>
                        <th scope="col" class="relative px-6 py-3">
                            <span class="sr-only">Cancel invitation</span>
                        </th>
                    </tr>
                </thead>
                <tbody class="bg-white divide-y divide-gray-200">
                    <tr *ngFor="let user of listOfInvites">
                        <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                            {{user.email}}
                        </td>
                        <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                            <span *ngIf="loggedUser === Users.superAdmin"
                                class="inline-flex bg-green-100 text-green-800 items-center px-2.5 py-0.5 rounded-full text-xs font-medium  text-gray-800">
                                Manager
                            </span>
                            <span *ngIf="loggedUser === Users.companyAdmin"
                                class="inline-flex bg-green-100 text-green-800 items-center px-2.5 py-0.5 rounded-full text-xs font-medium  text-gray-800">
                                Member
                            </span>
                        </td>
                        <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                            {{user.status}}
                        </td>
                        <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                            {{user.created_at | date:'MM. dd. yyyy.'}}
                        </td>
                        <div>
                            <td class="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                                <a (click)="reinviteUser(user.id)"
                                    class="text-indigo-600 hover:text-indigo-900">Reinvite</a>
                            </td>
                            <td class="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                                <a (click)="cancelInvitation(user.id)"
                                    class="text-indigo-600 hover:text-indigo-900">Cancel
                                    invitation</a>
                            </td>
                        </div>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>