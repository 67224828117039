import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {SourceSyncsHttpService} from '../../../services/http-services/source-syncs-http.service';
import {SourceSyncDetailsInSidebar} from '../../../interfaces/source-syncs-interfaces';
import * as moment from 'moment';
import {SwiperComponent} from 'swiper/angular';
import {Subscription} from 'rxjs';
import {EchoHelperService} from '../../../services/helpers/echo-helper.service';

@Component({
    selector: 'app-last-synced',
    templateUrl: './last-synced.component.html'
})
export class LastSyncedComponent implements OnInit, OnDestroy {

    sourceSyncs: Array<SourceSyncDetailsInSidebar | any> = [];
    activeSyncIndex = 0;
    moment = moment;
    numberOfCharsWhenSliceIsUsed = 10;
    intervalId: any;
    sliderInterval = 3000;
    dataSyncedSubscription = new Subscription();
    @ViewChild('swiperComponent') swiperComponent: undefined | SwiperComponent | any;

    constructor(private sourceSyncsHttpService: SourceSyncsHttpService,
                private echoHelperService: EchoHelperService) {
    }

    ngOnInit(): void {
        this.getSourceSyncs();
        this.changeSlidesOnInterval();
        this.listenToDataSynced();
    }

    ngOnDestroy(): void {
        clearInterval(this.intervalId);
        this.echoHelperService.disconnectFromDataSyncedChannel();
        this.dataSyncedSubscription.unsubscribe();
    }

    listenToDataSynced(): void {
        this.echoHelperService.listenToDataSyncedConnection();
        this.echoHelperService.dataSyncedSubject.subscribe((response: any) => {
            this.sourceSyncs.splice(0, 1);
            const newSync = {
                source_name: {name: response.source_name},
                synced_at: response.synced_at,
                name: response.document_name
            };
            this.sourceSyncs.push(newSync);
        });
    }

    changeSlidesOnInterval(): void {
        this.intervalId = setInterval(() => {
            if (this.swiperComponent && this.swiperComponent?.elementRef?.nativeElement?.swiper) {
                if (this.swiperComponent?.elementRef?.nativeElement?.swiper?.activeIndex === 2) {
                    this.changeSwiperIndex(0);
                } else {
                    this.changeSwiperIndex(this.swiperComponent?.elementRef?.nativeElement?.swiper?.activeIndex + 1);
                }
            }
        }, this.sliderInterval);
    }

    checkIfSourceSyncNameIsLongerThan(numOfChars: number): boolean {
        return this.sourceSyncs[this.activeSyncIndex]?.name?.length < numOfChars;
    }

    changeSwiperIndexAndResetInterval(newIndex: number): void {
        clearInterval(this.intervalId);
        this.changeSlidesOnInterval();
        this.changeSwiperIndex(newIndex);
    }

    changeSwiperIndex(newIndex: number): void {
        if (this.swiperComponent) {
            this.swiperComponent.index = newIndex;
        }
    }

    checkSwiperIndex(indexToCheck: number): boolean {
        if (this.swiperComponent) {
            return this.swiperComponent?.elementRef?.nativeElement?.swiper?.activeIndex === indexToCheck;
        }
        return false;
    }

    getSourceSyncs(): void {
        this.sourceSyncsHttpService.getLastSynced().then((response: Array<SourceSyncDetailsInSidebar>) => {
            this.sourceSyncs = response;
        });
    }

}
