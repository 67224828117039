import {trigger, style, transition, animate} from '@angular/animations';

export const ElementAppearsAnimation = [
    trigger('elementAppearsAnimation', [
        transition(':enter', [
            style({
                backgroundColor: '#caffca'
            }),
            animate(2000,
                style({
                    backgroundColor: '#fff'
                })
            )
        ])
    ])
];

