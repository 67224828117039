import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CloudIntegration} from '../../pages/source/add-source-modal/add-source-cloud-modal/cloud-integration';
import {AddSourceCloudSteps, CloudTypes, EditType} from '../../enums/sources-enums';

@Component({
    selector: 'app-cloud-integrations-checker',
    templateUrl: './cloud-integrations-checker.component.html'
})
export class CloudIntegrationsCheckerComponent implements OnInit {

    @Input() cloudIntegrations: Array<CloudIntegration> = [];
    @Input() checkedCloudIntegration: CloudIntegration | undefined;
    @Output() stepChanged = new EventEmitter<{ cloudIntegration: CloudIntegration, step: AddSourceCloudSteps }>();
    @Output() cloudIntegrationChanged = new EventEmitter<{ event: any, cloudIntegration: CloudIntegration }>();
    CloudTypes = CloudTypes;
    EditType = EditType;

    constructor() {
    }

    ngOnInit(): void {
    }

    changeStepAndSetActiveIntegration(event: any, cloudIntegration: CloudIntegration, editType: EditType): void {
        cloudIntegration.editType = editType;
        this.cloudIntegrationChanged.emit({event, cloudIntegration});
        this.stepChanged.emit({cloudIntegration, step: AddSourceCloudSteps.secondStep});
    }

    onCloudIntegrationCheckChange(cloudIntegration: CloudIntegration, event: any): void {
        this.cloudIntegrationChanged.emit({event, cloudIntegration});
    }

}
