import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToasterHelperService } from 'src/app/services/helpers/toaster-helper.service';
import { AuthHttpService } from 'src/app/services/http-services/auth-http.service';

@Component({
    selector: 'app-forgot-password',
    templateUrl: './forgot-password.component.html'
})
export class ForgotPasswordComponent implements OnInit {

    forgotPasswordForm!: FormGroup;
    @Output() changedToLogin = new EventEmitter<boolean>();

    constructor(private fb: FormBuilder, private authHttpService: AuthHttpService, private toasterService: ToasterHelperService) {
    }

    ngOnInit(): void {
        this.buildForm();
    }

    buildForm(): any {
        this.forgotPasswordForm = this.fb.group({
            email: ['', Validators.required],
        });
    }

    onSubmit(): void {
        this.authHttpService.sendForgotPasswordLink(this.forgotPasswordForm.value).subscribe((response: any) => {
            if (response.message === 'We can\'t find a user with that email address.') {
                this.toasterService.showError('We can\'t find a user with that email address!');
            } else {
                this.toasterService.showSuccess('Email for password reset sent!');
                this.changedToLogin.emit(true);
            }
        }, (error: any) => {
            this.toasterService.showError(error);
        });
    }
}
