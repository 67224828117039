import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthenticationHelperService } from 'src/app/services/helpers/authentication-helper.service';
import { PAGES_AND_PATHS } from '../../constants/pages-and-paths';
import { TranslateService } from '@ngx-translate/core';
import { TranslateHelperService } from '../../services/helpers/translate-helper.service';
import { AuthHttpService } from 'src/app/services/http-services/auth-http.service';
import { Router } from '@angular/router';
import { ToasterHelperService } from 'src/app/services/helpers/toaster-helper.service';
import { Users } from 'src/app/enums/general-enums';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html'
})
export class LoginComponent implements OnInit {

    userCredentials = new FormGroup({
        email: new FormControl('', Validators.required),
        password: new FormControl('', Validators.required),
        // lang: new FormControl('', null)
    });
    errMessage = '';
    isLoginScreen = true;

    constructor(
        private authenticationHelperService: AuthenticationHelperService,
        public translateService: TranslateService,
        public translateHelperService: TranslateHelperService,
        private authHttpService: AuthHttpService,
        private router: Router,
        private toasterService: ToasterHelperService) {
        // this.userCredentials.controls.lang.setValue(this.translateHelperService.getFullLangName(this.translateHelperService.getLang()));
    }

    ngOnInit(): void {
    }

    changeLang(event: any): void {
        this.translateHelperService.changeLang((event.target.value));
    }

    login(): void {
        this.authHttpService.login(this.userCredentials.value).subscribe((response: any) => {
            this.toasterService.showSuccess(response.message);

            if (response.entity.user.role_name === Users.companyAdmin) {
                if (response.entity.user.company_admin_companies[0].id !== undefined) {
                    this.authenticationHelperService.setCompanyId(response.entity.user.company_admin_companies[0].id);
                }
            } else if (response.entity.user.role_name === Users.companyMember) {
                if (response.entity.user.company_member_companies[0].id !== undefined) {
                    this.authenticationHelperService.setCompanyId(response.entity.user.company_member_companies[0].id);
                }
            }

            this.authenticationHelperService.clientLogin(response.entity.user, response.entity.access_token, PAGES_AND_PATHS.useCases.pagePath);
            this.getUserData();
        }, (error: any) => {
            this.toasterService.showError('Wrong username or password');
        });
    }

    getUserData(): void {
        this.authHttpService.getUserData().subscribe((response: any) => {
            this.authenticationHelperService.setUserRole(response.entity.role_name);
        }, (error: any) => {
            this.toasterService.showError(error);
        });
    }

    forgotPassword(): void {
        this.isLoginScreen = false;
    }

}
