import {
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges
} from '@angular/core';
import {
    SourceSync,
    SourceSyncDetails,
    SourceSyncDetailsResponse
} from '../../../../interfaces/source-syncs-interfaces';
import {SourceSyncsHttpService} from '../../../../services/http-services/source-syncs-http.service';
import {GeneralHelperService} from '../../../../services/helpers/general-helper.service';
import {environment} from '../../../../../environments/environment';
import {DomSanitizer} from '@angular/platform-browser';
import {FadeInAnimation} from '../../../../animations/fade-in-animation';
import {UseCaseDataHttpService} from '../../../../services/http-services/use-case-data-http.service';

@Component({
    selector: 'app-single-sync-data-modal',
    templateUrl: './single-sync-data-modal.component.html',
    animations: [FadeInAnimation]
})
export class SingleSyncDataModalComponent implements OnInit, OnChanges {

    @Input() sourceSync: SourceSync | null = null;
    sourceSyncDetails: SourceSyncDetailsResponse | undefined;
    openedSyncDetails: SourceSyncDetails | null = null;
    environment = environment;
    @Output() syncClosed = new EventEmitter<{ sourceSync: SourceSync | null, show: boolean }>();

    constructor(private sourceSyncHttp: SourceSyncsHttpService,
                private changeDetectorRef: ChangeDetectorRef,
                private generalHelperService: GeneralHelperService,
                public domSanitizer: DomSanitizer,
                private useCaseDataHttpService: UseCaseDataHttpService) {
    }

    ngOnInit(): void {
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.sourceSync && changes.sourceSync.currentValue) {
            this.getSingleSyncSource();
        }
    }

    showSyncImage(syncDetails: SourceSyncDetails): void {
        if (this.openedSyncDetails && this.openedSyncDetails.id === syncDetails.id) {
            this.openedSyncDetails = null;
        } else if (syncDetails.image_preview) {
            this.openedSyncDetails = syncDetails;
        } else {
            this.useCaseDataHttpService.getSyncDocumentPreview(syncDetails.id).then((documentPreview: string) => {
                syncDetails.image_preview = documentPreview;
                this.openedSyncDetails = syncDetails;
                console.log('IMage cabblack: ', this.openedSyncDetails.image_preview);
                this.changeDetectorRef.detectChanges();
            }, error => {
                this.openedSyncDetails = syncDetails;
            });
        }
    }

    paginateSourceSyncDetails(scrollEvent: any): void {
        if (this.generalHelperService.checkIfScrolledToBottom(scrollEvent)) {
            if (this.sourceSyncDetails?.pagination?.nextPageUrl && this.sourceSync) {
                console.log('Req');
                this.sourceSyncHttp.getSingleSyncSource(this.sourceSync.id, this.sourceSyncDetails?.pagination?.nextPageUrl).then((sourceSyncDetails: SourceSyncDetailsResponse) => {
                    if (this.sourceSyncDetails) {
                        this.sourceSyncDetails.pagination = sourceSyncDetails.pagination;
                        this.sourceSyncDetails.data = this.sourceSyncDetails.data.concat(sourceSyncDetails.data);
                    }
                });
            }
        }
    }

    getSingleSyncSource(): void {
        if (this.sourceSync) {
            this.sourceSyncHttp.getSingleSyncSource(this.sourceSync.id, null).then((sourceSyncDetails: SourceSyncDetailsResponse) => {
                console.log('Response: ', sourceSyncDetails);
                this.sourceSyncDetails = sourceSyncDetails;
            });
        }
    }

    closeSync(): void {
        this.syncClosed.emit({sourceSync: null, show: false});
    }
}
