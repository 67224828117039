import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {PAGES_AND_PATHS} from '../../constants/pages-and-paths';
import {SourceComponent} from '../source/source.component';
import {AuthGuardService} from '../../guards/auth-guard.service';
import {IntegrationsComponent} from './integrations.component';

const routes: Routes = [{
    path: PAGES_AND_PATHS.integrations.pageInRouting,
    data: {data: PAGES_AND_PATHS.integrations},
    component: IntegrationsComponent,
    canActivate: [AuthGuardService],
    loadChildren: () => import('./integrations.module').then(m => m.IntegrationsModule)
}];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class IntegrationsRoutingModule {
}
