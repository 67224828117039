import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-register-step-two',
  templateUrl: './register-step-two.component.html'
})
export class RegisterStepTwoComponent implements OnInit {
  // password = { password: '', password_confirmation: '' };
  @Output() step3: EventEmitter<any> = new EventEmitter<any>();
  isPasswordValid = true;
  isPasswordLenghtValid = true;
  userPass = JSON.parse(localStorage.getItem('passwordData') as any);
  password = this.userPass !== null ? this.userPass : { password: '', password_confirmation: '' };

  constructor() { }

  ngOnInit(): void {
  }

  thirdStep(): void {
    if (this.password.password !== this.password.password_confirmation) {
      this.isPasswordValid = false;
    } else if (this.password.password.length < 8 && this.password.password_confirmation.length < 8) {
      this.isPasswordLenghtValid = false;
    } else {
      this.step3.emit({ form: this.password, step: true });
      localStorage.setItem('passwordData', JSON.stringify(this.password));
    }
  }
}
