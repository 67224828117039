import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SidebarComponent } from './sidebar.component';
import { RouterModule } from '@angular/router';
import { NotificationsModule } from './notifications/notifications.module';
import {LastSyncedModule} from './last-synced/last-synced.module';

@NgModule({
  declarations: [SidebarComponent],
  exports: [
    SidebarComponent
  ],
	imports: [
		CommonModule,
		RouterModule,
		NotificationsModule,
		LastSyncedModule
	]
})
export class SidebarModule { }
