import {Component, ElementRef, EventEmitter, Input, OnInit, Output, Renderer2, ViewChild} from '@angular/core';
import {
    CloudType,
    SearchAndFilterCloudsReqObj,
} from '../../../interfaces/sources-interfaces';
import {SourcesHttpService} from '../../../services/http-services/sources-http.service';
import {GeneralHelperService} from '../../../services/helpers/general-helper.service';

@Component({
    selector: 'app-integration-search-and-filters',
    templateUrl: './integration-search-and-filters.component.html'
})
export class IntegrationSearchAndFiltersComponent implements OnInit {

    @Input() searchAndFilter: SearchAndFilterCloudsReqObj = {query: '', cloud_type: {id: 0, name: ''}, type_id: 0};
    @ViewChild('toggleButtonType') toggleButtonType: ElementRef | undefined;
    @ViewChild('menuType') menuType: ElementRef | undefined;
    @Output() searchAndFilterChange = new EventEmitter<SearchAndFilterCloudsReqObj>();
    cloudTypes: Array<CloudType> = [];
    activeCloudType: CloudType = {id: 0, name: 'all'};
    showDropdownType = false;

    constructor(private sourcesHttpService: SourcesHttpService,
                private renderer: Renderer2,
                private generalHelperService: GeneralHelperService) {
        this.listenToClickType();
    }

    ngOnInit(): void {
        this.getCloudTypes();
    }

    listenToClickType(): void {
        this.renderer.listen('window', 'click', (e: Event) => {
            if (this.generalHelperService.checkIfClickedOutsideOfTheElement(this.toggleButtonType, this.menuType, e)) {
                this.showDropdownType = false;
            }
        });
    }

    getCloudTypes(): void {
        this.sourcesHttpService.getCloudTypes().then(response => {
            this.cloudTypes = response;
        });
    }

    changeCloudType(cloudType: CloudType): void {
        this.activeCloudType = cloudType;
        this.searchAndFilter.cloud_type = cloudType;
        this.searchAndFilterChange.emit(this.searchAndFilter);
        this.showDropdownType = false;
    }

}
