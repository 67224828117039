<app-search-with-type-status-chrono [searchAndFilter]="searchAndFilter"
                                    (searchAndFilterChange)="searchSources($event)">
</app-search-with-type-status-chrono>
<!-- ./Header loaded -->
<div class="flex flex-col w-full px-6 py-4" *ngIf="showData(sources)">
    <div class="flex flex-col">
        <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-6">
            <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-6">
                <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                    <table class="min-w-full divide-y divide-gray-200">
                        <thead class="bg-gray-50">
                        <tr>
                            <th scope="col"
                                class="px-6 py-3 text-left text-xs font-medium text-gray-500 tracking-wider">
                                Name
                            </th>
                            <th scope="col" *ngIf="useCaseId === 0"
                                class="px-6 py-3 text-left text-xs font-medium text-gray-500 tracking-wider">
                                Use case
                            </th>
                            <th scope="col"
                                class="px-6 py-3 text-left text-xs font-medium text-gray-500 tracking-wider">
                                Source type
                            </th>
                            <th scope="col"
                                class="px-6 py-3 text-left text-xs font-medium text-gray-500 tracking-wider">
                                Frequency log
                            </th>
                            <th scope="col"
                                class="px-6 py-3 text-left text-xs font-medium text-gray-500 tracking-wider">
                                Last sync
                            </th>
                            <th scope="col"
                                class="px-6 py-3 text-left text-xs font-medium text-gray-500 tracking-wider">
                                Status
                            </th>
                            <th scope="col-span-4"
                                class="px-6 py-3 text-left text-xs font-medium text-gray-500 tracking-wider">
                                Actions
                            </th>
                        </tr>
                        </thead>
                        <tbody class="bg-white divide-y divide-gray-200">
                        <tr *ngFor="let source of sources?.data" @fadingIn>
                            <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                {{source.name}}
                            </td>
                            <td *ngIf="useCaseId === 0 && source.use_case_name"
                                class="px-6 py-4 whitespace-nowrap text-sm text-gray-500 capitalize cursor-pointer"
                                [routerLink]="[PAGES_AND_PATHS.useCaseSingle.pagePath + source.use_case_name?.id]">
                                {{source.use_case_name?.name}}
                            </td>
                            <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500"
                                style="text-transform: capitalize">
                                {{source.source_type?.name + " " + ((source.cloud) ? "- " + source.cloud.name : "")}}
                            </td>
                            <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                {{(source.frequency) ? source.frequency.frequency : '/'}}
                            </td>
                            <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                <div class="flex items-center">
                                    {{ source.last_sync }}
                                </div>
                            </td>
                            <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                <div class="flex items-center">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-green-500 mr-1"
                                         viewBox="0 0 20 20" fill="currentColor"
                                         *ngIf="source.status == 'Processed'">
                                        <path fill-rule="evenodd"
                                              d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                              clip-rule="evenodd"/>
                                    </svg>
                                    <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-red-500 mr-1"
                                         viewBox="0 0 20 20" fill="currentColor" *ngIf="source.status == 'Error'">
                                        <path fill-rule="evenodd"
                                              d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
                                              clip-rule="evenodd"/>
                                    </svg>
                                    <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-yellow-500 mr-1"
                                         viewBox="0 0 20 20" fill="currentColor" *ngIf="source.status == 'On hold'">
                                        <path fill-rule="evenodd"
                                              d="M10 18a8 8 0 100-16 8 8 0 000 16zM7 9a1 1 0 000 2h6a1 1 0 100-2H7z"
                                              clip-rule="evenodd"/>
                                    </svg>
                                    {{source.status}}
                                </div>
                            </td>

                            <td class="px-6 py-4 whitespace-nowrap text-left text-sm font-medium ">
                                <a class="text-gray-600 hover:text-gray-900 cursor-pointer"
                                   (click)="openEditModal(source.id)">Edit</a>
                            </td>
                            <td class="px-6 py-4 whitespace-nowrap text-left text-sm font-medium">
                                <a class="text-red-600 hover:text-red-900 cursor-pointer"
                                   (click)="deleteSourceDetails = {source: source, show: true}">Delete</a>
                            </td>
                            <td class="px-6 py-4 whitespace-nowrap text-left text-sm font-medium"
                                *ngIf="source.source_type.name === AddingSourceType.cloud">
                                <a (click)="syncSourceDetails = {source: source, show: true}"
                                   class="text-indigo-600 hover:text-indigo-900 cursor-pointer">Syncs</a>
                            </td>
                            <td class="px-6 py-4 whitespace-nowrap text-left text-sm font-medium"
                                *ngIf="source.source_type.name === AddingSourceType.manual">
                                <a (click)="addDocumentsSourceDetails = {source: source, show: true}"
                                   class="text-indigo-600 hover:text-indigo-900 cursor-pointer">Add Documents</a>
                            </td>
                            <td class="px-6 py-4 whitespace-nowrap text-left text-sm font-medium">
                                <a class="text-indigo-600 hover:text-indigo-900" style="opacity:0.5">{{source.processing}}</a>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
    <!-- Pagination -->
    <div class="mt-4">
        <div class="flex w-full justify-between items-center border-t border-gray-200 pt-2">
            <app-pagination-data-details *ngIf="showData(sources)" [pagination]="sources?.pagination">
            </app-pagination-data-details>
            <app-ppg-pagination *ngIf="showData(sources)" [currentPage]="sources?.pagination?.currentPage"
                                [pagId]="'js-sources-pag'"
                                [perPage]="sources?.pagination?.perPage"
                                [totalItems]="sources?.pagination?.totalItems"
                                (pageChanged)="onPageChanged($event)">
            </app-ppg-pagination>
        </div>
    </div>
    <!-- End Pagination -->
</div>
<app-ppg-slide-modal-wrapper [showModal]="addDocumentsSourceDetails.show">
    <app-add-files-modal [source]="addDocumentsSourceDetails?.source"
                         class="fixed inset-y-0 right-0 pl-10 max-w-full flex sm:pl-16" modal-body @slide
                         *ngIf="addDocumentsSourceDetails.show" (modalClosed)="resetAddDocumentsDetails()">
    </app-add-files-modal>
</app-ppg-slide-modal-wrapper>

<app-delete-confirmation-modal *ngIf="deleteSourceDetails?.show"
                               [deleteTitle]="deleteSourceDetails?.source?.name"
                               [objToEmitBack]="deleteSourceDetails?.source"
                               (modalClosed)="onSourceDeleteModalClosed()"
                               (deleted)="onSourceDelete($event)">
</app-delete-confirmation-modal>
<app-ppg-slide-modal-wrapper [showModal]="syncSourceDetails.show">
    <app-syncs-modal class="fixed inset-y-0 right-0 pl-10 max-w-full flex sm:pl-16" modal-body @slide
                     *ngIf="syncSourceDetails.show" (modalClosed)="resetSyncSourceDetails()"
                     [source]="syncSourceDetails.source">
    </app-syncs-modal>
</app-ppg-slide-modal-wrapper>
<app-ppg-slide-modal-wrapper [showModal]="editSourceDetails.show">
    <app-edit-source-modal class="fixed inset-y-0 right-0 pl-10 max-w-full flex sm:pl-16" modal-body @slide
                           *ngIf="editSourceDetails.show"
                           (modalClosed)="resetEditSourceDetailsToInitialState()"
                           (sourceEdited)="onSourceEdited($event)"
                           [sourceId]="editSourceDetails.sourceId">
    </app-edit-source-modal>
</app-ppg-slide-modal-wrapper>
<app-ppg-no-results *ngIf="!showData(sources) && showData(initialSources)"
                    [title]="'No results'"
                    [subtitle]="'Looks like there are no Sources with such name.'">
</app-ppg-no-results>
<app-add-new-item *ngIf="!showData(initialSources) && initialRequestFinished"
                  [title]="'You need to add at least one source to the usecase to start.'"
                  [announcement]="'No Sources here yet.'"
                  [buttonTitle]="'Add Source'"
                  (addNewItem)="showAddSourceModal = true">
</app-add-new-item>
<app-ppg-slide-modal-wrapper [showModal]="showAddSourceModal">
    <app-add-source-modal class="fixed inset-y-0 right-0 pl-10 max-w-full flex sm:pl-16" modal-body @slide
                          *ngIf="showAddSourceModal"
                          (modalClosed)="onClosedModalForAdding()"
                          (sourceAdded)="onSourceAdded($event)"
                          [useCaseId]="useCaseId">
    </app-add-source-modal>
</app-ppg-slide-modal-wrapper>
