import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {
    GetUseCaseSourcesResponse,
    SearchAndFilterSourcesReqObj,
    Source,
    SourceModalDetails
} from '../../../interfaces/sources-interfaces';
import {UseCase} from '../../../interfaces/use-case-interfaces';
import {UseCasesHttpService} from '../../../services/http-services/use-cases-http.service';
import {SourcesHttpService} from '../../../services/http-services/sources-http.service';
import {SlideInOutAnimation} from '../../../animations/slide-modal-animation';
import {PositionChangeAnimation} from '../../../animations/position-change-animation';
import {FadeInAnimation} from '../../../animations/fade-in-animation';
import {AddingSourceType} from '../../../enums/sources-enums';
import {UseCaseHelperService} from '../../../services/helpers/use-case-helper.service';
import {PAGES_AND_PATHS} from '../../../constants/pages-and-paths';
import {EchoHelperService} from "../../../services/helpers/echo-helper.service";

@Component({
    selector: 'app-sources-with-search-and-filters',
    templateUrl: './sources-with-search-and-filters.component.html',
    animations: [SlideInOutAnimation, PositionChangeAnimation, FadeInAnimation]
})
export class SourcesWithSearchAndFiltersComponent implements OnInit, OnChanges {

    @Input() useCaseId = 0;
    @Input() useCase: UseCase | undefined;
    @Input() showAddSourceModal = false;
    sources: GetUseCaseSourcesResponse | undefined;
    initialSources: GetUseCaseSourcesResponse | undefined;
    AddingSourceType = AddingSourceType;
    searchAndFilter: SearchAndFilterSourcesReqObj = {
        query: '',
        source_type: {id: 0, name: '', nameForView: ''},
        status_id: 0
    };
    editSourceDetails: { sourceId: number | null, show: boolean } = {
        sourceId: 0,
        show: false
    };
    syncSourceDetails: SourceModalDetails = {
        source: null,
        show: false
    };
    addDocumentsSourceDetails: SourceModalDetails = {
        source: null,
        show: false
    };
    deleteSourceDetails: SourceModalDetails = {source: null, show: false};
    initialRequestFinished = false;
    PAGES_AND_PATHS = PAGES_AND_PATHS;
    @Output() showAddSourceModalChange = new EventEmitter();

    constructor(private useCasesHttpService: UseCasesHttpService,
                private sourcesHttpService: SourcesHttpService,
                private useCaseHelper: UseCaseHelperService,
                private echoHelperService: EchoHelperService) {
    }

    ngOnChanges(changes: SimpleChanges): void {
        if ((changes.useCaseId || changes.useCaseId === 0) && (changes.useCaseId.currentValue || changes.useCaseId.currentValue === 0)) {
            this.getUseCaseSources(this.useCaseId);
        }
    }

    ngOnInit(): void {
        this.listenToDataSynced();
    }

    onSourceDeleteModalClosed(): void {
        if (this.deleteSourceDetails) {
            this.deleteSourceDetails.show = false;
            this.deleteSourceDetails.source = null;
        }
    }

    onSourceDelete(source: any): void {
        this.sourcesHttpService.deleteSource(source).then((deletedSource: Source) => {
            if (deletedSource) {
                this.deleteSourceFromView(this.deleteSourceDetails, deletedSource);
            }
        });
    }

    deleteSourceFromView(sourceModalDetails: SourceModalDetails | undefined, deletedSource: Source): void {
        const sourceIndex: number | undefined = this.sources?.data.findIndex((x: Source) => x.id === deletedSource.id);
        if (typeof sourceIndex !== 'undefined' && sourceIndex !== -1) {
            this.sources?.data.splice(sourceIndex, 1);
        }
        this.deleteSourceDetails = {show: false, source: null};
    }

    resetAddDocumentsDetails(): void {
        this.addDocumentsSourceDetails = {source: null, show: false};
    }

    resetSyncSourceDetails(): void {
        this.syncSourceDetails = {source: null, show: false};
    }

    resetEditSourceDetailsToInitialState(): void {
        this.editSourceDetails = {sourceId: null, show: false};
    }

    onSourceEdited(editedSource: Source | null): void {
        if (this.sources && this.sources.data && editedSource) {
            const sourceIndex = this.sources.data.findIndex(x => x.id === editedSource.id);
            if (sourceIndex !== -1) {
                this.sources.data[sourceIndex] = editedSource;
                this.resetEditSourceDetailsToInitialState();
            }
        }
    }

    openEditModal(sourceId: number): void {
        this.editSourceDetails.sourceId = sourceId;
        this.editSourceDetails.show = true;
    }

    searchSources(searchAndFilter: SearchAndFilterSourcesReqObj, pageNUmber: number | null = null): void {
        if (searchAndFilter.source_type.id === 0 && !searchAndFilter.query.trim()) {
            this.getUseCaseSources(this.useCaseId);
        } else {
            this.sourcesHttpService.searchAndFilterSources(this.searchAndFilter, this.useCaseId, pageNUmber).then(response => {
                this.sources = response;
            });
        }
    }

    listenToDataSynced(): void {
        this.echoHelperService.listenToDataSyncedConnection();
        this.echoHelperService.dataSyncedSubject.subscribe((response: any) => {
            this.sources?.data.filter(x => x.id === response.source_id)?.map(r => {
                r.last_sync = response.synced_at_humans;
                r.status = response.status;
                r.processing = response.processing;
            });
        });
    }

    onSourceAdded(source: Source): void {
        this.getUseCaseSources(this.useCaseId);
    }

    onPageChanged(pageNumber: number): void {
        (this.searchAndFilter.query.trim() || this.searchAndFilter.source_type.id !== 0) ?
            this.searchSources(this.searchAndFilter, pageNumber) :
            this.getSourcePagination(pageNumber);
    }

    getSourcePagination(pageNumber: number | null = null): void {
        this.sourcesHttpService.getUseCaseSources(this.useCaseId, pageNumber).then((response: GetUseCaseSourcesResponse) => {
            this.sources = {data: response.data, pagination: response.pagination};
        });
    }

    getUseCaseSources(useCaseId: number): void {
        this.sourcesHttpService.getUseCaseSources(useCaseId).then((response: GetUseCaseSourcesResponse) => {
            this.sources = {data: response.data, pagination: response.pagination};
            this.initialSources = {data: response.data, pagination: response.pagination};
            this.initialRequestFinished = true;
        });
    }

    showData(sources: GetUseCaseSourcesResponse | undefined): boolean | undefined {
        return sources && sources.data && typeof sources.pagination.totalItems !== 'undefined' && sources.pagination.totalItems > 0;
    }

    onClosedModalForAdding(): void {
        this.showAddSourceModal = false;
        this.showAddSourceModalChange.emit(false);
    }

}
