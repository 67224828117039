import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {UseCasesViewTypes} from '../../../enums/use-cases-enums';

@Component({
    selector: 'app-use-cases-search-filters',
    templateUrl: './use-cases-search-filters.component.html'
})
export class UseCasesSearchFiltersComponent implements OnInit {

    @Input() searchQuery = '';
    @Output() changedDisplayType = new EventEmitter<UseCasesViewTypes>();
    @Output() searchChanged = new EventEmitter<string>();
    UseCasesViewTypes = UseCasesViewTypes;

    constructor() {
    }


    ngOnInit(): void {
    }

}
