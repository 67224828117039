<div class="flex bg-gray-50 min-w-full min-h-screen">
    <div class="w-full flex justify-center items-center  p-8">
        <div class="flex-grow lg:max-w-xl">
            <h2 class="text-center text-3xl leading-8 font-bold">Welcome, John Doe</h2>
            <h4 class="text-center text-xl leading-7 font-medium mb-8 text-gray-500">Create your first <span class="font-bold text-gray-700">Use Case</span> to start your jorney.</h4>
            <div class="bg-white overflow-hidden shadow rounded-lg">
                <div class="px-4 py-5 sm:p-6">
                    <form [formGroup]="useCaseForm" class="space-y-8">
                        <div>
                            <label class="block text-sm font-medium text-gray-700">Use Case name</label>
                            <div class="mt-2">
                                <input formControlName="useCaseName" type="text" class="shadow-sm focus:ring-gray-400 focus:border-gray-400 block w-full sm:text-sm border-gray-300 rounded-md" placeholder="">
                            </div>
                        </div>

                        <div class="">
                            <div class="text-sm font-medium text-gray-700 mb-4">Type of extraction</div>
                            <div class="flex items-center space-x-6">
                                <div class="relative flex items-start" *ngFor="let type of types">
                                    <div class="flex items-center h-5 cursor-pointer">
                                        <input [formControlName]="type.name" type="checkbox" class="focus:ring-gray-500 h-4 w-4 text-gray-600 border-gray-300 rounded">
                                    </div>
                                    <div class="ml-3 text-sm">
                                        <label class="font-medium text-gray-700" style="text-transform: capitalize">{{type.name}}</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="flex">
                            <button [disabled]="!useCaseForm.valid" (click)="createUseCase()"  class="flex w-full items-center justify-center px-3 py-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500">Create</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>



