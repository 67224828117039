import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {modalSliderTime} from '../../constants/general-variables';

@Component({
    selector: 'app-ppg-slide-modal-wrapper',
    templateUrl: './ppg-slide-modal-wrapper.component.html'
})
export class PpgSlideModalWrapperComponent implements OnInit, OnChanges {

    @Input() showModal = false;
    visibility = 'hidden';

    constructor() {
    }

    ngOnInit(): void {
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.showModal) {
            if (changes.showModal.currentValue) {
                this.visibility = 'visible';
            } else {
                setTimeout(() => {
                    this.visibility = 'hidden';
                }, modalSliderTime);
            }
        }
    }

}
