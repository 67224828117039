import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Router, RouterStateSnapshot} from '@angular/router';
import {LocalStorageProperties, Users} from '../enums/general-enums';
import {AuthenticationHelperService} from '../services/helpers/authentication-helper.service';
import {ActivePageHelperService} from '../services/helpers/active-page-helper.service';

@Injectable({
    providedIn: 'root'
})
export class ManageRouteGuardService {
    constructor(public authHelperService: AuthenticationHelperService,
                private activePageHelperService: ActivePageHelperService) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        this.activePageHelperService.activePage = route.data.data;
        const user = localStorage.getItem(LocalStorageProperties.userRole);
        if (user === Users.superAdmin || user === Users.companyAdmin) {
            return true;
        }
        return false;
    }
}
