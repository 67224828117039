import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'app-source',
    templateUrl: './source.component.html'
})
export class SourceComponent implements OnInit {

    displayEl = false;

    constructor() {
    }

    ngOnInit(): void {
    }

}
