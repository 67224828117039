import {Component, ElementRef, EventEmitter, Input, OnInit, Output, Renderer2, ViewChild} from '@angular/core';
import {GeneralHelperService} from '../../services/helpers/general-helper.service';

@Component({
    selector: 'app-delete-confirmation-modal',
    templateUrl: './delete-confirmation-modal.component.html'
})
export class DeleteConfirmationModalComponent implements OnInit {

    @Input() deleteTitle: string | undefined;
    @Input() objToEmitBack: any;
    @Output() deleted = new EventEmitter<any>();
    @Output() modalClosed = new EventEmitter<boolean>();
    opened = false;
    @ViewChild('modal') modal: ElementRef | undefined;

    constructor(private renderer: Renderer2,
                private generalHelperService: GeneralHelperService) {
    }

    ngOnInit(): void {
        this.opened = true;
        setTimeout(() => {
            this.listenToClickType();
        });
    }

    listenToClickType(): void {
        this.renderer.listen('window', 'click', (e: Event) => {
            if (this.generalHelperService.checkIfClickedOutsideOfTheElement(undefined, this.modal, e)) {
                if (this.opened) {
                    this.modalClosed.emit(true);
                    this.opened = false;
                }
            }
        });
    }

    delete(): void {
        this.deleted.emit(this.objToEmitBack);
        this.opened = false;
    }

    closeModal(): void {
        this.modalClosed.emit(true);
        this.opened = false;
    }

}
