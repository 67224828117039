<div class="w-screen max-w-2xl" modal>
    <form [formGroup]="queryForm" class="h-full flex flex-col bg-white shadow-xl overflow-y-scroll">
        <div class="flex-1">
            <!-- Header -->
            <div class="px-4 py-6 bg-gray-700 sm:px-6">
                <div class="flex items-start justify-between space-x-3">
                    <div class="space-y-1">
                        <h2 class="text-lg font-medium text-white" id="slide-over-title">
                            Create new Query
                        </h2>
                        <p class="text-sm text-gray-300">
                            Filter your raw data with query and export with new destination.
                        </p>
                    </div>
                    <div class="h-7 flex items-center">
                        <button type="button" class="text-gray-400 hover:text-gray-500"
                                (click)="modalClosed.emit(true)">
                            <span class="sr-only">Close panel</span>
                            <!-- Heroicon name: outline/x -->
                            <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                 stroke="currentColor" aria-hidden="true">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                      d="M6 18L18 6M6 6l12 12"/>
                            </svg>
                        </button>
                    </div>
                </div>
            </div>

            <div class="p-5 grid grid-cols-12 gap-4">
                <div class="col-span-12">
                    <div>
                        <label for="name" class="block text-sm font-medium text-gray-700">Query name</label>
                        <div class="mt-1">
                            <input formControlName="name" type="text" name="name" id="name"
                                   [ngClass]="{'border-red-500': errors.hasOwnProperty('name')}"
                                   class="h-11 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                                   placeholder="Finnancial Statement">
                        </div>
                    </div>
                </div>
                <!--                TODO ADD frequency if coming from source.name === 'AWS'-->
                <div class="col-span-12">
                    <div>
                        <label class="block text-xs font-medium text-gray-400 uppercase">
                            Point of interest
                        </label>
                        <div>
                            <div class="mt-1 relative">
                                <svg class="absolute mr-2 mt-2 mb-2 pl-4 border-l border-gray-200 right-0 w-10"
                                     width="24" height="24" viewBox="0 0 24 24" fill="none"
                                     xmlns="http://www.w3.org/2000/svg"
                                     (click)="searchLabels(labelCategory.value)">
                                    <path
                                        d="M11 20C15.9706 20 20 15.9706 20 11C20 6.02944 15.9706 2 11 2C6.02944 2 2 6.02944 2 11C2 15.9706 6.02944 20 11 20Z"
                                        stroke="#292D32" stroke-width="1.5" stroke-linecap="round"
                                        stroke-linejoin="round"/>
                                    <path
                                        d="M18.9304 20.6898C19.4604 22.2898 20.6704 22.4498 21.6004 21.0498C22.4504 19.7698 21.8904 18.7198 20.3504 18.7198C19.2104 18.7098 18.5704 19.5998 18.9304 20.6898Z"
                                        stroke="#292D32" stroke-width="1.5" stroke-linecap="round"
                                        stroke-linejoin="round"/>
                                </svg>
                                <input #labelCategory formControlName="category"
                                       type="text"
                                       name="category"
                                       id="category" class="h-11 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm
                                        border-gray-300 rounded-md" placeholder="Products"
                                       [ngClass]="{'border-red-500': errors.hasOwnProperty('category')}">
                                <span class="text-red-500" *ngIf="errors.hasOwnProperty('category')">
                                    The point of interest field is required.
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <ng-container *ngIf="labels.length">
                    <div *ngFor="let labelInput of labelsInputSelection; let i = index;"
                         class="col-span-12 gap-4 grid grid-cols-12 ng-star-inserted">
                        <div class="col-span-8">
                            <div>
                                <label for="label" class="block text-xs font-medium text-gray-400 uppercase">Point of
                                    interest</label>
                                <div>
                                    <div>
                                        <span>Rule: </span>
                                        <select #match formControlName="label" name="andOr"
                                                (change)="labelsInputSelection[i].match = match.value">
                                            <option value="" disabled selected>And</option>
                                            <option value="and">And</option>
                                            <option value="or">Or</option>
                                        </select>
                                    </div>
                                    <div class="mt-1 relative">
                                        <select #label formControlName="label" name="label" id="label"
                                                class="h-11 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                                                (change)="updateAlias(label.value, i)">
                                            <option value="" disabled selected>Income</option>
                                            <option *ngFor="let label of labels"
                                                    value="{{ label.id }}">{{ label.label }}</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-span-4">
                            <div>
                                <label class="block text-xs font-medium text-gray-400 uppercase">Alias</label>
                                <div class="mt-1">
                                    <input type="text"
                                           disabled
                                           class="h-11 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm
                                       border-gray-300 rounded-md"
                                           placeholder="Finnancial Statement" [ngModel]="labelInput.alias"
                                           [ngModelOptions]="{standalone: true}">
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-container>
                <div class="col-span-12">
                    <span style="color:#00a1ff;cursor:pointer;" (click)="addNewLabel()">+ FIELD NAME TO MATCH</span>
                </div>
                <div class="col-span-6">
                    <mat-form-field appearance="fill">
                        <mat-label>Choose a date</mat-label>
                        <input matInput [matDatepicker]="picker1" (dateChange)="onDateChanged('start', $event)">
                        <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                        <mat-datepicker #picker1></mat-datepicker>
                    </mat-form-field>
                </div>
                <div class="col-span-6">
                    <mat-form-field appearance="fill">
                        <mat-label>Choose a date</mat-label>
                        <input matInput [matDatepicker]="picker2" (dateChange)="onDateChanged('end', $event)">
                        <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                        <mat-datepicker #picker2></mat-datepicker>
                    </mat-form-field>
                </div>
            </div>
        </div>
        <!-- Action buttons -->
        <div class="flex-shrink-0 px-4 border-t border-gray-200 py-5 sm:px-6">
            <div class="space-x-3 flex justify-end">
                <button type="button"
                        class="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        (click)="modalClosed.emit(true)">
                    Cancel
                </button>
                <button type="submit"
                        class="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gray-700 hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
                        (click)="createQuery()">
                    Create Query
                </button>
            </div>
        </div>
    </form>
</div>
