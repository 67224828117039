<div class="flex flex-col" style="opacity:0.5" *ngIf="sourceSyncs">
    <span class="text-gray-400 uppercase font-bold text-sm mb-2">Last sync</span>
    <swiper [pagination]="{ clickable: true }" #swiperComponent [noSwiping]="true"
            class="mySwiper">
        <ng-template *ngFor="let sync of sourceSyncs" swiperSlide>
            <div
                class="relative rounded-lg bg-gray-700 px-6 py-2 shadow-sm flex flex-col focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500">
                <div class="text-gray-200 text-sm">{{sync.source_name.name}}</div>
                <div class="flex items-center justify-between">
                    <div class="flex items-center">
                        <div class="flex-shrink-0">
                            <img class="mr-1" src="../../../assets/images/icons/destinations-orange-arrow.svg"
                                 alt="">
                        </div>
                        <div class="flex-1 min-w-0">
                            <div class="focus:outline-none">
                                <span class="absolute inset-0" aria-hidden="true"></span>
                                <p class="text-sm font-medium text-gray-200">
                                    {{checkIfSourceSyncNameIsLongerThan(numberOfCharsWhenSliceIsUsed) ?
                                    sync.name :
                                    (sync.name| slice : 0 : numberOfCharsWhenSliceIsUsed) + '...'}}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="flex text-gray-200 text-sm ml-auto" *ngIf="sync.synced_at">
                        {{moment(sync.synced_at * 1000).fromNow()}}
                    </div>
                </div>
            </div>
        </ng-template>
    </swiper>
    <div class="flex space-x-2 mt-2 justify-center">
        <span class='w-6 h-1 bg-white cursor-pointer' [ngClass]="{'bg-brand': checkSwiperIndex(0)}"
              (click)="changeSwiperIndexAndResetInterval(0)"></span>
        <span class='w-6 h-1 bg-white cursor-pointer' [ngClass]="{'bg-brand': checkSwiperIndex(1)}"
              (click)="changeSwiperIndexAndResetInterval(1)"></span>
        <span class='w-6 h-1 bg-white cursor-pointer' [ngClass]="{'bg-brand': checkSwiperIndex(2)}"
              (click)="changeSwiperIndexAndResetInterval(2)"></span>
    </div>
</div>
<style>
    .swiper {
        width: 100%;
        height: 100%;
    }

</style>
