<div class="flex-col md:flex-row flex px-6 py-4 items-center bg-white">
    <div class="w-full md:w-auto relative flex items-stretch flex-grow focus-within:z-50 mb-2 xs:space-y-0 md:space-y-2">
        <div class="absolute inset-y-0 left-0 flex items-center pointer-events-none">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M11 20C15.9706 20 20 15.9706 20 11C20 6.02944 15.9706 2 11 2C6.02944 2 2 6.02944 2 11C2 15.9706 6.02944 20 11 20Z"
                      stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M18.9304 20.6898C19.4604 22.2898 20.6704 22.4498 21.6004 21.0498C22.4504 19.7698 21.8904 18.7198 20.3504 18.7198C19.2104 18.7098 18.5704 19.5998 18.9304 20.6898Z"
                      stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
        </div>
        <app-ppg-search-input [searchQuery]="searchAndFilter.query"
                              (searchChanged)="searchAndFilter.query = $event;
                              searchAndFilterChange.emit(searchAndFilter)">
        </app-ppg-search-input>
    </div>
    <button type="button" (click)="openCreateModal.emit(true)" class="bg-gray-700 py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500">Create</button>
</div>
