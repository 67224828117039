import {Injectable} from '@angular/core';
import {ToastrService} from 'ngx-toastr';

@Injectable({
    providedIn: 'root'
})
export class ToasterHelperService {

    constructor(private toastr: ToastrService) {
    }

    showSuccess(msg: string): void {
        this.toastr.success(msg, '');
    }

    showWarning(msg: string): void {
        this.toastr.warning(msg, '');
    }

    showError(msg: string): void {
        this.toastr.error(msg, '');
    }
}
