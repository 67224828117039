import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {UseCase, UseCaseEditModal, UseCaseType} from '../../../interfaces/use-case-interfaces';
import {UseCasesHttpService} from '../../../services/http-services/use-cases-http.service';

@Component({
    selector: 'app-edit-use-case',
    templateUrl: './edit-use-case.component.html'
})
export class EditUseCaseComponent implements OnInit {

    @Input() useCaseForEdit: UseCaseEditModal | undefined;
    useCaseForm = new FormGroup({
        useCaseName: new FormControl({value: '', disabled: false}, Validators.required)
    });
    types: Array<UseCaseType> = [];
    @Output() useCaseEdited = new EventEmitter<UseCaseEditModal | undefined>();
    @Output() modalClosed = new EventEmitter();

    constructor(private useCaseHttp: UseCasesHttpService) {
    }

    ngOnInit(): void {
        this.setUseCaseName();
    }

    setUseCaseName(): void {
        if (this.useCaseForEdit && this.useCaseForEdit.useCase) {
            this.useCaseForm.controls.useCaseName.setValue(this.useCaseForEdit.useCase.name);
        }
    }

    saveUseCase(): void {
        if (this.useCaseForEdit && this.useCaseForEdit.useCase) {
            this.useCaseHttp.editUseCase(this.useCaseForEdit.useCase, this.useCaseForm, this.useCaseForEdit?.useCase.id).then((useCase: UseCase) => {
                if (this.useCaseForEdit) {
                    this.useCaseForEdit.useCase = useCase;
                    this.useCaseEdited.emit(this.useCaseForEdit);
                    this.modalClosed.emit(true);
                }
            });
        }
    }

    closeModal(): void {
        this.modalClosed.emit(true);
    }
}
