<div *ngIf="sourcesHttpServiceHelper.fileUploadedSubject.totalNumberOfFiles">
    <div>
        {{sourcesHttpServiceHelper.fileUploadedSubject.numberOfFilesUploaded + '/' + sourcesHttpServiceHelper.fileUploadedSubject.totalNumberOfFiles}} Files
    </div>
    <div class="w-full h-3 rounded-lg overflow-hidden">
        <div class="bg-green-5 h-3 bg-green-500" [style]="{'width': calcPercentageOfFilesUploaded(sourcesHttpServiceHelper.fileUploadedSubject.totalNumberOfFiles, sourcesHttpServiceHelper.fileUploadedSubject.numberOfFilesUploaded) + '%'}"></div>
    </div>
</div>

<div class="w-full rounded-tl-md rounded-tr-md relative border flex flex-col content mt-2">
    <div *ngFor="let file of uploadedFiles">
        <div class="flex content-between items-center p-4">
            <img src="../../../assets/images/icons/PG_Icon.svg" alt="icon">
            <div class="flex flex-col flex-grow space-y-1">
                <h4 class="text-sm leading-5 font-normal gray-400 uppercase">{{file.name.substr(file.name.lastIndexOf('.') + 1)}}</h4>
                <h3 class="text-sm leading-5 font-semibold">{{file.name}}</h3>
            </div>
            <input name="comments" type="checkbox" [(ngModel)]="file.isChecked" (ngModelChange)="filesChanged.emit(uploadedFiles)"
                   class="focus:ring-gray-500 h-6 w-6 text-gray-600 border-gray-300 rounded cursor-pointer">
        </div>
        <div class="w-full border-t border-gray-200"></div>
    </div>
</div>
