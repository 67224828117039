import {Component, ElementRef, Input, OnInit, Renderer2, ViewChild} from '@angular/core';
import {UseCase, UseCaseHistorySearch} from '../../../interfaces/use-case-interfaces';
import {UseCasesHttpService} from '../../../services/http-services/use-cases-http.service';
import {PpgPagination} from '../../../interfaces/general-interfaces';
import {GeneralHelperService} from '../../../services/helpers/general-helper.service';
import {Router} from '@angular/router';
import {PAGES_AND_PATHS} from '../../../constants/pages-and-paths';
import {UseCaseHelperService} from '../../../services/helpers/use-case-helper.service';

@Component({
    selector: 'app-use-case-navigation',
    templateUrl: './use-case-navigation.component.html'
})
export class UseCaseNavigationComponent implements OnInit {

    @Input() useCase: UseCase | undefined;
    @ViewChild('toggleButton') toggleButton: ElementRef | undefined;
    @ViewChild('menu') menu: ElementRef | undefined;
    historySearchUseCases: Array<UseCaseHistorySearch> = [];
    searchedUseCases: { pagination: PpgPagination | null, useCases: Array<UseCase> } | undefined;
    showDropdown = false;
    searchQuery = '';

    constructor(private useCasesHttpService: UseCasesHttpService,
                private generalHelperService: GeneralHelperService,
                private router: Router,
                private renderer: Renderer2,
                private useCaseHelper: UseCaseHelperService) {
        this.listenToClick();
    }

    ngOnInit(): void {
        this.getUseCasesHistory();
        this.searchUseCases(this.searchQuery);
    }

    listenToClick(): void {
        this.renderer.listen('window', 'click', (e: Event) => {
            if (this.generalHelperService.checkIfClickedOutsideOfTheElement(this.toggleButton, this.menu, e)) {
                this.showDropdown = false;
            }
        });
    }

    toggleDropdown(): void {
        this.searchQuery = '';
        this.showDropdown = !this.showDropdown;
        if (this.showDropdown) {
            this.searchUseCases(this.searchQuery);
        }
    }

    searchUseCases(searchQuery: string): void {
        this.useCasesHttpService.searchUseCases(searchQuery).then((useCases: { pagination: PpgPagination, useCases: Array<UseCase> }) => {
            this.searchedUseCases = useCases;
            this.addNewHistorySearch(searchQuery);
        });
    }

    searchQueryByHistory(historyQuery: string): void {
        this.searchQuery = historyQuery;
        this.searchUseCases(this.searchQuery);
    }

    checkIfSearchAlreadyExists(searchQuery: string): boolean {
        for (const search of this.historySearchUseCases) {
            if (search.query.toLowerCase() === searchQuery.toLowerCase()) {
                return true;
            }
        }
        return false;
    }

    addNewHistorySearch(searchQuery: string): void {
        if (searchQuery.trim() && !this.checkIfSearchAlreadyExists(searchQuery)) {
            console.log('SEARCH: ', this.historySearchUseCases);
            this.historySearchUseCases.splice(0, 0, {
                company_id: 0,
                created_at: '',
                id: 0,
                query: searchQuery,
                type: '',
                updated_at: '',
                user_id: 0
            });
            this.historySearchUseCases.splice(this.historySearchUseCases.length - 1, 1);
        }
    }

    navigateToSingleUseCase(useCaseId: number): void {
        this.useCaseHelper.resetUseCaseFilters.next(true);
        this.router.navigate([PAGES_AND_PATHS.useCaseSingle.pagePath + '/' + useCaseId]);
        this.toggleDropdown();
    }

    paginateSearchUseCases(scrollEvent: any): void {
        if (this.generalHelperService.checkIfScrolledToBottom(scrollEvent)) {
            if (this.searchedUseCases?.pagination?.nextPageUrl) {
                this.useCasesHttpService.paginateSearchUseCases(this.searchedUseCases?.pagination?.nextPageUrl).then((useCases: { pagination: PpgPagination, useCases: Array<UseCase> }) => {
                    if (this.searchedUseCases) {
                        this.searchedUseCases.pagination = useCases.pagination;
                        this.searchedUseCases.useCases = this.searchedUseCases.useCases.concat(useCases.useCases);
                    }
                });
            }
        }
    }

    getUseCasesHistory(): void {
        this.useCasesHttpService.getUseCaseHistorySearch().then((useCases: Array<UseCaseHistorySearch>) => {
            this.historySearchUseCases = useCases;
        });
    }

}
