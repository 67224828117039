import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'app-ppg-no-results',
    templateUrl: './ppg-no-results.component.html'
})
export class PpgNoResultsComponent implements OnInit {

    @Input() title = 'No results';
    @Input() subtitle = 'Looks like there are no Use cases with such name.';

    constructor() {
    }

    ngOnInit(): void {
    }

}
