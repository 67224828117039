import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {LastSyncedComponent} from './last-synced.component';
import {SwiperModule} from 'swiper/angular';


@NgModule({
    declarations: [
        LastSyncedComponent
    ],
    exports: [
        LastSyncedComponent
    ],
    imports: [
        CommonModule,
        SwiperModule
    ]
})
export class LastSyncedModule {
}
