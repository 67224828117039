import {AddingSourceType, AddSourceManualSteps} from '../../../../enums/sources-enums';
import {FileExtension, SourceType} from '../../../../interfaces/sources-interfaces';
import {PpgFile} from '../../../../derived-classes/ppg-file';

export interface PossibleSteps {
    firstStep: AddSourceManualSteps.firstStep;
    secondStep: AddSourceManualSteps.secondStep;
    thirdStep: AddSourceManualSteps.thirdStep;
}

export class AddSourceManual {
    private _files: Array<PpgFile> = [];
    private _possibleSteps: PossibleSteps = {
        firstStep: AddSourceManualSteps.firstStep,
        secondStep: AddSourceManualSteps.secondStep,
        thirdStep: AddSourceManualSteps.thirdStep,
    };
    private _steps: Array<any> = [];
    private _activeStep = '';
    private _sourceName = '';
    private _processSelectedPagesInDocument = false;
    private _expectedFileExtension: Array<FileExtension> = [];
    private _selectedType: SourceType | null = null;
    private _useCaseId = 0;

    constructor() {
        this.fillStepsArray();
    }

    get useCaseId(): number {
        return this._useCaseId;
    }

    set useCaseId(value: number) {
        this._useCaseId = value;
    }

    get selectedType(): SourceType | null {
        return this._selectedType;
    }

    set selectedType(value: SourceType | null) {
        this._selectedType = value;
    }

    private showNextButton = true;

    fillStepsArray(): void {
        for (const step in this._possibleSteps) {
            if (step) {
                this._steps.push(step);
            }
        }
        this._activeStep = this._steps[0];
    }

    nextStep(): void {
        const indexOfActiveStep = this._steps.indexOf(this.activeStep);
        if (indexOfActiveStep < (this._steps.length - 1)) {
            this.activeStep = this._steps[indexOfActiveStep + 1];
        } else {
            this.showNextButton = false;
        }
    }

    get possibleSteps(): PossibleSteps {
        return this._possibleSteps;
    }

    // constructor(files: Array<any>, steps: Array<any>, activeStep: string, sourceName: string, processSelectedPagesInDocument: boolean, expectedFileExtension: Array<string>) {
    //     this._files = files;
    //     this._steps = steps;
    //     this._activeStep = activeStep;
    //     this._sourceName = sourceName;
    //     this._processSelectedPagesInDocument = processSelectedPagesInDocument;
    //     this._expectedFileExtension = expectedFileExtension;
    // }

    get files(): Array<PpgFile> {
        return this._files;
    }

    set files(value: Array<PpgFile>) {
        this._files = value;
    }

    get steps(): Array<any> {
        return this._steps;
    }

    set steps(value: Array<any>) {
        this._steps = value;
    }

    get activeStep(): string {
        return this._activeStep;
    }

    set activeStep(value: string) {
        this._activeStep = value;
    }

    get sourceName(): string {
        return this._sourceName;
    }

    set sourceName(value: string) {
        this._sourceName = value;
    }

    get processSelectedPagesInDocument(): boolean {
        return this._processSelectedPagesInDocument;
    }

    set processSelectedPagesInDocument(value: boolean) {
        this._processSelectedPagesInDocument = value;
    }

    get expectedFileExtension(): Array<FileExtension> {
        return this._expectedFileExtension;
    }

    set expectedFileExtension(value: Array<FileExtension>) {
        this._expectedFileExtension = value;
    }
}
