import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-add-source-email-modal',
  templateUrl: './add-source-email-modal.component.html'
})
export class AddSourceEmailModalComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
