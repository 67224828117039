<div class="block mt-2">
    <label class="block text-sm font-medium text-gray-700">Expected File Extension</label>
    <div class="flex mt-4 justify-between">
        <div class="relative pb-2" *ngFor="let extension of fileExtensions" [ngStyle]="{'opacity': !extension.active ? '0.5' : '1'}">
            <img [src]="returnExtensionIcon(extension)" alt="icon">
            <input (change)="extensionChanged(extension)" [checked]="extension.isChecked"
                   [disabled]="!extension.active"
                   name="comments" type="checkbox"
                   class="focus:ring-blue-500 h-6 w-6 text-blue-600 border-gray-300 rounded absolute bottom-0 left-0" [ngClass]="{'cursor-pointer': extension.active}">
        </div>
    </div>
</div>
