<div class="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
    <div class="sm:mx-auto sm:w-full sm:max-w-lg">
        <img class="mx-auto h-20 w-auto text-red-600 fill-current" src="/assets/images/logo-light.svg" alt="Workflow">
        <h2 class="mt-6 text-center text-3xl font-extrabold text-gray-900 leading-9">
            Sign in to your account
        </h2>
    </div>
    <div class="flex my-4 justify-center -mx-2">
        <ul class="flex space-x-8">
            <!-- If it's active use bg-blue-100, if it's not active use bg-gray-100 -->
            <li (click)="openRegisterTab('displayStep1')" class="px-2 py-1 rounded-md cursor-pointer flex items-center" [ngClass]="displayStep1 ? 'bg-gray-100' : 'bg-transparent'">
                <!-- If it's active use border-indigo-200 bg-indigo-500, if it's not active use border-gray-100 bg-gray-300 -->
                <div class="border-4 rounded-full w-4 h-4" [ngClass]="displayStep1 ? 'border-gray-200 bg-gray-600' : 'border-gray-50 bg-gray-300'"></div>
                <div class="ml-2 flex flex-col">
                    <!-- If it's active use border-indigo-200 bg-indigo-500, if it's not active use text-gray-500 -->
                    <span id="privacy-setting-1-label" class="text-gray-500 block text-sm font-medium" [ngClass]="displayStep1 ? 'border-gray-200 text-gray-600' : 'text-gray-500'">
                        Users info
                    </span>
                </div>
            </li>
            <!-- If it's active use bg-blue-100, if it's not active use bg-gray-100 -->
            <li class="px-2 py-1 rounded-md flex items-center" [ngClass]="displayStep2 ? 'bg-gray-100' : 'bg-transparent'">
                <!-- If it's active use border-indigo-200 bg-indigo-500, if it's not active use border-gray-100 bg-gray-300 -->
                <div class="border-4 rounded-full w-4 h-4" [ngClass]="displayStep2 ? 'border-gray-200 bg-gray-600' : 'border-gray-50 bg-gray-300'"></div>
                <div class="ml-2 flex flex-col">
                    <!-- If it's active use border-indigo-200 bg-indigo-500, if it's not active use text-gray-500 -->
                    <span id="privacy-setting-1-label" class="text-gray-500 block text-sm font-medium" [ngClass]="displayStep2 ? 'border-gray-200 text-gray-500' : 'text-gray-500'">
                        Password
                    </span>
                </div>
            </li>
        </ul>

    </div>
    <!-- Registration form - company info -->
    <div class="sm:mx-auto sm:w-full sm:max-w-lg mb-6">
        <div class="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
            <span class="text-sm text-gray-700 mb-2">Invited to company:</span>
            <h1 class="text-lg text-gray-700 font-bold">CompanyName</h1>
        </div>
    </div>
    <!-- ./Registration form - company info -->
    <div class="sm:mx-auto sm:w-full sm:max-w-lg">
        <div class="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
            <form class="space-y-6">

                <!-- Registration form - Step one -  -->
                <app-register-step-one [userEmail]="userEmail" *ngIf="displayStep1" (step2)="secondStep($event)">
                </app-register-step-one>
                <!-- ./Registration form - Step one -  -->

                <!-- Registration form - Step two - Password -->
                <app-register-step-two *ngIf="displayStep2" (step3)="thirdStep($event)">
                </app-register-step-two>
                <!-- ./Registration form - Step two - Password -->
            </form>
        </div>
    </div>
</div>
