import {CloudRegion, CloudType} from '../../../../interfaces/sources-interfaces';
import {EditType} from '../../../../enums/sources-enums';

export class CloudIntegration {

    private _id = 0;
    private _name = '';
    private _email = '';
    private _cloudType: CloudType | null = null;
    private _companyId = 0;
    private _cloudRegion: CloudRegion | null = null;
    private _cloudRegionId = 0;
    private _folder = '';
    private _bucket = '';
    private _secretAccessKey = '';
    private _accessKey = '';
    private _username = '';
    private _password = '';
    private _port = 0;
    private _ipAddress = '';
    private _ssl = false;
    private _editType: EditType | null = null;

    get editType(): EditType | null {
        return this._editType;
    }

    set editType(value: EditType | null) {
        this._editType = value;
    }

    get cloudRegionId(): number {
        return this._cloudRegionId;
    }

    set cloudRegionId(value: number) {
        this._cloudRegionId = value;
    }

    get username(): string {
        return this._username;
    }

    set username(value: string) {
        this._username = value;
    }

    get password(): string {
        return this._password;
    }

    set password(value: string) {
        this._password = value;
    }

    get port(): number {
        return this._port;
    }

    set port(value: number) {
        this._port = value;
    }

    get ipAddress(): string {
        return this._ipAddress;
    }

    set ipAddress(value: string) {
        this._ipAddress = value;
    }

    get ssl(): boolean {
        return this._ssl;
    }

    set ssl(value: boolean) {
        this._ssl = value;
    }

    get id(): number {
        return this._id;
    }

    set id(value: number) {
        this._id = value;
    }

    get name(): string {
        return this._name;
    }

    set name(value: string) {
        this._name = value;
    }

    get email(): string {
        return this._email;
    }

    set email(value: string) {
        this._email = value;
    }

    get cloudType(): CloudType | null {
        return this._cloudType;
    }

    set cloudType(value: CloudType | null) {
        this._cloudType = value;
    }

    get companyId(): number {
        return this._companyId;
    }

    set companyId(value: number) {
        this._companyId = value;
    }

    get cloudRegion(): CloudRegion | null {
        return this._cloudRegion;
    }

    set cloudRegion(value: CloudRegion | null) {
        this._cloudRegion = value;
    }

    get folder(): string {
        return this._folder;
    }

    set folder(value: string) {
        this._folder = value;
    }

    get bucket(): string {
        return this._bucket;
    }

    set bucket(value: string) {
        this._bucket = value;
    }

    get secretAccessKey(): string {
        return this._secretAccessKey;
    }

    set secretAccessKey(value: string) {
        this._secretAccessKey = value;
    }

    get accessKey(): string {
        return this._accessKey;
    }

    set accessKey(value: string) {
        this._accessKey = value;
    }

    constructor() {
    }
}
