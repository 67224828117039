import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {UseCaseBodyDataViewTypes, UseCaseDataViewTypes} from '../../../../enums/use-cases-enums';
import {
    ActiveQueryImage,
    DataDocument, QueryBody,
    SearchAndFilterUseCaseData,
    UseCaseDataResponse, UseCaseQuery, UseCaseQueryResponse
} from '../../../../interfaces/use-case-data-interfaces';
import {ActivePageHelperService} from '../../../../services/helpers/active-page-helper.service';
import {UseCaseDataHttpService} from '../../../../services/http-services/use-case-data-http.service';
import {FadeInAnimation} from '../../../../animations/fade-in-animation';

@Component({
    selector: 'app-use-case-query-single',
    templateUrl: './use-case-query-single.component.html',
    animations: [FadeInAnimation]
})
export class UseCaseQuerySingleComponent implements OnInit, OnDestroy {

    @Input() singleUseCaseQuery: UseCaseQuery | undefined;
    @Input() activeDataBody: QueryBody | undefined;
    @Input() useCaseData: UseCaseDataResponse | undefined;
    @Input() useCaseId = 0;
    useCaseDataForView: Array<UseCaseQuery> | undefined = [];
    UseCaseDataViewTypes = UseCaseDataViewTypes;
    documentImage = '';
    UseCaseBodyDataViewTypes = UseCaseBodyDataViewTypes;
    activeDataBodyTypeView = UseCaseBodyDataViewTypes.current;
    waitingImageResponse = false;
    dataDocument: DataDocument | undefined;
    lastImage: number | undefined;
    documentImages: Array<number> | undefined = [];
    activeDocImgPage: ActiveQueryImage | undefined;
    searchAndFilter: SearchAndFilterUseCaseData = {
        query: '',
        source: null,
        syncSource: null
    };
    @Output() changedView = new EventEmitter<UseCaseDataViewTypes>();

    constructor(private activePageHelper: ActivePageHelperService,
                private useCaseDataHttpService: UseCaseDataHttpService) {
    }

    ngOnInit(): void {
        if (this.singleUseCaseQuery) {
            this.fillUseCaseDataForView([this.singleUseCaseQuery]);
            this.getDocumentImage(this.activeDataBody?.image_id);
            this.getDocumentImages();
        }
        this.activePageHelper.showSideMenu = false;
    }

    changeQueryBody(event: QueryBody | undefined, useCaseSingleData: UseCaseQuery): void {
        if (this.singleUseCaseQuery && useCaseSingleData && this.singleUseCaseQuery?.id !== useCaseSingleData.id) {
            this.singleUseCaseQuery = useCaseSingleData;
            this.getDocumentImage(this.activeDataBody?.image_id);
            this.getDocumentImages();
        }

        this.activeDataBody = event;
        this.getDocumentImage(this.activeDataBody?.image_id);
    }

    fillUseCaseDataForView(useCaseData: Array<UseCaseQuery | undefined> | undefined): void {
        if (this.useCaseDataForView && useCaseData) {
            this.useCaseDataForView = JSON.parse(JSON.stringify(useCaseData));
        }
    }

    ngOnDestroy(): void {
        this.activePageHelper.showSideMenu = true;
    }

    getDocumentImages(): void {
        this.documentImages = this.singleUseCaseQuery?.bodies.map(x => x.image_id);
    }

    changeCurrentPage(): void {
        if (this.documentImages) {
            for (let i = 0; i < this.documentImages.length; i++) {
                if (this.singleUseCaseQuery && this.singleUseCaseQuery?.bodies[0].image_id === this.documentImages[i]) {
                    const activeDocImg = this.documentImages[i];
                    this.activeDocImgPage = {index: i, id: activeDocImg};
                }
            }
        }
    }

    getDocumentImage(imageId: number | undefined): void {
        if (imageId === undefined) {
            return;
        }

        if (this.lastImage === imageId) {
            return;
        }

        this.lastImage = imageId;

        this.waitingImageResponse = true;
        this.useCaseDataHttpService.getImagePreview(imageId).then((documentPreview: string) => {
            this.waitingImageResponse = false;
            this.documentImage = documentPreview;
            this.changeCurrentPage();
        }, error => {
            this.waitingImageResponse = false;
        });
    }

    showData(useCaseData: UseCaseDataResponse | undefined): boolean | undefined {
        return useCaseData && useCaseData.useCaseData && typeof useCaseData.pagination.totalItems !== 'undefined' && useCaseData.pagination.totalItems > 0;
    }

}
