<!-- Header loaded -->
<app-destination-search-with-type-and-output-filter
    (destinationTypesFetched)="destinationTypes = $event;"
    [searchAndFilter]="searchAndFilter"
    (searchAndFilterChange)="searchDestinations($event)">
</app-destination-search-with-type-and-output-filter>
<!-- ./Header loaded -->

<div class="flex flex-col w-full px-6 py-4" *ngIf="showData(destinations)">
    <div class="flex flex-col">
        <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-6">
            <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-6">
                <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                    <table class="min-w-full divide-y divide-gray-200">
                        <thead class="bg-gray-50">
                        <tr>
                            <th scope="col"
                                class="px-6 py-3 text-left text-xs font-medium text-gray-500 tracking-wider">
                                Destination Name
                            </th>
                            <th scope="col" *ngIf="useCaseId === 0"
                                class="px-6 py-3 text-left text-xs font-medium text-gray-500 tracking-wider">
                                Use case
                            </th>
                            <th scope="col"
                                class="px-6 py-3 text-left text-xs font-medium text-gray-500 tracking-wider">
                                Destination Type
                            </th>
                            <th scope="col"
                                class="px-6 py-3 text-left text-xs font-medium text-gray-500 tracking-wider">
                                Output Type
                            </th>
                            <th scope="col"
                                class="px-6 py-3 text-left text-xs font-medium text-gray-500 tracking-wider">
                                Actions
                            </th>
                        </tr>
                        </thead>
                        <tbody class="bg-white divide-y divide-gray-200">
                        <tr *ngFor="let destination of destinations?.data" @fadingIn>
                            <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                {{destination.name}}
                            </td>
                            <td *ngIf="useCaseId === 0 && destination.use_case_name"
                                [routerLink]="[PAGES_AND_PATHS.useCaseSingle.pagePath + destination.use_case_name?.id]"
                                class="px-6 py-4 whitespace-nowrap text-sm text-gray-500 capitalize cursor-pointer">
                                {{destination.use_case_name?.name}}
                            </td>
                            <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                {{destination.output_type.name}}
                            </td>

                            <td class="px-6 py-4 whitespace-nowrap text-left text-sm font-medium">
                                <a class="text-gray-600 hover:text-gray-900 cursor-pointer"
                                   (click)="destinationForEdit = {destination: destination, show: true}">Edit</a>
                            </td>
                            <td
                                class="px-6 py-4 whitespace-nowrap text-left text-sm font-medium">
                                <a (click)="destinationDeleteModalDetails = {destination: destination, show: true}"
                                   class="text-indigo-600 hover:text-indigo-900 cursor-pointer">Delete</a>
                            </td>
                            <td *ngIf="destination.output_type.destination_type.name === DestinationType.businessIntelligence && destination.output_type.name === DestinationOutputType.api"
                                class="px-6 py-4 whitespace-nowrap text-left text-sm font-medium">
                                <a (click)="openDestinationDetailsModal(destination)" class="text-indigo-600 hover:text-indigo-900 cursor-pointer">View</a>
                            </td>
                            <td *ngIf="destination.output_type.destination_type.name !== DestinationType.businessIntelligence && destination.output_type.name !== DestinationOutputType.api"
                                class="px-6 py-4 whitespace-nowrap text-left text-sm font-medium">
                                <a (click)="downloadDocument(destination)"
                                   class="text-indigo-600 hover:text-indigo-900 cursor-pointer">Download</a>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
    <!-- Pagination -->
    <div class="mt-4">
        <div class="flex w-full justify-between items-center border-t border-gray-200 pt-2">
            <app-pagination-data-details *ngIf="showData(destinations)" [pagination]="destinations?.pagination">
            </app-pagination-data-details>
            <app-ppg-pagination *ngIf="showData(destinations)" [currentPage]="destinations?.pagination?.currentPage"
                                [pagId]="'js-destination-pag'"
                                [perPage]="destinations?.pagination?.perPage"
                                [totalItems]="destinations?.pagination?.totalItems"
                                (pageChanged)="onPageChanged($event)">
            </app-ppg-pagination>
        </div>
    </div>
    <!-- End Pagination -->
</div>

<app-delete-confirmation-modal *ngIf="destinationDeleteModalDetails?.show"
                               [deleteTitle]="destinationDeleteModalDetails?.destination?.name"
                               [objToEmitBack]="destinationDeleteModalDetails?.destination"
                               (modalClosed)="onDestinationDeleteModalClosed()"
                               (deleted)="onDestinationDelete($event)">
</app-delete-confirmation-modal>
<app-ppg-slide-modal-wrapper [showModal]="destinationForEdit.show">
    <app-edit-destination-modal *ngIf="destinationForEdit.show" class="fixed inset-y-0 right-0 pl-10 max-w-full flex sm:pl-16" @slide modal-body
                                (modalClosed)="destinationForEdit = {destination: undefined, show: false}"
                                (destinationEdited)="destinationForEdit = {destination: undefined, show: false}"
                                [destination]="destinationForEdit.destination">
    </app-edit-destination-modal>
</app-ppg-slide-modal-wrapper>
<app-modal [classess]="'max-w-2xl'" *ngIf="destinationDetailsModal.show" (modalClosed)="resetDestinationDetailsModal()">
    <div modal-header>
        <h3 class="font-medium text-2xl text-gray-700">Destination Data</h3>
    </div>
    <div modal-body>
        <div class="mt-6">
            <app-destination-details-modal *ngIf="destinationDetailsModal.show"
                                           [destinationId]="destinationDetailsModal.destination?.id">
            </app-destination-details-modal>
        </div>
    </div>
</app-modal>
<app-add-new-item *ngIf="!showData(initialDestinations) && initialRequestFinished"
                  [title]="'You need to add at least one Destination to the use case to start.'"
                  [announcement]="'No Destinations here yet.'"
                  [buttonTitle]="'Add Destination'"
                  (addNewItem)="showAddDestinationModal = true">
</app-add-new-item>
<app-ppg-slide-modal-wrapper [showModal]="showAddDestinationModal">
    <app-add-destination-modal class="fixed inset-y-0 right-0 pl-10 max-w-full flex sm:pl-16" @slide modal-body
                               *ngIf="showAddDestinationModal"
                               [destinationTypes]="destinationTypes"
                               (modalClosed)="onDestinationClosed()"
                               (destinationAdded)="onDestinationAdded($event)"
                               [useCaseId]="useCaseId">
    </app-add-destination-modal>
</app-ppg-slide-modal-wrapper>
<app-ppg-no-results *ngIf="!showData(destinations) && showData(initialDestinations)"
                    [title]="'No results'"
                    [subtitle]="'Looks like there are no Sources with such name.'">
</app-ppg-no-results>
