<!-- Add Source Manual-->
<div class="w-screen max-w-2xl" #modal>
    <div class="h-full flex flex-col bg-white shadow-xl overflow-y-scroll" id="js-add-source-modal">
        <div class="flex-1">
            <!-- Header -->
            <div class="px-4 py-6 bg-gray-700 sm:px-6">
                <div class="flex items-start justify-between space-x-3">
                    <div class="space-y-1">
                        <h2 class="text-lg font-medium text-white" id="slide-over-title">
                            New Source
                        </h2>
                        <p class="text-sm text-gray-300">
                            Get started by filling in the information below to create your new use case.
                        </p>
                    </div>
                    <div class="h-7 flex items-center">
                        <button (click)="modalClosed.emit(true)" type="button"
                                class="text-gray-400 hover:text-gray-500">
                            <span class="sr-only">Close panel</span>
                            <!-- Heroicon name: outline/x -->
                            <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none"
                                 viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                      d="M6 18L18 6M6 6l12 12"/>
                            </svg>
                        </button>
                    </div>
                </div>
            </div>
            <!-- 1st Step Content -->
            <div class="p-6 space-y-6">
                <div>
                    <label class="block text-sm font-medium text-gray-700">Source Name</label>
                    <div class="mt-2">
                        <input type="text" *ngIf="source"
                               class="shadow-sm focus:ring-gray-400 focus:border-gray-400 block w-full sm:text-sm border-gray-300 rounded-md"
                               placeholder="" [(ngModel)]="source!.name">
                        <small class="text-red-500" *ngIf="checkIfNeedToShowErrMessage() && source">Source name
                            need to have at least 2 characters</small>
                    </div>
                </div>
                <div *ngIf="source?.source_type?.name === AddingSourceType.cloud">
                    <label class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                        Frequency
                    </label>
                    <div class="mt-2">
                        <select id="cloudFrequency" name="cloudFrequency"
                                class="w-full block focus:ring-gray-400 focus:border-gray-400 shadow-sm border-gray-300 rounded-md capitalize"
                                (ngModelChange)="selectCloudFrequency($event)"
                                [(ngModel)]="source!.frequency">
                            <option *ngFor="let type of cloudFrequencies" [ngValue]="type"
                                    class="capitalize">{{type.frequency}}</option>
                        </select>
                    </div>
                </div>
                <app-file-extensions *ngIf="source" [checkedExtensions]="source.extensions"
                                     (extensionsChanged)="source!.extensions = $event">
                </app-file-extensions>
                <div class="relative flex items-start" *ngIf="source?.source_type?.name === AddingSourceType.cloud">
                    <div class="flex items-center h-5">
                        <input id="comments" aria-describedby="comments-description" name="comments" type="checkbox"
                               class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded cursor-pointer"
                               [(ngModel)]="source!.sync">
                    </div>
                    <div class="ml-3 text-sm">
                        <label for="comments" class="font-medium text-gray-700 text-sm leading-5">Immediately pull all the files</label>
                    </div>
                </div>
                <!-- Action buttons -->
                <div class="flex-shrink-0 px-4 border-t border-gray-200 py-5 sm:px-6 mt-auto">
                    <div class="space-x-3 flex justify-end">
                        <button type="button"
                                (click)="editSource()"
                                class="bg-gray-700 py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500">
                            Save
                        </button>
                    </div>
                </div>
            </div>
            <!-- 1st Step Content End -->
        </div>
    </div>
</div>
