<ngx-file-drop dropZoneClassName="border-0" dropZoneLabel="" (onFileDrop)="dropped($event)" [accept]="returnStringOfAcceptedExtensions()"
               class="pr-2 bg-white text-lg leading-7 font-medium text-gray-500" (invalid)="invalidFileUploaded($event)"
               (onFileOver)="fileOver($event)" (onFileLeave)="fileLeave($event)" [showBrowseBtn]="true" browseBtnLabel="Upload File">
    <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
        <div class="w-full">
            <div class="mt-1 sm:mt-0 sm:col-span-2">
                <div class="w-full flex justify-center p-2 border-2 border-gray-300 border-dashed rounded-md">
                    <div class="space-y-1 text-center flex flex-grow space-x-4">
                        <svg width="87" height="89" viewBox="0 0 87 89" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect width="87" height="88" transform="translate(0 0.799805)" fill="#6B7280"/>
                            <path d="M37.6667 52.0915C33.6396 52.0915 30.375 48.8269 30.375 44.7998C30.375 41.2291 32.9415 38.2579 36.3305 37.6303C36.9581 34.2414 39.9293 31.6748 43.5 31.6748C47.0707 31.6748 50.0419 34.2414 50.6695 37.6303C54.0585 38.2579 56.625 41.2291 56.625 44.7998C56.625 48.8269 53.3604 52.0915 49.3333 52.0915M39.125 44.7998L43.5 40.4248M43.5 40.4248L47.875 44.7998M43.5 40.4248V57.9248"
                                  stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                        <div class="flex flex-col content-center ">
                            <div class="my-auto">
                                <div class="flex text-sm text-gray-600">
                                    <label for="file-upload"class="relative cursor-pointer rounded-md text-sm leading-5 font-semibold">
                                        <input id="file-upload" name="file-upload" type="file" class="sr-only">
                                    </label>
                                    <p class="text-sm leading-5 font-semibold">Drag and drop files</p>
                                    <button class="pl-1 text-sm leading-5 font-semibold" type="button" (click)="openFileSelector()">or Browse Files</button>
                                </div>
                                <p class="text-sm leading-5 font-normal text-gray-500">
                                    Maximum size of the file up to 10MB
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ng-template>
</ngx-file-drop>
<!--<input type="file"-->
<!--       id="file"-->
<!--       (change)="droppedFromInput($event)">-->
<p>{{acceptedExtensions | allowedFileExtensions}}</p>
