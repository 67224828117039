import {AddingSourceType} from '../../../enums/sources-enums';
import {FileExtension, SourceType} from '../../../interfaces/sources-interfaces';

export enum AddingSourceTypeEmailFirstStepEnum {
    dataSourceInOption = 'Email'
}

export class AddingSourceTypeEmailFirstStep {

    sourceType = AddingSourceType.email;
    private _dataSourceInOption = AddingSourceTypeEmailFirstStepEnum.dataSourceInOption;
    private _sourceName = '';
    private _processOnlySelectedPages = true;
    private _expectedFileExtension: Array<FileExtension> = [];
    private _sourceTypeObj: SourceType | null = null;

    constructor() {
    }

    get sourceTypeObj(): SourceType | null {
        return this._sourceTypeObj;
    }

    set sourceTypeObj(value: SourceType | null) {
        this._sourceTypeObj = value;
    }

    get expectedFileExtension(): Array<FileExtension> {
        return this._expectedFileExtension;
    }

    set expectedFileExtension(value: Array<FileExtension>) {
        this._expectedFileExtension = value;
    }

    get dataSourceInOption(): AddingSourceTypeEmailFirstStepEnum {
        return this._dataSourceInOption;
    }

    set dataSourceInOption(value: AddingSourceTypeEmailFirstStepEnum) {
        this._dataSourceInOption = value;
    }

    get sourceName(): string {
        return this._sourceName;
    }

    set sourceName(value: string) {
        this._sourceName = value;
    }

    get processOnlySelectedPages(): boolean {
        return this._processOnlySelectedPages;
    }

    set processOnlySelectedPages(value: boolean) {
        this._processOnlySelectedPages = value;
    }
}
