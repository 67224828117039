import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ToasterHelperService } from 'src/app/services/helpers/toaster-helper.service';
import { AuthHttpService } from 'src/app/services/http-services/auth-http.service';
import { LocalStorageProperties, Users } from '../../../../enums/general-enums';

@Component({
  selector: 'app-invites',
  templateUrl: './invites.component.html',
  styleUrls: ['./invites.component.scss']
})
export class InvitesComponent implements OnInit, OnDestroy {
  loggedUser = localStorage.getItem(LocalStorageProperties.userRole);
  listOfInvites: any[] = [];
  listOfInvitesSubscription: Subscription | undefined;
  Users = Users;

  constructor(
    private authHttpService: AuthHttpService,
    private toasterService: ToasterHelperService) { }

  ngOnInit(): void {
    this.getInvites();
  }

  getInvites(): void {
    if (this.loggedUser === Users.superAdmin) {
      this.listOfInvitesSubscription = this.authHttpService.getAllSuperAdminInvitations().subscribe((response: any) => {
        this.listOfInvites = response.entity.data;
      }, (error: any) => {
        this.toasterService.showError(error);
      });
    } else if (this.loggedUser === Users.companyAdmin) {
      this.listOfInvitesSubscription = this.authHttpService.getAllMemberInvitations().subscribe((response: any) => {
        this.listOfInvites = response.entity.data;
      }, (error: any) => {
        this.toasterService.showError(error);
      });
    }
  }

  reinviteUser(id: any): void {
    if (this.loggedUser === Users.superAdmin) {
      this.authHttpService.resendAdminInvitation(id).subscribe((response: any) => {
        this.toasterService.showSuccess('Company admin successfully reinvited!');
        this.getInvites();
      }, (error: any) => {
        this.toasterService.showError(error);
      });
    } else if (this.loggedUser === Users.companyAdmin) {
      this.authHttpService.resendMemberInvitation(id).subscribe((response: any) => {
        this.toasterService.showSuccess('Company member successfully reinvited!');
        this.getInvites();
      }, (error: any) => {
        this.toasterService.showError(error);
      });
    }
  }

  cancelInvitation(id: any): void {
    if (this.loggedUser === Users.superAdmin) {
      this.authHttpService.cancelAdminInvitation(id).subscribe((response: any) => {
        this.toasterService.showSuccess('Company admin invitation successfully canceled!');
        this.getInvites();
      }, (error: any) => {
        this.toasterService.showError(error);
      });
    } else if (this.loggedUser === Users.companyAdmin) {
      this.authHttpService.cancelMemberInvitation(id).subscribe((response: any) => {
        this.toasterService.showSuccess('Company member invitation successfully canceled!');
        this.getInvites();
      }, (error: any) => {
        this.toasterService.showError(error);
      });
    }
  }

  ngOnDestroy(): void {
    this.listOfInvitesSubscription?.unsubscribe();
  }
}
