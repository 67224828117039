<div class="text-center max-w-5xl w-full mx-auto flex flex-col justify-center">
    <img src="../../../assets/images/icons/Empty_State.svg" class="max-h-48 mt-12" alt="icon">
    <h3 class="mt-2 text-sm font-medium text-gray-900">{{announcement}}</h3>
    <p class="mt-1 text-sm text-gray-500">
        {{title}}
    </p>
    <div class="mt-6" *ngIf="buttonTitle !== ''">
        <button (click)="addNewItem.emit(true)"
                type="button"
                class="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
            <!-- Heroicon name: solid/plus -->
            <svg class="-ml-1 mr-2 h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"
                 aria-hidden="true">
                <path fill-rule="evenodd"
                      d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z"
                      clip-rule="evenodd"/>
            </svg>
            {{buttonTitle}}
        </button>
    </div>
</div>
