import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AddingSourceType, AddSourceCloudSteps} from '../../../../enums/sources-enums';
import {AddingSourceTypeEmailFirstStep} from '../AddingSourceTypeEmailFirstStep';
import {AddingSourceTypeCloudFirstStep} from '../AddingSourceTypeCloudFirstStep';
import {AddingSourceTypeManualFirstStep} from '../AddingSourceTypeManualFirstStep';
import {SourcesHttpService, SourcesHttpServiceHelper} from '../../../../services/http-services/sources-http.service';
import {
    CloudFrequency,
    CloudType,
    FileExtension,
    FirstStepSourcesText, Source,
    SourceType
} from '../../../../interfaces/sources-interfaces';
import {ToasterHelperService} from '../../../../services/helpers/toaster-helper.service';
import {CloudIntegration} from '../add-source-cloud-modal/cloud-integration';
import {GeneralHelperService} from '../../../../services/helpers/general-helper.service';

export const manualFirstStepText = {
    manual: {
        title: 'File upload',
        subtitle: 'No files to display. Please add new file.',
        buttonText: 'New File'
    }
};

export const cloudFirstStepText: any = {
    AWS: {
        title: 'AWS Accounts',
        subtitle: 'No AWS accounts to display. Please add new source.',
        buttonText: 'New AWS Account'
    },
    SFTP: {
        title: 'SFTP Accounts',
        subtitle: 'No SFTP accounts to display. Please add new source.',
        buttonText: 'New SFTP Account'
    },
    FTP: {
        title: 'FTP Accounts',
        subtitle: 'No FTP accounts to display. Please add new source.',
        buttonText: 'New FTP Account'
    }
};

@Component({
    selector: 'app-add-source-modal-first-step',
    templateUrl: './add-source-modal-first-step.component.html'
})
export class AddSourceModalFirstStepComponent implements OnInit {

    @Input() useCaseId = 0;
    @Output() typeOfAddingSourceSelected = new EventEmitter<AddingSourceTypeEmailFirstStep | AddingSourceTypeCloudFirstStep | AddingSourceTypeManualFirstStep>();
    @Output() stepChanged = new EventEmitter();
    @Output() cloudFrequenciesFetched = new EventEmitter<Array<CloudFrequency>>();
    @Output() sourceAdded = new EventEmitter<Source>();
    addingSourceTypes: Array<SourceType> = [];
    selectedAddingType: SourceType | null = null;
    AddingSourceType = AddingSourceType;
    sourceName = '';
    processOnlySelectedPages = false;
    sync = false;
    expectedFileExtension: Array<FileExtension> = [];
    cloudTypes: Array<CloudType> = [];
    selectedCloudType: CloudType = {id: 0, name: ''};
    cloudFrequencies: Array<CloudFrequency> = [];
    selectedCloudFrequency: CloudFrequency = {id: 0, frequency: ''};
    frequency = '';
    AddSourceCloudSteps = AddSourceCloudSteps;
    cloudIntegrations: Array<CloudIntegration> = [];
    selectedCloudIntegration: CloudIntegration | undefined;
    submitted = false;

    constructor(private sourcesHttpService: SourcesHttpService,
                private toasterHelperService: ToasterHelperService,
                private sourcesHttpServiceHelper: SourcesHttpServiceHelper,
                private generalHelper: GeneralHelperService) {
    }

    ngOnInit(): void {
        this.getSourceTypes();
    }

    checkIfNeedToShowErrMessage(): boolean | null {
        return this.sourceName.trim().length < 2 && this.submitted;
    }

    getSourceTypes(): void {
        this.sourcesHttpService.getSourceTypes().then((sourceTypes: Array<SourceType>) => {
            this.addingSourceTypes = sourceTypes;
            this.selectedAddingType = this.addingSourceTypes[0];
        });
    }

    getCloudIntegrations(cloudId: number): void {
        this.sourcesHttpService.getCloudRegionsAndIntegrations(cloudId).then(response => {
            this.cloudIntegrations = this.sourcesHttpServiceHelper.configureCloudIntegrationsObjects(response.cloudIntegrations, [], response.cloudType);
        });
    }

    onCloudIntegrationChanged(event: { event: any, cloudIntegration: CloudIntegration }): void {
        if (this.selectedCloudIntegration && this.selectedCloudIntegration.id === event.cloudIntegration.id) {
            event.event.preventDefault();
        } else {
            this.selectedCloudIntegration = event.cloudIntegration;
        }
    }

    changeStep(): void {
        if (this.generalHelper.checkExtensionValidity(this.expectedFileExtension)) {
            if (this.selectedAddingType?.name === AddingSourceType.email) {
                const selectedAddingType = new AddingSourceTypeEmailFirstStep();
                selectedAddingType.sourceName = this.sourceName;
                selectedAddingType.processOnlySelectedPages = this.processOnlySelectedPages;
                selectedAddingType.expectedFileExtension = this.expectedFileExtension;
                selectedAddingType.sourceTypeObj = this.selectedAddingType;
                this.typeOfAddingSourceSelected.emit(selectedAddingType);
            }
            if (this.selectedAddingType?.name === AddingSourceType.cloud) {
                const selectedAddingType = new AddingSourceTypeCloudFirstStep();
                selectedAddingType.sourceName = this.sourceName;
                selectedAddingType.processOnlySelectedPages = this.processOnlySelectedPages;
                selectedAddingType.expectedFileExtension = this.expectedFileExtension;
                selectedAddingType.sync = this.sync;
                selectedAddingType.sourceTypeObj = this.selectedAddingType;
                selectedAddingType.cloudType = this.selectedCloudType;
                selectedAddingType.frequency = this.selectedCloudFrequency;
                if (this.selectedCloudIntegration) {
                    selectedAddingType.cloudIntegration = this.selectedCloudIntegration;
                }
                this.typeOfAddingSourceSelected.emit(selectedAddingType);
            }
            if (this.selectedAddingType?.name === AddingSourceType.manual) {
                const selectedAddingType = new AddingSourceTypeManualFirstStep();
                selectedAddingType.sourceName = this.sourceName;
                selectedAddingType.processOnlySelectedPages = this.processOnlySelectedPages;
                selectedAddingType.expectedFileExtension = this.expectedFileExtension;
                selectedAddingType.sourceTypeObj = this.selectedAddingType;
                setTimeout(() => {
                    this.typeOfAddingSourceSelected.emit(selectedAddingType);
                });
            }
        } else {
            this.toasterHelperService.showWarning('Please select file extension');
        }
    }

    getCloudTypes(): void {
        this.sourcesHttpService.getCloudTypes().then((cloudTypes: Array<CloudType>) => {
            this.cloudTypes = cloudTypes;
            this.selectedCloudType = this.cloudTypes[0];
            this.getCloudIntegrations(this.selectedCloudType.id);
        });
    }

    getCloudFrequencies(): void {
        this.sourcesHttpService.getCloudFrequencies().then((cloudFrequencies: Array<CloudFrequency>) => {
            this.cloudFrequencies = cloudFrequencies;
            this.cloudFrequenciesFetched.emit(this.cloudFrequencies);
            this.selectedCloudFrequency = this.cloudFrequencies[0];
        });
    }

    selectTypeOfSourceAdding(type: SourceType): void {
        this.selectedAddingType = type;
        if (this.selectedAddingType?.name === AddingSourceType.cloud) {
            this.getCloudTypes();
            this.getCloudFrequencies();
        }
    }

    selectCloudFrequency(frequency: CloudFrequency): void {
        this.selectedCloudFrequency = frequency;
    }

    selectCloudTypeOfSourceAdding(type: CloudType): void {
        this.selectedCloudType = type;
        this.getCloudIntegrations(this.selectedCloudType.id);
    }

    returnTextForView(): FirstStepSourcesText {
        if (this.selectedAddingType) {
            return (this.selectedAddingType.name === AddingSourceType.manual) ? manualFirstStepText.manual : cloudFirstStepText[this.selectedCloudType.name];
        } else {
            return {title: '', subtitle: '', buttonText: ''};
        }
    }

    saveNewSource(): void {
        this.submitted = true;
        const selectedAddingType = new AddingSourceTypeCloudFirstStep();
        selectedAddingType.sourceName = this.sourceName;
        selectedAddingType.processOnlySelectedPages = this.processOnlySelectedPages;
        selectedAddingType.sync = this.sync;
        selectedAddingType.expectedFileExtension = this.expectedFileExtension;
        selectedAddingType.sourceTypeObj = this.selectedAddingType;
        selectedAddingType.cloudType = this.selectedCloudType;
        selectedAddingType.frequency = this.selectedCloudFrequency;
        if (this.selectedCloudIntegration) {
            selectedAddingType.cloudIntegration = this.selectedCloudIntegration;
        }
        const addSourceClass = this.sourcesHttpServiceHelper.createAndReturnSourceObject(selectedAddingType, this.useCaseId);
        if (addSourceClass && addSourceClass.sourceName.trim().length > 2) {
            this.submitted = false;
            if (addSourceClass) {
                this.sourcesHttpService.addSource(addSourceClass).then((source: Source) => {
                    this.sourceAdded.emit(source);
                });
            }
        } else {
            const addSourceForm = document.getElementById('js-add-item-modal');
            if (addSourceForm) {
                addSourceForm.scrollTop = 0;
            }
        }
    }
}
