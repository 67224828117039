<div class="bg-white w-80 h-full fixed top-0 z-20 shadow-lg hidden">
    <div class="flex border-b border-gray-200 justify-between py-6 px-6">
        <span class="text-sm text-gray-900 font-medium">Notifications</span>
        <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
        </svg>
    </div>
    <div class="flex border-b border-gray-200 justify-between py-6 px-6">
        <div class="relative flex items-start">
            <div class="flex items-center h-5">
                <input id="comments" name="comments" type="checkbox" class="focus:ring-gray-500 h-4 w-4 text-gray-600 border-gray-300 rounded cursor-pointer">
            </div>
            <div class="ml-3 text-sm">
                <label for="comments" class="font-medium text-gray-700">Successful syncs</label>
            </div>
        </div>
        <div>
            <div class="relative flex items-start">
                <div class="flex items-center h-5">
                    <input id="candidates" name="candidates" type="checkbox" class="focus:ring-gray-500 h-4 w-4 text-gray-600 border-gray-300 rounded cursor-pointer">
                </div>
                <div class="ml-3 text-sm">
                    <label for="candidates" class="font-medium text-gray-700">Failed syncs</label>
                </div>
            </div>
        </div>
    </div>

    <div>
        <ul>
            <li class="flex p-4">
                <div class="w-11 flex justify-center">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-green-500" viewBox="0 0 20 20" fill="currentColor">
                        <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd" />
                    </svg>
                </div>
                <div class="flex flex-col">
                    <p class="text-sm text-gray-500">Successful initial synchronization of Usecase_1!</p>
                    <p class="text-sm text-gray-300 mt-2">1 week ago</p>
                </div>
                <div class="w-9 flex justify-center">
                    <span class="w-2 h-2 border border-gray-300 rounded-full mt-1"></span>
                </div>
            </li>
            <li class="flex p-4 bg-gray-50">
                <div class="w-11 flex justify-center">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-green-500" viewBox="0 0 20 20" fill="currentColor">
                        <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd" />
                    </svg>
                </div>
                <div class="flex flex-col">
                    <p class="text-sm text-gray-500">Successful initial synchronization of Usecase_1!</p>
                    <p class="text-sm text-gray-300 mt-2">1 week ago</p>
                </div>
                <div class="w-9 flex justify-center">
                    <span class="w-2 h-2 border border-gray-300 rounded-full mt-1"></span>
                </div>
            </li>
            <li class="flex p-4 bg-gray-50">
                <div class="w-11 flex justify-center">

                    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-red-500" viewBox="0 0 20 20" fill="currentColor">
                        <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z" clip-rule="evenodd" />
                    </svg>
                </div>
                <div class="flex flex-col">
                    <p class="text-sm text-gray-500">Successful initial synchronization of Usecase_1!</p>
                    <p class="text-sm text-gray-300 mt-2">1 week ago</p>
                </div>
                <div class="w-9 flex justify-center">
                    <span class="w-2 h-2 border border-gray-300 rounded-full mt-1"></span>
                </div>
            </li>
        </ul>
    </div>
</div>
