export const PAGES_AND_PATHS = {
    login: {
        pageName: 'LOGIN',
        pageInRouting: 'login',
        pagePath: '/login',
        showTab: false,
        inTab: false,
        tabIcon: null,
        showSidemenu: false,
        isChild: false,
        parent: null,
        pageHaveParams: false,
        needAuthentication: false,
        pagePathWithoutParams: ['/login']
    },
    register: {
        pageName: 'REGISTER',
        pageInRouting: 'register/:key/:email',
        pagePath: '/register',
        showTab: false,
        inTab: false,
        tabIcon: null,
        showSidemenu: false,
        isChild: false,
        parent: null,
        pageHaveParams: true,
        needAuthentication: false,
        pagePathWithoutParams: ['/register']
    },
    sources: {
        pageName: 'SOURCES',
        pageInRouting: 'sources',
        pagePath: '/sources',
        showTab: true,
        inTab: true,
        tabIcon: null,
        showSidemenu: true,
        isChild: false,
        parent: null,
        pageHaveParams: false,
        needAuthentication: true,
        pagePathWithoutParams: ['/sources']
    },
    integrations: {
        pageName: 'INTEGRATIONS',
        pageInRouting: 'integrations',
        pagePath: '/integrations',
        showTab: true,
        inTab: true,
        tabIcon: null,
        showSidemenu: true,
        isChild: false,
        parent: null,
        pageHaveParams: false,
        needAuthentication: true,
        pagePathWithoutParams: ['/integrations']
    },
    userProfile: {
        pageName: 'USER_PROFILE',
        pageInRouting: 'user-profile',
        pagePath: '/user-profile',
        showTab: true,
        inTab: true,
        tabIcon: null,
        showSidemenu: true,
        isChild: false,
        parent: null,
        pageHaveParams: false,
        needAuthentication: true,
        pagePathWithoutParams: ['/user-profile']
    },
    testPage: {
        pageName: 'TEST_PAGE',
        pageInRouting: 'test-page',
        pagePath: '/test-page',
        showTab: true,
        inTab: true,
        tabIcon: null,
        showSidemenu: true,
        isChild: false,
        parent: null,
        pageHaveParams: false,
        needAuthentication: true,
        pagePathWithoutParams: ['/test-page']
    },
    useCaseSingle: {
        pageName: 'USE_CASES_SINGLE',
        pageInRouting: 'use-cases/:id',
        pagePath: '/use-cases/',
        showTab: true,
        inTab: true,
        tabIcon: null,
        showSidemenu: true,
        isChild: false,
        parent: null,
        pageHaveParams: false,
        needAuthentication: true,
        pagePathWithoutParams: ['/use-cases']
    },
    useCases: {
        pageName: 'USE_CASES',
        pageInRouting: 'use-cases',
        pagePath: '/use-cases',
        showTab: true,
        inTab: true,
        tabIcon: null,
        showSidemenu: true,
        isChild: false,
        parent: null,
        pageHaveParams: false,
        needAuthentication: true,
        pagePathWithoutParams: ['/use-cases']
    },
    labels: {
        pageName: 'LABELS',
        pageInRouting: 'labels',
        pagePath: '/labels',
        showTab: true,
        inTab: true,
        tabIcon: null,
        showSidemenu: true,
        isChild: false,
        parent: null,
        pageHaveParams: false,
        needAuthentication: true,
        pagePathWithoutParams: ['/labels']
    },
    destinations: {
        pageName: 'DESTINATIONS',
        pageInRouting: 'destinations',
        pagePath: '/destinations',
        showTab: true,
        inTab: true,
        tabIcon: null,
        showSidemenu: true,
        isChild: false,
        parent: null,
        pageHaveParams: false,
        needAuthentication: true,
        pagePathWithoutParams: ['/destinations']
    },
    manage: {
        pageName: 'MANAGE',
        pageInRouting: 'manage',
        pagePath: '/manage',
        showTab: true,
        inTab: true,
        tabIcon: null,
        showSidemenu: true,
        isChild: false,
        parent: null,
        pageHaveParams: false,
        needAuthentication: true,
        pagePathWithoutParams: ['/manage']
    },
    forgotPassword: {
        pageName: 'FORGOT-PASSWORD',
        pageInRouting: 'forgot-password',
        pagePath: '/forgot-password',
        showTab: false,
        inTab: false,
        tabIcon: null,
        showSidemenu: false,
        isChild: false,
        parent: null,
        pageHaveParams: false,
        needAuthentication: false,
        pagePathWithoutParams: ['/forgot-password']
    },
    resetPassword: {
        pageName: 'RESET-PASSWORD',
        pageInRouting: 'reset/password/:email/:token',
        pagePath: '/reset/password',
        showTab: false,
        inTab: false,
        tabIcon: null,
        showSidemenu: false,
        isChild: false,
        parent: null,
        pageHaveParams: true,
        needAuthentication: false,
        pagePathWithoutParams: ['/reset/password']
    },
};

export const DEFAULT_AUTHENTICATED_PAGE = {
    page: PAGES_AND_PATHS.userProfile
};
