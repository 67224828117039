import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import {
    CreateQuery,
    GetUseCasesPaginationResponse,
    GetUseCasesResponse, Labels,
    NewUseCaseReqObj, UpdateAliasesReq,
    UseCase, UseCaseHistorySearch,
    UseCaseType
} from '../../interfaces/use-case-interfaces';
import { FormGroup } from '@angular/forms';
import { AuthenticationHelperService } from '../helpers/authentication-helper.service';
import { UseCasesCategories } from '../../enums/use-cases-enums';
import { ToasterHelperService } from '../helpers/toaster-helper.service';
import { GeneralHttpHelperService } from './general-http-helper.service';
import { PpgPagination } from '../../interfaces/general-interfaces';
import {
    Column,
    DataBody, QueryBody,
    Row,
    UseCaseData, UseCaseQuery,
} from '../../interfaces/use-case-data-interfaces';
import {SearchAndFilterCloudsReqObj, SearchAndFilterSourcesReqObj} from 'src/app/interfaces/sources-interfaces';

@Injectable({
    providedIn: 'root'
})
export class UseCasesHttpService {

    constructor(private httpClient: HttpClient,
                private useCasesHelper: UseCasesHttpServiceHelper,
                private toasterHelperService: ToasterHelperService,
                private generalHttpHelperService: GeneralHttpHelperService,
                private authHelperService: AuthenticationHelperService) {
    }

    private createUseCaseReq(reqBody: NewUseCaseReqObj): Observable<any> {
        const headers = new HttpHeaders({
            'route-key': environment.API_ENDPOINT_MS_UC_ROUTE_KEY,
            'payload-key': environment.API_ENDPOINT_MS_UC_PAYLOAD_KEY,
        });

        return this.httpClient.post<any>(environment.API_ENDPOINT_MS_UC + 'api/doc-manipulation/use-cases', reqBody, {headers});
    }

    private getUseCasesReq(useCaseCategory: UseCasesCategories): Observable<any> {
        const headers = new HttpHeaders({
            'route-key': environment.API_ENDPOINT_MS_UC_ROUTE_KEY,
            'payload-key': environment.API_ENDPOINT_MS_UC_PAYLOAD_KEY,
        });

        return this.httpClient.get<any>(environment.API_ENDPOINT_MS_UC + 'api/doc-manipulation/' + useCaseCategory + '/use-cases' + '/' + this.authHelperService.pagination, {headers});
    }

    private getUseCaseTypesReq(): Observable<any> {
        const headers = new HttpHeaders({
            'route-key': environment.API_ENDPOINT_MS_UC_ROUTE_KEY,
            'payload-key': environment.API_ENDPOINT_MS_UC_PAYLOAD_KEY,
        });

        return this.httpClient.get<any>(environment.API_ENDPOINT_MS_UC + 'api/doc-manipulation/use-case-types', {headers});
    }

    private getUseCasesPaginationReq(pageNumber: number, useCaseCategory: UseCasesCategories): Observable<any> {
        const headers = new HttpHeaders({
            'route-key': environment.API_ENDPOINT_MS_UC_ROUTE_KEY,
            'payload-key': environment.API_ENDPOINT_MS_UC_PAYLOAD_KEY,
        });

        return this.httpClient.get<any>(environment.API_ENDPOINT_MS_UC + 'api/doc-manipulation/paginate/' + useCaseCategory + '/use-cases/' + this.authHelperService.pagination + '?page=' + pageNumber, {headers});
    }

    private toggleUseCasePinReq(useCase: UseCase, useCasesCategory: UseCasesCategories): Observable<any> {
        const useCaseCategoryTemp = (useCasesCategory === UseCasesCategories.all) ? 'company' : 'personal';
        const headers = new HttpHeaders({
            'route-key': environment.API_ENDPOINT_MS_UC_ROUTE_KEY,
            'payload-key': environment.API_ENDPOINT_MS_UC_PAYLOAD_KEY,
        });

        return this.httpClient.get<any>(environment.API_ENDPOINT_MS_UC + 'api/doc-manipulation/pin/' + useCaseCategoryTemp + '/use-cases/' + useCase.id, {headers});
    }

    private searchUseCasesReq(searchQuery: string, pageNumber: number | null = null): Observable<any> {
        const headers = new HttpHeaders({
            'route-key': environment.API_ENDPOINT_MS_UC_ROUTE_KEY,
            'payload-key': environment.API_ENDPOINT_MS_UC_PAYLOAD_KEY,
        });

        return (pageNumber) ?
            this.httpClient.post<any>(environment.API_ENDPOINT_MS_UC + 'api/search/use-cases?query=' + searchQuery + '&page=' + pageNumber, {
                pagination: this.authHelperService.pagination
            }, {headers}) :
            this.httpClient.post<any>(environment.API_ENDPOINT_MS_UC + 'api/search/use-cases', {
                query: searchQuery,
                pagination: this.authHelperService.pagination
            }, {headers});
    }

    private searchUseCaseDocumentsReq(reqObj: SearchAndFilterSourcesReqObj | null, useCaseId: number, pageNumber: number | null = null): Observable<any> {
        const headers = new HttpHeaders({
            'route-key': environment.API_ENDPOINT_MS_UC_ROUTE_KEY,
            'payload-key': environment.API_ENDPOINT_MS_UC_PAYLOAD_KEY,
        });

        return (pageNumber) ?
            this.httpClient.post<any>(environment.API_ENDPOINT_MS_UC + 'api/search/documents?query=' + reqObj?.query + '&page=' + pageNumber, {
                pagination: this.authHelperService.pagination
            }, {headers}) :
            this.httpClient.post<any>(environment.API_ENDPOINT_MS_UC + 'api/search/documents', {
                query: reqObj?.query,
                pagination: this.authHelperService.pagination
            }, {headers});
    }

    private getSingleUseCaseReq(useCaseId: number): Observable<any> {
        const headers = new HttpHeaders({
            'route-key': environment.API_ENDPOINT_MS_UC_ROUTE_KEY,
            'payload-key': environment.API_ENDPOINT_MS_UC_PAYLOAD_KEY,
        });

        return this.httpClient.get<any>(environment.API_ENDPOINT_MS_UC + 'api/doc-manipulation/use-cases/' + useCaseId, {headers});
    }

    private getUseCaseHistorySearchReq(): Observable<any> {
        const headers = new HttpHeaders({
            'route-key': environment.API_ENDPOINT_MS_UC_ROUTE_KEY,
            'payload-key': environment.API_ENDPOINT_MS_UC_PAYLOAD_KEY,
        });

        return this.httpClient.get<any>(environment.API_ENDPOINT_MS_UC + 'api/use-cases/history', {headers});
    }

    private paginateSearchUseCasesReq(nextPageUrl: string): Observable<any> {
        const headers = new HttpHeaders({
            'route-key': environment.API_ENDPOINT_MS_UC_ROUTE_KEY,
            'payload-key': environment.API_ENDPOINT_MS_UC_PAYLOAD_KEY,
        });

        return this.httpClient.post<any>(nextPageUrl, { pagination: 10 }, {headers});
    }

    private editUseCaseReq(reqBody: { name: string }, useCaseId: number | undefined): Observable<any> {
        const headers = new HttpHeaders({
            'route-key': environment.API_ENDPOINT_MS_UC_ROUTE_KEY,
            'payload-key': environment.API_ENDPOINT_MS_UC_PAYLOAD_KEY,
        });

        return this.httpClient.put<any>(environment.API_ENDPOINT_MS_UC + 'api/doc-manipulation/use-cases/' + useCaseId, reqBody, {headers});
    }

    private deleteUseCaseReq(useCase: UseCase): Observable<any> {
        const headers = new HttpHeaders({
            'route-key': environment.API_ENDPOINT_MS_UC_ROUTE_KEY,
            'payload-key': environment.API_ENDPOINT_MS_UC_PAYLOAD_KEY,
        });

        return this.httpClient.delete<any>(environment.API_ENDPOINT_MS_UC + 'api/doc-manipulation/use-cases/' + useCase.id, {headers});
    }

    getUseCaseDocuments(useCaseId: number): Observable<any> {
        const headers = new HttpHeaders({
            'route-key': environment.API_ENDPOINT_MS_UC_ROUTE_KEY,
            'payload-key': environment.API_ENDPOINT_MS_UC_PAYLOAD_KEY,
        });

        return this.httpClient.get<any>(environment.API_ENDPOINT_MS_UC + 'api/data-manipulation/documents/use-cases/' + useCaseId, {headers});
    }

    searchUseCaseDocuments(reqObj: SearchAndFilterSourcesReqObj | null, useCaseId: number, pageNumber: number | null = null): any {
        return new Promise<{ pagination: PpgPagination, useCases: Array<UseCase> }>((resolve: any) => {
            this.searchUseCaseDocumentsReq(reqObj, useCaseId, pageNumber).subscribe(response => {
                const pagination = this.generalHttpHelperService.returnPagination(response);
                resolve({
                    documents: response.data.hits,
                    pagination
                });
            }, (error: any) => {
            });
        });
    }


    deleteUseCase(useCase: UseCase): Promise<UseCase> {
        return new Promise<UseCase>((resolve: any, reject: any) => {
            this.deleteUseCaseReq(useCase).subscribe(response => {
                resolve(useCase);
                this.toasterHelperService.showSuccess('Successfully deleted use case');
            }, error => {
                this.toasterHelperService.showError('Can\'t delete this use case, try again later');
                reject(null);
            });
        });
    }

    editUseCase(useCase: UseCase, formGroup: FormGroup, useCaseId: number | undefined): Promise<UseCase> {
        return new Promise<UseCase>((resolve: any) => {
            this.editUseCaseReq({ name: formGroup.value.useCaseName }, useCaseId).subscribe(response => {
                this.toasterHelperService.showSuccess('Successfully edited use case');
                useCase.name = formGroup.value.useCaseName;
                resolve(this.useCasesHelper.returnNewCreatedUseCase(useCase));
            }, (error: any) => {
                resolve();
            });
        });
    }

    paginateSearchUseCases(nextPageUrl: string): Promise<{ pagination: PpgPagination, useCases: Array<UseCase> }> {
        return new Promise<{ pagination: PpgPagination, useCases: Array<UseCase> }>((resolve: any) => {
            this.paginateSearchUseCasesReq(nextPageUrl).subscribe(response => {
                const pagination = this.generalHttpHelperService.returnPagination(response);
                resolve({
                    useCases: response.data.hits,
                    pagination
                });
            }, (error: any) => {
                resolve({ allUseCases: [], pinnedUseCases: [] });
            });
        });
    }

    getUseCaseHistorySearch(): Promise<Array<UseCaseHistorySearch>> {
        return new Promise<Array<UseCaseHistorySearch>>((resolve: any, reject: any) => {
            this.getUseCaseHistorySearchReq().subscribe((response: any) => {
                resolve(response.entity);
            }, error => {
                reject(null);
            });
        });
    }

    getSingleUseCase(useCaseId: number): Promise<UseCase> {
        return new Promise<UseCase>((resolve: any, reject: any) => {
            this.getSingleUseCaseReq(useCaseId).subscribe((response: any) => {
                resolve(response.entity);
            }, error => {
                reject(null);
            });
        });
    }

    searchUseCases(searchQuery: string, pageNumber: number | null = null): Promise<{ pagination: PpgPagination, useCases: Array<UseCase> }> {
        return new Promise<{ pagination: PpgPagination, useCases: Array<UseCase> }>((resolve: any) => {
            this.searchUseCasesReq(searchQuery, pageNumber).subscribe(response => {
                const pagination = this.generalHttpHelperService.returnPagination(response);
                resolve({
                    useCases: response.data.hits,
                    pagination
                });
            }, (error: any) => {
                resolve({ allUseCases: [], pinnedUseCases: [] });
            });
        });
    }

    toggleUseCasePin(useCase: UseCase, isPinned: boolean, useCasesCategory: UseCasesCategories): Promise<UseCase> {
        return new Promise<UseCase>((resolve: any, reject: any) => {
            this.toggleUseCasePinReq(useCase, useCasesCategory).subscribe((response: any) => {
                resolve(useCase);
                (isPinned) ?
                    this.toasterHelperService.showSuccess('Successfully unpinned use case') :
                    this.toasterHelperService.showSuccess('Successfully pinned use case');
            }, error => {
                this.toasterHelperService.showSuccess('Can\'t pin this use case, try again later');
                reject(null);
            });
        });
    }

    getUseCasesPagination(pageNumber: number, useCaseCategory: UseCasesCategories): Promise<GetUseCasesPaginationResponse> {
        return new Promise<GetUseCasesPaginationResponse>((resolve: any) => {
            this.getUseCasesPaginationReq(pageNumber, useCaseCategory).subscribe(response => {
                const pagination = this.generalHttpHelperService.returnPagination(response.entity);
                resolve({
                    allUseCases: { data: response.entity.data, pagination }
                });
            }, (error: any) => {
                resolve({ allUseCases: [], pinnedUseCases: [] });
            });
        });
    }

    getUseCases(useCaseCategory: UseCasesCategories): Promise<GetUseCasesResponse> {
        return new Promise<GetUseCasesResponse>((resolve: any) => {
            this.getUseCasesReq(useCaseCategory).subscribe(response => {
                const pagination = this.generalHttpHelperService.returnPagination(response.entity.use_cases);
                resolve({
                    allUseCases: { data: response.entity.use_cases.data, pagination },
                    pinnedUseCases: response.entity.pinned
                });
            }, (error: any) => {
                resolve({ allUseCases: [], pinnedUseCases: [] });
            });
        });
    }

    getUseCaseTypes(): Promise<Array<UseCaseType>> {
        return new Promise<Array<UseCaseType>>((resolve: any) => {
            this.getUseCaseTypesReq().subscribe(response => {
                resolve(response.entity);
            }, (error: any) => {
                resolve([]);
            });
        });
    }

    createUseCase(formGroup: FormGroup, types: Array<UseCaseType>): Promise<UseCase> {
        return new Promise<UseCase>((resolve: any) => {
            this.createUseCaseReq(this.useCasesHelper.returnRequestBodyFromCreateUseCaseForm(formGroup, types)).subscribe(response => {
                this.toasterHelperService.showSuccess('Successfully created use case');
                resolve(this.useCasesHelper.returnNewCreatedUseCase(response.entity));
            }, (error: any) => {
                resolve();
            });
        });
    }

    getLabels(pageNumber: number): Promise<{ entity: Array <Labels> }> {
        return new Promise<{ entity: Array <Labels> }>((resolve: any, reject: any) => {
            this.getLabelsReq(pageNumber).subscribe((response: any) => {
                const pagination = this.generalHttpHelperService.returnPagination(response.entity);
                resolve({
                    labels: response.entity.data,
                    pagination
                });
            }, error => {
                reject(null);
            });
        });
    }

    private getLabelsReq(pageNumber: number): Observable<any> {
        const headers = new HttpHeaders({
            'route-key': environment.API_ENDPOINT_MS_UC_ROUTE_KEY,
            'payload-key': environment.API_ENDPOINT_MS_UC_PAYLOAD_KEY,
        });

        return this.httpClient.get<any>(environment.API_ENDPOINT_MS_UC + 'api/parser/labels?page=' + pageNumber, {headers});
    }

    getLabelsAliases(reqBody: {aliases_ids: Array<number>}): Promise<Labels> {
        return new Promise<Labels>((resolve: any, reject: any) => {
            this.getLabelsAliasesReq(reqBody).subscribe((response: any) => {
                resolve(response.entity);
            }, error => {
                reject(null);
            });
        });
    }

    private getLabelsAliasesReq(reqBody: {aliases_ids: Array<number>}): Observable<any> {
        const headers = new HttpHeaders({
            'route-key': environment.API_ENDPOINT_MS_UC_ROUTE_KEY,
            'payload-key': environment.API_ENDPOINT_MS_UC_PAYLOAD_KEY,
        });

        return this.httpClient.post<any>(environment.API_ENDPOINT_MS_UC + 'api/parser/labels/aliases', reqBody, {headers});
    }

    storeLabel(reqBody: {label: string; category: string | null; create_aliases: Array<string>}): Promise<void> {
        return new Promise<void>((resolve: any, reject: any) => {
            this.storeLabelReq(reqBody).subscribe((response: any) => {
                resolve(response.entity);
                this.toasterHelperService.showSuccess('Successfully created label');
            }, error => {
                reject(error);
            });
        });
    }

    private storeLabelReq(reqBody: {label: string; category: string | null; create_aliases: Array<string>}): Observable<any> {
        const headers = new HttpHeaders({
            'route-key': environment.API_ENDPOINT_MS_UC_ROUTE_KEY,
            'payload-key': environment.API_ENDPOINT_MS_UC_PAYLOAD_KEY,
        });

        return this.httpClient.put<any>(environment.API_ENDPOINT_MS_UC + 'api/parser/labels', reqBody, {headers});
    }

    updateLabel(labelId: number, reqBody: {label: string; category: string | null; create_aliases: Array<string>; update_aliases: Array<UpdateAliasesReq>; delete_aliases: Array<number>}): Promise<void> {
        return new Promise<void>((resolve: any, reject: any) => {
            this.updateLabelReq(labelId, reqBody).subscribe((response: any) => {
                resolve(response.entity);
                this.toasterHelperService.showSuccess('Successfully updated label');
            }, error => {
                reject(error);
            });
        });
    }

    private updateLabelReq(labelId: number, reqBody: {label: string; category: string | null; create_aliases: Array<string>; update_aliases: Array<UpdateAliasesReq>; delete_aliases: Array<number>}): Observable<any> {
        const headers = new HttpHeaders({
            'route-key': environment.API_ENDPOINT_MS_UC_ROUTE_KEY,
            'payload-key': environment.API_ENDPOINT_MS_UC_PAYLOAD_KEY,
        });

        return this.httpClient.post<any>(environment.API_ENDPOINT_MS_UC + 'api/parser/labels/' + labelId, reqBody, {headers});
    }

    searchLabels(searchAndFilter: SearchAndFilterCloudsReqObj, pageNumber: number | null): Promise<Labels> {
        return new Promise<Labels>((resolve: any, reject: any) => {
            this.searchLabelsReq(searchAndFilter, pageNumber).subscribe((response: any) => {
                const pagination = this.generalHttpHelperService.returnPagination(response.entity);
                resolve({
                    labels: response.entity.data,
                    pagination
                });
            }, error => {
                reject(null);
            });
        });
    }

    private searchLabelsReq(searchAndFilter: SearchAndFilterCloudsReqObj, pageNumber: number | null): Observable<any> {
        const headers = new HttpHeaders({
            'route-key': environment.API_ENDPOINT_MS_UC_ROUTE_KEY,
            'payload-key': environment.API_ENDPOINT_MS_UC_PAYLOAD_KEY,
        });

        return this.httpClient.post<any>(environment.API_ENDPOINT_MS_UC + 'api/parser/labels/search?page=' + pageNumber, searchAndFilter, {headers});
    }

    searchLabelsByCategory(reqBody: {category: string}): Promise<{ entity: Array <Labels> }> {
        return new Promise<{ entity: Array <Labels> }>((resolve: any, reject: any) => {
            this.searchLabelsByCategoryReq(reqBody).subscribe((response: any) => {
                resolve(response);
            }, error => {
                reject(error);
            });
        });
    }

    private searchLabelsByCategoryReq(reqBody: {category: string}): Observable<any> {
        const headers = new HttpHeaders({
            'route-key': environment.API_ENDPOINT_MS_UC_ROUTE_KEY,
            'payload-key': environment.API_ENDPOINT_MS_UC_PAYLOAD_KEY,
        });

        return this.httpClient.post<any>(environment.API_ENDPOINT_MS_UC + 'api/parser/labels/search-by-category', reqBody, {headers});
    }

    createQuery(reqBody: CreateQuery): Promise<void> {
        return new Promise<void>((resolve: any, reject: any) => {
            this.createQueryReq(reqBody).subscribe((response: any) => {
                resolve(response);
            }, error => {
                reject(error);
            });
        });
    }

    private createQueryReq(reqBody: CreateQuery): Observable<any> {
        const headers = new HttpHeaders({
            'route-key': environment.API_ENDPOINT_MS_UC_ROUTE_KEY,
            'payload-key': environment.API_ENDPOINT_MS_UC_PAYLOAD_KEY,
        });

        return this.httpClient.post<any>(environment.API_ENDPOINT_MS_UC + 'api/doc-manipulation/create-query', reqBody, {headers});
    }
}

@Injectable({
    providedIn: 'root'
})
export class UseCasesHttpServiceHelper {

    constructor(private authHelperService: AuthenticationHelperService) {
    }

    returnParsedUseCaseDataBodies(useCaseData: Array<UseCaseData>): Array<UseCaseData> {
        useCaseData.map((useCaseSingleData: UseCaseData) => {
            useCaseSingleData.body = JSON.parse(useCaseSingleData.body as any);
            useCaseSingleData.body.forEach((body: DataBody) => {
                body.labels = [];
                body.standardizeLabels = [];
                body.categories = [];
                if (body.negative.negative_column) {
                    body.negative.negative_column.forEach((negativeColumn: Column) => {
                        if (negativeColumn.hasOwnProperty('label')) {
                            body.standardizeLabels.push({
                                label: negativeColumn.label,
                                label_id: negativeColumn.label_id,
                                initLabel: negativeColumn.raw_value,
                                label_alias_id: negativeColumn.label_alias_id,
                                label_name: negativeColumn.label_name,
                                label_alias_name: negativeColumn.label_alias_name,
                                label_category_name: negativeColumn.label_category_name,
                            });
                        }

                        if (negativeColumn.label_category_name !== null) {
                            body.categories?.push(negativeColumn.label_category_name);
                        }

                        body.labels.push(negativeColumn.raw_value);
                    });
                }
                if (body.negative.negative_row) {
                    body.negative.negative_row.forEach((negativeRow: Row) => {
                        if (negativeRow.hasOwnProperty('label')) {
                            body.standardizeLabels.push({
                                label: negativeRow.label,
                                label_id: negativeRow.label_id,
                                initLabel: negativeRow.raw_value,
                                label_alias_id: negativeRow.label_alias_id,
                                label_name: negativeRow.label_name,
                                label_alias_name: negativeRow.label_alias_name,
                                label_category_name: negativeRow.label_category_name,
                            });
                        }

                        if (negativeRow.label_category_name !== null) {
                            body.categories?.push(negativeRow.label_category_name);
                        }

                        body.labels.push(negativeRow.raw_value);
                    });
                }
                if (body.positive.positive_column) {
                    body.positive.positive_column.forEach((positiveColumn: Column) => {
                        if (positiveColumn.hasOwnProperty('label')) {
                            body.standardizeLabels.push({
                                label: positiveColumn.label,
                                label_id: positiveColumn.label_id,
                                initLabel: positiveColumn.raw_value,
                                label_alias_id: positiveColumn.label_alias_id,
                                label_name: positiveColumn.label_name,
                                label_alias_name: positiveColumn.label_alias_name,
                                label_category_name: positiveColumn.label_category_name,
                            });
                        }

                        if (positiveColumn.label_category_name !== null) {
                            body.categories?.push(positiveColumn.label_category_name);
                        }

                        body.labels.push(positiveColumn.raw_value);
                    });
                }
                if (body.positive.positive_row) {
                    body.positive.positive_row.forEach((positiveRow: Row) => {
                        if (positiveRow.hasOwnProperty('label')) {
                            body.standardizeLabels.push({
                                label: positiveRow.label,
                                label_id: positiveRow.label_id,
                                initLabel: positiveRow.raw_value,
                                label_alias_id: positiveRow.label_alias_id,
                                label_name: positiveRow.label_name,
                                label_alias_name: positiveRow.label_alias_name,
                                label_category_name: positiveRow.label_category_name,
                            });
                        }

                        if (positiveRow.label_category_name !== null) {
                            body.categories?.push(positiveRow.label_category_name);
                        }

                        body.labels.push(positiveRow.raw_value);
                    });
                }

                if (body.categories?.length) {
                    body.firstCategory = body.categories[0];
                }
            });
        });

        return useCaseData;
    }

    returnParsedUseCaseQueryBodies(useCaseData: Array<UseCaseQuery>): Array<UseCaseQuery> {
        useCaseData.map((useCaseSingleData: UseCaseQuery) => {
            useCaseSingleData.bodies.forEach((body: QueryBody) => {
                body.body = JSON.parse(body.body as any).body;

                body.labels = [];
                body.standardizeLabels = [];
                body.categories = [];
                if (body.body.negative.negative_column) {
                    body.body.negative.negative_column.forEach((negativeColumn: Column) => {
                        if (negativeColumn.hasOwnProperty('label')) {
                            body.standardizeLabels.push({
                                label: negativeColumn.label,
                                label_id: negativeColumn.label_id,
                                initLabel: negativeColumn.raw_value,
                                label_alias_id: negativeColumn.label_alias_id,
                                label_name: negativeColumn.label_name,
                                label_alias_name: negativeColumn.label_alias_name,
                                label_category_name: negativeColumn.label_category_name,
                            });
                        }

                        if (negativeColumn.label_category_name !== null) {
                            body.categories?.push(negativeColumn.label_category_name);
                        }

                        body.labels.push(negativeColumn.raw_value);
                    });
                }
                if (body.body.negative.negative_row) {
                    body.body.negative.negative_row.forEach((negativeRow: Row) => {
                        if (negativeRow.hasOwnProperty('label')) {
                            body.standardizeLabels.push({
                                label: negativeRow.label,
                                label_id: negativeRow.label_id,
                                initLabel: negativeRow.raw_value,
                                label_alias_id: negativeRow.label_alias_id,
                                label_name: negativeRow.label_name,
                                label_alias_name: negativeRow.label_alias_name,
                                label_category_name: negativeRow.label_category_name,
                            });
                        }

                        if (negativeRow.label_category_name !== null) {
                            body.categories?.push(negativeRow.label_category_name);
                        }

                        body.labels.push(negativeRow.raw_value);
                    });
                }
                if (body.body.positive.positive_column) {
                    body.body.positive.positive_column.forEach((positiveColumn: Column) => {
                        if (positiveColumn.hasOwnProperty('label')) {
                            body.standardizeLabels.push({
                                label: positiveColumn.label,
                                label_id: positiveColumn.label_id,
                                initLabel: positiveColumn.raw_value,
                                label_alias_id: positiveColumn.label_alias_id,
                                label_name: positiveColumn.label_name,
                                label_alias_name: positiveColumn.label_alias_name,
                                label_category_name: positiveColumn.label_category_name,
                            });
                        }

                        if (positiveColumn.label_category_name !== null) {
                            body.categories?.push(positiveColumn.label_category_name);
                        }

                        body.labels.push(positiveColumn.raw_value);
                    });
                }
                if (body.body.positive.positive_row) {
                    body.body.positive.positive_row.forEach((positiveRow: Row) => {
                        if (positiveRow.hasOwnProperty('label')) {
                            body.standardizeLabels.push({
                                label: positiveRow.label,
                                label_id: positiveRow.label_id,
                                initLabel: positiveRow.raw_value,
                                label_alias_id: positiveRow.label_alias_id,
                                label_name: positiveRow.label_name,
                                label_alias_name: positiveRow.label_alias_name,
                                label_category_name: positiveRow.label_category_name,
                            });
                        }

                        if (positiveRow.label_category_name !== null) {
                            body.categories?.push(positiveRow.label_category_name);
                        }

                        body.labels.push(positiveRow.raw_value);
                    });
                }

                if (body.categories?.length) {
                    body.firstCategory = body.categories[0];
                }
            });
        });

        return useCaseData;
    }

    returnNewCreatedUseCase(useCase: any): UseCase {
        return {
            name: useCase.name,
            sources_count: 0,
            destinations_count: 0,
            created_at: useCase.created_at,
            id: useCase.id
        };
    }

    returnRequestBodyFromCreateUseCaseForm(formGroup: FormGroup, types: Array<UseCaseType>): NewUseCaseReqObj {
        const reqObj: NewUseCaseReqObj = {
            name: formGroup.controls.useCaseName.value,
            use_case_types: [],
            company_id: this.authHelperService.getCompanyId
        };
        types.forEach((type: UseCaseType) => {
            const useCaseTypeId = formGroup.controls[type.name].value ? type.id : null;
            if (useCaseTypeId) {
                reqObj.use_case_types.push(useCaseTypeId);
            }
        });
        return reqObj;
    }

}
