import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {LoginRoutingModule} from './pages/login/login-routing.module';
import {UserProfileRoutingModule} from './pages/user-profile/user-profile-routing.module';
import {PAGES_AND_PATHS} from './constants/pages-and-paths';
import {SourceRoutingModule} from './pages/source/source-routing.module';
import {ManageRoutingModule} from './pages/manage/manage-routing.module';
import {UseCasesRoutingModule} from './pages/use-cases/use-cases-routing.module';
import {LabelsRoutingModule} from './pages/labels/labels-routing.module';
import {RegisterRoutingModule} from './pages/register/register-routing.module';
import {DestinationsRoutingModule} from './pages/destinations/destinations-routing.module';
import {TestPageRoutingModule} from './pages/test-page/test-page-routing.module';
import {IntegrationsRoutingModule} from './pages/integrations/integrations-routing.module';
import {UseCaseRoutingModule} from './pages/use-case-single/use-case-routing.module';
import {ResetPasswordRoutingModule} from './pages/reset-password/reset-password-routing.module';

const routes: Routes = [
    {
        path: '',
        pathMatch: 'full',
        redirectTo: PAGES_AND_PATHS.login.pagePath
    },
    // { path: 'register', loadChildren: () => import('./pages/register/register.module').then(m => m.RegisterModule) },
    // { path: 'sources', loadChildren: () => import('./pages/source/source.module').then(m => m.SourceModule) },
    // { path: 'use-cases-single', loadChildren: () => import('./pages/use-cases-single/use-cases-single.module').then(m => m.UseCasesModule) },
    // { path: 'manage', loadChildren: () => import('./pages/manage/manage.module').then(m => m.ManageModule) },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes),
        LoginRoutingModule,
        UserProfileRoutingModule,
        SourceRoutingModule,
        ManageRoutingModule,
        UseCasesRoutingModule,
        LabelsRoutingModule,
        RegisterRoutingModule,
        DestinationsRoutingModule,
        IntegrationsRoutingModule,
        TestPageRoutingModule,
        UseCaseRoutingModule,
        ResetPasswordRoutingModule
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
