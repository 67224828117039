import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {
    DataDocumentResponse,
    DocumentImagesResponse, SearchDataDocumentReq,
    SearchTypeParams,
    UseCaseDataResponse, UseCaseQueryResponse
} from '../../interfaces/use-case-data-interfaces';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {AuthenticationHelperService} from '../helpers/authentication-helper.service';
import {UseCasesHttpServiceHelper} from './use-cases-http.service';
import {GeneralHttpHelperService} from './general-http-helper.service';

@Injectable({
    providedIn: 'root'
})
export class UseCaseDataHttpService {

    constructor(private httpClient: HttpClient,
                private useCaseDataHttpServiceHelper: UseCaseDataHttpServiceHelper,
                private generalHttpHelperService: GeneralHttpHelperService,
                private authHelperService: AuthenticationHelperService,
                private useCasesHelper: UseCasesHttpServiceHelper) {
    }

    private searchUseCaseDataReq(searchQuery: string, pageNumber: number | null = null, searchTypeParams: SearchTypeParams): Observable<any> {
        const headers = new HttpHeaders({
            'route-key': environment.API_ENDPOINT_MS_UC_ROUTE_KEY,
            'payload-key': environment.API_ENDPOINT_MS_UC_PAYLOAD_KEY,
        });

        return (pageNumber) ?
            this.httpClient.post<any>(environment.API_ENDPOINT_MS_UC + 'api/search/data?query=' + searchQuery + '&page=' + pageNumber, {
                pagination: this.authHelperService.pagination,
                field: searchTypeParams.searchDataType,
                field_value: searchTypeParams.searchDataValue,
            }, {headers}) :
            this.httpClient.post<any>(environment.API_ENDPOINT_MS_UC + 'api/search/data', {
                query: searchQuery,
                pagination: this.authHelperService.pagination,
                field: searchTypeParams.searchDataType,
                field_value: searchTypeParams.searchDataValue,
            }, {headers});
    }

    private searchUseCaseQueryReq(searchQuery: string, pageNumber: number | null = null, searchTypeParams: SearchTypeParams): Observable<any> {
        const headers = new HttpHeaders({
            'route-key': environment.API_ENDPOINT_MS_UC_ROUTE_KEY,
            'payload-key': environment.API_ENDPOINT_MS_UC_PAYLOAD_KEY,
        });

        return (pageNumber) ?
            this.httpClient.post<any>(environment.API_ENDPOINT_MS_UC + 'api/search/data-query?query=' + searchQuery + '&page=' + pageNumber, {
                pagination: this.authHelperService.pagination,
                field: searchTypeParams.searchDataType,
                field_value: searchTypeParams.searchDataValue,
            }, {headers}) :
            this.httpClient.post<any>(environment.API_ENDPOINT_MS_UC + 'api/search/data-query', {
                query: searchQuery,
                pagination: this.authHelperService.pagination,
                field: searchTypeParams.searchDataType,
                field_value: searchTypeParams.searchDataValue,
            }, {headers});
    }

    private searchUseCaseDataDocumentsReq(searchDataDocumentReq: SearchDataDocumentReq, pageNumber: number | null = null, useCaseId: number): Observable<any> {
        const headers = new HttpHeaders({
            'route-key': environment.API_ENDPOINT_MS_UC_ROUTE_KEY,
            'payload-key': environment.API_ENDPOINT_MS_UC_PAYLOAD_KEY,
        });

        return (pageNumber) ?
            this.httpClient.post<any>(environment.API_ENDPOINT_MS_UC + 'api/search/documents?query=' + searchDataDocumentReq.query + '&page=' + pageNumber, {
                pagination: this.authHelperService.pagination,
                use_case_id: useCaseId,
                source_id: searchDataDocumentReq.source_id,
                sync_source_id: searchDataDocumentReq.sync_source_id
            }, {headers}) :
            this.httpClient.post<any>(environment.API_ENDPOINT_MS_UC + 'api/search/documents', {
                query: searchDataDocumentReq.query,
                pagination: this.authHelperService.pagination,
                use_case_id: useCaseId,
                source_id: searchDataDocumentReq.source_id,
                sync_source_id: searchDataDocumentReq.sync_source_id
            }, {headers});
    }

    private searchUseCaseQueryDocumentsReq(searchDataDocumentReq: SearchDataDocumentReq, pageNumber: number | null = null, useCaseId: number): Observable<any> {
        const headers = new HttpHeaders({
            'route-key': environment.API_ENDPOINT_MS_UC_ROUTE_KEY,
            'payload-key': environment.API_ENDPOINT_MS_UC_PAYLOAD_KEY,
        });

        return (pageNumber) ?
            this.httpClient.post<any>(environment.API_ENDPOINT_MS_UC + 'api/search/query?query=' + searchDataDocumentReq.query + '&page=' + pageNumber, {
                pagination: this.authHelperService.pagination,
                use_case_id: useCaseId,
                source_id: searchDataDocumentReq.source_id,
                sync_source_id: searchDataDocumentReq.sync_source_id
            }, {headers}) :
            this.httpClient.post<any>(environment.API_ENDPOINT_MS_UC + 'api/search/query', {
                query: searchDataDocumentReq.query,
                pagination: this.authHelperService.pagination,
                use_case_id: useCaseId,
                source_id: searchDataDocumentReq.source_id,
                sync_source_id: searchDataDocumentReq.sync_source_id
            }, {headers});
    }

    private getDocumentImagesReq(documentId: number): Observable<any> {
        const headers = new HttpHeaders({
            'route-key': environment.API_ENDPOINT_MS_UC_ROUTE_KEY,
            'payload-key': environment.API_ENDPOINT_MS_UC_PAYLOAD_KEY,
        });

        return this.httpClient.get<any>(environment.API_ENDPOINT_MS_UC + 'api/data-manipulation/images/documents/' + documentId, {headers});
    }

    getDocumentImages(documentId: number): Promise<DocumentImagesResponse> {
        return new Promise<DocumentImagesResponse>((resolve: any, reject: any) => {
            this.getDocumentImagesReq(documentId).subscribe(response => {
                resolve(response.entity);
            });
        });
    }

    searchUseCaseDataDocuments(searchDataDocumentReq: SearchDataDocumentReq, pageNumber: number | null = null, useCaseId: number): Promise<DataDocumentResponse> {
        return new Promise<DataDocumentResponse>((resolve: any, reject: any) => {
            this.searchUseCaseDataDocumentsReq(searchDataDocumentReq, pageNumber, useCaseId).subscribe((response: any) => {
                const pagination = this.generalHttpHelperService.returnPagination(response);
                resolve({
                    data: response.data,
                    pagination
                });
            }, error => {
                reject(null);
            });
        });
    }

    searchUseCaseQueryDocuments(searchDataDocumentReq: SearchDataDocumentReq, pageNumber: number | null = null, useCaseId: number): Promise<DataDocumentResponse> {
        return new Promise<DataDocumentResponse>((resolve: any, reject: any) => {
            this.searchUseCaseQueryDocumentsReq(searchDataDocumentReq, pageNumber, useCaseId).subscribe((response: any) => {
                const pagination = this.generalHttpHelperService.returnPagination(response);
                resolve({
                    data: response.data,
                    pagination
                });
            }, error => {
                reject(null);
            });
        });
    }

    searchUseCaseData(searchQuery: string, pageNumber: number | null = null, searchTypeParams: SearchTypeParams): Promise<UseCaseDataResponse> {
        return new Promise<UseCaseDataResponse>((resolve: any, reject: any) => {
            this.searchUseCaseDataReq(searchQuery, pageNumber, searchTypeParams).subscribe((response: any) => {
                const pagination = this.generalHttpHelperService.returnPagination(response);
                response.data.hits = this.useCasesHelper.returnParsedUseCaseDataBodies(response.data);
                resolve({
                    useCaseData: response.data.hits,
                    pagination
                });
            }, error => {
                reject(null);
            });
        });
    }

    searchUseCaseQuery(searchQuery: string, pageNumber: number | null = null, searchTypeParams: SearchTypeParams): Promise<UseCaseQueryResponse> {
        return new Promise<UseCaseQueryResponse>((resolve: any, reject: any) => {
            this.searchUseCaseQueryReq(searchQuery, pageNumber, searchTypeParams).subscribe((response: any) => {
                const pagination = this.generalHttpHelperService.returnPagination(response.entity);
                resolve({
                    useCaseData: this.useCasesHelper.returnParsedUseCaseQueryBodies(response.entity.data),
                    pagination
                });
            }, error => {
                reject(null);
            });
        });
    }

    getSyncDocumentPreview(documentId: number): Promise<string> {
        return new Promise<string>((resolve: any, reject: any) => {
            this.useCaseDataHttpServiceHelper.toDataURL(environment.API_ENDPOINT_MS_UC + 'api/data-manipulation/preview/images/documents/' + documentId, (dataUrl: any) => {
                resolve(dataUrl);
            });
        });
    }

    getImagePreview(documentId: number): Promise<string> {
        return new Promise<string>((resolve: any, reject: any) => {
            this.useCaseDataHttpServiceHelper.toDataURL(environment.API_ENDPOINT_MS_UC + 'api/data-manipulation/preview/images/' + documentId, (dataUrl: any) => {
                resolve(dataUrl);
            });
        });
    }
}

@Injectable({
    providedIn: 'root'
})
export class UseCaseDataHttpServiceHelper {

    constructor(private authHelperService: AuthenticationHelperService) {
    }

    toDataURL(url: string, callback: any): void {
        const xhr = new XMLHttpRequest();
        xhr.onload = (e) => {
            const reader = new FileReader();
            reader.onloadend = (event) => {
                callback(reader.result);
            };
            reader.readAsDataURL(xhr.response);
        };
        xhr.open('GET', url);
        xhr.setRequestHeader('Company', JSON.stringify(this.authHelperService.getCompanyId));
        xhr.setRequestHeader('Authorization', 'Bearer ' + this.authHelperService.getToken);
        xhr.setRequestHeader('route-key', environment.API_ENDPOINT_MS_UC_ROUTE_KEY);
        xhr.setRequestHeader('payload-key', environment.API_ENDPOINT_MS_UC_PAYLOAD_KEY);
        xhr.responseType = 'blob';
        xhr.send();
    }
}
