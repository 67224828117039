<div class="h-full flex flex-col bg-white shadow-xl overflow-y-scroll"
     (scroll)="paginateSourceSyncDetails($event)">
    <div class="flex-1">
        <!-- Header -->
        <div class="px-4 py-6 sm:px-6 bg-gray-700">
            <div class="flex items-start justify-between space-x-3">
                <div class="space-y-1">
                    <h2 class="text-lg font-medium text-white" id="slide-over-title">
                        Total {{sourceSync?.total_files}}
                    </h2>
                </div>
<!--                <div class="h-7 flex items-center">-->
<!--                    <button (click)="closeSync()" type="button"-->
<!--                            class="text-gray-400 hover:text-gray-500">-->
<!--                        <span class="sr-only">Close panel</span>-->
<!--                        &lt;!&ndash; Heroicon name: outline/x &ndash;&gt;-->
<!--                        <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none"-->
<!--                             viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">-->
<!--                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"-->
<!--                                  d="M6 18L18 6M6 6l12 12"/>-->
<!--                        </svg>-->
<!--                    </button>-->
<!--                </div>-->
            </div>
        </div>
        <div class="overflow-y-scroll syncs-modal-scroll syncs-modal-scroll--single-sync">
            <div *ngFor="let syncDetails of sourceSyncDetails?.data" class="cursor-pointer" (click)="showSyncImage(syncDetails)">
                <div class="flex flex-col py-4 px-6 border-b border-gray-200">
                    <div class="flex items-center justify-between">
                        <p class="font-medium text-gray-900 text-sm">{{syncDetails.file_name + '.' + syncDetails.file_extension}}</p>
                        <img src="../../../../assets/images/icons/icon-gray-arrow-right.svg" alt="" *ngIf="syncDetails?.id !== openedSyncDetails?.id">
                        <img class="rotate-icon-arrow-90" src="../../../../assets/images/icons/icon-gray-arrow-right.svg" alt="" *ngIf="syncDetails?.id === openedSyncDetails?.id">
                    </div>
                    <div class="py-4 px-6" *ngIf="syncDetails?.id === openedSyncDetails?.id" @fadingIn>
                        <img *ngIf="syncDetails.image_preview" [src]="domSanitizer.bypassSecurityTrustUrl(syncDetails.image_preview)"/>
<!--                        <img [src]="environment.API_ENDPOINT_MS_UC + 'api/data-manipulation/preview/images/documents/' + syncDetails.id"/>-->
                        <div class="h-40 flex justify-center items-center" *ngIf="!syncDetails.image_preview">
                            <span class="text-2xl text-gray-600 font-medium">No preview for this document.</span>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="sourceSyncDetails?.data?.length === 0" class="py-4 px-6">
                <span class="text-2xl text-gray-600 font-medium">No document previews.</span>
            </div>
        </div>
    </div>
</div>
