<app-forgot-password *ngIf="!isLoginScreen" (changedToLogin)="isLoginScreen=true"></app-forgot-password>
<div class="min-h-screen bg-gray-50 flex flex-col justify-center py-12 px-6 lg:px-8" *ngIf="isLoginScreen">
    <div class="sm:mx-auto sm:w-full sm:max-w-md">
        <img class="mx-auto h-20 w-auto text-red-600 fill-current" src="/assets/images/logo-light.svg" alt="Workflow">
        <h2 class="mt-6 text-center text-3xl font-extrabold text-gray-900 leading-9">
            Sign in to your account
        </h2>
    </div>

    <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div class="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
            <form [formGroup]="userCredentials" (ngSubmit)="login()" class="space-y-6">

                <!-- Email input -->
                <div>
                    <label for="email" class="block text-sm font-medium text-gray-700">
                        Email address
                    </label>
                    <div class="mt-1">
                        <input type="email" formControlName="email" required email autocomplete="false"
                               spellcheck="false"
                               class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-gray-500 focus:border-gray-500 sm:text-sm">
                    </div>
                </div>
                <!-- ./Email input -->

                <!-- Password input -->
                <div>
                    <label for="password" class="block text-sm font-medium text-gray-700">
                        Password
                    </label>
                    <div class="mt-1">
                        <input type="password" formControlName="password" required autocomplete="false"
                               spellcheck="false"
                               class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-gray-500 focus:border-gray-500 sm:text-sm">
                    </div>

                    <span *ngIf="errMessage" class="inline-flex mt-2 text-red-600">Wrong email or password</span>
                </div>
                <!-- ./Password input -->

                <div class="flex items-center justify-between">
                    <!-- Remember me input -->
<!--                    <div class="flex items-center">-->
<!--                        <input id="remember-me" name="remember-me" type="checkbox"-->
<!--                               class="h-4 w-4 text-gray-600 focus:ring-gray-500 border-gray-300 rounded">-->
<!--                        <label for="remember-me" class="ml-2 block text-sm text-gray-900">-->
<!--                            Remember me-->
<!--                        </label>-->
<!--                    </div>-->
                    <!-- ./Remember me input -->

                    <!-- Forgot password input -->
                    <div class="text-sm">
                        <a (click)="forgotPassword()"
                           class="cursor-pointer font-medium text-gray-600 hover:text-gray-500">
                            Forgot your password?
                        </a>
                    </div>
                    <!-- ./Forgot password input -->

                </div>
                <!-- Form submit button -->
                <div>
                    <button [disabled]="!userCredentials.valid" type="submit"
                            class="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500">
                        Sign in
                    </button>
                </div>
                <!-- ./Form submit button -->
            </form>
        </div>
    </div>
</div>
