<!-- Add Source Manual-->
<div class="w-screen max-w-2xl" #modal>
    <div class="h-full flex flex-col bg-white shadow-xl overflow-y-scroll" id="js-add-source-modal">
        <div class="flex-1">
            <!-- Header -->
            <div class="px-4 py-6 bg-gray-700 sm:px-6">
                <div class="flex items-start justify-between space-x-3">
                    <div class="space-y-1">
                        <h2 class="text-lg font-medium text-white" id="slide-over-title">
                            Edit label
                        </h2>
                    </div>
                    <div class="h-7 flex items-center">
                        <button (click)="modalClosed.emit(true)" type="button"
                                class="text-gray-400 hover:text-gray-500">
                            <span class="sr-only">Close panel</span>
                            <!-- Heroicon name: outline/x -->
                            <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none"
                                 viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                      d="M6 18L18 6M6 6l12 12"/>
                            </svg>
                        </button>
                    </div>
                </div>
            </div>
            <!-- 1st Step Content -->
            <div class="p-6 space-y-6">
                <form [formGroup]="labelForm" class="space-y-8">
                    <div>
                        <label class="block text-sm font-medium text-gray-700">Label</label>
                        <div class="mt-2">
                            <input formControlName="label" [ngClass]="{'border-red-500': errors.hasOwnProperty('label')}" type="text" class="shadow-sm focus:ring-gray-400 focus:border-gray-400 block w-full sm:text-sm border-gray-300 rounded-md" placeholder="Label">
                            <span class="text-red-500" *ngIf="errors.hasOwnProperty('label')">{{ errors.label }}</span>
                        </div>
                    </div>
                    <div>
                        <label class="block text-sm font-medium text-gray-700">Label category</label>
                        <div class="mt-2">
                            <input formControlName="category" [ngClass]="{'border-red-500': errors.hasOwnProperty('category')}" type="text" class="shadow-sm focus:ring-gray-400 focus:border-gray-400 block w-full sm:text-sm border-gray-300 rounded-md" placeholder="Category">
                            <span class="text-red-500" *ngIf="errors.hasOwnProperty('label')">{{ errors.category }}</span>
                        </div>
                    </div>
                    <div *ngIf="label?.aliases?.length">
                        <label class="text-sm font-medium text-gray-700 flex justify-between">
                            <span>Aliases</span>
                            <button class="bg-gray-700 py-1 px-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500" (click)="createdAlias()">Create alias</button>
                        </label>
                        <div class="flex gap-2 mt-2">
                            <p *ngFor="let alias of label?.aliases" class="bg-gray-700 p-1 rounded-xl">
                                <span class="text-white p-1" (click)="updatedAlias(alias)">{{ alias.alias }}</span>
                                <span (click)="deleteAlias(alias)" class="ml-2 cursor-pointer text-red-500 p-1">X</span>
                            </p>
                        </div>
                    </div>
                    <div *ngIf="updatingAlias">
                        <label class="block text-sm font-medium text-gray-700">Update alias</label>
                        <div class="mt-2 flex space-x-2">
                            <input #aliasInput class="shadow-sm focus:ring-gray-400 focus:border-gray-400 block w-full sm:text-sm border-gray-300 rounded-md" (keyup.enter)="updateValue(updatingAlias, aliasInput.value)" type="text" value="{{ updatingAlias.alias }}">
                            <button type="button" (click)="updateValue(updatingAlias, aliasInput.value)" class="bg-gray-700 py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500">Save</button>
                        </div>
                    </div>
                    <div *ngIf="creatingAlias">
                        <label class="block text-sm font-medium text-gray-700">Create alias</label>
                        <div class="mt-2 flex space-x-2">
                            <input #createAliasInput class="shadow-sm focus:ring-gray-400 focus:border-gray-400 block w-full sm:text-sm border-gray-300 rounded-md" (keyup.enter)="createValue(createAliasInput.value)" type="text">
                            <button type="button" (click)="createValue(createAliasInput.value)" class="bg-gray-700 py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500">Save</button>
                        </div>
                    </div>
                    <div *ngIf="createdAliases.length">
                        <label class="block text-sm font-medium text-gray-700">Created Aliases</label>
                        <div class="flex gap-2 mt-2">
                            <p *ngFor="let alias of createdAliases" class="bg-gray-700 p-1 rounded-xl">
                                <span class="text-white p-1">{{ alias }}</span>
                                <span (click)="deleteCreatedAlias(alias)" class="ml-2 cursor-pointer text-red-500 p-1">X</span>
                            </p>
                        </div>
                    </div>
                    <div *ngIf="updatedAliases.length">
                        <label class="block text-sm font-medium text-gray-700">Updated Aliases</label>
                        <div class="flex gap-2 mt-2">
                            <p *ngFor="let alias of updatedAliases" class="bg-gray-700 p-1 rounded-xl">
                                <span class="text-white p-1">Old: {{ alias.alias }}</span>
                                <span class="text-white p-1">New: {{ alias.newAlias }}</span>
                                <span (click)="deleteUpdatedAlias(alias)" class="ml-2 cursor-pointer text-red-500 p-1">X</span>
                            </p>
                        </div>
                    </div>
                    <div *ngIf="deletedAliases.length">
                        <label class="block text-sm font-medium text-gray-700">Deleted Aliases</label>
                        <div class="flex gap-2 mt-2">
                            <p *ngFor="let alias of deletedAliases" class="bg-gray-700 p-1 rounded-xl">
                                <span class="text-white p-1">{{ alias.alias }}</span>
                            </p>
                        </div>
                    </div>
                </form>
                <!-- Action buttons -->
                <div class="flex-shrink-0 px-4 border-t border-gray-200 py-5 sm:px-6 mt-auto">
                    <div class="space-x-3 flex justify-end">
                        <button type="button"
                                (click)="modalClosed.emit(true)"
                                class="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500">
                            Close
                        </button>
                        <button type="button" (click)="updateModal()" class="bg-gray-700 py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500">Save</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
