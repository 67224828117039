import {Component, Input, OnInit} from '@angular/core';
import {PpgPagination} from '../../interfaces/general-interfaces';

@Component({
    selector: 'app-pagination-data-details',
    templateUrl: './pagination-data-details.component.html'
})
export class PaginationDataDetailsComponent implements OnInit {

    @Input() pagination: PpgPagination | undefined;

    constructor() {
    }

    ngOnInit(): void {
    }

}
