import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {
    AddSourceReqObj,
    AWSCloudIntegrationAddReqObj,
    AWSCloudIntegrationEditReqObj, AWSSecretKeyEditReqObj,
    CloudFrequency,
    CloudIntegrationResponse,
    CloudRegion,
    CloudType,
    EditSourceReqObj,
    FileExtension,
    FilesUploadedDetails,
    FTPAndSFTPCloudIntegrationAddReqObj,
    FTPAndSFTPCloudIntegrationEditReqObj,
    FTPAndSFTPPasswordEditReqObj, GetIntegrationsResponse,
    GetUseCaseSourcesResponse, GetUseCaseSourcesSyncsResponse, SearchAndFilterCloudsReqObj,
    SearchAndFilterSourcesReqObj,
    Source,
    SourceType
} from '../../interfaces/sources-interfaces';
import {AddingSourceType, AddingSourceTypeForView, CloudTypes, EditType} from '../../enums/sources-enums';
import {AddSourceManual} from '../../pages/source/add-source-modal/add-source-manual-modal/add-source-manual';
import {AuthenticationHelperService} from '../helpers/authentication-helper.service';
import {GeneralHttpHelperService} from './general-http-helper.service';
import {PpgPagination} from '../../interfaces/general-interfaces';
import {PpgFormGroup} from '../../derived-classes/ppg-form-group';
import {CloudIntegration} from '../../pages/source/add-source-modal/add-source-cloud-modal/cloud-integration';
import {AddSourceCloud} from '../../pages/source/add-source-modal/add-source-cloud-modal/add-source-cloud';
import {GeneralHelperService} from '../helpers/general-helper.service';
import {ToasterHelperService} from '../helpers/toaster-helper.service';
import {AddingSourceTypeEmailFirstStep} from '../../pages/source/add-source-modal/AddingSourceTypeEmailFirstStep';
import {AddingSourceTypeCloudFirstStep} from '../../pages/source/add-source-modal/AddingSourceTypeCloudFirstStep';
import {AddingSourceTypeManualFirstStep} from '../../pages/source/add-source-modal/AddingSourceTypeManualFirstStep';
import {PpgFile} from '../../derived-classes/ppg-file';

@Injectable({
    providedIn: 'root'
})
export class SourcesHttpService {

    constructor(private httpClient: HttpClient,
                private sourcesHttpServiceHelper: SourcesHttpServiceHelper,
                private authHelperService: AuthenticationHelperService,
                private generalHttpHelperService: GeneralHttpHelperService,
                private toasterHelperService: ToasterHelperService) {
    }

    private getSourceTypesReq(): Observable<any> {
        const headers = new HttpHeaders({
            'route-key': environment.API_ENDPOINT_MS_UC_ROUTE_KEY,
            'payload-key': environment.API_ENDPOINT_MS_UC_PAYLOAD_KEY,
        });

        return this.httpClient.get<any>(environment.API_ENDPOINT_MS_UC + 'api/doc-manipulation/source-types', {headers});
    }

    private addSourceReq(addSourceManualReqObj: AddSourceReqObj): Observable<any> {
        const headers = new HttpHeaders({
            'route-key': environment.API_ENDPOINT_MS_UC_ROUTE_KEY,
            'payload-key': environment.API_ENDPOINT_MS_UC_PAYLOAD_KEY,
        });

        return this.httpClient.post<any>(environment.API_ENDPOINT_MS_UC + 'api/doc-manipulation/sources', addSourceManualReqObj, {headers});
    }

    private getExtensionsReq(): Observable<any> {
        const headers = new HttpHeaders({
            'route-key': environment.API_ENDPOINT_MS_UC_ROUTE_KEY,
            'payload-key': environment.API_ENDPOINT_MS_UC_PAYLOAD_KEY,
        });

        return this.httpClient.get<any>(environment.API_ENDPOINT_MS_UC + 'api/doc-manipulation/extensions', {headers});
    }

    private storeFileManualReq(fileFormData: FormData): Observable<any> {
        const headers = new HttpHeaders({
            'route-key': environment.API_ENDPOINT_MS_UC_ROUTE_KEY,
            'payload-key': environment.API_ENDPOINT_MS_UC_PAYLOAD_KEY,
        });
        headers.append('Content-Type', 'multipart/form-data');

        return this.httpClient.post<any>(environment.API_ENDPOINT_MS_UC + 'api/data-manipulation/documents', fileFormData, {headers});
    }

    private getUseCaseSourcesReq(useCaseId: number, pageNumber: number | null = null): Observable<any> {
        const headers = new HttpHeaders({
            'route-key': environment.API_ENDPOINT_MS_UC_ROUTE_KEY,
            'payload-key': environment.API_ENDPOINT_MS_UC_PAYLOAD_KEY,
        });

        if (useCaseId === 0) {
            return (pageNumber) ?
                this.httpClient.get<any>(environment.API_ENDPOINT_MS_UC + 'api/doc-manipulation/sources?page=' + pageNumber, {headers}) :
                this.httpClient.get<any>(environment.API_ENDPOINT_MS_UC + 'api/doc-manipulation/sources', {headers});
        } else {
            return (pageNumber) ?
                this.httpClient.get<any>(environment.API_ENDPOINT_MS_UC + 'api/doc-manipulation/sources/use-cases/' + useCaseId + '?page=' + pageNumber, {headers}) :
                this.httpClient.get<any>(environment.API_ENDPOINT_MS_UC + 'api/doc-manipulation/sources/use-cases/' + useCaseId, {headers});
        }
    }

    private searchAndFilterSourcesReq(reqObj: SearchAndFilterSourcesReqObj | null, useCaseId: number, pageNumber: number | null = null): Observable<any> {
        // return this.httpClient.post<any>(environment.API_ENDPOINT_MS_UC + 'api/doc-manipulation/search/sources/use-cases', );
        const headers = new HttpHeaders({
            'route-key': environment.API_ENDPOINT_MS_UC_ROUTE_KEY,
            'payload-key': environment.API_ENDPOINT_MS_UC_PAYLOAD_KEY,
        });

        return (pageNumber) ?
            this.httpClient.post<any>(environment.API_ENDPOINT_MS_UC + 'api/search/sources?query=' + reqObj?.query + ' ' + reqObj?.source_type.name + '&page=' + pageNumber,
                {
                    query: reqObj?.query + ' ' + reqObj?.source_type.name,
                    use_case_id: (useCaseId === 0) ? undefined : Number(useCaseId),
                    pagination: 10
                }, {headers}) :
            this.httpClient.post<any>(environment.API_ENDPOINT_MS_UC + 'api/search/sources',
                {
                    query: reqObj?.query + ' ' + reqObj?.source_type.name,
                    use_case_id: (useCaseId === 0) ? undefined : Number(useCaseId),
                    pagination: 10
                }, {headers});
    }

    private deleteSourceReq(source: Source): Observable<any> {
        const headers = new HttpHeaders({
            'route-key': environment.API_ENDPOINT_MS_UC_ROUTE_KEY,
            'payload-key': environment.API_ENDPOINT_MS_UC_PAYLOAD_KEY,
        });

        return this.httpClient.delete<any>(environment.API_ENDPOINT_MS_UC + 'api/doc-manipulation/sources/' + source.id, {headers});
    }


    deleteSource(source: Source): Promise<Source> {
        return new Promise<Source>((resolve: any, reject: any) => {
            this.deleteSourceReq(source).subscribe(response => {
                resolve(source);
                this.toasterHelperService.showSuccess('Successfully deleted source');
            }, error => {
                this.toasterHelperService.showError('Can\'t delete this source, try again later');
                reject(null);
            });
        });
    }

    private getCloudTypesReq(): Observable<any> {
        const headers = new HttpHeaders({
            'route-key': environment.API_ENDPOINT_MS_UC_ROUTE_KEY,
            'payload-key': environment.API_ENDPOINT_MS_UC_PAYLOAD_KEY,
        });

        return this.httpClient.get<any>(environment.API_ENDPOINT_MS_UC + 'api/doc-manipulation/clouds', {headers});
    }

    private getCloudFrequenciesReq(): Observable<any> {
        const headers = new HttpHeaders({
            'route-key': environment.API_ENDPOINT_MS_UC_ROUTE_KEY,
            'payload-key': environment.API_ENDPOINT_MS_UC_PAYLOAD_KEY,
        });

        return this.httpClient.get<any>(environment.API_ENDPOINT_MS_UC + 'api/doc-manipulation/frequencies', {headers});
    }

    private getCloudRegionsAndIntegrationsReq(cloudId: number | undefined): Observable<any> {
        const headers = new HttpHeaders({
            'route-key': environment.API_ENDPOINT_MS_UC_ROUTE_KEY,
            'payload-key': environment.API_ENDPOINT_MS_UC_PAYLOAD_KEY,
        });

        return this.httpClient.get<any>(environment.API_ENDPOINT_MS_UC + 'api/doc-manipulation/clouds/' + cloudId, {headers});
    }

    private editIntegrationReq(cloudIntegrationReqObj: AWSCloudIntegrationEditReqObj | FTPAndSFTPCloudIntegrationEditReqObj, cloudForEdit: CloudIntegration): Observable<any> {
        const headers = new HttpHeaders({
            'route-key': environment.API_ENDPOINT_MS_UC_ROUTE_KEY,
            'payload-key': environment.API_ENDPOINT_MS_UC_PAYLOAD_KEY,
        });

        return this.httpClient.put<any>(environment.API_ENDPOINT_MS_UC + 'api/doc-manipulation/integration/clouds/' + cloudForEdit.id, cloudIntegrationReqObj, {headers});
    }

    private editSourceReq(editSourceReqObj: EditSourceReqObj, sourceId: number): Observable<any> {
        const headers = new HttpHeaders({
            'route-key': environment.API_ENDPOINT_MS_UC_ROUTE_KEY,
            'payload-key': environment.API_ENDPOINT_MS_UC_PAYLOAD_KEY,
        });

        return this.httpClient.put<any>(environment.API_ENDPOINT_MS_UC + 'api/doc-manipulation/sources/' + sourceId, editSourceReqObj, {headers});
    }

    private getSourceReq(sourceId: number): Observable<any> {
        const headers = new HttpHeaders({
            'route-key': environment.API_ENDPOINT_MS_UC_ROUTE_KEY,
            'payload-key': environment.API_ENDPOINT_MS_UC_PAYLOAD_KEY,
        });

        return this.httpClient.get<any>(environment.API_ENDPOINT_MS_UC + 'api/doc-manipulation/sources/' + sourceId, {headers});
    }

    private editCloudIntegrationSecretInformationReq(cloudIntegrationReqObj: FTPAndSFTPPasswordEditReqObj | AWSSecretKeyEditReqObj, cloudForEdit: CloudIntegration): Observable<any> {
        const headers = new HttpHeaders({
            'route-key': environment.API_ENDPOINT_MS_UC_ROUTE_KEY,
            'payload-key': environment.API_ENDPOINT_MS_UC_PAYLOAD_KEY,
        });

        return this.httpClient.post<any>(environment.API_ENDPOINT_MS_UC + 'api/doc-manipulation/credentials/integration/clouds/' + cloudForEdit.id, cloudIntegrationReqObj, {headers});
    }

    private searchAndFilterIntegrationsReq(reqObj: SearchAndFilterCloudsReqObj | null, useCaseId: number, pageNumber: number | null = null): Observable<any> {
        // return this.httpClient.post<any>(environment.API_ENDPOINT_MS_UC + 'api/doc-manipulation/search/sources/use-cases', );
        const headers = new HttpHeaders({
            'route-key': environment.API_ENDPOINT_MS_UC_ROUTE_KEY,
            'payload-key': environment.API_ENDPOINT_MS_UC_PAYLOAD_KEY,
        });

        return (pageNumber) ?
            this.httpClient.post<any>(environment.API_ENDPOINT_MS_UC + 'api/search/cloud-integrations?query=' + reqObj?.query + ' ' + reqObj?.cloud_type.name + '&page=' + pageNumber,
                {
                    query: reqObj?.query + ' ' + reqObj?.cloud_type.name,
                    // use_case_id: (useCaseId === 0) ? undefined : Number(useCaseId),
                    pagination: 10
                }, {headers}) :
            this.httpClient.post<any>(environment.API_ENDPOINT_MS_UC + 'api/search/cloud-integrations',
                {
                    query: reqObj?.query + ' ' + reqObj?.cloud_type.name,
                    // use_case_id: (useCaseId === 0) ? undefined : Number(useCaseId),
                    pagination: 10
                }, {headers});
    }

    private getIntegrationsReq(cloudType: AddingSourceType, pageNumber: number | null = null): Observable<any> {
        const headers = new HttpHeaders({
            'route-key': environment.API_ENDPOINT_MS_UC_ROUTE_KEY,
            'payload-key': environment.API_ENDPOINT_MS_UC_PAYLOAD_KEY,
        });

        if (cloudType === AddingSourceType.email) {
            return (pageNumber) ?
                this.httpClient.get<any>(environment.API_ENDPOINT_MS_UC + 'api/doc-manipulation/source-emails?page=' + pageNumber, {headers}) :
                this.httpClient.get<any>(environment.API_ENDPOINT_MS_UC + 'api/doc-manipulation/source-emails', {headers});
        } else {
            return (pageNumber) ?
                this.httpClient.get<any>(environment.API_ENDPOINT_MS_UC + 'api/doc-manipulation/integration/clouds?page=' + pageNumber, {headers}) :
                this.httpClient.get<any>(environment.API_ENDPOINT_MS_UC + 'api/doc-manipulation/integration/clouds', {headers});
        }
    }

    private searchAndFilterSyncSourcesReq(query: string, useCaseId: number, pageNumber: number | null = null): Observable<any> {
        // return this.httpClient.post<any>(environment.API_ENDPOINT_MS_UC + 'api/doc-manipulation/search/sources/use-cases', );
        // return (pageNumber) ?
        //     this.httpClient.post<any>(environment.API_ENDPOINT_MS_UC + 'api/sync-sources/use-cases?query=' + reqObj?.query + ' ' + reqObj?.source_type.name + '&page=' + pageNumber,
        //         {
        //             query: reqObj?.query + ' ' + reqObj?.source_type.name,
        //             use_case_id: (useCaseId === 0) ? undefined : Number(useCaseId),
        //             pagination: 10
        //         }) :
        const headers = new HttpHeaders({
            'route-key': environment.API_ENDPOINT_MS_UC_ROUTE_KEY,
            'payload-key': environment.API_ENDPOINT_MS_UC_PAYLOAD_KEY,
        });

        return this.httpClient.get<any>(environment.API_ENDPOINT_MS_UC + 'api/doc-manipulation/sync-sources/use-cases/' + useCaseId, {headers});
    }

    private deleteIntegrationReq(integration: CloudIntegration): Observable<any> {
        const headers = new HttpHeaders({
            'route-key': environment.API_ENDPOINT_MS_UC_ROUTE_KEY,
            'payload-key': environment.API_ENDPOINT_MS_UC_PAYLOAD_KEY,
        });

        return this.httpClient.delete<any>(environment.API_ENDPOINT_MS_UC + 'api/doc-manipulation/integration/clouds/' + integration.id, {headers});
    }

    private addIntegrationReq(cloudIntegrationReqObj: AWSCloudIntegrationAddReqObj | FTPAndSFTPCloudIntegrationAddReqObj): Observable<any> {
        const headers = new HttpHeaders({
            'route-key': environment.API_ENDPOINT_MS_UC_ROUTE_KEY,
            'payload-key': environment.API_ENDPOINT_MS_UC_PAYLOAD_KEY,
        });

        return this.httpClient.post<any>(environment.API_ENDPOINT_MS_UC + 'api/doc-manipulation/integration/clouds', cloudIntegrationReqObj, {headers});
    }

    addIntegration(ppgFormGroup: PpgFormGroup, cloudType: CloudType): Promise<CloudIntegration> {
        return new Promise<CloudIntegration>((resolve: any, reject: any) => {
            const reqObj = (cloudType.name === CloudTypes.aws) ?
                this.sourcesHttpServiceHelper.returnAddReqObjForCreatingIntegration(ppgFormGroup, cloudType) :
                this.sourcesHttpServiceHelper.returnAddReqObjForCreatingIntegrationFTPAndSFTP(ppgFormGroup, cloudType);
            this.addIntegrationReq(reqObj).subscribe((response: any) => {
                (cloudType.name === CloudTypes.aws) ?
                    resolve(this.sourcesHttpServiceHelper.returnCloudIntegrationObject(response.entity, ppgFormGroup.controls.region.value, cloudType)) :
                    resolve(this.sourcesHttpServiceHelper.returnCloudIntegrationObject(response.entity, null, cloudType));
            }, error => {
                if (error.error.message['information.ip']) {
                    this.toasterHelperService.showWarning('IP Address is not valid');
                    reject('IP Address is not valid');
                }
            });
        });
    }

    deleteIntegration(integration: CloudIntegration): Promise<CloudIntegration> {
        return new Promise<CloudIntegration>((resolve: any, reject: any) => {
            this.deleteIntegrationReq(integration).subscribe(response => {
                resolve(integration);
                this.toasterHelperService.showSuccess('Successfully deleted integration');
            }, error => {
                this.toasterHelperService.showError('Can\'t delete this integration, try again later');
                reject(null);
            });
        });
    }

    searchAndFilterSyncSources(query: string, useCaseId: number, pageNumber: number | null = null): Promise<GetUseCaseSourcesSyncsResponse> {
        return new Promise<GetUseCaseSourcesSyncsResponse>((resolve: any, reject: any) => {
            this.searchAndFilterSyncSourcesReq(query, useCaseId, pageNumber).subscribe((response: any) => {
                console.log('Res: ', response);
                const pagination = this.generalHttpHelperService.returnPagination(response);
                resolve({
                    data: response.entity.data,
                    pagination
                });
            }, error => {
                reject(null);
            });
        });
    }

    getIntegrations(cloudType: AddingSourceType, pageNumber: number | null = null): Promise<GetIntegrationsResponse> {
        return new Promise<GetIntegrationsResponse>((resolve: any, reject: any) => {
            this.getIntegrationsReq(cloudType, pageNumber).subscribe((response: any) => {
                const pagination = this.generalHttpHelperService.returnPagination(response.entity);
                // TODO Implement bellow
                resolve({
                    data: this.sourcesHttpServiceHelper.configureCloudIntegrationsObjects(response.entity.data, [], null),
                    pagination
                });
            }, error => {
                reject(null);
            });
        });
    }

    searchAndFilterIntegrations(reqObj: SearchAndFilterCloudsReqObj | null, useCaseId: number, pageNumber: number | null = null): Promise<{ pagination: PpgPagination, data: Array<CloudIntegration> }> {
        return new Promise<{ pagination: PpgPagination, data: Array<CloudIntegration> }>((resolve: any, reject: any) => {
            this.searchAndFilterIntegrationsReq(reqObj, useCaseId, pageNumber).subscribe((response: any) => {
                const pagination = this.generalHttpHelperService.returnPagination(response);
                resolve({
                    data: this.sourcesHttpServiceHelper.configureCloudIntegrationsObjects(response.data.hits, [], null),
                    pagination
                });
            }, error => {
                reject(null);
            });
        });
    }

    editCloudIntegrationSecretInformation(ppgFormGroup: PpgFormGroup, cloudForEdit: CloudIntegration, cloudType: CloudType): Promise<CloudIntegration> {
        return new Promise<CloudIntegration>((resolve: any, reject: any) => {
            const reqObj = (EditType.accessKey === cloudForEdit.editType) ?
                this.sourcesHttpServiceHelper.returnEditReqObjForEditingAWSSecretKey(ppgFormGroup, cloudForEdit.cloudType?.id) :
                this.sourcesHttpServiceHelper.returnEditReqObjForEditingFTPAndSFTPPassword(ppgFormGroup, cloudForEdit.cloudType?.id);
            this.editCloudIntegrationSecretInformationReq(reqObj, cloudForEdit).subscribe((response: any) => {
                resolve(this.sourcesHttpServiceHelper.returnCloudIntegrationObject(response.entity, null, cloudType));
            }, error => {
                if (error.error.message['information.ip']) {
                    this.toasterHelperService.showWarning('IP Address is not valid');
                }
            });
        });
    }

    getSource(sourceId: number): Promise<Source> {
        return new Promise<Source>((resolve: any, reject: any) => {
            this.getSourceReq(sourceId).subscribe((sourceRes: any) => {
                resolve(sourceRes.entity);
            }, error => {
                reject(null);
            });
        });
    }

    editSource(source: Source): Promise<Source> {
        return new Promise<Source>((resolve: any, reject: any) => {
            this.editSourceReq(this.sourcesHttpServiceHelper.returnEditSourceReqObj(source), source.id).subscribe((sourceRes: Source) => {
                this.toasterHelperService.showSuccess('Successfully edited source');
                resolve(sourceRes);
            }, error => {
                this.toasterHelperService.showWarning('Error editing source, please try again later');
                reject(null);
            });
        });
    }

    editIntegration(ppgFormGroup: PpgFormGroup, cloudForEdit: CloudIntegration, cloudType: CloudType): Promise<CloudIntegration> {
        return new Promise<CloudIntegration>((resolve: any, reject: any) => {
            const reqObj = (cloudType.name === CloudTypes.aws) ?
                this.sourcesHttpServiceHelper.returnEditReqObjForCreatingIntegrationAWS(ppgFormGroup) :
                this.sourcesHttpServiceHelper.returnEditReqObjForCreatingIntegrationFTPAndSFTP(ppgFormGroup);
            this.editIntegrationReq(reqObj, cloudForEdit).subscribe((response: any) => {
                (cloudType.name === CloudTypes.aws) ?
                    resolve(this.sourcesHttpServiceHelper.returnCloudIntegrationObject(response.entity, ppgFormGroup.controls.region.value, cloudType)) :
                    resolve(this.sourcesHttpServiceHelper.returnCloudIntegrationObject(response.entity, null, cloudType));
            }, error => {
                if (error.error.message['information.ip']) {
                    this.toasterHelperService.showWarning('IP Address is not valid');
                }
            });
        });
    }

    getCloudRegionsAndIntegrations(cloudId: number | undefined): Promise<{ cloudRegions: Array<CloudRegion>, cloudIntegrations: Array<CloudIntegrationResponse>, cloudType: CloudType }> {
        return new Promise<{ cloudRegions: Array<CloudRegion>, cloudIntegrations: Array<CloudIntegrationResponse>, cloudType: CloudType }>((resolve: any, reject: any) => {
            this.getCloudRegionsAndIntegrationsReq(cloudId).subscribe((response: any) => {
                resolve({
                    cloudRegions: response.entity.cloud_regions,
                    cloudIntegrations: response.entity.cloud_integrations,
                    cloudType: {id: response.entity.id, name: response.entity.name}
                });
            }, error => {
                reject(null);
            });
        });
    }

    getCloudFrequencies(): Promise<Array<CloudFrequency>> {
        return new Promise<Array<CloudFrequency>>((resolve: any, reject: any) => {
            this.getCloudFrequenciesReq().subscribe((response: any) => {
                resolve(response.entity);
            }, error => {
                reject(null);
            });
        });
    }

    getCloudTypes(): Promise<Array<CloudType>> {
        return new Promise<Array<CloudType>>((resolve: any, reject: any) => {
            this.getCloudTypesReq().subscribe((response: any) => {
                resolve(response.entity);
            }, error => {
                reject(null);
            });
        });
    }

    searchAndFilterSources(reqObj: SearchAndFilterSourcesReqObj | null, useCaseId: number, pageNumber: number | null = null): Promise<{ pagination: PpgPagination, data: Array<Source> }> {
        return new Promise<{ pagination: PpgPagination, data: Array<Source> }>((resolve: any, reject: any) => {
            this.searchAndFilterSourcesReq(reqObj, useCaseId, pageNumber).subscribe((response: any) => {
                const pagination = this.generalHttpHelperService.returnPagination(response);
                resolve({
                    data: response.data.hits,
                    pagination
                });
            }, error => {
                reject(null);
            });
        });
    }

    getUseCaseSources(useCaseId: number, pageNumber: number | null = null): Promise<GetUseCaseSourcesResponse> {
        return new Promise<GetUseCaseSourcesResponse>((resolve: any, reject: any) => {
            this.getUseCaseSourcesReq(useCaseId, pageNumber).subscribe((response: any) => {
                const pagination = this.generalHttpHelperService.returnPaginationFromCollection(response.meta, response.links);
                resolve({data: response.data, pagination});
            }, error => {
                reject(null);
            });
        });
    }

    storeFileManual(fileFormData: FormData): Promise<File> {
        return new Promise<File>((resolve: any, reject: any) => {
            this.storeFileManualReq(fileFormData).subscribe((response: any) => {
                resolve(response.entity);
            }, error => {
                reject(null);
            });
        });
    }

    getExtensions(): Promise<Array<FileExtension>> {
        return new Promise<Array<FileExtension>>((resolve: any, reject: any) => {
            this.getExtensionsReq().subscribe((response: any) => {
                resolve(response.entity);
            }, error => {
                reject(null);
            });
        });
    }

    addSource(source: AddSourceManual | AddSourceCloud): Promise<Source> {
        return new Promise<Source>((resolve: any, reject: any) => {
            const reqObj = (source.selectedType?.name === AddingSourceType.manual) ?
                this.sourcesHttpServiceHelper.createSourceManualReqObj(source as AddSourceManual, this.authHelperService.getCompanyId) :
                this.sourcesHttpServiceHelper.createSourceCloudReqObj(source as AddSourceCloud, this.authHelperService.getCompanyId);
            this.addSourceReq(reqObj).subscribe((response: any) => {
                resolve(response.entity);
            }, error => {
                reject(null);
            });
        });
    }

    getSourceTypes(): Promise<Array<SourceType>> {
        return new Promise<Array<SourceType>>((resolve: any, reject: any) => {
            this.getSourceTypesReq().subscribe((response: any) => {
                resolve(this.sourcesHttpServiceHelper.createAndReturnSourceTypesArr(response.entity));
            }, error => {
                reject(null);
            });
        });
    }

    uploadFiles(source: Source, ppgFiles: Array<PpgFile>): Promise<Source> {
        return new Promise<Source>((resolve: any, reject: any) => {
            this.sourcesHttpServiceHelper.fileUploadedSubject.totalNumberOfFiles = this.sourcesHttpServiceHelper.getTotalNumberOfCheckedFiles(ppgFiles);
            ppgFiles.forEach((file: PpgFile) => {
                if (file.isChecked) {
                    const formData = new FormData();
                    formData.append('document', file);
                    formData.append('source_id', JSON.stringify(source.id));
                    this.storeFileManual(formData).then((response: File) => {
                        this.sourcesHttpServiceHelper.fileUploadedSubject.numberOfFilesUploaded++;
                        if (this.sourcesHttpServiceHelper.fileUploadedSubject.numberOfFilesUploaded === this.sourcesHttpServiceHelper.fileUploadedSubject.totalNumberOfFiles) {
                            resolve(source);
                        }
                    });
                }
            });
        });
    }
}

@Injectable({
    providedIn: 'root'
})
export class SourcesHttpServiceHelper {
    fileUploadedSubject: FilesUploadedDetails = {
        numberOfFilesUploaded: 0,
        totalNumberOfFiles: 0
    };

    constructor(private authHelperService: AuthenticationHelperService,
                private generalHelper: GeneralHelperService) {
    }

    getTotalNumberOfCheckedFiles(files: Array<PpgFile> | undefined): number {
        let numberToRet = 0;
        if (files) {
            files.forEach(file => {
                if (file.isChecked) {
                    numberToRet++;
                }
            });
        }
        return numberToRet;
    }

    returnEditSourceReqObj(source: Source): EditSourceReqObj {
        return {
            name: source.name,
            extensions: this.returnIdsFromExtension(source.extensions),
            sync: source.sync,
            frequency_id: source.frequency_id
        };
    }

    configureCloudIntegrationsObjects(cloudIntegrations: Array<CloudIntegrationResponse>, cloudRegions: Array<CloudRegion>, cloudType: CloudType | null): Array<CloudIntegration> {
        const arrToRet: Array<CloudIntegration> = [];
        cloudIntegrations.forEach(integration => {
            if (integration.cloud_region) {
                arrToRet.push(this.returnCloudIntegrationObject(integration, integration.cloud_region, cloudType));
            } else {
                arrToRet.push(this.returnCloudIntegrationObject(integration, this.returnRegionById(integration.cloud_region_id, cloudRegions), cloudType));
            }
        });
        return arrToRet;
    }

    returnRegionById(regionId: number, regions: Array<CloudRegion>): CloudRegion | null {
        for (const region of regions) {
            if (regionId === region.id) {
                return region;
            }
        }
        return null;
    }

    returnCloudIntegrationObject(cloudIntegrationResponse: CloudIntegrationResponse, cloudRegion: CloudRegion | null, cloudType: CloudType | null): CloudIntegration {
        const cloudIntegration = new CloudIntegration();
        cloudIntegration.accessKey = cloudIntegrationResponse.information.access_key;
        cloudIntegration.secretAccessKey = cloudIntegrationResponse.information.secret_access_key;
        cloudIntegration.bucket = cloudIntegrationResponse.information.bucket;
        cloudIntegration.cloudRegion = cloudRegion;
        cloudIntegration.cloudType = (!cloudType && cloudIntegrationResponse.cloud) ? cloudIntegrationResponse.cloud : cloudType;
        cloudIntegration.companyId = this.authHelperService.getCompanyId;
        cloudIntegration.name = cloudIntegrationResponse.name;
        cloudIntegration.folder = cloudIntegrationResponse.folder;
        cloudIntegration.id = cloudIntegrationResponse.id;
        cloudIntegration.ssl = cloudIntegrationResponse.information.ssl;
        cloudIntegration.ipAddress = cloudIntegrationResponse.information.ip;
        cloudIntegration.username = cloudIntegrationResponse.information.username;
        cloudIntegration.password = cloudIntegrationResponse.information.password;
        cloudIntegration.port = cloudIntegrationResponse.information.port;
        cloudIntegration.cloudRegionId = cloudIntegrationResponse.cloud_region_id;
        return cloudIntegration;
    }

    returnAddReqObjForCreatingIntegrationFTPAndSFTP(ppgFormGroup: PpgFormGroup, cloudType: CloudType): FTPAndSFTPCloudIntegrationAddReqObj {
        return {
            name: ppgFormGroup.controls.integrationName.value,
            cloud_id: cloudType.id,
            company_id: this.authHelperService.getCompanyId,
            folder: ppgFormGroup.controls.folder.value,
            information: {
                ip: this.generalHelper.returnIpWithDots(ppgFormGroup.controls.ipAddress.value),
                ssl: Boolean(ppgFormGroup.controls.ssl.value),
                port: ppgFormGroup.controls.port.value,
                password: ppgFormGroup.controls.password.value,
                username: ppgFormGroup.controls.username.value
            }
        };
    }

    returnAddReqObjForCreatingIntegration(ppgFormGroup: PpgFormGroup, cloudType: CloudType): AWSCloudIntegrationAddReqObj {
        return {
            name: ppgFormGroup.controls.integrationName.value,
            cloud_id: cloudType.id,
            company_id: this.authHelperService.getCompanyId,
            folder: ppgFormGroup.controls.folder.value,
            cloud_region_id: ppgFormGroup.controls.region.value.id,
            information: {
                secret_access_key: ppgFormGroup.controls.secretAccessKey.value,
                access_key: ppgFormGroup.controls.accessKey.value,
                bucket: ppgFormGroup.controls.bucket.value
            }
        };
    }

    returnEditReqObjForCreatingIntegrationFTPAndSFTP(ppgFormGroup: PpgFormGroup): FTPAndSFTPCloudIntegrationEditReqObj {
        return {
            name: ppgFormGroup.controls.integrationName.value,
            folder: ppgFormGroup.controls.folder.value,
            sync: Boolean(ppgFormGroup.controls.sync.value),
            information: {
                ip: this.generalHelper.returnIpWithDots(ppgFormGroup.controls.ipAddress.value),
                ssl: Boolean(ppgFormGroup.controls.ssl.value),
                port: ppgFormGroup.controls.port.value,
                username: ppgFormGroup.controls.username.value
            }
        };
    }

    returnEditReqObjForEditingAWSSecretKey(ppgFormGroup: PpgFormGroup, cloudId: number | undefined): AWSSecretKeyEditReqObj {
        return {
            cloud_id: cloudId,
            sync: Boolean(ppgFormGroup.controls.sync.value),
            information: {
                secret_access_key: ppgFormGroup.controls.secretAccessKey.value,
                access_key: ppgFormGroup.controls.accessKey.value
            },
        };
    }

    returnEditReqObjForEditingFTPAndSFTPPassword(ppgFormGroup: PpgFormGroup, cloudId: number | undefined): FTPAndSFTPPasswordEditReqObj {
        return {
            cloud_id: cloudId,
            sync: Boolean(ppgFormGroup.controls.sync.value),
            information: {
                password: ppgFormGroup.controls.password.value
            }
        };
    }

    returnEditReqObjForCreatingIntegrationAWS(ppgFormGroup: PpgFormGroup): AWSCloudIntegrationEditReqObj {
        return {
            name: ppgFormGroup.controls.integrationName.value,
            folder: ppgFormGroup.controls.folder.value,
            cloud_region_id: ppgFormGroup.controls.region.value.id,
            sync: Boolean(ppgFormGroup.controls.sync.value),
            information: {
                bucket: ppgFormGroup.controls.bucket.value
            }
        };
    }

    resetFileUploadSubject(): void {
        this.fileUploadedSubject = {
            numberOfFilesUploaded: 0,
            totalNumberOfFiles: 0
        };
    }

    createSourceCloudReqObj(source: AddSourceCloud, companyId: number): AddSourceReqObj {
        return {
            name: source.sourceName,
            extensions: this.returnIdsFromExtension(source.expectedFileExtension),
            process_selected_pages: source.processSelectedPagesInDocument,
            source_type_id: (source.selectedType) ? source.selectedType.id : null,
            use_case_id: Number(source.useCaseId),
            sync: source.sync,
            company_id: companyId,
            cloud_integration_id: source.cloudIntegration.id,
            source_email_id: undefined,
            frequency_id: source.frequency?.id
        };
    }

    createSourceManualReqObj(source: AddSourceManual, companyId: number): AddSourceReqObj {
        return {
            name: source.sourceName,
            extensions: this.returnIdsFromExtension(source.expectedFileExtension),
            process_selected_pages: source.processSelectedPagesInDocument,
            source_type_id: (source.selectedType) ? source.selectedType.id : null,
            use_case_id: Number(source.useCaseId),
            company_id: companyId,
            sync: undefined,
            cloud_integration_id: undefined,
            source_email_id: undefined,
            frequency_id: undefined
        };
    }

    returnIdsFromExtension(extensions: Array<FileExtension>): Array<number> {
        const arrToRet: Array<number> = [];
        extensions.forEach(ext => {
            if (ext.isChecked) {
                arrToRet.push(ext.id);
            }
        });
        return arrToRet;
    }

    createAndReturnSourceTypesArr(sourceTypes: Array<{ name: string, id: number }>): Array<SourceType> {
        const sourceTypesToRet: Array<SourceType> = [];
        sourceTypes.forEach(sourceType => {
            switch (sourceType.name) {
                case AddingSourceType.manual:
                    sourceTypesToRet.push({
                        id: sourceType.id,
                        name: sourceType.name,
                        nameForView: AddingSourceTypeForView.manual
                    });
                    break;
                case AddingSourceType.cloud:
                    sourceTypesToRet.push({
                        id: sourceType.id,
                        name: sourceType.name,
                        nameForView: AddingSourceTypeForView.cloud
                    });
                    break;
                case AddingSourceType.email:
                    sourceTypesToRet.push({
                        id: sourceType.id,
                        name: sourceType.name,
                        nameForView: AddingSourceTypeForView.email
                    });
                    break;
                default:
                    throw new Error('There is a new type of source, please add in source code');
            }
        });
        return sourceTypesToRet;
    }

    // tslint:disable-next-line:max-line-length
    createAndReturnSourceObject(type: AddingSourceTypeEmailFirstStep | AddingSourceTypeCloudFirstStep | AddingSourceTypeManualFirstStep, useCaseId: number): AddSourceManual | AddSourceCloud | null {
        const addSourceManualClass: AddSourceManual | any = new AddSourceManual();
        const addSourceCloudClass: AddSourceCloud = new AddSourceCloud();
        if (type.sourceType === AddingSourceType.manual) {
            const manualType = type as AddingSourceTypeManualFirstStep;
            addSourceManualClass.sourceName = manualType.sourceName;
            addSourceManualClass.processSelectedPagesInDocument = manualType.processOnlySelectedPages;
            addSourceManualClass.expectedFileExtension = manualType.expectedFileExtension;
            addSourceManualClass.activeStep = addSourceManualClass?.possibleSteps?.secondStep;
            addSourceManualClass.selectedType = manualType.sourceTypeObj;
            addSourceManualClass.useCaseId = useCaseId;
            return addSourceManualClass;
        }
        if (type.sourceType === AddingSourceType.cloud) {
            const cloudType = type as AddingSourceTypeCloudFirstStep;
            addSourceCloudClass.sourceName = cloudType.sourceName;
            addSourceCloudClass.cloudIntegration = cloudType.cloudIntegration;
            addSourceCloudClass.processSelectedPagesInDocument = cloudType.processOnlySelectedPages;
            addSourceCloudClass.sync = cloudType.sync;
            addSourceCloudClass.expectedFileExtension = cloudType.expectedFileExtension;
            addSourceCloudClass.activeStep = addSourceCloudClass?.possibleSteps?.secondStep;
            addSourceCloudClass.selectedType = cloudType.sourceTypeObj;
            addSourceCloudClass.frequency = cloudType.frequency;
            addSourceCloudClass.cloudType = cloudType.cloudType;
            addSourceCloudClass.useCaseId = useCaseId;
            return addSourceCloudClass;
        }
        return null;
    }
}
