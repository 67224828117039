import {Injectable} from '@angular/core';
import {PpgPagination} from '../../interfaces/general-interfaces';

@Injectable({
    providedIn: 'root'
})
export class GeneralHttpHelperService {

    constructor() {
    }

    returnPagination(response: any): PpgPagination {
        return {
            currentPage: response.current_page,
            totalItems: response.total,
            perPage: response.per_page,
            nextPageUrl: response.next_page_url,
            to: response.to,
            from: response.from
        };
    }

    returnPaginationFromCollection(meta: any, links: any): PpgPagination {
        return {
            currentPage: meta.current_page,
            totalItems: meta.total,
            perPage: meta.per_page,
            nextPageUrl: links.next,
            to: meta.to,
            from: meta.from
        };
    }
}
