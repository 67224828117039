import {AddingSourceType} from '../../../enums/sources-enums';
import {CloudFrequency, CloudType, FileExtension, SourceType} from '../../../interfaces/sources-interfaces';
import {CloudIntegration} from './add-source-cloud-modal/cloud-integration';

export enum AddingSourceTypeCloudFirstStepEnum {
    dataSourceInOption = 'Cloud'
}

export class AddingSourceTypeCloudFirstStep {

    sourceType = AddingSourceType.cloud;
    private _dataSourceInOption = AddingSourceTypeCloudFirstStepEnum.dataSourceInOption;
    private _sourceName = '';
    private _cloudType: CloudType = {id: 0, name: ''};
    private _frequency: CloudFrequency = {id: 0, frequency: ''};
    private _processOnlySelectedPages = true;
    private _expectedFileExtension: Array<FileExtension> = [];
    private _sourceTypeObj: SourceType | null = null;
    private _cloudIntegration = new CloudIntegration();
    private _sync = false;

    get sync(): boolean {
        return this._sync;
    }

    set sync(value: boolean) {
        this._sync = value;
    }

    get cloudIntegration(): CloudIntegration {
        return this._cloudIntegration;
    }

    set cloudIntegration(value: CloudIntegration) {
        this._cloudIntegration = value;
    }

    constructor() {
    }

    get sourceTypeObj(): SourceType | null {
        return this._sourceTypeObj;
    }

    set sourceTypeObj(value: SourceType | null) {
        this._sourceTypeObj = value;
    }

    get expectedFileExtension(): Array<FileExtension> {
        return this._expectedFileExtension;
    }

    set expectedFileExtension(value: Array<FileExtension>) {
        this._expectedFileExtension = value;
    }

    get dataSourceInOption(): AddingSourceTypeCloudFirstStepEnum {
        return this._dataSourceInOption;
    }

    set dataSourceInOption(value: AddingSourceTypeCloudFirstStepEnum) {
        this._dataSourceInOption = value;
    }

    get sourceName(): string {
        return this._sourceName;
    }

    set sourceName(value: string) {
        this._sourceName = value;
    }

    get cloudType(): CloudType {
        return this._cloudType;
    }

    set cloudType(value: CloudType) {
        this._cloudType = value;
    }

    get frequency(): CloudFrequency {
        return this._frequency;
    }

    set frequency(value: CloudFrequency) {
        this._frequency = value;
    }

    get processOnlySelectedPages(): boolean {
        return this._processOnlySelectedPages;
    }

    set processOnlySelectedPages(value: boolean) {
        this._processOnlySelectedPages = value;
    }
}
