import {Component, ElementRef, EventEmitter, Input, OnInit, Output, Renderer2, ViewChild} from '@angular/core';
import {CloudFrequency, Source} from '../../../interfaces/sources-interfaces';
import {AddingSourceType} from '../../../enums/sources-enums';
import {SourcesHttpService} from '../../../services/http-services/sources-http.service';
import {GeneralHelperService} from '../../../services/helpers/general-helper.service';
import {ToasterHelperService} from '../../../services/helpers/toaster-helper.service';

@Component({
    selector: 'app-edit-source-modal',
    templateUrl: './edit-source-modal.component.html'
})
export class EditSourceModalComponent implements OnInit {

    @Input() sourceId: number | null = 0;
    source: Source | null = null;
    AddingSourceType = AddingSourceType;
    cloudFrequencies: Array<CloudFrequency> = [];
    submitted = false;
    sync = false;
    opened = false;
    @ViewChild('modal') modal: ElementRef | undefined;
    @Output() modalClosed = new EventEmitter<boolean>();
    @Output() sourceEdited = new EventEmitter<Source | null>();

    constructor(private sourcesHttpService: SourcesHttpService,
                private generalHelper: GeneralHelperService,
                private toasterHelperService: ToasterHelperService,
                private renderer: Renderer2) {
    }

    ngOnInit(): void {
        this.getSingleSource();
        this.opened = true;
        setTimeout(() => {
            this.listenToClickType();
        });
    }

    listenToClickType(): void {
        this.renderer.listen('window', 'click', (e: Event) => {
            if (this.generalHelper.checkIfClickedOutsideOfTheElement(undefined, this.modal, e)) {
                if (this.opened) {
                    this.modalClosed.emit(true);
                    this.opened = false;
                }
            }
        });
    }

    getSingleSource(): void {
        if (this.sourceId) {
            this.sourcesHttpService.getSource(this.sourceId).then((source: Source) => {
                this.source = source;
                this.getCloudFrequencies();
            });
        }
    }

    checkIfNeedToShowErrMessage(): boolean | null {
        if (this.source && this.source.name) {
            return this.source.name.trim().length < 2 && this.submitted;
        }
        return true;
    }

    getCloudFrequencies(): void {
        this.sourcesHttpService.getCloudFrequencies().then((cloudFrequencies: Array<CloudFrequency>) => {
            this.cloudFrequencies = cloudFrequencies;
            this.setCloudFrequency();
        });
    }

    setCloudFrequency(): void {
        if (this.source?.source_type?.name === AddingSourceType.cloud) {
            const frequencyIndex = this.cloudFrequencies.findIndex(x => x.id === this.source?.frequency_id);
            if (frequencyIndex !== -1) {
                console.log('EEE');
                this.source.frequency = this.cloudFrequencies[frequencyIndex];
            }
        }
    }

    editSource(): void {
        this.submitted = true;
        if (this.source) {
            if (this.generalHelper.checkExtensionValidity(this.source?.extensions)) {
                this.sourcesHttpService.editSource(this.source).then((source: Source) => {
                    console.log('Updated source: ', source);
                    this.sourceEdited.emit(this.source);
                });
            } else {
                this.toasterHelperService.showWarning('Please select file extension');
            }
        }
    }

    selectCloudFrequency(frequency: CloudFrequency): void {
        if (this.source) {
            this.source.frequency = frequency;
            this.source.frequency_id = frequency.id;
        }
    }

}
