<!-- This example requires Tailwind CSS v2.0+ -->
<div class="h-screen flex fixed bg-gray-800">
    <!-- Off-canvas menu for mobile, show/hide based on off-canvas menu state. -->
    <div class="fixed inset-0 flex z-40 xs:hidden" role="dialog" aria-modal="true"
        [ngStyle]="{'visibility': isOpen ? 'visible' : 'hidden'}">
        <!--
      Off-canvas menu overlay, show/hide based on off-canvas menu state.

      Entering: "transition-opacity ease-linear duration-300"
        From: "opacity-0"
        To: "opacity-100"
      Leaving: "transition-opacity ease-linear duration-300"
        From: "opacity-100"
        To: "opacity-0"
    -->
        <div class="fixed inset-0 bg-gray-600 bg-opacity-75" aria-hidden="true"></div>

        <!--
      Off-canvas menu, show/hide based on off-canvas menu state.

      Entering: "transition ease-in-out duration-300 transform"
        From: "-translate-x-full"
        To: "translate-x-0"
      Leaving: "transition ease-in-out duration-300 transform"
        From: "translate-x-0"
        To: "-translate-x-full"
    -->
        <div class="relative flex-1 flex flex-col max-w-xs w-full bg-gray-800">
            <!--
        Close button, show/hide based on off-canvas menu state.

        Entering: "ease-in-out duration-300"
          From: "opacity-0"
          To: "opacity-100"
        Leaving: "ease-in-out duration-300"
          From: "opacity-100"
          To: "opacity-0"
      -->
            <div class="absolute top-0 right-0 -mr-12 pt-2">
                <button
                    class="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                    (click)="isOpen = false">
                    <span class="sr-only">Close sidebar</span>
                    <!-- Heroicon name: outline/x -->
                    <svg class="h-6 w-6 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                        stroke="currentColor" aria-hidden="true">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                            d="M6 18L18 6M6 6l12 12" />
                    </svg>
                </button>
            </div>

            <div class="flex flex-col h-0 flex-1 bg-gray-800 px-2">
                <div class="flex-1 flex flex-col pt-5">
                    <div class="flex justify-center items-center flex-shrink-0 px-4">
                        <img class="h-12 w-auto cursor-pointer" src="../../../assets/images/atyso_white_logo.svg" alt="Atyso-logo" (click)="routeToPage(PAGES_AND_PATHS.useCases.pagePath)">
                    </div>
                    <div class="border-t border-gray-700 mt-6">
                        <span class="text-brand text-xs font-bold">Menu</span>
                    </div>
                    <nav class="mt-2 flex-1 bg-gray-800 space-y-5 pb-4 overflow-y-auto">
                        <!-- Current: "bg-gray-900 text-white", Default: "text-gray-300 hover:bg-gray-700 hover:text-white" -->
                        <a (click)="routeToPage(PAGES_AND_PATHS.useCases.pagePath)"
                            [ngClass]="{'bg-gray-900': getRoute() === PAGES_AND_PATHS.useCases.pagePath }"
                            class="bg-gray-900 text-gray-300 group flex items-center px-2 py-2 font-normal text-base rounded-md cursor-pointer">
                            <!--
                Heroicon name: outline/home

                Current: "text-gray-300", Default: "text-gray-400 group-hover:text-gray-300"
              -->
                            <img class="mr-3" src="../../../assets/images/icons/usecase.svg" alt=""> Use cases
                        </a>

                        <a (click)="routeToPage(PAGES_AND_PATHS.sources.pagePath)"
                            [ngClass]="{'bg-gray-900': getRoute() === PAGES_AND_PATHS.sources.pagePath }"
                            class="text-gray-300 hover:bg-gray-700 hover:text-white group flex items-center px-2 py-2 font-normal text-base rounded-md cursor-pointer">
                            <!-- Heroicon name: outline/users -->
                            <img class="mr-3" src="../../../assets/images/icons/sources.svg" alt="">Sources
                        </a>


                        <a (click)="routeToPage(PAGES_AND_PATHS.testPage.pagePath)"
                            [ngClass]="{'bg-gray-900': getRoute() === PAGES_AND_PATHS.testPage.pagePath }"
                            class="text-gray-300 hover:bg-gray-700 hover:text-white group flex items-center px-2 py-2 font-normal text-base rounded-md cursor-pointer">
                            <!-- Heroicon name: outline/users -->
                            <img class="mr-3" src="../../../assets/images/icons/sources.svg" alt="">TEST PAGE (TESTING
                            PURPOSE)
                        </a>


                        <a (click)="routeToPage(PAGES_AND_PATHS.destinations.pagePath)"
                            [ngClass]="{'bg-gray-900': getRoute() === PAGES_AND_PATHS.destinations.pagePath }"
                            class="text-gray-300 hover:bg-gray-700 hover:text-white group flex items-center px-2 py-2 font-normal text-base rounded-md cursor-pointer">
                            <!-- Heroicon name: outline/users -->
                            <img class="mr-3" src="../../../assets/images/icons/destinations.svg" alt=""> Destinations
                        </a>
                        <a (click)="routeToPage(PAGES_AND_PATHS.integrations.pagePath)"
                            [ngClass]="{'bg-gray-900': getRoute() === PAGES_AND_PATHS.integrations.pagePath }"
                            class="text-gray-300 hover:bg-gray-700 hover:text-white group flex items-center px-2 py-2 font-normal text-base rounded-md cursor-pointer">
                            <!-- Heroicon name: outline/users -->
                            <img class="mr-3" src="../../../assets/images/icons/integrations.svg" alt=""> Integrations
                        </a>

                        <div *ngIf="!isCompanyMember" class="border-t border-gray-700 mt-6">
                            <span class="text-brand text-xs font-bold">Company</span>
                        </div>

                        <a *ngIf="!isCompanyMember" (click)="routeToPage(PAGES_AND_PATHS.manage.pagePath)"
                            [ngClass]="getRoute() === PAGES_AND_PATHS.manage.pagePath ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white'"
                            class="group flex items-center px-2 py-2 font-normal text-base rounded-md cursor-pointer">
                            <!-- Heroicon name: outline/users -->
                            <img class="mr-3" src="../../../assets/images/icons/profile-2user.svg" alt="">Members
                        </a>

                        <div class="border-t border-gray-700 mt-6">
                            <span class="text-brand text-xs font-bold">Settings</span>
                        </div>
                        <a (click)="routeToPage(PAGES_AND_PATHS.userProfile.pagePath)"
                            [ngClass]="getRoute() === PAGES_AND_PATHS.userProfile.pagePath ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white'"
                            class="text-gray-300 hover:bg-gray-700 hover:text-white group flex items-center px-2 py-2 text-base rounded-md mb-6 cursor-pointer">
                            <!-- Heroicon name: outline/users -->
                            <img class="mr-3" src="../../../assets/images/icons/profile.svg" alt=""> Profile
                        </a>
                        <a (click)="routeToPage(PAGES_AND_PATHS.labels.pagePath)"
                            [ngClass]="getRoute() === PAGES_AND_PATHS.labels.pagePath ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white'"
                            class="text-gray-300 hover:bg-gray-700 hover:text-white group flex items-center px-2 py-2 text-base rounded-md mb-6 cursor-pointer">
                            <!-- Heroicon name: outline/users -->
                            <img class="mr-3" src="../../../assets/images/icons/notification-status.svg" alt=""> Labels
                        </a>
                    </nav>
                </div>
            </div>

            <div class="flex-shrink-0 p-4 border-t border-gray-700 mt-6 block">
                <div class="flex-shrink-0 w-full group block">
                    <div class="flex items-center">
                        <div class="" *ngIf="userInfo">
                            <p class="text-sm font-medium text-white">
                                {{userInfo.userName}}
                            </p>
                            <p class="text-xs font-medium text-gray-300 group-hover:text-gray-200">
                                {{userInfo.userRole}}
                            </p>
                            <form role="none">
                                <button (click)="logout()" type="button"
                                    class="text-orange-400 text-sm rounded-lg mt-2">
                                    Sign out
                                </button>
                            </form>
                        </div>
                        <div class="flex-shrink-0 pr-2 ml-auto relative">
                            <div *ngIf="isOpen"
                                class="bg-white origin-top-left absolute right-0 mt-2 w-56 bottom-full inset-x-1/2 shadow z-20 transform -translate-x-2/4 -translate-y-4 focus:outline-none rounded-md"
                                role="menu" aria-orientation="vertical" aria-labelledby="menu-button" tabindex="-1">
                                <div class="py-1" role="none">
                                    <!-- Active: "bg-gray-100 text-gray-900", Not Active: "text-gray-700" -->
                                    <a href="#" class="text-gray-700 block px-4 py-2 text-sm" role="menuitem"
                                        tabindex="-1" id="menu-item-0">Account settings</a>
                                    <a href="#" class="text-gray-700 block px-4 py-2 text-sm" role="menuitem"
                                        tabindex="-1" id="menu-item-1">Support</a>
                                    <a href="#" class="text-gray-700 block px-4 py-2 text-sm" role="menuitem"
                                        tabindex="-1" id="menu-item-2">License</a>
                                    <form role="none">
                                        <button (click)="logout()" type="button"
                                            class="text-gray-700 block w-full text-left px-4 py-2 text-sm"
                                            role="menuitem" tabindex="-1" id="menu-item-3">
                                            Sign out
                                        </button>
                                    </form>
                                </div>
                            </div>
                            <button
                                class="inline-flex items-center justify-center text-gray-400 bg-transparent focus:outline-none relative">
                                <!-- Heroicon name: solid/dots-vertical -->
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path d="M12 6.43994V9.76994" stroke="white" stroke-width="1.5"
                                        stroke-miterlimit="10" stroke-linecap="round" />
                                    <path
                                        d="M12.0209 2C8.34087 2 5.36087 4.98 5.36087 8.66V10.76C5.36087 11.44 5.08087 12.46 4.73087 13.04L3.46087 15.16C2.68087 16.47 3.22087 17.93 4.66087 18.41C9.44087 20 14.6109 20 19.3909 18.41C20.7409 17.96 21.3209 16.38 20.5909 15.16L19.3209 13.04C18.9709 12.46 18.6909 11.43 18.6909 10.76V8.66C18.6809 5 15.6809 2 12.0209 2Z"
                                        stroke="#9CA3AF" stroke-width="1.5" stroke-miterlimit="10"
                                        stroke-linecap="round" />
                                    <path
                                        d="M15.3299 18.8198C15.3299 20.6498 13.8299 22.1498 11.9999 22.1498C11.0899 22.1498 10.2499 21.7698 9.64992 21.1698C9.04992 20.5698 8.66992 19.7298 8.66992 18.8198"
                                        stroke="#9CA3AF" stroke-width="1.5" stroke-miterlimit="10" />
                                </svg>
                                <span
                                    class="-ml-2 px-1.5 py-0.5 absolute left-full -top-2 text-xs rounded-full flex bg-red-500 text-white">44</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="flex-shrink-0 w-14">
            <!-- Force sidebar to shrink to fit close icon -->
        </div>
    </div>

    <!-- Static sidebar for desktop -->
    <div class="hidden lg:flex lg:flex-shrink-0">
        <div class="flex flex-col w-64">
            <!-- Sidebar component, swap this element with another sidebar if you like -->
            <div class="flex flex-col h-0 flex-1 bg-gray-800 px-2">
                <div class="flex-1 flex flex-col pt-5">
                    <div class="flex justify-center items-center flex-shrink-0 px-4">
                        <img class="h-12 w-auto cursor-pointer" src="../../../assets/images/atyso_white_logo.svg" (click)="routeToPage(PAGES_AND_PATHS.useCases.pagePath)" alt="Atyso-logo">
                    </div>
                    <div class="border-t border-gray-700 mt-6">
                        <span class="text-brand text-xs font-bold">Menu</span>
                    </div>
                    <nav class="mt-2 flex-1 bg-gray-800 space-y-5 pb-4 overflow-y-auto">
                        <!-- Current: "bg-gray-900 text-white", Default: "text-gray-300 hover:bg-gray-700 hover:text-white" -->
                        <a (click)="routeToPage(PAGES_AND_PATHS.useCases.pagePath)"
                            [ngClass]="{'bg-gray-900': getRoute() === PAGES_AND_PATHS.useCases.pagePath }"
                            class="bg-gray-900 text-gray-300 group flex items-center px-2 py-2 font-normal text-base rounded-md cursor-pointer">
                            <!--
                Heroicon name: outline/home

                Current: "text-gray-300", Default: "text-gray-400 group-hover:text-gray-300"
              -->
                            <img class="mr-3" src="../../../assets/images/icons/usecase.svg" alt=""> Use cases
                        </a>

                        <a (click)="routeToPage(PAGES_AND_PATHS.sources.pagePath)"
                            [ngClass]="{'bg-gray-900': getRoute() === PAGES_AND_PATHS.sources.pagePath }"
                            class="text-gray-300 hover:bg-gray-700 hover:text-white group flex items-center px-2 py-2 font-normal text-base rounded-md cursor-pointer">
                            <!-- Heroicon name: outline/users -->
                            <img class="mr-3" src="../../../assets/images/icons/sources.svg" alt="">Sources
                        </a>


                        <!-- <a (click)="routeToPage(PAGES_AND_PATHS.testPage.pagePath)"
                           [ngClass]="{'bg-gray-900': getRoute() === PAGES_AND_PATHS.testPage.pagePath }"
                           class="text-gray-300 hover:bg-gray-700 hover:text-white group flex items-center px-2 py-2 font-normal text-base rounded-md cursor-pointer">
                            <img class="mr-3" src="../../../assets/images/icons/sources.svg" alt="">TEST PAGE (TESTING
                            PURPOSE)
                        </a> -->


                        <a (click)="routeToPage(PAGES_AND_PATHS.destinations.pagePath)"
                            [ngClass]="{'bg-gray-900': getRoute() === PAGES_AND_PATHS.destinations.pagePath }"
                            class="text-gray-300 hover:bg-gray-700 hover:text-white group flex items-center px-2 py-2 font-normal text-base rounded-md cursor-pointer">
                            <!-- Heroicon name: outline/users -->
                            <img class="mr-3" src="../../../assets/images/icons/destinations.svg" alt=""> Destinations
                        </a>
                        <a (click)="routeToPage(PAGES_AND_PATHS.integrations.pagePath)"
                            [ngClass]="{'bg-gray-900': getRoute() === PAGES_AND_PATHS.integrations.pagePath }"
                            class="text-gray-300 hover:bg-gray-700 hover:text-white group flex items-center px-2 py-2 font-normal text-base rounded-md cursor-pointer">
                            <!-- Heroicon name: outline/users -->
                            <img class="mr-3" src="../../../assets/images/icons/integrations.svg" alt=""> Integrations
                        </a>

                        <div *ngIf="!isCompanyMember" class="border-t border-gray-700 mt-6">
                            <span class="text-brand text-xs font-bold">Company</span>
                        </div>

                        <a *ngIf="!isCompanyMember" (click)="routeToPage(PAGES_AND_PATHS.manage.pagePath)"
                            [ngClass]="getRoute() === PAGES_AND_PATHS.manage.pagePath ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white'"
                            class="group flex items-center px-2 py-2 font-normal text-base rounded-md cursor-pointer">
                            <!-- Heroicon name: outline/users -->
                            <img class="mr-3" src="../../../assets/images/icons/profile-2user.svg" alt="">Members
                        </a>

                        <div class="border-t border-gray-700 mt-6">
                            <span class="text-brand text-xs font-bold">Settings</span>
                        </div>
                        <a (click)="routeToPage(PAGES_AND_PATHS.userProfile.pagePath)"
                            [ngClass]="getRoute() === PAGES_AND_PATHS.userProfile.pagePath ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white'"
                            class="text-gray-300 hover:bg-gray-700 hover:text-white group flex items-center px-2 py-2 text-base rounded-md mb-6 cursor-pointer">
                            <!-- Heroicon name: outline/users -->
                            <img class="mr-3" src="../../../assets/images/icons/profile.svg" alt=""> Profile
                        </a>
                        <a (click)="routeToPage(PAGES_AND_PATHS.labels.pagePath)"
                            [ngClass]="getRoute() === PAGES_AND_PATHS.labels.pagePath ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white'"
                            class="text-gray-300 hover:bg-gray-700 hover:text-white group flex items-center px-2 py-2 text-base rounded-md mb-6 cursor-pointer">
                            <!-- Heroicon name: outline/users -->
                            <img class="mr-3" src="../../../assets/images/icons/notification-status.svg" alt=""> Labels
                        </a>
                        <div class="border-t border-gray-700 mt-6 block"></div>
                    </nav>

                    <app-last-synced>
                    </app-last-synced>

                    <div class="flex-shrink-0 p-4 border-t border-gray-700 mt-6 block">
                        <div class="flex-shrink-0 w-full group block">
                            <div class="flex items-center">
                                <div class="" *ngIf="userInfo">
                                    <p class="text-sm font-medium text-white">
                                        {{userInfo.userName}}
                                    </p>
                                    <p class="text-xs font-medium text-gray-300 group-hover:text-gray-200">
                                        {{userInfo.userRole}}
                                    </p>
                                    <form role="none">
                                        <button (click)="logout()" type="button"
                                            class="text-orange-400 text-sm rounded-lg mt-2">
                                            Sign out
                                        </button>
                                    </form>
                                </div>
                                <div class="flex-shrink-0 pr-2 ml-auto relative">
                                    <div *ngIf="isOpen"
                                        class="bg-white origin-top-left absolute right-0 mt-2 w-56 bottom-full inset-x-1/2 shadow z-20 transform -translate-x-2/4 -translate-y-4 focus:outline-none rounded-md"
                                        role="menu" aria-orientation="vertical" aria-labelledby="menu-button"
                                        tabindex="-1">
                                        <div class="py-1" role="none">
                                            <!-- Active: "bg-gray-100 text-gray-900", Not Active: "text-gray-700" -->
                                            <a href="#" class="text-gray-700 block px-4 py-2 text-sm" role="menuitem"
                                                tabindex="-1" id="menu-item-0">Account settings</a>
                                            <a href="#" class="text-gray-700 block px-4 py-2 text-sm" role="menuitem"
                                                tabindex="-1" id="menu-item-1">Support</a>
                                            <a href="#" class="text-gray-700 block px-4 py-2 text-sm" role="menuitem"
                                                tabindex="-1" id="menu-item-2">License</a>
                                            <form role="none">
                                                <button (click)="logout()" type="button"
                                                    class="text-gray-700 block w-full text-left px-4 py-2 text-sm"
                                                    role="menuitem" tabindex="-1" id="menu-item-3">
                                                    Sign out
                                                </button>
                                            </form>
                                        </div>
                                    </div>
                                    <button style="opacity:0.5"
                                        class="inline-flex items-center justify-center text-gray-400 bg-transparent focus:outline-none relative cursor-default ">
                                        <!-- Heroicon name: solid/dots-vertical -->
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path d="M12 6.43994V9.76994" stroke="white" stroke-width="1.5"
                                                stroke-miterlimit="10" stroke-linecap="round" />
                                            <path
                                                d="M12.0209 2C8.34087 2 5.36087 4.98 5.36087 8.66V10.76C5.36087 11.44 5.08087 12.46 4.73087 13.04L3.46087 15.16C2.68087 16.47 3.22087 17.93 4.66087 18.41C9.44087 20 14.6109 20 19.3909 18.41C20.7409 17.96 21.3209 16.38 20.5909 15.16L19.3209 13.04C18.9709 12.46 18.6909 11.43 18.6909 10.76V8.66C18.6809 5 15.6809 2 12.0209 2Z"
                                                stroke="#9CA3AF" stroke-width="1.5" stroke-miterlimit="10"
                                                stroke-linecap="round" />
                                            <path
                                                d="M15.3299 18.8198C15.3299 20.6498 13.8299 22.1498 11.9999 22.1498C11.0899 22.1498 10.2499 21.7698 9.64992 21.1698C9.04992 20.5698 8.66992 19.7298 8.66992 18.8198"
                                                stroke="#9CA3AF" stroke-width="1.5" stroke-miterlimit="10" />
                                        </svg>
                                        <span
                                            class="-ml-2 px-1.5 py-0.5 absolute left-full -top-2 text-xs rounded-full flex bg-red-500 text-white">44</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <app-notifications></app-notifications>
    </div>
</div>
