import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {PpgFile} from '../../derived-classes/ppg-file';
import {SourcesHttpServiceHelper} from '../../services/http-services/sources-http.service';

@Component({
    selector: 'app-uploaded-files',
    templateUrl: './uploaded-files.component.html'
})
export class UploadedFilesComponent implements OnInit {

    @Input() uploadedFiles: Array<PpgFile> | undefined = [];
    @Output() filesChanged = new EventEmitter<Array<PpgFile>>();

    constructor(public sourcesHttpServiceHelper: SourcesHttpServiceHelper) {
    }

    ngOnInit(): void {
    }

    calcPercentageOfFilesUploaded(totalValue: number, partialValue: number): number {
        return (100 * partialValue) / totalValue;
    }

}
