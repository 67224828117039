import {Component, ElementRef, EventEmitter, Input, OnInit, Output, Renderer2, ViewChild} from '@angular/core';
import {Destination} from '../../../interfaces/destination-interfaces';
import {DestinationsHttpService} from '../../../services/http-services/destinations-http.service';
import {GeneralHelperService} from '../../../services/helpers/general-helper.service';

@Component({
    selector: 'app-edit-destination-modal',
    templateUrl: './edit-destination-modal.component.html'
})
export class EditDestinationModalComponent implements OnInit {

    @Input() destination: Destination | undefined;
    destinationForEdit: Destination | undefined;
    submitted = false;
    @Output() modalClosed = new EventEmitter<boolean>();
    @Output() destinationEdited = new EventEmitter<Destination>();
    opened = false;
    @ViewChild('modal') modal: ElementRef | undefined;

    constructor(private destinationHttpService: DestinationsHttpService,
                private renderer: Renderer2,
                private generalHelperService: GeneralHelperService) {
    }

    ngOnInit(): void {
        this.destinationForEdit = JSON.parse(JSON.stringify(this.destination));
        this.opened = true;
        setTimeout(() => {
            this.listenToClickType();
        });
    }

    listenToClickType(): void {
        this.renderer.listen('window', 'click', (e: Event) => {
            if (this.generalHelperService.checkIfClickedOutsideOfTheElement(undefined, this.modal, e)) {
                if (this.opened) {
                    this.modalClosed.emit(true);
                    this.opened = false;
                }
            }
        });
    }

    checkIfNeedToShowErrMessage(): boolean {
        return !(this.destinationForEdit && this.destinationForEdit.name && this.destinationForEdit?.name.trim().length > 2);
    }

    editDestination(): void {
        this.submitted = true;
        if (this.destinationForEdit && this.destinationForEdit.name && this.destinationForEdit?.name.trim().length > 2) {
            if (this.destinationForEdit) {
                this.destinationHttpService.editDestination(this.destinationForEdit).then((destination: Destination) => {
                    if (this.destination && this.destinationForEdit) {
                        this.destination.name = this.destinationForEdit.name;
                    }
                    this.submitted = false;
                    this.destinationEdited.emit(this.destinationForEdit);
                    this.modalClosed.emit(true);
                    this.opened = false;
                }, error => {
                    this.submitted = false;
                });
            }
        } else {
            console.log('dsadsa')
        }
    }

}
