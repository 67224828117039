import {trigger, style, transition, animate, state} from '@angular/animations';
import {modalSliderTime} from '../constants/general-variables';

export const PositionChangeAnimation = [
    trigger('slideInOut', [
        transition('in => out',
            animate(modalSliderTime,
                style({
                    transform: 'translateX(0)',
                })
            )),
        transition('out => in',
            animate(modalSliderTime,
                style({
                    transform: 'translateX(100%)',
                })
            ))
    ])
];

