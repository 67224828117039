import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {OnboardingSection} from './onboarding-section';

@Component({
    selector: 'app-onboarding',
    templateUrl: './onboarding.component.html'
})
export class OnboardingComponent implements OnInit {

    @Input() section: OnboardingSection | undefined | null;
    @Output() finishedOnboarding = new EventEmitter<boolean>();
    @Output() nextSection = new EventEmitter<boolean>();

    constructor() {
    }

    ngOnInit(): void {
    }

}
