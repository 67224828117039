<app-use-case-data-search-with-filters *ngIf="useCaseId"
        [searchAndFilter]="searchAndFilter"
        [useCaseId]="useCaseId"
        (searchAndFilterChange)="searchAndFilter = {query: $event.query, source: $event.source, syncSource: $event.syncSource}">
</app-use-case-data-search-with-filters>
<div class="grid grid-cols-12 gap-4 pb-10 bg-gray-100">
    <div class="col-span-1 bg-white border-r border-gray-200">
        <div [ngClass]="{'bg-gray-50': activeTab === 'all'}" class="border-b border-gray-200 flex flex-col items-center p-2 cursor-pointer" (click)="activeTab = 'all'">
            <img src="../../../assets/images/icons/notification-status.svg" alt="">
            <span>All Data</span>
        </div>
        <div [ngClass]="{'bg-gray-50': activeTab !== 'all'}" class="border-b border-gray-200 flex flex-col items-center p-2 cursor-pointer" style="opacity:0.5" (click)="activeTab = 'query'">
            <img src="../../../assets/images/icons/notification-circle.svg" alt="">
            <span>Query</span>
        </div>
    </div>
    <div class="col-span-11 pr-6 scroll-data overflow-y-auto">
        <app-use-case-all-body-data-with-pagination *ngIf="activeTab === 'all'"
                                                    [useCaseId]="useCaseId"
                                                    [searchAndFilter]="searchAndFilter"
                                                    (changedView)="changedView.emit($event)">
        </app-use-case-all-body-data-with-pagination>
        <app-use-case-query-body-data-with-pagination *ngIf="activeTab === 'query'"
                                                    [useCaseId]="useCaseId"
                                                    [searchAndFilter]="searchAndFilter"
                                                    (changedView)="changedQueryView.emit($event)">
        </app-use-case-query-body-data-with-pagination>
    </div>
</div>
