import { Component, OnInit } from '@angular/core';
import { ToasterHelperService } from 'src/app/services/helpers/toaster-helper.service';
import { AuthHttpService } from 'src/app/services/http-services/auth-http.service';
import { LocalStorageProperties, Users } from '../../enums/general-enums';

@Component({
    selector: 'app-manage',
    templateUrl: './manage.component.html'
})
export class ManageComponent implements OnInit {
    isNewForm = false;
    isEditForm = false;
    loggedUser = localStorage.getItem(LocalStorageProperties.userRole);
    listOfCompanyAdmins: any[] = [];
    listOfCompanyMembers: any[] = [];
    userToUpdate: any;
    displayMembers = true;
    displayPending = false;
    tabActive = 'tab-members-show';
    showAddMemberModal = false;

    constructor(private authService: AuthHttpService, private toasterService: ToasterHelperService) {
    }

    ngOnInit(): void {
    }

    selectTab(tabSelected: string): void {
        this.tabActive = tabSelected;

        if (this.tabActive === 'tab-members-show') {
            this.displayMembers = true;
            this.displayPending = false;
        } else {
            this.displayMembers = false;
            this.displayPending = true;
        }
    }

    toggleForm(): void {
        this.isNewForm = !this.isNewForm;
    }

    createNewUser(formData: any): void {
        if (this.loggedUser === Users.superAdmin) {
            this.authService.inviteNewCompanyAdmin(formData).subscribe((response: any) => {
                this.toasterService.showSuccess('Company Admin invited!');
                this.isNewForm = false;
            }, (error: any) => {
                this.toasterService.showError(error);
            });
        } else if (this.loggedUser === Users.companyAdmin) {
            this.authService.inviteNewCompanyMember(formData).subscribe((response: any) => {
                this.toasterService.showSuccess('Company Member invited!');
            }, (error: any) => {
                this.toasterService.showError(error);
            });
        }
    }

    refreshManageComponent(): void {

    }

}
