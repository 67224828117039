import {Component, ElementRef, EventEmitter, Input, OnInit, Output, Renderer2, ViewChild} from '@angular/core';
import {
    Destination, DestinationOutputTypes,
    DestinationType, DestinationTypeWithOutput, DestinationWithAPIKey
} from '../../../interfaces/destination-interfaces';
import {DestinationsHttpService} from '../../../services/http-services/destinations-http.service';
import {AddDestination} from './add-destination';
import {DestinationOutputTypesEnums, DestinationTypesEnums} from '../../../enums/destination-enums';
import {GeneralHelperService} from '../../../services/helpers/general-helper.service';

@Component({
    selector: 'app-add-destination-modal',
    templateUrl: './add-destination-modal.component.html'
})
export class AddDestinationModalComponent implements OnInit {

    @Input() useCaseId = 0;
    @Input() destinationTypes: Array<DestinationType> = [];
    addDestinationClass: AddDestination = new AddDestination();
    submitted = false;
    outputTypes: Array<DestinationOutputTypes> = [];
    showDestinationDetailsModal: { destinationWithAPIKey: DestinationWithAPIKey | null, show: boolean } | undefined;
    opened = false;
    @ViewChild('modal') modal: ElementRef | undefined;
    @Output() modalClosed = new EventEmitter<boolean>();
    @Output() destinationAdded = new EventEmitter<Destination>();

    constructor(private destinationHttpService: DestinationsHttpService,
                private renderer: Renderer2,
                private generalHelperService: GeneralHelperService) {
    }

    ngOnInit(): void {
        this.configureViewAndFetchData();
        this.opened = true;
        setTimeout(() => {
            this.listenToClickType();
        });
    }

    listenToClickType(): void {
        this.renderer.listen('window', 'click', (e: Event) => {
            if (this.generalHelperService.checkIfClickedOutsideOfTheElement(undefined, this.modal, e)) {
                if (this.opened) {
                    this.modalClosed.emit(true);
                    this.opened = false;
                }
            }
        });
    }

    configureViewAndFetchData(): void {
        if (this.destinationTypes.length === 0) {
            this.getDestinationTypes();
        } else {
            this.getDestinationTypeWithOutput(this.destinationTypes[0]);
            this.addDestinationClass.destinationType = this.destinationTypes[0];
        }
        this.addDestinationClass.useCaseId = Number(this.useCaseId);
    }

    onCloudIntegrationChanged(outputType: DestinationOutputTypes, event: any): void {
        if (this.addDestinationClass) {
            if (this.addDestinationClass.outputType?.id === outputType.id) {
                event.preventDefault();
            }
        }
        if (this.addDestinationClass) {
            this.addDestinationClass.outputType = outputType;
        }
    }

    checkIfNeedToShowErrMessage(): boolean | null {
        return this.addDestinationClass && this.addDestinationClass.name.trim().length < 2 && this.submitted;
    }

    getDestinationTypes(): void {
        this.destinationHttpService.getDestinationsTypes().then((response: Array<DestinationType>) => {
            this.destinationTypes = response;
            this.getDestinationTypeWithOutput(this.destinationTypes[0]);
        });
    }

    getDestinationTypeWithOutput(destinationType: DestinationType): void {
        this.destinationHttpService.getSingleDestinationTypeWithOutputTypes(destinationType).then((response: DestinationTypeWithOutput) => {
            this.outputTypes = response.output_types;
            this.addDestinationClass.outputType = this.outputTypes[0];
        });
    }

    selectIntegrationType(integration: any): void {
        this.getDestinationTypeWithOutput(integration);
    }

    saveDestination(): void {
        this.submitted = true;
        this.destinationHttpService.addDestination(this.addDestinationClass).then((response: DestinationWithAPIKey) => {
            this.destinationAdded.emit(response.destination);
            if (response.destination.output_type.destination_type.name === DestinationTypesEnums.businessIntelligence && response.destination.output_type.name === DestinationOutputTypesEnums.api) {
                this.showDestinationDetailsModal = {destinationWithAPIKey: response, show: true};
            }
            this.modalClosed.emit(true);
        });
    }

    resetDestinationDetailsModal(): void {
        this.showDestinationDetailsModal = {destinationWithAPIKey: null, show: false};
    }

}
