<div class="w-full overflow-y-auto max-h-screen pb-10 md:pb-0"
     *ngIf="showUseCases(initialUseCases)">
    <div class="flex mb-4">
        <div class="bg-white flex flex-col w-full">
            <app-use-cases-header (showModal)="showModal = true"
                                  [activeCategory]="categoryView"
                                  (changedCategoryView)="onChangedCategoryView($event);
                                  searchQuery = '';">
            </app-use-cases-header>
            <app-use-cases-search-filters
                    [searchQuery]="searchQuery"
                    (changedDisplayType)="onChangedDisplayType($event)"
                    (searchChanged)="onSearchChanged($event)">
            </app-use-cases-search-filters>
        </div>
    </div>

    <div class="text-sm text-gray-500 font-semibold px-6 py-4 flex cursor-pointer" (click)="collapse()">
        Pinned Personal Use Cases
        <div class="ml-auto">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 transform transition"
                 [class]="collapsedPinned ? '-rotate-90' : 'rotate-0'" fill="none" viewBox="0 0 24 24"
                 stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7"/>
            </svg>
        </div>
    </div>

    <!-- Cards grid view -->
    <div [hidden]="!collapsedPinned" class="swiper-width">
        <!--class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 gap-4 px-6"-->
        <div *ngIf="viewType === UseCasesViewTypes.grid">
            <!-- Swiper -->
            <swiper [config]="config" #swiper (slideChange)="onActiveSwiperIndexChange($event)">
                <ng-template
                        class="grid grid-cols-1 sm:grid-cols-1 lg:grid-cols-5 xl:grid-cols-5 2xl:grid-cols-5 gap-4 px-6 pr-0"
                        *ngFor="let num of calculateNumberOfSlidesPerPinnedUseCases()" swiperSlide>
                    <app-use-case-single-grid *ngFor="let useCase of returnUseCases(num)" @fadingIn
                                              [useCase]="useCase"
                                              [isPinned]="true"
                                              (togglePinUseCase)="onUseCaseTogglePin(true, $event, categoryView)"
                                              (deleteUseCase)="promptDeleteUseCaseModal($event, true)"
                                              (editUseCase)="showModalForUseCaseEdit($event, true)">
                    </app-use-case-single-grid>
                </ng-template>
            </swiper>
        </div>

        <!-- Empty State -->
        <div class="flex flex-col justify-center items-center py-10 space-y-4 text-sm leading-5 font-medium text-gray-500"
             *ngIf="useCases?.pinnedUseCases?.length === 0">
            <img class="block mb-4" src="../../../assets/images/icons/pin-empty.svg" alt="">
            No pinned Use Cases yet.
        </div>
        <!-- End Empty State -->
    </div>
    <!-- ./Cards grid view -->

    <!-- Cards list view -->
    <div [hidden]="!collapsedPinned" class="swiper-width">
        <div class="px-6 pl-6 pr-0" *ngIf="viewType === UseCasesViewTypes.list">
            <div class="">
                <!-- Swiper -->
                <swiper [config]="config" #swiper (slideChange)="onActiveSwiperIndexChange($event)">
                    <ng-template *ngFor="let num of calculateNumberOfSlidesPerPinnedUseCases()" swiperSlide>
                        <ul role="list" class="divide-y divide-gray-200 space-y-4">
                            <app-use-case-single-list *ngFor="let useCase of returnUseCases(num)" @fadingIn
                                                      [useCase]="useCase"
                                                      [isPinned]="true"
                                                      (togglePinUseCase)="onUseCaseTogglePin(true, $event, categoryView)"
                                                      (deleteUseCase)="promptDeleteUseCaseModal($event, true)"
                                                      (editUseCase)="showModalForUseCaseEdit($event, true)">
                            </app-use-case-single-list>
                        </ul>
                    </ng-template>
                </swiper>
            </div>
        </div>
    </div>
    <!-- ./Cards list view -->

    <!-- Indicator Slider -->
    <div class="flex space-x-2 mt-2 justify-center"
         *ngIf="useCases?.pinnedUseCases?.length! > 0">
        <span class='w-6 h-1 bg-gray-400 cursor-pointer'
              *ngFor="let num of calculateNumberOfSlidesPerPinnedUseCases()"
              [ngClass]="{'bg-brand': activeSwiperIndex == num}"
              (click)="slideTo(num)">
        </span>
    </div>
    <!-- End Indicator Slider -->
    <app-pagination-details-selector [currentPaginationNum]="authHelperService.pagination"
                                     *ngIf="showUseCases(useCases)"
                                     (selectedPaginationDetails)="onSelectedPaginationDetails($event)">
    </app-pagination-details-selector>

    <!-- Cards grid view -->

    <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 gap-4 px-6"
         *ngIf="viewType === UseCasesViewTypes.grid">
        <app-use-case-single-grid *ngFor="let useCase of useCases?.allUseCases?.data" [useCase]="useCase" @fadingIn
                                  [isPinned]="false"
                                  (togglePinUseCase)="onUseCaseTogglePin(false, $event, categoryView)"
                                  (deleteUseCase)="promptDeleteUseCaseModal($event, false)"
                                  (editUseCase)="showModalForUseCaseEdit($event, false)">
        </app-use-case-single-grid>
    </div>

    <!-- ./Cards grid view -->

    <!-- Cards list view -->
    <div class="px-6 py-4" *ngIf="viewType === UseCasesViewTypes.list">
        <div class="">
            <ul role="list" class="divide-y divide-gray-200 space-y-4">
                <app-use-case-single-list *ngFor="let useCase of useCases?.allUseCases?.data" [useCase]="useCase"
                                          @fadingIn
                                          [isPinned]="false"
                                          (togglePinUseCase)="onUseCaseTogglePin(false, $event, categoryView)"
                                          (deleteUseCase)="promptDeleteUseCaseModal($event, false)"
                                          (editUseCase)="showModalForUseCaseEdit($event, false)">
                </app-use-case-single-list>
            </ul>
        </div>
    </div>

    <!-- Pagination -->
    <div class="px-6 mt-4" *ngIf="showUseCases(useCases)">
        <div class="flex w-full justify-between items-center border-t border-gray-200 pt-2">
            <app-pagination-data-details [pagination]="useCases?.allUseCases?.pagination">
            </app-pagination-data-details>
            <app-ppg-pagination [currentPage]="useCases?.allUseCases?.pagination?.currentPage"
                                [pagId]="'js-use-case-pag'"
                                [perPage]="useCases?.allUseCases?.pagination?.perPage"
                                [totalItems]="useCases?.allUseCases?.pagination?.totalItems"
                                [from]="useCases?.allUseCases?.pagination?.from"
                                [to]="useCases?.allUseCases?.pagination?.to"
                                (pageChanged)="onPageChanged($event)">
            </app-ppg-pagination>
        </div>
    </div>
    <!-- End Pagination -->

    <!-- ./Cards list view -->
    <app-ppg-no-results *ngIf="!showUseCases(useCases) && useCasesFetchRequestFinished && showUseCases(initialUseCases)"
                        [title]="'No results'" [subtitle]="'Looks like there are no Use cases with such name.'">
    </app-ppg-no-results>

</div>
<app-delete-confirmation-modal *ngIf="deleteUseCaseModal?.show"
                               [deleteTitle]="deleteUseCaseModal?.useCase?.name"
                               [objToEmitBack]="deleteUseCaseModal?.useCase"
                               (modalClosed)="onUseCaseDeleteModalClosed()"
                               (deleted)="onUseCaseDelete($event)">
</app-delete-confirmation-modal>
<app-create-first-use-case *ngIf="!showUseCases(initialUseCases) && useCasesFetchRequestFinished"
                           (useCaseCreated)="getUseCases();showModal = false;">
</app-create-first-use-case>

<app-onboarding-wrapper *ngIf="authHelperService.showGuideToUser()"
                        (finishedOnboarding)="onFinishedOnboarding()">
</app-onboarding-wrapper>

<!-- Create new source modal -->
<app-modal [classess]="'max-w-2xl'" *ngIf="editUseCaseModal?.show" (modalClosed)="editUseCaseModal!.show = false">
    <div modal-header>
        <h3 class="mt-2 font-medium text-lg leading-6 text-gray-900">Edit Use Case</h3>
    </div>
    <div modal-body>
        <div class="mt-6">
            <app-edit-use-case [useCaseForEdit]="editUseCaseModal"
                               (modalClosed)="restoreEditUseCaseModalToDefault()"
                               (useCaseEdited)="onUseCaseEdited($event)">
            </app-edit-use-case>
        </div>
    </div>
</app-modal>
<!-- ./Create new source modal -->
<!--    <app-create-first-use-case-single></app-create-first-use-case-single>-->
<!-- Create new source modal -->
<app-modal [classess]="'max-w-2xl'" *ngIf="showModal" (modalClosed)="showModal = false">
    <div modal-header>
        <h3 class="mt-2 font-medium text-lg leading-6 text-gray-900">Create new Use Case</h3>
    </div>
    <div modal-body>
        <div class="mt-6">
            <app-create-use-case (modalClosed)="showModal = false"
                                 (useCaseCreated)="getUseCases();showModal = false;">
            </app-create-use-case>
        </div>
    </div>
</app-modal>
<!-- ./Create new source modal -->
