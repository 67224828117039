<div class="bg-white border-b border-gray-200">
    <div class="flex flex-col md:flex-row space-y-2 w-full justify-between items-center px-6 py-4">
        <h1 class="text-2xl text-gray-900 leading-7 font-bold">Members</h1>
        <div class="flex">
            <!-- Current: "bg-gray-100 text-gray-700", Default: "text-gray-500 hover:text-gray-700" -->
            <a (click)="selectTab('tab-members-show')"
               [ngClass]="displayMembers ? 'bg-gray-200 text-gray-700' : 'text-gray-500 hover:text-gray-700'"
               class="p-2 rounded-md text-sm font-medium mx-2 cursor-pointer">
                Registered
            </a>
            <a (click)="selectTab('tab-invites-show')"
               [ngClass]="displayPending ? 'bg-gray-200 text-gray-700' : 'text-gray-500 hover:text-gray-700'"
               class="p-2 rounded-md text-sm font-medium mx-2 cursor-pointer">
                Pending
            </a>
        </div>
        <button type="button" (click)="showAddMemberModal = true"
                class="inline-flex items-center px-3 py-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500">
            <svg xmlns="http://www.w3.org/2000/svg" class="-ml-0.5 mr-2 h-4 w-4" viewBox="0 0 20 20"
                 fill="currentColor">
                <path fill-rule="evenodd"
                      d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z"
                      clip-rule="evenodd"/>
            </svg>
            Add New Member
        </button>
    </div>
</div>


<div class="flex flex-col w-full px-6 py-4">
    <app-members *ngIf="displayMembers"></app-members>
    <app-invites *ngIf="displayPending"></app-invites>
    <!-- Create new source modal -->
    <app-modal [classess]="'max-w-2xl'" *ngIf="showAddMemberModal" (modalClosed)="showAddMemberModal = false">
        <div modal-header>
            <h3 class="font-medium text-2xl text-gray-700">Add new member</h3>
        </div>
        <div modal-body>
            <div class="mt-6">
                <app-new-user *ngIf="showAddMemberModal" (newUser)="createNewUser($event)">
                </app-new-user>
            </div>
        </div>
    </app-modal>
</div>
