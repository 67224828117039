import {Component, Output, EventEmitter, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {UseCasesHttpService} from '../../../services/http-services/use-cases-http.service';
import {UseCase, UseCaseType} from '../../../interfaces/use-case-interfaces';
import {UseCaseHelperService} from '../../../services/helpers/use-case-helper.service';

@Component({
    selector: 'app-create-use-case',
    templateUrl: './create-use-case.component.html',
})
export class CreateUseCaseComponent implements OnInit {

    useCaseForm = new FormGroup({
        useCaseName: new FormControl({value: '', disabled: false}, Validators.required),
        forms: new FormControl({value: false, disabled: true}),
        tables: new FormControl(),
        sentences: new FormControl({value: false, disabled: true})
    });
    types: Array<UseCaseType> = [];
    @Output() useCaseCreated = new EventEmitter<UseCase>();
    @Output() modalClosed = new EventEmitter();

    constructor(private useCaseHttp: UseCasesHttpService,
                private useCaseHelper: UseCaseHelperService) {
    }

    ngOnInit(): void {
        this.getUseCaseTypes();
    }

    getUseCaseTypes(): void {
        this.useCaseHttp.getUseCaseTypes().then((types: Array<UseCaseType>) => {
            types.forEach((type: UseCaseType) => {
                this.useCaseForm.addControl(type.name, new FormControl({value: false, disabled: type.active}));
            });
            this.types = types;
        });
    }

    createUseCase(): void {
        this.useCaseHelper.createUseCase(this.useCaseForm, this.types).then((useCase: UseCase) => {
            this.useCaseCreated.emit(useCase);
        });
    }

    closeModal(): void {
        this.modalClosed.emit(true);
    }
}
