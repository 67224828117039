<div class="flex flex-col md:flex-row px-6 py-4 items-center">
    <div class="relative w-full md:w-auto flex items-stretch flex-grow focus-within:z-10">
        <div class="absolute inset-y-0 left-0 flex items-center pointer-events-none">

            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M11 20C15.9706 20 20 15.9706 20 11C20 6.02944 15.9706 2 11 2C6.02944 2 2 6.02944 2 11C2 15.9706 6.02944 20 11 20Z"
                    stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path
                    d="M18.9304 20.6898C19.4604 22.2898 20.6704 22.4498 21.6004 21.0498C22.4504 19.7698 21.8904 18.7198 20.3504 18.7198C19.2104 18.7098 18.5704 19.5998 18.9304 20.6898Z"
                    stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>

        </div>
        <app-ppg-search-input [searchQuery]="searchQuery"
                              (searchChanged)="searchChanged.emit($event)"></app-ppg-search-input>
    </div>

    <div class="hidden md:flex ml-auto">
        <!--TODO Zoca nabaviti i ubaciti inactive ikonice-->
        <button class="border border-gray-200 bg-white hover:bg-gray-50 rounded-md px-3 py-2"
                (click)="changedDisplayType.emit(UseCasesViewTypes.grid)">
            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M1 3C1 1.89543 1.89543 1 3 1H5C6.10457 1 7 1.89543 7 3V5C7 6.10457 6.10457 7 5 7H3C1.89543 7 1 6.10457 1 5V3Z"
                    stroke="#6B7280" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path
                    d="M11 3C11 1.89543 11.8954 1 13 1H15C16.1046 1 17 1.89543 17 3V5C17 6.10457 16.1046 7 15 7H13C11.8954 7 11 6.10457 11 5V3Z"
                    stroke="#6B7280" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path
                    d="M1 13C1 11.8954 1.89543 11 3 11H5C6.10457 11 7 11.8954 7 13V15C7 16.1046 6.10457 17 5 17H3C1.89543 17 1 16.1046 1 15V13Z"
                    stroke="#6B7280" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path
                    d="M11 13C11 11.8954 11.8954 11 13 11H15C16.1046 11 17 11.8954 17 13V15C17 16.1046 16.1046 17 15 17H13C11.8954 17 11 16.1046 11 15V13Z"
                    stroke="#6B7280" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
        </button>
        <!--TODO Zoca nabaviti i ubaciti active ikonice-->
        <button class="border border-gray-200 bg-white hover:bg-gray-50 rounded-md px-3 py-2 mx-2"
                (click)="changedDisplayType.emit(UseCasesViewTypes.list)">
            <svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 1H17M1 5H17M1 9H17M1 13H17" stroke="#D1D5DB" stroke-width="1.5" stroke-linecap="round"
                      stroke-linejoin="round"/>
            </svg>
        </button>
        <!--        <div class="relative inline-block text-left">-->
        <!--            <div>-->
        <!--                <button class="border border-gray-200 bg-white hover:bg-gray-50 rounded-md px-3 py-2">-->
        <!--                    <div class="flex items-center">-->
        <!--                        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">-->
        <!--                            <path d="M1 1.6665H9.66667M1 4.33317H7M1 6.99984H5M7.66667 6.99984L10.3333 4.33317M10.3333 4.33317L13 6.99984M10.3333 4.33317V12.3332" stroke="#4B5563" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>-->
        <!--                        </svg>-->
        <!--                        <span class="text-sm text-gray-500 ml-2">All Chronological</span>-->
        <!--                    </div>-->
        <!--                </button>-->
        <!--            </div>-->


        <!--            <div class="origin-top-right absolute z-10 right-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none" role="menu" aria-orientation="vertical" aria-labelledby="menu-button" tabindex="-1">-->
        <!--                <div class="py-1" role="none">-->
        <!--                    &lt;!&ndash; Active: "bg-gray-100 text-gray-900", Not Active: "text-gray-700" &ndash;&gt;-->
        <!--                    <a href="#" class="text-gray-700 group flex items-center px-4 py-2 text-sm" role="menuitem" tabindex="-1" id="menu-item-0">-->
        <!--                        Option 1-->
        <!--                    </a>-->
        <!--                    <a href="#" class="text-gray-700 group flex items-center px-4 py-2 text-sm" role="menuitem" tabindex="-1" id="menu-item-1">-->
        <!--                        Option 2-->
        <!--                    </a>-->
        <!--                </div>-->
        <!--            </div>-->
        <!--        </div>-->
    </div>
</div>
