import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';
import {INPUT_DEBOUNCE_TIME} from '../../constants/general-variables';
import {Subject} from 'rxjs';

@Component({
    selector: 'app-ppg-search-input',
    templateUrl: './ppg-search-input.component.html'
})
export class PpgSearchInputComponent implements OnInit {

    @Input() searchQuery = '';
    searchSubject = new Subject<string>();
    @Output() searchChanged = new EventEmitter<string>();

    constructor() {
    }

    ngOnInit(): void {
        this.debounceSearch();
    }

    debounceSearch(): void {
        this.searchSubject.pipe(
            debounceTime(INPUT_DEBOUNCE_TIME),
            distinctUntilChanged())
            .subscribe(value => {
                this.searchQuery = value;
                this.searchChanged.emit(this.searchQuery);
            });
    }

}
