import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {OnboardingSection} from './onboard/onboarding-section';

@Component({
    selector: 'app-onboarding-wrapper',
    templateUrl: './onboarding-wrapper.component.html'
})
export class OnboardingWrapperComponent implements OnInit {

    sections: Array<OnboardingSection> = [
        new OnboardingSection(true, '../../../assets/images/icons/usecase-color.svg', 'Create Use case', 'Enter use case main details usch as name in order to group your documents, data.'),
        new OnboardingSection(true, '../../../assets/images/icons/source-color.svg', 'Add Source', 'Upload or link your custom data'),
        new OnboardingSection(false, '../../../assets/images/icons/usecase-color.svg', 'Add destination', 'Using your favorite tools and integrations')
    ];
    activeSectionIndex = 0;
    activeSection: OnboardingSection | undefined | null = this.sections[this.activeSectionIndex];
    @Output() finishedOnboarding = new EventEmitter<boolean>()

    constructor() {
    }

    ngOnInit(): void {
    }

    onFinishedOnboarding(): void {
        this.activeSection = null;
        this.finishedOnboarding.emit(true);
    }

    changeToNextSection(): void {
        this.activeSectionIndex++;
        (this.activeSectionIndex > (this.sections.length - 1)) ?
            this.activeSection = null :
            this.activeSection = this.sections[this.activeSectionIndex];
    }

}
