<app-add-source-manual-modal-second-step
        *ngIf="addSourceManualClass?.activeStep === addSourceManualClass?.possibleSteps?.secondStep"
        [addSourceClass]="addSourceManualClass"
        (modalClosed)="modalClosed.emit(true)"
        (stepChanged)="onStepChanged($event)">
</app-add-source-manual-modal-second-step>
<app-add-source-manual-modal-third-step
        *ngIf="addSourceManualClass?.activeStep === addSourceManualClass?.possibleSteps?.thirdStep"
        (sourceAdded)="sourcesAdded.emit($event)"
        [addSourceClass]="addSourceManualClass"
        (modalClosed)="modalClosed.emit(true)"
        (stepChanged)="onStepChanged($event)">
</app-add-source-manual-modal-third-step>
