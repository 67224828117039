<div class="flex flex-col w-full overflow-x-auto">
    <app-label-search [searchAndFilter]="searchAndFilter"
                                        (searchAndFilterChange)="searchLabel($event)"
                                        (openCreateModal)="createModal = true">
    </app-label-search>
    <div class="flex flex-col w-full px-6 py-4">
        <div class="flex flex-col">
            <div class="-my-2 sm:-mx-6 lg:-mx-6">
                <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-6">
                    <div class="shadow border-b border-gray-200 sm:rounded-lg">
                        <table class="min-w-full divide-y divide-gray-200">
                            <thead class="bg-gray-50">
                            <tr>
                                <th scope="col"
                                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 tracking-wider">
                                    Label
                                </th>
                                <th scope="col"
                                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 tracking-wider">
                                    Aliases
                                </th>
                                <th scope="col"
                                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 tracking-wider">
                                    Category
                                </th>
                                <th scope="col"
                                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 tracking-wider">
                                    Actions
                                </th>
                            </tr>
                            </thead>
                            <tbody class="bg-white divide-y divide-gray-200">
                            <tr *ngFor="let label of labels">
                                <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                    {{label.label}}
                                </td>
                                <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500"
                                    style="text-transform: capitalize">
                                    <span *ngFor="let alias of label.aliases" class="bg-gray-200 p-1 rounded-xl">
                                        {{ alias.alias }}
                                    </span>
                                </td>
                                <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                    <span *ngFor="let category of label.categories" class="bg-gray-200 p-1 rounded-xl">
                                        {{ category.category }}
                                    </span>
                                </td>
                                <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                    <div class="flex items-center">
                                        <a class="text-indigo-600 hover:text-indigo-900 cursor-pointer"
                                           (click)="editLabel(label)">Edit</a>
                                    </div>
                                </td>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <!-- Pagination -->
        <div class="mt-4">
            <div class="flex w-full justify-between items-center border-t border-gray-200 pt-2">
                <app-pagination-data-details [pagination]="pagination">
                </app-pagination-data-details>
                <app-ppg-pagination [currentPage]="pagination?.currentPage"
                                    [pagId]="'js-integration-pag'"
                                    [perPage]="pagination?.perPage"
                                    [totalItems]="pagination?.totalItems"
                                    (pageChanged)="onPageChanged($event)">
                </app-ppg-pagination>
            </div>
        </div>
        <!-- End Pagination -->
    </div>
</div>

<app-ppg-slide-modal-wrapper [showModal]="editModal">
    *ngIf="editModal"
    <app-view-single-label-modal class="fixed inset-y-0 right-0 pl-10 max-w-full flex sm:pl-16" modal-body @slide
        *ngIf="editModal"
        [label]="label"
        (modalClosed)="resetEditModal()"
        (updateLabel)="updateLabelModal()">
    </app-view-single-label-modal>
</app-ppg-slide-modal-wrapper>

<app-ppg-slide-modal-wrapper [showModal]="createModal">
    *ngIf="createModal"
    <app-create-label-modal class="fixed inset-y-0 right-0 pl-10 max-w-full flex sm:pl-16" modal-body @slide
        *ngIf="createModal"
        (modalClosed)="resetCreateModal()"
        (createLabel)="createLabelModal()">
    </app-create-label-modal>
</app-ppg-slide-modal-wrapper>
