<header class="w-full bg-white border-b border-gray-200 pb-2">

    <div
        class="flex space-y-2 flex-col md:flex-row w-full justify-between items-center md:px-6 md:py-4 xs:px-2 xs:py-1">
        <h1 class="text-2xl text-gray-900 leading-7 font-bold">{{useCase?.name}}</h1>
        <div class="flex xs:w-full md:w-auto md:space-x-2 xs:space-x-1">
            <button type="button" class="p-2 rounded-md text-sm font-medium text-gray-500"
                *ngFor="let tab of useCaseTabsClass?.tabs" [ngClass]="{'text-gray-700': useCaseTabsClass?.activeTab === tab,'bg-gray-200': useCaseTabsClass?.activeTab === tab,
                    'cursor-default': tab === useCaseTabsEnums.documents}" (click)="changeTab(tab)">{{tab}}
            </button>
        </div>
        <button type="button" (click)="emitAddSource()" *ngIf="useCaseTabsClass?.activeTab === useCaseTabsEnums.sources"
            class="w-40 inline-flex items-center px-6 py-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500">
            <svg xmlns="http://www.w3.org/2000/svg" class="-ml-0.5 mr-2 h-4 w-4" viewBox="0 0 20 20"
                fill="currentColor">
                <path fill-rule="evenodd"
                    d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z"
                    clip-rule="evenodd" />
            </svg>
            Add Source
        </button>
        <button type="button" (click)="addNewDestination.emit(true)"
            *ngIf="useCaseTabsClass?.activeTab === useCaseTabsEnums.destinations"
            class="w-40 inline-flex items-center px-3 py-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500">
            <svg xmlns="http://www.w3.org/2000/svg" class="-ml-0.5 mr-2 h-4 w-4" viewBox="0 0 20 20"
                fill="currentColor">
                <path fill-rule="evenodd"
                    d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z"
                    clip-rule="evenodd" />
            </svg>
            Add Destination
        </button>
        <div class="w-40"
            *ngIf="useCaseTabsClass?.activeTab !== useCaseTabsEnums.destinations && useCaseTabsClass?.activeTab !== useCaseTabsEnums.sources"
        ></div>
    </div>

</header>
