<div class="flex justify-center items-center bg-gray-700">
    <div class="relative w-full flex justify-center">
        <button #toggleButton class="flex p-2 h-11 justify-center items-center text-gray-400 space-x-2"
                (click)="toggleDropdown()">
            Usecases / &nbsp;
            <span class="text-gray-50">{{useCase?.name}}</span>
            <svg width="9" height="10" viewBox="0 0 9 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M7.125 3.875L4.5 6.5L1.875 3.875" stroke="white" stroke-width="1.2" stroke-linecap="round"
                      stroke-linejoin="round"/>
            </svg>
        </button>
        <div *ngIf="showDropdown" #menu
             class="top-8 left-1/2 transform w-5/6 md:max-w-2xl sm:max-w-full -translate-x-1/2 absolute z-20 right-0 mt-2 rounded-md shadow-lg bg-white"
             role="menu">
            <div class="flex flex-col w-full">
                <div class="relative flex items-stretch flex-grow px-2 py-2">
                    <div class="absolute inset-y-0 left-4 flex items-center pointer-events-none">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                             xmlns="http://www.w3.org/2000/svg">
                            <path d="M11 20C15.9706 20 20 15.9706 20 11C20 6.02944 15.9706 2 11 2C6.02944 2 2 6.02944 2 11C2 15.9706 6.02944 20 11 20Z"
                                  stroke="#292D32" stroke-width="1.5" stroke-linecap="round"
                                  stroke-linejoin="round"/>
                            <path d="M18.9304 20.6898C19.4604 22.2898 20.6704 22.4498 21.6004 21.0498C22.4504 19.7698 21.8904 18.7198 20.3504 18.7198C19.2104 18.7098 18.5704 19.5998 18.9304 20.6898Z"
                                  stroke="#292D32" stroke-width="1.5" stroke-linecap="round"
                                  stroke-linejoin="round"/>
                        </svg>
                    </div>
                    <app-ppg-search-input [searchQuery]="searchQuery"
                                          (searchChanged)="searchUseCases($event)">
                    </app-ppg-search-input>
                </div>
            </div>
            <div class="border-t border-gray-200"></div>
            <div class="flex flex-col md:flex-row">
                <!-- Recent Searches -->
                <div class="p-2 md:flex flex-col md:w-1/2 space-y-2 hidden">
                    <h5 class="uppercase flex items-center space-x-2 text-gray-300 text-sm mb-2">
                        <svg class="mr-1" width="16" height="16" viewBox="0 0 16 16" fill="none"
                             xmlns="http://www.w3.org/2000/svg">
                            <path d="M14.6673 7.99992C14.6673 11.6799 11.6807 14.6666 8.00065 14.6666C4.32065 14.6666 1.33398 11.6799 1.33398 7.99992C1.33398 4.31992 4.32065 1.33325 8.00065 1.33325C11.6807 1.33325 14.6673 4.31992 14.6673 7.99992Z"
                                  stroke="#D1D5DB" stroke-width="1.5" stroke-linecap="round"
                                  stroke-linejoin="round"/>
                            <path d="M10.4739 10.1202L8.40724 8.88684C8.04724 8.6735 7.75391 8.16017 7.75391 7.74017V5.00684"
                                  stroke="#D1D5DB" stroke-width="1.5" stroke-linecap="round"
                                  stroke-linejoin="round"/>
                        </svg>
                        Recent searches
                    </h5>

                    <a *ngFor="let historySearch of historySearchUseCases"
                       class="text-gray-600 hover:text-gray-900 text-sm leading-4 font-medium py-1"
                       style="cursor:pointer;"
                       (click)="searchQueryByHistory(historySearch.query)">{{historySearch.query}}</a>
                </div>
                <!-- End Recent Searches -->
                <div class="border-r border-gray-200"></div>
                <!-- Recent Searches -->
                <div class="p-2 flex flex-col space-y-2 md:w-1/2 overflow-y-auto max-h-64 w-full"
                     (scroll)="paginateSearchUseCases($event)">

                    <a class="text-gray-600 hover:text-gray-900 text-sm leading-4 font-medium py-1"
                       style="cursor:pointer"
                       *ngFor="let useCase of searchedUseCases?.useCases"
                       (click)="navigateToSingleUseCase(useCase.id)">{{useCase.name}}
                    </a>
                    <p class="text-base text-gray-700 font-medium block text-center my-auto" *ngIf="searchedUseCases?.useCases?.length === 0 || !searchedUseCases">No search results!</p>
                </div>
                <!-- End Recent Searches -->

            </div>
        </div>
    </div>
</div>
