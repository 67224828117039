import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ToasterHelperService } from 'src/app/services/helpers/toaster-helper.service';
import { AuthHttpService } from 'src/app/services/http-services/auth-http.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html'
})
export class RegisterComponent implements OnInit {
  displayStep1 = true;
  displayStep2 = false;
  registerForm!: FormGroup;
  stepOneData: any;
  stepTwoData: any;
  routeSubscription = new Subscription();
  invitationToken = '';
  reqObj = {
    user: {
      first_name: '',
      last_name: '',
      email: '',
      password: '',
      password_confirmation: ''
    },
    invitation_token: ''
  };
  userEmail: any;

  constructor(private router: Router, private authHttpService: AuthHttpService, private route: ActivatedRoute, private toasterService: ToasterHelperService) { }

  ngOnInit(): void {
    this.getParams();
  }

  getParams(): void {
    this.routeSubscription = this.route.paramMap.subscribe(params => {
      this.invitationToken = String(params.get('key'));
      this.userEmail = String(params.get('email'));
      this.removeDataFromLS();
    });
  }

  openRegisterTab(step: string): void {
    if (step === 'displayStep1') {
      this.displayStep1 = true;
      this.displayStep2 = false;
    } else if (step === 'displayStep2') {
      this.displayStep1 = false;
      this.displayStep2 = true;
    }
  }

  // buildForm(): any {
  //   return this.registerForm = this.fb.group({
  //     first_name: ['', Validators.required],
  //     last_name: ['', Validators.required],
  //     email: ['', Validators.required],
  //     password: ['', Validators.required],
  //     password_confirmation: ['', Validators.required],
  //     invitation_token: ['', Validators.required],
  //     name: ''
  //   });
  // }

  secondStep(data: any): void {
    this.displayStep2 = data.step;
    this.displayStep1 = false;

    this.reqObj.user.first_name = data.form.first_name;
    this.reqObj.user.last_name = data.form.last_name;
  }

  thirdStep(data: any): void {
    this.displayStep2 = true;
    this.displayStep1 = false;

    this.reqObj.user.password = data.form.password;
    this.reqObj.user.password_confirmation = data.form.password_confirmation;

    this.submitForm();
  }

  submitForm(): void {
    this.reqObj.invitation_token = this.invitationToken;
    this.reqObj.user.email = this.userEmail;

    this.authHttpService.registerCompanyUser(this.reqObj).subscribe((response: any) => {
      this.toasterService.showSuccess('User successfully registered!');
      this.router.navigateByUrl('/login');
      this.removeDataFromLS();
    }, (error: any) => {
      this.toasterService.showError(error);
    });
  }

  removeDataFromLS(): void {
    localStorage.removeItem('usersInfo');
    localStorage.removeItem('passwordData');
  }
}
