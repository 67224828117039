import {
    Component,
    ElementRef,
    EventEmitter,
    Input, OnDestroy,
    OnInit,
    Output,
    Pipe,
    PipeTransform,
    ViewChild
} from '@angular/core';
import {AddSourceCloud} from '../add-source-cloud';
import {PpgFormGroup} from '../../../../../derived-classes/ppg-form-group';
import {ControlType, InputType, PpgFormControl} from '../../../../../derived-classes/ppg-form-control';
import {SourcesHttpService, SourcesHttpServiceHelper} from '../../../../../services/http-services/sources-http.service';
import {CloudIntegration} from '../cloud-integration';
import {CloudTypes, EditType} from '../../../../../enums/sources-enums';
import {EchoHelperService} from '../../../../../services/helpers/echo-helper.service';
import {Subscription} from 'rxjs';

@Pipe({name: 'values'})
export class ValuesPipe implements PipeTransform {
    transform(value: any): Array<PpgFormControl> {
        return Object.values(value);
    }
}

@Component({
    selector: 'app-source-cloud-form',
    templateUrl: './source-cloud-form.component.html'
})
export class SourceCloudFormComponent implements OnInit, OnDestroy {

    @Input() addSourceClass: AddSourceCloud | null = null;
    @Input() ppgFormGroup: PpgFormGroup | any = new PpgFormGroup({});
    ControlType = ControlType;
    InputType = InputType;
    cloudIntegratedDetails = {
        subscription: new Subscription(),
        successful: false,
        triedConnection: false,
        connecting: false,
        redirecting: false
    };
    newlyCreatedIntegration: CloudIntegration | undefined;
    isCloudEdit = false;
    @Output() cloudIntegrationAdded = new EventEmitter<CloudIntegration>();
    @Output() cloudIntegrationEdited = new EventEmitter<CloudIntegration>();
    @Output() modalClosed = new EventEmitter<boolean>();
    @ViewChild('selectRegion') selectRegionEl: ElementRef | undefined;

    constructor(private sourcesHttpService: SourcesHttpService,
                private sourcesHttpHelperService: SourcesHttpServiceHelper,
                private echoHelperService: EchoHelperService) {
    }

    ngOnInit(): void {
        setTimeout(() => {
            this.predefineValues();
        });
        this.subscribeForCloudIntegration();
        console.log('EEEE": ', this.ppgFormGroup);
    }

    ngOnDestroy(): void {
        this.echoHelperService.disconnectFromCloudChannel();
        this.cloudIntegratedDetails.subscription.unsubscribe();
    }

    subscribeForCloudIntegration(): void {
        this.cloudIntegratedDetails.subscription = this.echoHelperService.cloudIntegratedSubject.subscribe((response: any) => {
            this.cloudIntegratedDetails.connecting = false;
            this.cloudIntegratedDetails.successful = response.active;
            this.echoHelperService.disconnectFromCloudChannel();
            if (this.addSourceClass && this.cloudIntegratedDetails.successful && this.newlyCreatedIntegration) {
                this.cloudIntegratedDetails.redirecting = true;
                setTimeout(() => {
                    if (this.addSourceClass && this.newlyCreatedIntegration) {
                        (this.isCloudEdit) ?
                            this.cloudIntegrationEdited.emit(this.newlyCreatedIntegration) :
                            this.cloudIntegrationAdded.emit(this.newlyCreatedIntegration);
                        this.addSourceClass.cloudIntegration = this.newlyCreatedIntegration;
                        this.cloudIntegratedDetails.redirecting = false;
                        this.addSourceClass.nextStep();
                    }
                }, 1000);
            }
        });
    }

    predefineValues(): void {
        if (this.addSourceClass?.cloudType?.name === CloudTypes.aws &&
            this.addSourceClass?.cloudIntegration.editType !== EditType.password &&
            this.addSourceClass?.cloudIntegration.editType !== EditType.accessKey) {
            if (this.addSourceClass?.cloudIntegration.cloudRegion) {
                const regionIndex = this.ppgFormGroup.controls.region.options.findIndex((x: any) => x.id === this.addSourceClass?.cloudIntegration?.cloudRegion?.id);
                this.ppgFormGroup.controls.region.setValue(this.ppgFormGroup.controls.region.options[regionIndex]);
            } else {
                this.ppgFormGroup.controls.region.setValue(this.sourcesHttpHelperService.returnRegionById(this.addSourceClass?.cloudIntegration.cloudRegionId, this.ppgFormGroup.controls.region.options));
            }
        }
    }

    onSelect(event: any): void {
    }

    saveForm(): void {
        if (this.addSourceClass?.cloudIntegration.id === 0) {
            this.addCloudIntegration();
            this.isCloudEdit = false;
        } else {
            if (this.addSourceClass?.cloudIntegration.editType === EditType.accessKey ||
                this.addSourceClass?.cloudIntegration.editType === EditType.password) {
                this.editCloudIntegrationSecretInformation();
                this.isCloudEdit = true;
            } else {
                this.editCloudIntegration();
                this.isCloudEdit = true;
            }
        }
    }

    editCloudIntegrationSecretInformation(): void {
        if (this.addSourceClass?.cloudType) {
            this.cloudIntegratedDetails.triedConnection = true;
            this.cloudIntegratedDetails.connecting = true;
            this.sourcesHttpService.editCloudIntegrationSecretInformation(this.ppgFormGroup, this.addSourceClass.cloudIntegration, this.addSourceClass.cloudType).then((integration: CloudIntegration) => {
                this.echoHelperService.listenToCloudConnection();
                if (this.addSourceClass) {
                    this.newlyCreatedIntegration = integration;
                }
            });
        }
    }

    editCloudIntegration(): void {
        if (this.addSourceClass?.cloudType) {
            this.cloudIntegratedDetails.triedConnection = true;
            this.cloudIntegratedDetails.connecting = true;
            this.sourcesHttpService.editIntegration(this.ppgFormGroup, this.addSourceClass.cloudIntegration, this.addSourceClass.cloudType).then((integration: CloudIntegration) => {
                this.echoHelperService.listenToCloudConnection();
                if (this.addSourceClass) {
                    this.newlyCreatedIntegration = integration;
                }
            });
        }
    }

    addCloudIntegration(): void {
        if (this.addSourceClass?.cloudType) {
            this.cloudIntegratedDetails.triedConnection = true;
            this.cloudIntegratedDetails.connecting = true;
            this.sourcesHttpService.addIntegration(this.ppgFormGroup, this.addSourceClass.cloudType).then((integration: CloudIntegration) => {
                this.echoHelperService.listenToCloudConnection();
                this.newlyCreatedIntegration = integration;
            }, error => {
                this.cloudIntegratedDetails.connecting = false;
            });
        }
    }

}
