<!--TODO Zoca srediti stranicu, mora scroll da ima da bi se videla paginacija, ili da se smanji content stranice-->
<app-use-case-all *ngIf="activeView === UseCaseDataViewTypes.all"
                  (changedView)="changedViewToSingle($event)"
                  (changedQueryView)="changedQueryViewToSingle($event)"
                  [useCaseId]="useCaseId">
</app-use-case-all>
<app-use-case-single *ngIf="activeView === UseCaseDataViewTypes.single"
                     [activeDataBody]="activeDataBody"
                     [useCaseId]="useCaseId"
                     [singleUseCaseData]="singleUseCaseData"
                     [useCaseData]="useCaseData"
                     (changedView)="activeView = $event; changedView.emit(activeView)">
</app-use-case-single>
<app-use-case-query-single *ngIf="activeView === UseCaseDataViewTypes.singleQuery"
                     [activeDataBody]="activeQueryBody"
                     [useCaseId]="useCaseId"
                     [singleUseCaseQuery]="singleUseCaseQuery"
                     [useCaseData]="useCaseData"
                     (changedView)="activeView = $event; changedView.emit(activeView)">
</app-use-case-query-single>
