<div class="grid grid-cols-12">
    <div class="col-span-12">
        <div class="bg-gray-500 h-8 flex justify-end items-center px-2">
            <div class="close bg-white h-6 w-6 rounded flex justify-center items-center cursor-pointer"
                 (click)="changedView.emit(UseCaseDataViewTypes.all)">
                <img src="../../../assets/images/icons/add.svg" alt="">
            </div>
        </div>
    </div>
</div>
<div class="grid grid-cols-12 bg-gray-700 h-20 flex justify-between items-center px-2">
    <div class="col-span-6">
        <span class="text-gray-400 text-xs font-medium">{{dataDocument?.name}}</span>
    </div>
    <div class="col-span-6" *ngIf="!waitingImageResponse">
        <div class="flex justify-center items-center">
            <span *ngIf="activeDocImgPage && ((activeDocImgPage!.index + 1) > 1)"
                  class="cursor-pointer h-8 w-10 text-white font-medium flex justify-center items-center"
                  (click)="changeDocImage(false)"><</span>
            <input
                class="text-white text-xs h-8 w-16 bg-gray-800 flex justify-center items-center rounded font-medium text-center"
                type="text" [mask]="'0000'" (keyup.enter)="changeDocImageBySpecificNumber()"
                [(ngModel)]="activeDocImgPage!.page_number"/>
            <span class="font-medium text-xs text-white mx-4">/</span>
            <span class="font-medium text-xs text-white">{{documentImages.length}}</span>
            <span class="cursor-pointer h-8 w-10 text-white font-medium flex justify-center items-center"
                  (click)="changeDocImage(true)"
                  *ngIf="activeDocImgPage && (activeDocImgPage!.index + 1) < documentImages.length">></span>
        </div>
    </div>
</div>
<div class="grid grid-cols-12 list-document">
    <div class="col-span-5 overflow-scroll bg-white">
        <div>
            <div class="m-4">
                <div class="sm:hidden">
                    <label for="tabs" class="sr-only">{{activeDataBodyTypeView}}</label>

                    <select id="tabs" name="tabs" [(ngModel)]="useCaseDataForView"
                            class="block w-full focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md">
                        <option [ngValue]="[singleUseCaseData]"
                                (click)="fillUseCaseDataForView([singleUseCaseData]);activeDataBodyTypeView = UseCaseBodyDataViewTypes.current">
                            Current Page
                        </option>
                        <option [ngValue]="useCaseData"
                                (click)="activeDataBodyTypeView = UseCaseBodyDataViewTypes.all">
                            All
                        </option>
                    </select>
                </div>
                <div class="hidden sm:block">
                    <nav class="flex justify-center items-center space-x-4" aria-label="Tabs">
                        <a (click)="fillUseCaseDataForView([singleUseCaseData]);activeDataBodyTypeView = UseCaseBodyDataViewTypes.current"
                           [ngClass]="{'text-gray-800': activeDataBodyTypeView === UseCaseBodyDataViewTypes.current,
                            'bg-gray-200': activeDataBodyTypeView === UseCaseBodyDataViewTypes.current}"
                           class="w-1/3 text-center text-gray-500 px-3 py-2 font-medium text-sm rounded-md hover:text-gray-800 cursor-pointer">
                            Current Page
                        </a>
                        <a (click)="activeDataBodyTypeView = UseCaseBodyDataViewTypes.all"
                           [ngClass]="{'text-gray-800': activeDataBodyTypeView === UseCaseBodyDataViewTypes.all,
                            'bg-gray-200': activeDataBodyTypeView === UseCaseBodyDataViewTypes.all}"
                           class="w-1/3 text-center text-gray-500 px-3 py-2 font-medium text-sm rounded-md hover:text-gray-800 cursor-pointer"
                           aria-current="page">
                            All
                        </a>
                    </nav>
                </div>
            </div>
        </div>
        <div class="flex flex-col px-6">
            <div *ngIf="activeDataBodyTypeView === UseCaseBodyDataViewTypes.current">
                <div class="bg-gray-100 border border-gray-200 mb-10 rounded-md"
                     *ngFor="let useCaseSingleData of useCaseDataForView" @fadingIn>
                    <div class="flex justify-between items-center p-2 cursor-pointer"
                         (click)="useCaseSingleData.collapsed = !useCaseSingleData.collapsed">
                        <span class="text-gray-800 font-medium text-xs">{{useCaseSingleData?.body?.length}}
                            RECORDS</span>
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 transform transition"
                             [class]="!useCaseSingleData.collapsed ? '-rotate-90' : 'rotate-0'" fill="none"
                             viewBox="0 0 24 24"
                             stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7"/>
                        </svg>
                    </div>
                    <div *ngIf="!useCaseSingleData.collapsed">
                        <app-use-case-single-body-data *ngIf="useCaseSingleData.body"
                                                       [useCaseSingleData]="useCaseSingleData"
                                                       (clickedDataBody)="changeDataBody($event, useCaseSingleData)">
                        </app-use-case-single-body-data>
                    </div>
                </div>
            </div>
            <div class="bg-gray-100 border border-gray-200 mb-10 rounded-md"
                 *ngIf="activeDataBodyTypeView === UseCaseBodyDataViewTypes.all">
                <app-use-case-all-body-data-with-pagination *ngIf="useCaseId"
                                                            [useCaseId]="useCaseId"
                                                            [searchAndFilter]="searchAndFilter"
                                                            (changedView)="changeDataBody($event.singleDataBody, $event.dataBody)">
                </app-use-case-all-body-data-with-pagination>
            </div>
        </div>
    </div>
    <!--     Preview document       -->
    <div class="col-span-6 overflow-scroll bg-gray-400 flex justify-center items-center py-2 px-4"
         *ngIf="documentImage && !waitingImageResponse">
        <app-ppg-canvas style="width: 100%;height: 100%;" [imageSrc]="documentImage"
                        [singleUseCaseDataBody]="activeDataBody">
        </app-ppg-canvas>
    </div>
</div>
