export const EnLang = {name: 'en', value: 'English'};
export const FrLang = {name: 'fr', value: 'French'};
export const ItLang = {name: 'it', value: 'Italian'};
export const DeLang = {name: 'de', value: 'German'};
export const DefaultLang = EnLang;
export const AvailableLanguages = [EnLang, FrLang, ItLang, DeLang];

export const FileExtensions = {
    csv: {name: 'csv', imagePath: '../../../assets/images/icons/CSV_Icon.svg'},
    xlsx: {name: 'xlsx', imagePath: '../../../assets/images/icons/XLSX_Icon.svg'},
    pdf: {name: 'pdf', imagePath: '../../../assets/images/icons/PDF_Icon.svg'},
    text: {name: 'text', imagePath: '../../../assets/images/icons/TEXT_Icon.svg'},
    json: {name: 'json', imagePath: '../../../assets/images/icons/JSON_Icon.svg'},

};
export const INPUT_DEBOUNCE_TIME = 1000;
export const PAGINATION_POSSIBLE_NUMBERS = [10, 20, 30];
export const modalSliderTime = 500;
export const onNewItemColorAnimationTime = 1500;
export const fadeInAnimation = 300;
