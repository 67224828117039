import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    Renderer2,
    ViewChild
} from '@angular/core';
import {SourceSyncsHttpService} from '../../../services/http-services/source-syncs-http.service';
import {SourceSync, SourceSyncResponse} from '../../../interfaces/source-syncs-interfaces';
import {Source} from '../../../interfaces/sources-interfaces';
import {GeneralHelperService} from '../../../services/helpers/general-helper.service';
import * as moment from 'moment';
import {SlideInOutAnimation} from '../../../animations/slide-modal-animation';
import {PositionChangeAnimation} from '../../../animations/position-change-animation';
import {modalSliderTime} from '../../../constants/general-variables';
import {EchoHelperService} from '../../../services/helpers/echo-helper.service';
import {Subscription} from 'rxjs';
import {ElementAppearsAnimation} from '../../../animations/element-appears-animation';
import {FadeInAnimation} from '../../../animations/fade-in-animation';

@Component({
    selector: 'app-syncs-modal',
    templateUrl: './syncs-modal.component.html',
    animations: [SlideInOutAnimation, PositionChangeAnimation, ElementAppearsAnimation, FadeInAnimation]
})
export class SyncsModalComponent implements OnInit, OnDestroy {

    @Input() source: Source | null = null;
    syncSources: SourceSyncResponse | undefined;
    moment = moment;
    animationFinished = true;
    syncOpened: { sourceSync: SourceSync | null, show: boolean } = {sourceSync: null, show: false};
    sourceSyncedSubscription = new Subscription();
    isAnimationDisabled = true;
    requestedSyncing = false;
    opened = false;
    @ViewChild('modal') modal: ElementRef | undefined;
    @Output() modalClosed = new EventEmitter<boolean>();
    @ViewChild('syncSourcesRef') syncSourcesRef: ElementRef | undefined;

    constructor(private sourceSyncsHttp: SourceSyncsHttpService,
                private generalHelperService: GeneralHelperService,
                private renderer: Renderer2,
                private echoHelperService: EchoHelperService) {
    }

    ngOnDestroy(): void {
        this.sourceSyncedSubscription.unsubscribe();
        this.echoHelperService.disconnectFromSourceSyncingChannel();
    }

    ngOnInit(): void {
        this.getSourceSyncs();
        this.subscribeToSourceSynced();
        this.opened = true;
        setTimeout(() => {
            this.listenToClickType();
        });
    }

    listenToClickType(): void {
        this.renderer.listen('window', 'click', (e: Event) => {
            if (this.generalHelperService.checkIfClickedOutsideOfTheElement(undefined, this.modal, e)) {
                if (this.opened) {
                    this.modalClosed.emit(true);
                    this.opened = false;
                }
            }
        });
    }

    subscribeToSourceSynced(): void {
        this.sourceSyncedSubscription = this.echoHelperService.sourceSyncedSubject.subscribe((sourceSync: SourceSync) => {
            console.log(this.syncSourcesRef);
            sourceSync.sync_date = this.generalHelperService.formatTimestampToMomentDateAndTime(Number(sourceSync.sync_date));
            if (this.syncSourcesRef) {
                this.syncSourcesRef.nativeElement.scrollTo(0, 0);
                this.syncSources?.data.unshift(sourceSync);
                this.requestedSyncing = false;
            }
        });
    }

    openSync(sync: SourceSync): void {
        if (!this.syncOpened.show) {
            this.animationFinished = false;
        }
        this.syncOpened = {sourceSync: sync, show: true};
        setTimeout(() => {
            this.animationFinished = true;
        }, modalSliderTime);
    }

    getSourceSyncs(): void {
        if (this.source) {
            this.sourceSyncsHttp.getSourceSyncs(this.source.id).then((sourceSyncs: SourceSyncResponse) => {
                this.syncSources = sourceSyncs;
                console.log('Source syncs: ', sourceSyncs);
                setTimeout(() => {
                    this.isAnimationDisabled = false;
                });
            });
        }
    }

    paginateSourceSyncs(scrollEvent: any): void {
        if (this.generalHelperService.checkIfScrolledToBottom(scrollEvent)) {
            if (this.syncSources?.pagination?.nextPageUrl && this.source) {
                this.sourceSyncsHttp.getSourceSyncs(this.source.id, this.syncSources?.pagination?.nextPageUrl).then((syncs: SourceSyncResponse) => {
                    if (this.syncSources) {
                        this.syncSources.pagination = syncs.pagination;
                        this.syncSources.data = this.syncSources.data.concat(syncs.data);
                    }
                });
            }
        }
    }

    syncDataForSource(): void {
        if (this.source) {
            this.requestedSyncing = true;
            this.echoHelperService.disconnectFromSourceSyncingChannel();
            this.sourceSyncsHttp.syncDataForSource(this.source.cloud_integration_id, this.source.id).then((response: any) => {
                this.echoHelperService.listenToSourceSynced();
            });
        }
    }

    rerunSyncDataForSource(): void {
        if (this.source) {
            this.requestedSyncing = true;
            this.echoHelperService.disconnectFromSourceSyncingChannel();
            this.sourceSyncsHttp.rerunSyncDataForSource(this.source.cloud_integration_id, this.source.id).then((response: any) => {
                this.echoHelperService.listenToSourceSynced();
                console.log('Response: ', response);
            });
        }
    }


}
