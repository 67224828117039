import {UseCaseTabs} from '../../enums/general-enums';

export class UseCaseTabsClass {

    private _tabs: Array<string> = [
        UseCaseTabs.sources,
        UseCaseTabs.destinations,
        UseCaseTabs.documents,
        UseCaseTabs.data
    ];
    private _activeTab: string = UseCaseTabs.sources;

    constructor() {
    }

    get tabs(): Array<string> {
        return this._tabs;
    }

    set tabs(value: Array<string>) {
        this._tabs = value;
    }

    get activeTab(): string {
        return this._activeTab;
    }

    set activeTab(value: string) {
        this._activeTab = value;
    }
}
