// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    API_ENDPOINT: 'http://papergin-app.test/',
    API_ENDPOINT_ROUTE_KEY: 'wM3Mw1ygSufJu8NJdOBq2PhlyZnTAcSRxOuiRZ7TFE0zOvMKql',
    API_ENDPOINT_PAYLOAD_KEY: 'nycRYT67GL406rbOcS3Cd4ewrdM3RZJrSDAR97qF0s6puRBJu3',
    API_ENDPOINT_MS_UC: 'http://papergin-use-cases.test/',
    API_ENDPOINT_MS_UC_ROUTE_KEY: 'h4j2wwUCQ2XOalmwxUbuHZA9hStfbNOB4Sa6ltiuCwTSYAxCBn',
    API_ENDPOINT_MS_UC_PAYLOAD_KEY: '8mGMV544ArJsWfQWKPCD82qmGEtFQXxjwLCGuf2mloCtIWbCEv',
    API_ENDPOINT_DESTINATION: 'http://papergin-destinations.test/',
    PUSHER_KEY: 'ba1b62e447404f2973b1',
    WEBSOCKET_HOST: 'papergin-websockets.test',
    WEBSOCKET_API_ENDPOINT: 'http://papergin-websockets.test'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
