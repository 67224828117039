import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {DataBody, UseCaseData} from '../../../../../interfaces/use-case-data-interfaces';
import {UseCaseDataViewTypes} from '../../../../../enums/use-cases-enums';
import {FadeInAnimation} from '../../../../../animations/fade-in-animation';

@Component({
    selector: 'app-use-case-single-body-data',
    templateUrl: './use-case-single-body-data.component.html',
    animations: [FadeInAnimation]
})
export class UseCaseSingleBodyDataComponent implements OnInit {

    @Input() useCaseSingleData: UseCaseData | undefined;
    @Output() clickedDataBody = new EventEmitter<DataBody | undefined>();
    UseCaseDataViewTypes = UseCaseDataViewTypes;

    constructor() {
    }

    ngOnInit(): void {
    }

}
