<div class="flex space-x-4">
    <div class="max-w-lg w-full w-96 flex flex-col min-w-3/5 rounded-md p-8 bg-white" id="onboardOne">
        <div class="flex items-center justify-center w-12 h-12 bg-orange-400 rounded-md">
            <img [src]="section?.icon" alt="icon">
        </div>
        <h1 class="text-4xl leading-10 font-medium my-4 break-all">{{section?.title}}</h1>
        <div class="text-base leading-6 font-normal mb-8 flex-grow">
            {{section?.subtitle}}
        </div>
        <div class="flex space-x-4">
            <button class="rounded p-3 transition duration-150 ease-in-out flex-grow" (click)="finishedOnboarding.emit(true)">Finish</button>
            <button class="bg-gray-900 hover:bg-gray-700 text-white rounded p-3 duration-150 ease-in-out flex-grow" *ngIf="section?.haveNextButton" (click)="nextSection.emit(true)">Next</button>
        </div>
    </div>
</div>
