import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {
    DataDocument, DataDocumentResponse,
    SearchAndFilterUseCaseData, SearchDataDocumentReq, SearchTypeParams,
    UseCaseData,
    UseCaseDataBodyEmitter,
    UseCaseDataResponse
} from '../../../../interfaces/use-case-data-interfaces';
import {SearchDataType, UseCaseDataViewTypes} from '../../../../enums/use-cases-enums';
import {UseCaseDataHttpService} from '../../../../services/http-services/use-case-data-http.service';
import {FadeInAnimation} from '../../../../animations/fade-in-animation';

@Component({
    selector: 'app-use-case-all-body-data-with-pagination',
    templateUrl: './use-case-all-body-data-with-pagination.component.html',
    animations: [FadeInAnimation]
})
export class UseCaseAllBodyDataWithPaginationComponent implements OnInit, OnChanges, OnDestroy {

    @Input() searchAndFilter: SearchAndFilterUseCaseData = {
        query: '',
        source: null,
        syncSource: null,
    };
    @Input() useCaseId = 0;
    useCaseData: UseCaseDataResponse | undefined;
    UseCaseDataViewTypes = UseCaseDataViewTypes;
    documents: DataDocumentResponse | undefined;
    @Output() changedView = new EventEmitter<UseCaseDataBodyEmitter>();

    constructor(private useCaseDataHttp: UseCaseDataHttpService) {
    }

    ngOnDestroy(): void {
    }

    ngOnInit(): void {
    }

    ngOnChanges(changes: SimpleChanges): void {
        if ((changes.searchAndFilter && changes.searchAndFilter.currentValue) ||
            ((changes.useCaseId || changes.useCaseId === 0) && (changes.useCaseId.currentValue || changes.useCaseId.currentValue === 0))) {
            this.searchUseCaseDataDocuments(1);
        }
    }

    searchUseCaseData(pageNum: number, documentId: number, document: DataDocument): void {
        if (document.isCollapsed) {
            const searchTypeParams: SearchTypeParams = {
                searchDataType: SearchDataType.document,
                searchDataValue: documentId
            };
            this.useCaseDataHttp.searchUseCaseData('', pageNum, searchTypeParams).then((response: UseCaseDataResponse) => {
                document.dataDocumentWithPag = response;
            });
        }
    }

    searchUseCaseDataDocuments(pageNum: number): void {
        const searchDataDocumentReq: SearchDataDocumentReq = {
            query: this.searchAndFilter.query,
            use_case_id: this.useCaseId,
            source_id: this.searchAndFilter.source?.id,
            sync_source_id: this.searchAndFilter.syncSource?.id
        };
        this.useCaseDataHttp.searchUseCaseDataDocuments(searchDataDocumentReq, pageNum, this.useCaseId).then((response: DataDocumentResponse) => {
            this.documents = response;
        });
    }

    showData(documents: DataDocumentResponse | undefined): boolean | undefined {
        return documents && documents.data && typeof documents.pagination.totalItems !== 'undefined' && documents.pagination.totalItems > 0;
    }

    showUseCasePag(useCases: UseCaseDataResponse | undefined): boolean | undefined {
        return useCases && useCases.useCaseData && typeof useCases.pagination.totalItems !== 'undefined' && useCases.pagination.totalItems > 0;
    }

    emitUseCaseData(useCaseSingleData: UseCaseData, $event: any): void {
        this.changedView.emit({
            dataBody: useCaseSingleData,
            viewType: UseCaseDataViewTypes.single,
            singleDataBody: $event,
            useCaseData: this.useCaseData
        });
    }
}
