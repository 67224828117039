import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {PAGES_AND_PATHS} from '../../constants/pages-and-paths';
import {UserProfileComponent} from '../user-profile/user-profile.component';
import {AuthGuardService} from '../../guards/auth-guard.service';
import {DestinationsComponent} from './destinations.component';

const routes: Routes = [
  {
    path: PAGES_AND_PATHS.destinations.pageInRouting,
    data: { data: PAGES_AND_PATHS.destinations },
    component: DestinationsComponent,
    canActivate: [AuthGuardService],
    loadChildren: () => import('./destinations.module').then(m => m.DestinationsModule)
  }, ];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DestinationsRoutingModule { }
