export enum LocalStorageProperties {
  token = 'ppg-token',
  user = 'ppg-user',
  companyId = 'ppg-company-id',
  userRole = 'user-role',
  ppgPagination = 'ppg-pagination'
}

export enum Navigation {
  userProfile = 'userProfile',
  sources = 'sources',
  useCases = 'use-cases',
  manage = 'manage'
}

export enum UseCaseTabs {
  sources = 'Sources',
  destinations = 'Destinations',
  documents = 'Documents',
  data = 'Data'
}

export enum Users {
  superAdmin = 'super-admin',
  companyAdmin = 'company-admin',
  companyMember = 'company-member'
}

export enum NgMasks {
  ipMask = '00.000.000.00'
}
