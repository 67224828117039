import { Component, OnInit } from '@angular/core';
import { LocalStorageProperties, Users } from 'src/app/enums/general-enums';
import { ToasterHelperService } from 'src/app/services/helpers/toaster-helper.service';
import { AuthHttpService } from 'src/app/services/http-services/auth-http.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { InputErrorMessages } from '../../enums/sources-enums';
import { AuthenticationHelperService } from '../../services/helpers/authentication-helper.service';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html'
})
export class UserProfileComponent implements OnInit {

  loggedUser: any = localStorage.getItem(LocalStorageProperties.userRole);
  userInfo: FormGroup = new FormGroup({});
  userPassword: FormGroup = new FormGroup({
    oldPassword: new FormControl('', Validators.required),
    newPassword: new FormControl('', Validators.required),
    confirmNewPassword: new FormControl('', Validators.required)
  });
  InputErrorMessages = InputErrorMessages;
  submitted = false;
  oldPasswordNotCorrect = false;
  UsersRoles = Users;

  constructor(private authHttpService: AuthHttpService,
    private toasterService: ToasterHelperService,
    private authHelper: AuthenticationHelperService) {
  }

  ngOnInit(): void {
    this.configureFormGroup();
  }

  configureFormGroup(): void {
    this.loggedUser = this.authHelper.getUserData;
    this.userInfo = new FormGroup({
      firstName: new FormControl(this.loggedUser.first_name, Validators.required),
      lastName: new FormControl(this.loggedUser.last_name, Validators.required),
      email: new FormControl(this.loggedUser.email, Validators.required),
    });
  }

  editUser(): void {
  }

  updateUser(): void {
    const reqObj = {
      email: this.userInfo.controls.email.value,
      first_name: this.userInfo.controls.firstName.value,
      last_name: this.userInfo.controls.lastName.value
    };
    this.authHttpService.updateProfileInfo(reqObj).subscribe((response: any) => {
      this.toasterService.showSuccess('Information successfully updated!');
      const userData = this.authHelper.getUserData;
      userData.first_name = reqObj.first_name;
      userData.last_name = reqObj.last_name;
      userData.email = reqObj.email;
      this.authHelper.setUserData(userData);
    }, (error: any) => {
      this.toasterService.showError(error);
    });
  }

  arePasswordSame(pass1: string, pass2: string): boolean {
    return pass1 === pass2;
  }

  updatePassword(): void {
    this.submitted = true;
    const reqObj = {
      old_password: this.userPassword.controls.oldPassword.value,
      password: this.userPassword.controls.newPassword.value,
      password_confirmation: this.userPassword.controls.confirmNewPassword.value
    };
    this.authHttpService.changePassword(reqObj).subscribe((response: any) => {
      this.toasterService.showSuccess('Password successfully changed!');
    }, (error: any) => {
      console.log(error);
      if (error.error.message.old_password) {
        this.oldPasswordNotCorrect = true;
      }
      this.toasterService.showError(error);
    });
  }

  updateCompanyName(): void {
    this.authHttpService.updateCompanyNameAdmin({ name: this.loggedUser.company_admin_companies[0].name }).subscribe((response: any) => {
      this.toasterService.showSuccess('Successfully updated information');
      this.authHelper.setUserData(this.loggedUser);
    });
  }

}
